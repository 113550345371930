import autoBind from 'react-autobind';
import ElectronIPCPacket from './Electron-IPCPacket';
//
export default class ElectronIPC {
  constructor() {
    autoBind(this);
    this.com = window.ipcRenderer;
    this.comFS = window.ipcFS;
    this.didReceiveMessage = null;
    this.didReceiveReply = null;
    this._listenToIPC();
  }
  isAvailable() { return !!this.com; }
  sendMessage(request, sync) {
    if (this.isAvailable()) {
      console.log('[ElectronIPC] - sending message', request);
      if (sync) this.com.sendSync(request.getChannel(), request.encodedMessage());
      else this.com.send(request.getChannel(), request.encodedMessage());
    }
  }
  getFSStream(filepath) {
    console.debug('[ElectronIPC] - creating FS stream', filepath);
    if (this.comFS) return this.comFS.createReadStream(filepath);
    return null;
  }
  /* private */
  _listenToIPC() {
    if (this.isAvailable()) {
      console.debug('[ElectronIPC] - ElectronIPC is available, listening to RPCs');
      this.com.on(ElectronIPC.ElectronIPCChannels.MAIN, this.handleCOMMessage);
      this.com.on(ElectronIPC.ElectronIPCChannels.MAIN_REPLY, this.handleCOMReply);
    } else console.log('[ElectronIPC] - ElectronIPC not available', window.ipcRenderer)
  }
  handleCOMMessage(event, arg) {
    console.debug('[ElectronIPC] - msg', event, arg)
    if (this.didReceiveMessage) {
      const packet = new ElectronIPCPacket(ElectronIPC.ElectronIPCChannels.MAIN);
      const err = packet.decodeMessage(arg);
      if (!err) this.didReceiveMessage(packet);
      else console.error(`[ElectronIPC] - Error while decoding message ${err}`);
    }
  }
  handleCOMReply(event, arg) {
    console.debug('[ElectronIPC] - reply', event, arg)
    if (this.didReceiveReply) {
      const packet = new ElectronIPCPacket(ElectronIPC.ElectronIPCChannels.MAIN_REPLY);
      const err = packet.decodeMessage(arg);
      if (!err) this.didReceiveReply(packet);
      else console.error(`[ElectronIPC] - Error while decoding reply-message ${err}`);
    }
  }
}
ElectronIPC.ElectronIPC_DesktopActions = { //we comment some to avoid any exploit under these events handler on auth
  UPLOAD: 'upload_v2',
  //operation in progress
  REQ_DRAG_AVIABILITY: 'request_drag_aviability_v2',
  //Send file deleted, so FE presents a warning to the user
  REQ_FILE_DELETED: 'request_file_deleted_v2',
};
ElectronIPC.ElectronIPC_WebActions = { //we comment some to avoid any exploit under these events handler on auth
  //authorization
  EVENT_SESSION_LOADING: 'event_session_load_started_v2',
  EVENT_SESSION_STARTED: 'event_session_started_v2',
  EVENT_SESSION_ENDED: 'event_session_ended_v2',
  EVENT_SESSION_ERR: 'event_session_error_v2',
  //Upload transaction
  EVENT_UPLOAD_STARTED: 'event_upload_started_v2',
  EVENT_UPLOAD_COMPLETED: 'event_upload_completed_v2',
  EVENT_UPLOAD_FAILED: 'event_upload_failed_v2',
  //Upload file (part of the transaction)
  EVENT_FILE_UPLOADED: 'event_file_uploaded_v2',
};
ElectronIPC.ElectronIPC_WebReplies = { //we comment some to avoid any exploit under these events handler on auth
  //Drag
  RESP_DRAG_AVIABILITY: 'response_drag_aviability_v2',
};
ElectronIPC.ElectronIPCChannels = {
  MAIN: 'asynchronous-message',
  MAIN_REPLY: 'asynchronous-reply',
  MAIN_SYNC: 'synchronous-message',
};
