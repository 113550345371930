import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Row, Col, Typography, Form } from 'antd';
import moment from 'moment';
//
import Globals from '../../../config/Globals';
//
import CustomComponent from '../../../ui-components/CustomComponent';
import CommonCreateDocketForm from '../../commonComponents/DocketCreate/CommonCreateDocketForm';
//
import '../../../assets/stylesheets/CommonCreateDocketController.scss';
//
const FormController = Form.create()(CommonCreateDocketForm);
// Props: app, vaultID, onDocketCreated, cancelText, onCreateDocketCancel, showDocketAttributes
export default class CommonCreateDocket extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false };
  }

  //Actions
  async createDocket(docket) {
    console.log({ docket });
    console.log(docket.attributes);

    this.setState({ isLoading: true });
    if (docket.attributes && Object.keys(docket.attributes).length) {
      docket.attributes = Object.keys(docket.attributes).map((key) => {
        const attribute = this.props.app.sharedCache().getCurrentVaultAttributeByID(key);
        //Proper parse date
        const isDate = attribute.type.toLowerCase() === 'date';
        let value = docket.attributes[key];
        if (isDate && value) value = new Date(moment(docket.attributes[key]).format(Globals.DefaultUIDateTimeFormat)).getTime();
        return { id: key, value: value };
      });
    }
    const response = await this.props.app.api.v2.vaultDocket.createDocket(
      this.props.vaultID || this.props.app.sharedCache().getCurrentVaultID(),
      { name: docket.name, notes: docket.notes || '', tags: docket.tags || [], attributes: docket.attributes || [] }
    );
    this.setState({ isLoading: false });

    console.log(response);

    if (response.statusCode === 200) this.props.onDocketCreated(docket, response.body.docketID);
    else this.props.app.alertController.showAPIErrorAlert(null, response);
  }

  // UI
  render() {
    return (
      <Layout.Content className="CreateDocketController-content commonTabContent">
        {this._renderHeader()}

        <FormController
          handleStateChange={(state) => this.setState(state)}
          app={this.props.app}
          datasource={this}
          wrappedComponentRef={(ref) => {
            this.form = ref;
          }}
          showDocketAttributes={this.props.showDocketAttributes}
          onCreateDocketCancel={this.props.onCreateDocketCancel}
          cancelText={this.props.cancelText}
        />
      </Layout.Content>
    );
  }
  /* private UI */
  _renderHeader() {
    return (
      <Row className="CreateDocketController-header">
        <Col span={24}>
          <Typography.Title level={4}>Create New Docket</Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Text>This docket will be used for uploading the documents.</Typography.Text>
        </Col>
      </Row>
    );
  }
}
