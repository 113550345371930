export default class ElectronIPCStream {}

ElectronIPCStream.getStream = async (file, ipc) => {
  //get stream
  const stream = await ipc.newIPCStream(file.path);
  console.debug('Asking for stream', file.path, stream);
  //stream to blob
  return await ElectronIPCStream.streamToBlob(stream);
}
ElectronIPCStream.streamToBlob = (stream) => {
  return new Promise((resolve, reject) => {
    const chunks = [];
    let set = false;
    const timeout = setTimeout(() => {
      console.debug('Stream FS timed out!');
      reject(new Error('Timeout error while reading file. Restarting Orange Piggy Desktop might solve this issue!'));
    }, 30000);
    //Setup reader
    stream.on('data', chunk => {
      console.debug('receivind data');
      chunks.push(chunk)
      //Setup listeners
      if (!set) {
        set = true;
        stream.once('end', () => {
          console.debug('stream closed');
          const blob = new Blob(chunks);
          clearTimeout(timeout);
          resolve(blob);
        });
      }
    });
    stream.once('error', (e, e1) => {
      console.debug('Stream FS error', e, e1);
      reject(e instanceof Error ? e : new Error(e))
    });
  });
}
