import React from 'react';
import autoBind from 'react-autobind';
//
import { message, notification } from 'antd';
//
import CommonOperationCancelModal from '../../../views/commonComponents/Modals/CommonOperationCancelModal';
import CommonTransferModal from '../../../views/commonComponents/Modals/TransferModals/CommonTransferModal';
import CommonTransferOperationModal from '../../../views/commonComponents/Modals/TransferModals/CommonTransferOperationModal';
import CommonTransferOperationContainer from '../../../views/commonComponents/CommonTransferOperationContainer';
import BasicOperationView from '../BasicOperationView';
//
import Utils from '../../../components/helpers/Utils';
import Globals from "../../../config/Globals";
//
//props are: app, dockets, documents, isMove
export default class TransferOperationView extends BasicOperationView {
  constructor(props) {
    super(props);
    autoBind(this);
    console.log('*-*- building TransferOperationView class'); console.log('*-*-*- dockets ',props.dockets); console.log('*-*-*- props.documents ', props.documents);
    this.state = {
      source: {
        vaultID: props.app.sharedCache().getCurrentVault().id,
        objects: (props.dockets || []).concat(props.documents || []).map((d) => ({
          docketID: d.docketID || d.id || d,
          ...(d.id ? { id: d.id } : {}),
          ...(d.name ? { metadata: d } : {}),
          //ref
          skipping: null, destDocketID: null,
        })),
      },
      destination: {
        vaultID: null, docketID: null,
      },
      //Operation
      transferPhase: null,
      transferType: null,
      dockets: [],
      documents: [],
      nameMatches: [],
      completed: false,
      started: false,
      //UI
      cancelModalVisible: false,
      showConfimationModal: false,
      showProgressContainer: false,
      progressPercentage: 0,
      //View
      isParentVisible: true,
      isVisible: true,
    };
    console.log('*-*-*- state is ', this.state);
    this.connectionManager = this.props.app.operationsController.connectionManager;
    this.totalOperations = 0;
    this.retryInProgress = false;
    this.completionTimeout = null;
    this.queueID = null;
    this.docketCreationNotificationKey = null;
    this.documentsNotificationKey = null;
    this.docketDeletionNotificationKey = null;
  }

  //Public actions
  start() {
    this.transferModal.show(this.props.isMove, this.state.source, this.state.destination);
  }
  async startTransfer() {
    console.log('*-*- will call _prepareOperations');
    this._prepareOperations();
    console.log('*-*- back from _prepareOperations will call _startOperations');
    await this._startOperations();
  }
  cancel(e) {
    if (e) { e.preventDefault(); e.stopPropagation(); }
    if (!this.state.completed) this.setState({ cancelModalVisible: true });
  }

  //Modal actions
  handleShowTransferOperationContainer() {
    this.setState({ showProgressContainer: true, showConfimationModal: false });
  }
  handleHideTransferOperationContainer() {
    this.setState({ showProgressContainer: false, showConfimationModal: true });
  }
  handleModalClose() {
    if (!this.state.started || (this.state.completed && this.state.started)) this.close();
    else if (!this.state.completed) this.cancel();
  }
  handleConfirmOperation(source, destination, removeFromSource, transferType) {
    console.log('*-*- inside handleConfirmOperation '); console.log('source objects is ', source.objects);
    const documents = source.objects.filter(obj => obj.id && !obj.skipping).map(document => ({
      vaultID: source.vaultID,
      docketID: document.docketID,
      documentID: document.id,
      destinationVaultID: destination.vaultID,
      destinationDocketID: document.destDocketID,
      sourceVaultName: source.vaultName,
      destinationVaultName: destination.vaultName,
      sourceDocketName: document.docketName,
      destinationDocketName: document.destDocketName,
      removeFromSource,
      metadata: document.metadata,
    }));
    console.log('*-*-*- documents is now ', documents);
    let dockets = [];
    if (transferType === Globals.Transfer_Type.DOCKET) {
      dockets = source.objects.filter(obj => !obj.id && !obj.skipping).map(docket => ({
        ...docket,
        vaultID: source.vaultID,
        sourceVaultName: source.vaultName,
        destinationVaultID: destination.vaultID,
        destinationVaultName: destination.vaultName,
        removeFromSource,
      }));
    }
    console.log('*-*- inside handleConfirmOperation just before setting state '); console.log('*-*- dockets ', dockets); console.log('*-*- documents ', documents); console.log('*-*- transfertype ', transferType);
    this.setState({ dockets, documents, transferType, showConfimationModal: true }, () => { this.startTransfer(); });
  }
  handleAbortOperation() {
    if (!this._isMounted) return;
    this.setState({ cancelModalVisible: false });
    this.connectionManager.abortQueue(this.queueID);
    this._startCompletionTimeout();
  }
  async handleRetryOperation() {
    const removeStatus = (d) => { if (d.status === 'failed') delete d.status; delete d.err; return d; }
    const dockets = this.state.dockets.map(d => { return removeStatus(d) });
    const documents = this.state.documents.map(d => { return removeStatus(d) });
    this.retryInProgress = true;
    this.setState({ dockets, documents, completed: false });
    this._prepareOperations();
    await this._startOperations();
  }

  get progressPercentage() {
    return (100 * (this.totalOperations - (this.connectionManager.getOperations(this.queueID)?.length || 0))) / this.totalOperations }
  get isVisible() { return (super.isVisible && this.state.started); }
  //UI
  render() {
    return super.render(
      <>
        {this._renderTransferModal()}
        {this._renderCancelModal()}
        {this._renderTransferOperationModal()}
        {this._renderTransferProgressContainer()}
      </>
    );
  }

  /* Private UI */
  _renderTransferProgressContainer() {
    return this.state.showProgressContainer && (
      <CommonTransferOperationContainer
        isMove={this.props.isMove}
        transferPhase={this.state.transferPhase}
        transferType={this.state.transferType}
        progressPercentage={this.progressPercentage}
        dockets={this.state.dockets}
        documents={this.state.documents}
        started={this.state.started}
        completed={this.state.completed}
        onHide={this.handleHideTransferOperationContainer}
        onCancel={this.handleModalClose}
        onRetry={this.handleRetryOperation}
      />
    );
  }

  /* Modals */
  _renderTransferOperationModal() {
    return (
      <CommonTransferOperationModal
        app={this.props.app}
        showConfimationModal={this.state.showConfimationModal}
        title={`${this._transferOperationModalTitle()}`}
        isMove={this.props.isMove}
        transferPhase={this.state.transferPhase}
        transferType={this.state.transferType}
        progressPercentage={this.progressPercentage}
        dockets={this.state.dockets}
        documents={this.state.documents}
        nameMatches={this.state.nameMatches}
        completed={this.state.completed}
        onClose={this.handleModalClose}
        onHide={this.handleShowTransferOperationContainer}
        onRetry={this.handleRetryOperation}
      />
    );
  }
  _renderCancelModal() {
    return (
      <CommonOperationCancelModal isVisible={this.state.cancelModalVisible}
        title={`Cancel ${Utils.capitalizeString(this._isMove())} Operation`}
        subtitle={'Are you sure you want to interrupt the current operation?'}
        onHide={() => { this.setState({ cancelModalVisible: false }); }}
        onCancel={this.handleAbortOperation}/>
    );
  }
  _renderTransferModal() {
    return (
      <CommonTransferModal app={this.props.app} {...Utils.propagateRef(this, 'transferModal')}
        onCancel={this.handleModalClose} onConfirmation={this.handleConfirmOperation}
      />
    );
  }

  /* private */
  // Operations
  _prepareOperations() {
    this.setState({ started: true });
    if (this.queueID) this.connectionManager.abortQueue(this.queueID);
    this.queueID = `transfer_${this._isMove()}_${Date.now()}`;
    console.log('set value to this.queueID ', this.queueID);
    if (this.state.dockets.length) {
      this._enqueueOperations(this.state.dockets, Globals.Transfer_Phase.DOCKETSCREATION, 0);
      this._enqueueOperations(this.state.dockets, Globals.Transfer_Phase.DOCKETSDELETION, 9);
    }
    console.log('will call _enqueueOperations, documents is ', this.state.documents);
    if (this.state.documents.length) this._enqueueOperations(this.state.documents, Globals.Transfer_Phase.DOCUMENTSTRANSFER, 1);
  }
  _enqueueOperations(objects, transferPhase, priority) {
    if (transferPhase === Globals.Transfer_Phase.DOCUMENTSTRANSFER) {
      console.log('*-*- inside _enqueueOperations objects is ', objects);
    }
    for (const object of objects) {
      let operation = null;
      if (!object.status) {
        if (transferPhase === Globals.Transfer_Phase.DOCKETSCREATION) operation = this.props.app.api.newTransferCreateDocketOperation(object.docketID, object.vaultID, object.docketName, object.destinationVaultID);
        if (transferPhase === Globals.Transfer_Phase.DOCKETSDELETION && object.removeFromSource)
          operation = this.props.app.api.newTransferDeleteDocketOperation(object.docketID, object.vaultID);
        if (transferPhase === Globals.Transfer_Phase.DOCUMENTSTRANSFER)
          operation = this.props.app.api.newTransferDocumentOperation(object.vaultID, object.docketID, object.documentID, object.destinationVaultID, object.destinationDocketID, object.removeFromSource);
      }
      if (operation) {
        operation.transferPhase = transferPhase;
        console.log('*-*- will enquue operation ', operation, ' queueID ',this.queueID); console.log(`${transferPhase}-${Date.now()}-${object.docketID || object.documentID}`);console.log('priority ', priority);
        this.connectionManager.enqueue(operation, this.queueID, `${transferPhase}-${Date.now()}-${object.docketID || object.documentID}`, priority);
      }
    }
  }
  async _startOperations() {
    if (!this._isMounted) return;
    this.docketCreationNotificationKey = Date.now() + '';
    this.documentsNotificationKey = Date.now() + '';
    this.docketDeletionNotificationKey = Date.now() + '';
    this.totalOperations = this.connectionManager.getOperations(this.queueID).length;
    await this.connectionManager.runQueue(this.queueID, this._operationDidStart, this._operationDidFinish);
    if (!this._isMounted) return;
    const errorCount = (this.state.documents.concat(this.state.dockets)).filter(d => d.status === 'failed').length;
    if (!this.retryInProgress && errorCount > 0 && errorCount < ((this.state.documents.concat(this.state.dockets)).length / 2)) {
      message.warn('Auto retrying failed transfers!');
      this.handleRetryOperation();
    } else {
      this.setState({ completed: true });
    }
  }
  _operationDidStart(operation) {
    if (!this._isMounted) return;
    const { transferPhase } = operation;
    if (this.state.transferPhase != transferPhase) this.setState({ transferPhase });
    if (transferPhase === Globals.Transfer_Phase.DOCKETSCREATION || transferPhase === Globals.Transfer_Phase.DOCKETSDELETION)
      this._operationUpdateDocket(operation.docket.docketID, 'started');
    if (transferPhase === Globals.Transfer_Phase.DOCUMENTSTRANSFER)
      this._operationUpdateDocument(operation.params.documentID, 'started');
  }
  async _operationDidFinish(operation, resp) {
    if (operation.transferPhase === Globals.Transfer_Phase.DOCKETSCREATION) await this._finishDocketCreation(operation, resp);
    if (operation.transferPhase === Globals.Transfer_Phase.DOCUMENTSTRANSFER) await this._finishDocumentTransfer(operation, resp);
    if (operation.transferPhase === Globals.Transfer_Phase.DOCKETSDELETION) await this._finishDocketDeletion(operation, resp);
  }
  async _finishDocketCreation(operation, resp) {
    const documentsTransfer = this._getOperations(Globals.Transfer_Phase.DOCUMENTSTRANSFER, operation.docket.docketID);
    if (resp.statusCode === 200 || resp.statusCode === 204) {
      this._operationUpdateDocket(operation.docket.docketID, 'finished');
      for (const ops of documentsTransfer) {
        ops.documentObj.destinationDocketID = resp.body.id; ops.params.destinationDocketID = resp.body.id; ops.params.destinationDocketName = resp.body.name;
        Promise.resolve(ops.documentObj.destinationDocketID);
      }
    } else {
      const err = resp.error || resp.body?.err || resp.body?.message;
      this._operationUpdateDocket(operation.docket.docketID, 'failed', err);
      for (const ops of documentsTransfer) ops.cancel();
      const docketsDeletion = this._getOperations(Globals.Transfer_Phase.DOCKETSDELETION, operation.docket.docketID);
      for (const ops of docketsDeletion) ops.cancel();
      if (!operation.cancelled) this._showNotification(Globals.Transfer_Phase.DOCKETSCREATION);
    }
  }
  async _finishDocumentTransfer(operation, resp) {
    const nameMatches = this.state.nameMatches.map(n => n);
    if (resp.statusCode === 200 || resp.statusCode === 204) {
      this._operationUpdateDocument(operation.params.documentID, 'finished');
      if (resp.body?.nameMatchesExistingDoc) nameMatches.push(this.state.documents.find(d => d.documentID === operation.params.documentID).documentID);
    } else {
      const err = resp.error || resp.body?.err || resp.body?.message;
      this._operationUpdateDocument(operation.params.documentID, 'failed', err);
      const docketsDeletion = this._getOperations(Globals.Transfer_Phase.DOCKETSDELETION, operation.params.docketID);
      for (const ops of docketsDeletion) if (!ops.cancelled) ops.cancel();
      if (!operation.cancelled) this._showNotification(Globals.Transfer_Phase.DOCUMENTSTRANSFER);
    }
  }
  async _finishDocketDeletion(operation, resp) {
    if (resp.statusCode === 200 || resp.statusCode === 204) {
      this._operationUpdateDocket(operation.docket.docketID, 'finished');
    } else {
      const err = resp.error || resp.body?.err || resp.body?.message;
      this._operationUpdateDocket(operation.docket.docketID, 'failed', err);
      if (!operation.cancelled) this._showNotification(Globals.Transfer_Phase.DOCKETSDELETION);
    }
  }

  // Helpers
  _startCompletionTimeout() {
    if (this.completionTimeout) return;
    this.completionTimeout = setTimeout(this.close.bind(this), 500);
  }
  _getOperations(transferPhase, opsID) {
    return this.connectionManager.getOperations(this.queueID)
                                 .filter(ops => ops.transferPhase === transferPhase &&
                                        (ops.params?.docketID === opsID || ops.docket?.docketID === opsID));
  }
  _operationUpdateDocket(docketID, status, err) {
    const dockets = this.state.dockets.map(d => d);
    const docket = dockets.find(d => d.docketID === docketID);
    dockets[dockets.findIndex(d => d.docketID === docketID)] = {...docket, status, ...(err && {err})};
    this.setState({ dockets });
  }
  _operationUpdateDocument(documentID, status, err) {
    const documents = this.state.documents.map(d => d);
    const document = documents.find(d => d.documentID === documentID);
    documents[documents.findIndex(d => d.documentID === documentID)] = {...document, status, ...(err && {err})};
    this.setState({ documents });
  }
  _isMove() { return this.props.isMove ? Globals.Transfer_Operation.MOVE : Globals.Transfer_Operation.COPY; }
  // eslint-disable-next-line no-nested-ternary
  _isSingular(length, type) { return type === Globals.Transfer_Type.DOCKET ? length === 1 ? "Docket" : "Dockets" : length === 1 ? "Document" : "Documents"; }
  _transferOperationModalTitle() {
    return this.state.transferType === Globals.Transfer_Type.DOCKET
           ? `${Utils.capitalizeString(this._isMove())} ${this.state.dockets.length} ${this._isSingular(this.state.dockets.length, Globals.Transfer_Type.DOCKET)}`
           : `${Utils.capitalizeString(this._isMove())} ${this.state.documents.length} ${this._isSingular(this.state.documents.length)}`;
  }
  _showNotification(transferPhase) {
    let key; let count; let description;
    switch (transferPhase) {
      case Globals.Transfer_Phase.DOCKETSCREATION:
        key = this.docketCreationNotificationKey;
        count = this.state.dockets.filter(d => d.status === 'failed').length;
        description = `${count} ${this._isSingular(count, Globals.Transfer_Type.DOCKET)} have failed to be created! You will be able to retry them at the end.`;
        break;
      case Globals.Transfer_Phase.DOCUMENTSTRANSFER:
        key = this.documentsNotificationKey;
        count = this.state.documents.filter(d => d.status === 'failed').length;
        description = `${count} ${this._isSingular(count, Globals.Transfer_Type.DOCUMENT)} have failed to be ${this.props.isMove ? 'moved' : 'copied'}! You will be able to retry them at the end.`
        break;
        case Globals.Transfer_Phase.DOCKETSDELETION:
          key = this.docketDeletionNotificationKey;
          count = this.state.dockets.filter(d => d.status === 'failed').length;
          description = `${count} ${this._isSingular(count, Globals.Transfer_Type.DOCKET)} have failed to be deleted! You will be able to retry them at the end.`;
          break;
      default:
        key = ''; count = ''; description = '';
    }
    notification.close(key);
    notification.error({message: `${Utils.capitalizeString(this._isMove())} failed`, key, duration: 0, description});
  }
}
