import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import { Row, Col, Typography, Input, Button, Select, Divider, InputNumber, DatePicker } from 'antd';
//
import Utils from '../../../components/helpers/Utils';
import Globals from '../../../config/Globals';
//
const FieldTypes = {
  GENERAL: 'General',
  DOCKET: 'Docket',
  DOCUMENT: 'Document',
  ATTRS: 'Custom Attributes',
  SPECIAL: 'Special'
};
const InputTypes = {
  INT: 'int',
  FLOAT: 'float',
  STRING: 'string',
  DATE: 'date'
};
//props: quantifier, app
export default class CommonSearchFilterRules extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    const filters = (this.props.parsedQueryParams.filters || []).map(filter => ({
      qualifier: filter.qualifier || Globals.SearchQualifiers.CONTAINS,
      field: filter.field || Globals.SearchFields.NAME,
      value: filter.value?.flat()?.[0],
      value2: filter.value?.flat()?.[1],
    }));

    this.state = {
      filters,
      createRow: {
        qualifier: Globals.SearchQualifiers.CONTAINS,
        field: Globals.SearchFields.NAME,
        value: ''
      }
    };
  }
  hasFilters() { return (this.state.filters.length > 0); }
  getFilters() {
    return this.state.filters.map( filter => {
        const val = (filter.value && moment.isMoment(filter.value) ? moment(filter.value).format(Globals.SearchDateFormat) : filter.value);
        const val2 = (filter.value2 && moment.isMoment(filter.value2) ? moment(filter.value2).format(Globals.SearchDateFormat) : filter.value2);
        return {
          field: filter.field,
          qualifier: filter.qualifier,
          value: (filter.value2 ? [ val, val2 ] : [ val ])
        };
    });
  }

  //Actions
  handleCreateFilterChange(id, value) {
    if (id == 'value' || id == 'value2') {
      this.setState({createRow: {
        ...this.state.createRow,
        [id]: (value && value.target ? value.target.value : value)
      }}, this.props.onChange);
    } else {
      //Set default qualifie when changing field
      let qualifier = this.state.createRow.qualifier;
      if (id == 'field') {
        const type = this._findTypeByField(value);
        qualifier = this._getDefaultQualifierByType(type);
      }
      //
      this.setState({createRow: {
        ...this.state.createRow,
        qualifier,
        [id]: value
      }}, this.props.onChange);
    }
  }
  handleCreateFilter() {
    const isBetween = (this.state.createRow.qualifier == Globals.SearchQualifiers.BETWEEN);
    this.setState({filters: this.state.filters.concat({...this.state.createRow,
      //make this safe check to avoid cleaning value2 when user change the qualifier
      //but make sure we just inject value2 when qualifier is between
      value2: (isBetween ? this.state.createRow.value2 : null)
    })}, this.props.onChange);
  }
  handleRemoveFilter(index) {
    this.state.filters.splice(index,1);
    this.setState({filters: this.state.filters}, this.props.onChange);
  }
  //UI
  render() {
    return (
      <>
        {this._renderFiltersCreateRow()}
        {this._renderFiltersRows()}
      </>
    );
  }
  /* private renders */
  _renderFiltersCreateRow() {
    const allValues = (this.props.quantifier == Globals.SearchQuantifier.ALL);
    const isBetween = (this.state.createRow.qualifier == Globals.SearchQualifiers.BETWEEN);
    const type = this._findTypeByField(this.state.createRow.field);
    //Valid input, if between both must be valid
    const validInput = this._validateByDataType(type, isBetween);
    //existing filter -- same filter is not allowed when using ALL quantifier, just ANY
    const existingFilter = this.state.filters.find( filter => {
      return (filter.qualifier == this.state.createRow.qualifier &&
              filter.field == this.state.createRow.field);
    });
    return (
      <Row className='filtersCreateRow' type='flex' align='middle'>
        <Divider className='filtersItemDivider'/>
        <Col offset={1} xs={24} sm={24} md={3} className='prefixLabelCol'> <Typography.Text>New rule:</Typography.Text> </Col>
        <Col xs={24} sm={24} md={4} className='fieldSelectCol' style={{ marginBottom: 8 }}> {this._renderFieldsSelect()} </Col>
        <Col xs={24} sm={24} md={4} className='qualifierSelectCol' style={{ marginBottom: 8 }}> {this._renderQualifierSelect(type)} </Col>
        <Col xs={24} sm={24} md={4} className='valueInputCol' style={{ marginBottom: 8 }}>{this._renderCreateRowTypedInput(type)}</Col>
        {isBetween &&
          <>
            <Col className='conditionalLabelCol'> <Typography.Text> AND </Typography.Text> </Col>
            <Col className='valueInput2Col'> {this._renderCreateRowTypedInput(type, true)} </Col>
          </>
        }
        <Col className='addButtonCol'>
          <Button onClick={this.handleCreateFilter} type='primary'
                  disabled={!validInput || (existingFilter && allValues)}>Add Rule</Button>
        </Col>
      </Row>
    );
  }
  _renderFiltersRows() {
    return (
      <>
        {this.state.filters.map( (filter, index) => {
          const type = this._findTypeByField(filter.field);
          const first = (index == 0);
          const last = (index == this.state.filters.length-1);
          return (
            <Row key={index} type='flex' className={`filtersItemRow ${(first ? 'first ' : '')}${(last ? 'last' : '')}`} align='middle'>
              <Col offset={1} className='fieldLabelCol'><Typography.Text> {this._textFieldName(filter.field)} </Typography.Text></Col>
              <Col className='qualifierLabelCol'><Typography.Text> {this._textQualifierName(filter.qualifier, false, type)} </Typography.Text></Col>
              <Col className='valueLabelCol'><Typography.Text> {this._textFilterValue(filter.value)} </Typography.Text></Col>
              {filter.value2 &&
              <>
                <Col className='conditionalLabelCol'><Typography.Text> and </Typography.Text></Col>
                <Col className='value2LabelCol'><Typography.Text> {this._textFilterValue(filter.value2)} </Typography.Text></Col>
              </>}
              <Col className='removeButtonCol'>
                <Button shape="circle" icon="close" size={'small'}
                        onClick={this.handleRemoveFilter.bind(this, index)}/>
              </Col>
            </Row>
          );
        })}
      </>
    )
  }
  /* sub renders */
  _renderCreateRowTypedInput(type, second) {
    const bFunc = this.handleCreateFilterChange.bind(this,(second ? 'value2' : 'value'));
    if (type == InputTypes.INT) return <InputNumber step={1} onChange={bFunc}/>;
    else if (type == InputTypes.FLOAT) return <InputNumber step={0.001} onChange={bFunc}/>
    else if (type == InputTypes.DATE) return <DatePicker onChange={bFunc}/>
    return <Input onChange={bFunc} placeholder="Search" />
  }
  _renderFieldsSelect() {
    return (
      <Select value={this.state.createRow.field} style={{width: '100%'}}
              onChange={this.handleCreateFilterChange.bind(this,'field')}>
        {Object.values(FieldTypes).map( (type) => {
          return (
            <Select.OptGroup key={type} label={type}>
              {this._getAvailableFieldsBySection(type).map( (field, index) => {
                  return (<Select.Option key={index} value={field.id}>{this._textFieldName(field.title)}</Select.Option>);
              })}
            </Select.OptGroup>
          );
        })}
      </Select>
    );
  }
  _renderQualifierSelect(type) {
    const allValues = (this.props.quantifier == Globals.SearchQuantifier.ALL);
    return (
      <Select value={this.state.createRow.qualifier} style={{width: '100%'}}
              onChange={this.handleCreateFilterChange.bind(this,'qualifier')}>
        {this._getAvailableQualifiers(type).map( (key, index) => {
            const existingQualifier = this.state.filters.find( filter => {
              return (filter.qualifier == Globals.SearchQualifiers[key] &&
                      filter.field == this.state.createRow.field);
            });
            return (
            <Select.Option key={index} disabled={!!existingQualifier && allValues}
                           value={Globals.SearchQualifiers[key]}>
              {this._textQualifierName(Globals.SearchQualifiers[key], true, type)}
            </Select.Option>);
        })}
      </Select>
    );
  }

  /* UI helpers */
  _textFieldName(value) {
    if (value == Globals.SearchFields.ATTRIBUTES_VAL) return 'Any Attribute';
    else if (value == Globals.SearchFields.FILENAME) return 'Document Name';
    else if (value == Globals.SearchFields.FILETYPE) return 'Document Type';
    else if (value == Globals.SearchFields.FILESIZE) return 'Document Size';
    else if (value == Globals.SearchFields.CREATEDON) return 'Created Date';
    else if (value == Globals.SearchFields.UPDATEDON) return 'Updated Date';
    else if (value == Globals.SearchFields.NUMBEROFFILES) return 'Number of Documents';
    else if (value == Globals.SearchFields.DOCKETSIZE) return 'Docket Size';
    else if (value == Globals.SearchFields.DOCKETNAME) return 'Docket Name';
    else if (value == Globals.SearchFields.ALL) return 'Any Field';
    else {
      const attr = this.props.app.sharedCache().getCurrentVaultAttributeByID(value);
      if (attr) return `Attribute: ${Utils.capitalizeString(attr.label)}`;
    } return Utils.capitalizeString(value);
  }
  _textQualifierName(value, isSelect, type) {
    if (value == Globals.SearchQualifiers.CONTAINS) return (isSelect ? 'Contains' : 'contains');
    else if (value == Globals.SearchQualifiers.LESSTHAN) {
      if (type == InputTypes.DATE) return (isSelect ? 'Is before' : 'is before');
      else return (isSelect ? 'Is smaller than' : 'is smaller than');
    } else if (value == Globals.SearchQualifiers.GREATERTHAN) {
      if (type == InputTypes.DATE) return (isSelect ? 'Is after' : 'is after');
      else return (isSelect ? 'Is larger than' : 'is larger than');
    }  else if (value == Globals.SearchQualifiers.EQUAL) {
      if (type == InputTypes.DATE) return (isSelect ? 'Is' : 'is');
      else return (isSelect ? 'Is' : 'is equal to');
    } else if (value == Globals.SearchQualifiers.NOTEQUAL) {
      if (type == InputTypes.DATE) return (isSelect ? 'Is not' : 'is not');
      else return (isSelect ? 'Is not' : 'is not equal to');
    }
    else if (value == Globals.SearchQualifiers.BETWEEN) return (isSelect ? 'Is between' : 'is between');
    return 'unknown';
  }
  _textFilterValue(value) {
    if (value && moment.isMoment(value)) { return moment(value).format(Globals.SearchDateFormat); }
    return value;
  }
  /* select helpers */
  _getAvailableQualifiers(selectedType) { //available qualifiers based on selected field
      return Object.keys(Globals.SearchQualifiers).filter( key => {
        if (selectedType == InputTypes.FLOAT || selectedType == InputTypes.INT) {
          return (Globals.SearchQualifiers[key] == Globals.SearchQualifiers.LESSTHAN ||
                  Globals.SearchQualifiers[key] == Globals.SearchQualifiers.GREATERTHAN ||
                  Globals.SearchQualifiers[key] == Globals.SearchQualifiers.BETWEEN ||
                  Globals.SearchQualifiers[key] == Globals.SearchQualifiers.EQUAL ||
                  Globals.SearchQualifiers[key] == Globals.SearchQualifiers.NOTEQUAL);
        } else if (selectedType == InputTypes.DATE) {
          return (Globals.SearchQualifiers[key] == Globals.SearchQualifiers.BETWEEN ||
                  Globals.SearchQualifiers[key] == Globals.SearchQualifiers.EQUAL ||
                  Globals.SearchQualifiers[key] == Globals.SearchQualifiers.NOTEQUAL ||
                  Globals.SearchQualifiers[key] == Globals.SearchQualifiers.LESSTHAN ||
                  Globals.SearchQualifiers[key] == Globals.SearchQualifiers.GREATERTHAN);
        } else {
          return (Globals.SearchQualifiers[key] == Globals.SearchQualifiers.CONTAINS ||
                  Globals.SearchQualifiers[key] == Globals.SearchQualifiers.EQUAL ||
                  Globals.SearchQualifiers[key] == Globals.SearchQualifiers.NOTEQUAL);
        }
      });
  }
  _getAvailableFieldsBySection(type) { //available field based on current attrs
    if (type == FieldTypes.ATTRS) {
      return this.props.app.sharedCache()?.getCurrentVaultAttributes()?.map( attr => {
        return { title: attr.label, id: attr.id };
      }).sort( (a,b) => a.title.localeCompare(b.title));
    } else if (type == FieldTypes.GENERAL) {
      return [Globals.SearchFields.NAME, Globals.SearchFields.DESCRIPTION,
              Globals.SearchFields.TAGS,
              Globals.SearchFields.CREATEDON, Globals.SearchFields.UPDATEDON].map( val => {
        return { title: val, id: val };
      }).sort( (a,b) => a.title.localeCompare(b.title));
    } else if (type == FieldTypes.DOCKET) {
      return [Globals.SearchFields.NUMBEROFFILES, Globals.SearchFields.DOCKETSIZE].map( val => {
        return { title: val, id: val };
      }).sort( (a,b) => a.title.localeCompare(b.title));
    } else if (type == FieldTypes.DOCUMENT) {
      return [Globals.SearchFields.DOCKETNAME, Globals.SearchFields.CONTENT,
              Globals.SearchFields.FILENAME, Globals.SearchFields.FILESIZE,
              Globals.SearchFields.FILETYPE].map( val => {
        return { title: val, id: val };
      }).sort( (a,b) => a.title.localeCompare(b.title));
    } else {
      return [Globals.SearchFields.ALL, Globals.SearchFields.ATTRIBUTES_VAL].map( val => {
        return { title: val, id: val };
      }).sort( (a,b) => a.title.localeCompare(b.title));
    }
  }
  _getDefaultQualifierByType(selectedType) {
    if (selectedType == InputTypes.FLOAT || selectedType == InputTypes.INT) {
      return Globals.SearchQualifiers.EQUAL;
    } else if (selectedType == InputTypes.DATE) {
      return Globals.SearchQualifiers.EQUAL;
    } else {
      return Globals.SearchQualifiers.CONTAINS;
    }
  }
  /* helper multi data type */
  _validateByDataType(type, isBetween) {
    const valid = (this.state.createRow.value &&
                  (this.state.createRow.value.length > 0 || this.state.createRow.value > 0) &&
                  (!isBetween || (isBetween && this.state.createRow.value2 && (this.state.createRow.value2.length > 0 || this.state.createRow.value2 > 0))));
    if (type == InputTypes.INT || type == InputTypes.FLOAT) {
      if (valid) {
        // eslint-disable-next-line radix
        return ( isBetween ? !isNaN(parseInt(this.state.createRow.value)) && !isNaN(parseInt(this.state.createRow.value)) : !isNaN(parseInt(this.state.createRow.value)) );
      }
    } return valid;
  }
  _findTypeByField(fieldName) { //get field name and return type (CAs works with ids)
    if (fieldName == Globals.SearchFields.FILESIZE ||
      fieldName == Globals.SearchFields.DOCKETSIZE ||
        fieldName == Globals.SearchFields.NUMBEROFFILES) return InputTypes.INT;
    else if (fieldName == Globals.SearchFields.UPDATEDON ||
             fieldName == Globals.SearchFields.CREATEDON) return InputTypes.DATE;
    else {
      const attr = this.props.app.sharedCache().getCurrentVaultAttributeByID(fieldName);
      if (attr) {
        if (attr.type == Globals.VaultSettings_CustomAttributeTypes.NUMERIC || //compatibility
            attr.type == Globals.VaultSettings_CustomAttributeTypes.INTEGER) return InputTypes.INT;
        else if (attr.type == Globals.VaultSettings_CustomAttributeTypes.FLOAT) return InputTypes.FLOAT;
        else if (attr.type == Globals.VaultSettings_CustomAttributeTypes.DATE) return InputTypes.DATE;
      }
    } return InputTypes.STRING;
  }
}
