/*eslint no-nested-ternary: "off"*/
import React from 'react';
import autoBind from 'react-autobind';
import { Icon, Spin, Drawer } from 'antd';
import { GridLoader } from 'react-spinners';
//
import CustomComponent from '../../../ui-components/CustomComponent';
//
import '../../../assets/stylesheets/CommonFileViewer.scss';
//
export default class CommonFileViewer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, isVisible: false, isDownloading: false, previewUnavailable: false, file: null, fileURL: null };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.isVisible) {
      document.addEventListener('keyup', this.handlePressKey);
    } else {
      document.removeEventListener('keyup', this.handlePressKey);
    }
  }
  //Lifecycle
  componentWillUnmount() {
    super.componentWillUnmount();
    document.removeEventListener('keyup', this.handlePressKey);
  }

  /* Public */
  async show(file) {
    console.log('file object', file);
    // if (!file.previewGenerated) {
    //   this.setState({ isVisible: true, file, previewUnavailable: true });
    //   return;
    // }
    //Continue and get preview signed URL
    this.setState({ isVisible: true, file, isLoading: true });
    const resp = await this.props.app.api.v2.document.signedGetURL(file.vaultID, file.docketID, file.id, true);
    if (resp.statusCode == 200) {
      this.setState({ fileURL: resp.body.fileURL, isLoading: false });
    } else if (resp?.body?.previewNotAvailable) {
      this.setState({ isVisible: true, isLoading: false, previewUnavailable: true });
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while retrieving document preview!', resp);
      this.handleClose();
    }
  }
  async handleDownloadFile() {
    this.setState({ isDownloading: true });
    this.props.app.operationsController.newDocumentDownloadOperationView(this.state.file, () => {
      this.setState({ isDownloading: false });
    });
  }
  handleClose() {
    this.setState({ isLoading: false, isVisible: false, previewUnavailable: false, file: null, fileURL: null });
  }
  handlePressKey(e) {
    if (e.keyCode === 27 || e.key.toLowerCase() == 'escape') this.handleClose();
  }

  // UI
  render() {
    if (!this.state.isVisible) {
      return null;
    }

    return (
      <Drawer visible={this.state.isVisible} closable={false} onClose={this.handleClose} placement="bottom" className="file-viewer-drawer">
        <div className="fileviewer-container">
          <div className="fileviewer-header">
            <span>{this.state.file?.name}</span>
            <div className="actions">
              <button type="button" onClick={this.handleDownloadFile}>
                {this.state.isDownloading ? <Spin /> : <Icon type="download" style={{ fontSize: 22 }} />}
              </button>
              <button type="button" onClick={this.handleClose}>
                <Icon type="close" style={{ fontSize: 22 }} />
              </button>
            </div>
          </div>

          {this.state.isLoading ? (
            <div className="fileviewer-loading">
              <GridLoader sizeUnit="px" size={20} color="#F88B0B" loading={this.state.isLoading} />
            </div>
          ) : (this._isPreviewUnavailable() ? this._renderPreviewNotAvailable() : this._renderPreview())}
        </div>
      </Drawer>
    );
  }

  /* File type checkers */
  _isPreviewUnavailable() { return this.state.previewUnavailable; }

  /* UI private */
  _renderPreviewNotAvailable() {
    return (
      <div className="fileviewer-invalid-type">
        <Icon type="file-exclamation" style={{ fontSize: 66 }} />
        <h1>Preview is not available</h1>
      </div>
    );
  }

  _renderPreview() {
    return (
      <div className="fileviewer-image">
        <img src={this.state.fileURL} alt="alt" />
      </div>
    );
  }
}
