import React from 'react';
import autoBind from 'react-autobind';
//
import { Row, Col, Input } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
//
import '../../../assets/stylesheets/CommonDocketFilterDocumentsBar.scss';

//props are: isLoading, onFilter
export default class CommonDocketFilterDocumentsBar extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.debounce = null;
    this.state = { filterValue: '' };
  }

  //Actions
  reset() { this.setState({ filterValue: '' }); }
  handleFilterChange(filterValue) {
    this.setState({ filterValue });
    this._scheduleFilter(filterValue);
  }

  //UI
  render() {
    return (
      <Row type="flex" className='filterBarRow'>
        <Col className="filterBarInputColumn">
            <Input
              className="filterBarInput"
              placeholder="Filter term"
              disabled={this.state.isLoading}
              value={this.state.filterValue}
              onChange={(e) => this.handleFilterChange(e.target.value)}
              suffix={<FilterOutlined />}
            />
        </Col>
      </Row>
    );
  }

  /* debouncer */
  _scheduleFilter(filterValue) {
    if (this.debounce) clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      if (this.props.onFilter) this.props.onFilter(filterValue);
      this.debounce = null;
    }, 300);
  }
}
