import React, { Component } from 'react';
import { Alert, Button, message, notification } from 'antd';
import autoBind from 'react-autobind';
import { datadogRum } from '@datadog/browser-rum';
//
class AlertController extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  // Actions
  showAPIErrorAlert(title, resp, resp2) {
    console.error(resp, resp2);
    //Try internal API
    let errMessage = this._extractInternalAPIError(resp2);
    if (!errMessage) errMessage = this._extractInternalAPIError(resp);
    //Try AXIOS
    if (!errMessage) errMessage = this._extractAxiosError(resp2);
    if (!errMessage) errMessage = this._extractAxiosError(resp);
    if (!errMessage) errMessage = "Unknown error.\nTry reloading the page, if the error persists, please, contact the support!";
    //Show
    notification.error({ message: !title ? 'Error' : title, description: errMessage, duration: 0 });
    //DataDog
    datadogRum.addError(new Error(errMessage));
  }
  showErrorAlert(title, description) {
    console.error({title, description});
    notification.error({ message: title, description, duration: 0 });
    datadogRum.addError(new Error(description));
  }

  showSuccessAlert(title, description) {
    message.success(title + description, 5);
  }
  showWarningAlert(title, description) {
    notification.warning({ message: title, description: description });
  }

  showQuestionAlert(title, description) {
    return window.confirm(`${title}\n${description}`);
  }

  showPromptAlert(title, description) {
    return window.prompt(`${title}\n${description}`);
  }

  // UI
  render() {
    return (
      <>
        {this.props.fileDeletedWarning && <Alert message={
          <>A file was deleted from your OP folder. Please, <Button type='link' className='desktopDeletionWarningAlertButton' onClick={this.props.onAppReload}>refresh your page</Button> to get the latest files!</>
          } banner/>}
      </>
    );
  }

  /* privates */
  _extractInternalAPIError(resp) {
    if (resp && resp.body && resp.body.err) return resp.body.err;
    //40x codes
    else if (resp && resp.body && resp.body.message) return  resp.body.message;
    //40x codes with error (auth)
    else if (resp && resp.error && resp.error.response && resp.error.response.data && resp.error.response.data.err) return resp.error.response.data.err;
    //20x codes with error (auth)
    else if (resp && resp.error && resp.error.message) return resp.error.message;
    return null;
  }
  _extractAxiosError(resp) {
    if (resp && resp.error && resp.error.response) return resp.error.response;
    else if (resp && resp.error && resp.error.request) return resp.error.request;
    else if (resp && resp.error && resp.error.target) return resp.error.target.error;
    else if (resp && resp.error && resp.error.message) return resp.error.message;
    else if (resp && resp.error) return resp.error;
    else return resp;
  }
}
export default AlertController;
