import React from 'react';
import autoBind from 'react-autobind';
import { Typography, Divider, Row, Col, Button, Spin, Progress, Icon } from 'antd';
//
import Utils from '../../components/helpers/Utils';
import Globals from "../../config/Globals";
//
import '../../assets/stylesheets/CommonTransferOperationContainer.scss';
//
//props: isMove, transferType, progressPercentage, dockets, documents, started,
//       completed, onHide, onCancel, onRetry
export default class CommonTransferOperationContainer extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  //actions
  handleHide() { this.props.onHide(); }
  handleCancel() { this.props.onCancel(); }
  handleRetry() { this.props.onRetry(); }

  //UI
  render() {
    return (
      <div className='transferOperationContainer'>
        <Row type="flex" className="transferContainerRow" justify="start" align="middle" onClick={() => this.handleHide()}>
          {this._renderTransferIconAndProgress()}
          {this._renderTitle()}
          {this._renderProgressCircle()}
        </Row>
        {(this._failed() || !this.props.completed) && this._renderButtons()}
      </div>
    );
  }

  // private UI
  _renderTransferIconAndProgress() {
    return (
      <Col span={3}>
        <Row justify="center" type="flex">
          <Icon className="upload-icon" type="snippets" />
        </Row>
        {!this.props.completed && (
        <Row justify="center" type="flex">
          <Col>
            <Typography.Text className="transferSizeLabel">{this._source().filter(d => !d.status || d.status !== 'started').length}</Typography.Text>
          </Col>
        </Row>
        )}
      </Col>
    )
  }
  _renderTitle() {
    return (
      <Col span={17} offset={1}>
        <Row type="flex" align="middle">
          <Col>
            <Typography.Text className="transferTitleMain">{Utils.capitalizeString(this._isMove())}:</Typography.Text>
            <Typography.Text className="transferTitleSub">{this._source().length} {this._isSingular(this._source().length, this.props.transferType)}</Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography.Text className="transferProgressLabel">{this._renderProgressLabel()}</Typography.Text>
          </Col>
        </Row>
      </Col>
    )
  }
  _renderProgressLabel() {
    if (!this.props.started) {
      return 'Waiting on queue...';
    } else {
      if (this.props.progressPercentage === 0 && !this.props.completed) return 'Starting transfer...';
      else if (this.props.completed) {
        return (
          <Typography.Text>
            {this._failed() && <> <Icon type="warning" theme="filled" />  {this._isMove()} completed with {this._failed().length} <strong>failed</strong> of {this._source().length}!</>}
            {!this._failed() && <> <Icon type="check-circle" theme="filled" /> {this._isMove()} completed ({this._source().length}) </>}
          </Typography.Text>
        );
      } else {
        // eslint-disable-next-line no-nested-ternary
        const title = (this.props.transferPhase === Globals.Transfer_Phase.DOCKETSCREATION) ? `Please wait while the dockets are being created.` :
        (this.props.transferPhase === Globals.Transfer_Phase.DOCKETSDELETION) ? `Please wait while the dockets are being deleted.` :
        `Please wait while the documents are being ${this.props.isMove ? 'moved' : 'copied'}.`;
        return (<Typography.Text>{title}</Typography.Text>);
      }
    }
  }
  _renderProgressCircle() {
    return (
      <Col justify="center" align="middle" span={3}>
      {(this.props.progressPercentage === 0 && !this.props.completed) ?
        <Spin className="uploadProgressCircle" indicator={<Icon type="loading" style={{ fontSize: 34 }} spin />} />
      :
        <Progress type="circle" className="transferProgressCircle"
          strokeColor={(this._failed() ? '#E00000' : { '0%': '#FECA9C', '100%': '#F88B0B' })}
          /* eslint-disable-next-line no-nested-ternary*/
          status={((this.props.completed && this._failed()) ? 'exception' : !this.props.completed ? 'active' : 'success')}
          percent={Number(parseFloat(this.props.progressPercentage).toFixed(0))} width={40}
        />
      }
      </Col>
    )
  }
  _renderButtons() {
    return (
      <Row type="flex" justify="center" className="transferProgressButtonsRow">
        <Divider />
        {!this.props.completed && <Button type="secondary" size="small" onClick={this.handleCancel}>Cancel Upload</Button>}
        {(this.props.completed && this._failed()) && <Button size="small" type="primary" onClick={this.handleRetry}>Retry All</Button>}
      </Row>
    )
  }

  // Helpers
  _source() { return this.props.transferType === Globals.Transfer_Type.DOCKET ? this.props.dockets : this.props.documents }
  _failed() { return !![...new Set((this.props.documents.concat(this.props.dockets)).filter(d => d.status === 'failed').map(d => d.documentID || d.docketID))].length }
  _isMove() { return this.props.isMove ? Globals.Transfer_Operation.MOVE : Globals.Transfer_Operation.COPY; }
  // eslint-disable-next-line no-nested-ternary
  _isSingular(length, type) { return type === Globals.Transfer_Type.DOCKET ? length === 1 ? "Docket" : "Dockets" : length === 1 ? "Document" : "Documents"; }
}
