import docket from "../../assets/images/file-icons/icons8-folder.svg";
import document from "../../assets/images/file-icons/icons8-document.svg";
//
import zipIcon from "../../assets/images/file-icons/icons8-zip.svg";
import csvIcon from "../../assets/images/file-icons/icons8-csv.svg";
import txtIcon from "../../assets/images/file-icons/icons8-txt.svg";
import imageIcon from "../../assets/images/file-icons/icons8-image-file.svg";
import pdfIcon from "../../assets/images/file-icons/icons8-pdf.svg";
import excelIcon from "../../assets/images/file-icons/icons8-microsoft-excel.svg";
import increaseIcon from "../../assets/images/file-icons/icons8-increase.svg";
import powerpointIcon from "../../assets/images/file-icons/icons8-microsoft-powerpoint.svg";
import accessIcon from "../../assets/images/file-icons/icons8-microsoft-access.svg";
import wordIcon from "../../assets/images/file-icons/icons8-microsoft-word.svg";
import timelineIcon from "../../assets/images/file-icons/icons8-timeline-64.png";
import securedLetterIcon from "../../assets/images/file-icons/icons8-secured-letter.svg";
import audioIcon from "../../assets/images/file-icons/icons8-audio-file.svg";
//
export const docketIcon = docket;
export const documentIcon = document;
//
export const iconsList = [
  {
    contentType: "application/octet-stream",
    icon: documentIcon,
    extensions: [
      {
        name: "docx",
        icon: wordIcon,
      },
      {
        name: "doc",
        icon: wordIcon,
      },
      {
        name: "ppt",
        icon: powerpointIcon,
      },
      {
        name: "svg",
        icon: imageIcon,
      },
      {
        name: "msg",
        icon: securedLetterIcon,
      },
      {
        name: "pdf",
        icon: pdfIcon,
      },
      {
        name: "xls",
        icon: excelIcon,
      },
      {
        name: "zip",
        icon: zipIcon,
      },
      {
        name: "csv",
        icon: csvIcon,
      },
      {
        name: "txt",
        icon: txtIcon,
      },
      {
        name: "heic",
        icon: imageIcon,
      },
      {
        name: "mp3",
        icon: audioIcon,
      },
      {
        name: "wav",
        icon: audioIcon,
      },
      {
        name: "wv",
        icon: audioIcon,
      },
      {
        name: "flac",
        icon: audioIcon,
      },
      {
        name: "m4a",
        icon: audioIcon,
      },
      {
        name: "wma",
        icon: audioIcon,
      },
      {
        name: "aa",
        icon: audioIcon,
      },
      {
        name: "aac",
        icon: audioIcon,
      },
      {
        name: "aax",
        icon: audioIcon,
      },
      {
        name: "aiff",
        icon: audioIcon,
      },
      {
        name: "alac",
        icon: audioIcon,
      },
    ],
  },
  {
    contentType: "application/pdf",
    icon: pdfIcon,
    extensions: [],
  },
  {
    contentType:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    icon: excelIcon,
    extensions: [],
  },
  {
    contentType:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    icon: excelIcon,
    extensions: [],
  },
  {
    contentType: "application/vnd.ms-excel",
    icon: excelIcon,
    extensions: [],
  },
  {
    contentType: "application/vnd.ms-visio.drawing",
    icon: increaseIcon,
    extensions: [],
  },
  {
    contentType: "application/vnd.visio",
    icon: increaseIcon,
    extensions: [],
  },
  {
    contentType:
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    icon: powerpointIcon,
    extensions: [],
  },
  {
    contentType:
      "application/vnd.openxmlformats-officedocument.presentationml.template",
    icon: powerpointIcon,
    extensions: [],
  },
  {
    contentType: "application/vnd.ms-powerpoint",
    icon: powerpointIcon,
    extensions: [],
  },
  {
    contentType: "application/msaccess",
    icon: accessIcon,
    extensions: [],
  },
  {
    contentType: "application/x-msaccess",
    icon: accessIcon,
    extensions: [],
  },
  {
    contentType: "application/x-rar",
    icon: zipIcon,
    extensions: [],
  },
  {
    contentType: "application/x-rar-compressed",
    icon: zipIcon,
    extensions: [],
  },
  {
    contentType:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    icon: wordIcon,
    extensions: [],
  },
  {
    contentType:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    icon: wordIcon,
    extensions: [],
  },
  {
    contentType: "application/msword",
    icon: wordIcon,
    extensions: [],
  },
  {
    contentType: "application/vnd.ms-project",
    icon: documentIcon,
    extensions: [
      {
        name: "mpp",
        icon: timelineIcon,
      },
    ],
  },
  {
    contentType: "application/x-tika-msoffice",
    icon: documentIcon,
    extensions: [
      {
        name: "mpp",
        icon: timelineIcon,
      },
    ],
  },
  {
    contentType: "application/vnd.ms-outlook",
    icon: securedLetterIcon,
    extensions: [],
  },
  {
    contentType: "image/gif",
    icon: imageIcon,
    extensions: [],
  },
  {
    contentType: "image/jpeg",
    icon: imageIcon,
    extensions: [],
  },
  {
    contentType: "image/png",
    icon: imageIcon,
    extensions: [],
  },
  {
    contentType: "audio/*",
    icon: audioIcon,
    extensions: [],
  },
];
