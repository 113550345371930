import config from '../../config/config';
import ReactGA from 'react-ga';
//
export default class Analytics {
  constructor() {
    if (config.ApplicationGoogleAnalyticsKey) ReactGA.initialize(config.ApplicationGoogleAnalyticsKey);
    this.logPageView(window.location.pathname); //Log initial loading
  }
  setUserID(userID) { ReactGA.set({ userId: userID }); }
  // Alternatively, you may set the user ID via the `set` method.
  logPageView(path) {
    if (config.ApplicationGoogleAnalyticsKey) {
      if (path.substr(0, 4) == 'http' && path.indexOf(window.location.origin) != 0) this.logEvent('Redirect', `Redirect - ${window.location.hostname}`);
      else if (path.substr(0, 4) == 'http') ReactGA.pageview(path.replace(window.location.origin, ''));
      else ReactGA.pageview(path);
    }
  }
  // Value	Notes
  //args.category	String. Required. A top level category for these events.E.g. 'User', 'Navigation', 'App Editing', etc.
  //args.action	String.Required.A description of the behaviour.E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
  //args.label	String.Optional.More precise labelling of the related action.E.g.alongside the 'Added a component' action, we could add the name of a component as the label.E.g. 'Survey', 'Heading', 'Button', etc.
  //args.value	Int.Optional.A means of recording a numerical value against an event.E.g.a rating, a score, etc.
  logEvent(category, action, additionalData) {
    if (config.ApplicationGoogleAnalyticsKey) ReactGA.event({ category, action, ...additionalData || {} });
  }
}
