import { PageHeader, Layout, Tooltip, Table, Row, Col, Typography, Icon, Popconfirm, Form, Button, Input, Empty } from 'antd';
import React from 'react';
import autoBind from 'react-autobind';
//
import CustomComponent from '../../ui-components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import Utils from '../../components/helpers/Utils';
import NavigationControllerView from '../../ui-components/NavigationControllerView';
//
import '../../assets/stylesheets/AdminUsersInvitesView.scss';
//
import _CommonEditUserModal from '../commonComponents/Modals/CommonEditUserModal';
import _CommonInviteModal from '../commonComponents/Modals/CommonInviteModal';
//
import config from '../../config/config';
import Globals from '../../config/Globals';
//
const CommonEditUserModal = Form.create()(_CommonEditUserModal);
const CommonInviteModal = Form.create()(_CommonInviteModal);

/* eslint max-classes-per-file: ["error", 2] */
class _AdminUsersInvitesView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      firstLoad: true,
      users: [],
      filteredUsers: [],
      invitations: [],
      selectedUser: {},
      selectedInvitation: {},
      showUpdateUser: false,
      showCreateInvitation: false,
      confirmLoading: false,
      sortedInfo: null,
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    if (this.state.firstLoad) this.fetchData();
    //Listen to drag stuff
    this.props.app.appDidReceiveDragFile = this.handleDragFile;
    this.props.app.appAcceptsDrag = this.handleAcceptDrag;
  }

  // API
  async fetchData() {
    this.startLoading();
    if (!this._isMounted) return;

    const response = await Promise.all([
      this.props.app.api.v2.vaultUser.getUsers(this.props.app.sharedCache().getCurrentVaultID()),
      this.props.app.api.v2.vaultInvitation.getInvitations(this.props.app.sharedCache().getCurrentVaultID()),
    ]);

    if (response[0].statusCode !== 200) this.props.app.alertController.showAPIErrorAlert('Error!', response[0]);
    if (response[1].statusCode !== 200) this.props.app.alertController.showAPIErrorAlert('Error!', response[1]);

    if (response[0].statusCode === 200 && response[1].statusCode === 200) {
      response.forEach((resp) => {
        if (resp.body.users) {
          const ownerID = this.props.app.sharedCache().getCurrentVaultOwnerID();

          const users = resp.body.users
            .map((elem) => ({
              ...elem,
              rowKey: elem.id,
            }))
            .filter((elem) => elem.id != ownerID);

          this.setState({
            users,
            filteredUsers: users,
          });
        }

        if (resp.body.invitations) {
          this.setState({
            invitations: resp.body.invitations.map((elem) => {
              elem.rowKey = elem.id;

              return elem;
            }),
          });
        }
      });
    } else this.props.app.alertController.showAPIErrorAlert('Error!', response[0], response[1]);
    this.stopLoading();
  }

  //Table
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }
  //Cancel modal
  handleCancelModalUser() {
    this.setState({
      showUpdateUser: false,
      selectedUser: {},
    });
  }
  handleCancelModalInvitation() {
    this.setState({
      showCreateInvitation: false,
      selectedInvitation: {},
    });
  }
  //Search input
  handleSearchInputChange(e) {
    const { value } = e.target;

    let { filteredUsers, users } = this.state;

    if (!value) {
      filteredUsers = this.state.users;
    }

    filteredUsers = users.filter(
      (item) => item.firstName.includes(value) || item.lastName.includes(value) || item.email.includes(value)
    );

    this.setState({ filteredUsers });
  }
  //Drag
  handleDragFile(files) {
    //forward -- more logic may be required on the future
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.homepage, null, null, null, { files });
  }
  handleBack() {
    if (window.history.length > 1) {
      window.history.back()
    } else {
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.homepage);
    }
  }
  handleAcceptDrag() { return true; }
  // UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const isLoading = this.state.isLoading || this.props.app.isAuthenticating;
    const account = this.props.app.sharedCache().getCurrentVaultAccount();
    const isAccountWriteLocked = this.props.app.sharedCache().isCurrentVaultAccountWriteLocked();
    return (
      <Layout.Content className="pageContent">
        <PageHeader onBack={this.handleBack} title="Users / Invitations" />
        <CommonLoadingView isLoading={isLoading} />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div className="tableContainer">
              <Table
                rowKey="id"
                title={() => (
                  <Row gutter={[16, 16]}>
                    <div className="usersTableHeader">
                      <div className="usersTableHeaderTitle">
                        <Typography.Title level={4}>USERS</Typography.Title>
                        {isAccountWriteLocked ?
                          <Tooltip placement='topRight' title={Globals.AccountLockActionErrorMessage}> <Button type="primary" disabled> INVITE USER </Button> </Tooltip>
                          : <Button type="primary"
                            onClick={() => {
                              this.invitationForm.setFieldsValue({ createInvitation: {} });
                              this.setState({ showCreateInvitation: true });
                            }}> INVITE USER </Button>}

                      </div>
                      <div className="usersTableHeaderSearch">
                        <Input.Search
                          placeholder="Search for a user by name, or e-mail"
                          onChange={this.handleSearchInputChange}
                        />
                      </div>
                    </div>
                  </Row>
                )}
                onChange={this.handleFilterChange}
                dataSource={this.state.filteredUsers}
                scroll={{ x: true }}
                columns={[
                  {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    render: (text, record) => `${record.firstName} ${record.lastName}`,
                    sorter: (a, b) =>
                      (a.firstName ? `${a.firstName} ${a.lastName}` : '').localeCompare(
                        b.firstName ? `${b.firstName} ${b.lastName}` : ''
                      ),
                    sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                  },
                  {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                    sorter: (a, b) => (a.email || '').localeCompare(b.email || ''),
                    sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order,
                  },
                  {
                    title: 'Role',
                    dataIndex: 'role',
                    key: 'role',
                    sorter: (a, b) => (a.role || '').localeCompare(b.role || ''),
                    sortOrder: sortedInfo.columnKey === 'role' && sortedInfo.order,
                  },
                  {
                    title: 'Enrollment date',
                    dataIndex: 'createdOn',
                    key: 'createdOn',
                    render: (date) => Utils.getDateAndTimeOnUIFormat(new Date(date)),
                    sorter: (a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime(),
                    sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
                  },
                  {
                    title: 'Actions',
                    align: 'right',
                    render: (text, record) => {
                      if (record.role === Globals.Vault_Roles.OWNER) {
                        return null;
                      }

                      return (
                        <Row type="flex" justify="end" align="middle" gutter={20}>
                          {(!!account.limits.userRolesEnabled) && (
                            <Col>
                              {isAccountWriteLocked ?
                                <Tooltip placement='topRight' title={Globals.AccountLockActionErrorMessage}>
                                  <Button type='link' disabled style={{ width: '50px' }}> <Icon type="edit" /> &nbsp; <Typography.Text>Edit</Typography.Text> </Button>
                                </Tooltip>
                                : <Button type="link" onClick={() => {
                                  this.userForm.setFieldsValue({ userUpdate: record });
                                  this.setState({ selectedUser: record, showUpdateUser: true });
                                }}>
                                <Icon type="edit" /> &nbsp; <Typography.Text>Edit</Typography.Text>
                              </Button>}
                            </Col>
                          )}
                          <Col>
                            {isAccountWriteLocked ?
                              <Tooltip placement='topRight' title={Globals.AccountLockActionErrorMessage}>
                                <Button type='link' disabled style={{width: '30px'}}>
                                  <Icon type="delete" /> &nbsp; <Typography.Text>Remove</Typography.Text>
                                </Button>
                              </Tooltip>
                              : <Popconfirm title="Are you sure you want to remove this user?" onConfirm={() => this.deleteUser(record.rowKey)}>
                                <Button type="link">
                                  <Icon type="delete" /> &nbsp; <Typography.Text>Remove</Typography.Text>
                                </Button>
                              </Popconfirm>}
                          </Col>
                        </Row>
                      );
                    },
                  },
                ]}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div className="tableContainer">
              <Table
                rowKey="createdOn"
                title={() => <Typography.Title level={4}>PENDING INVITATIONS</Typography.Title>}
                scroll={{ x: true }}
                columns={[
                  {
                    title: 'Email',
                    width: 100,
                    dataIndex: 'userEmail',
                    key: 'userEmail',
                  },
                  {
                    title: 'Role',
                    width: 100,
                    dataIndex: 'role',
                    key: 'role',
                  },
                  {
                    title: 'Actions',
                    width: 20,
                    align: 'right',
                    render: (text, record) => {
                      return isAccountWriteLocked
                        ? (
                          <Tooltip placement='topRight' title={Globals.AccountLockActionErrorMessage}>
                              <Button type='link' disabled style={{ width: '30px' }}>
                                <Icon type="delete" /> &nbsp; <Typography.Text>Remove</Typography.Text>
                              </Button>
                            </Tooltip>
                        ) : (
                          <Popconfirm title="Are you sure you want to remove this invitation?" onConfirm={() => this.deleteInvitation(record.userEmail)}>
                            <Button type="link">
                              <Icon type="delete" /> &nbsp; <Typography.Text>Remove</Typography.Text>
                            </Button>
                          </Popconfirm>
                        )
                    },
                  },
                ]}
                locale={{
                  emptyText: (
                    <Empty description="No pending invitation" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )
                }}
                dataSource={this.state.invitations}
              />
            </div>
          </Col>
        </Row>

        {this.state.selectedUser && !isAccountWriteLocked && (
          <CommonEditUserModal
            handleStateChange={(state) => this.setState(state)}
            app={this.props.app}
            datasource={this}
            {...Utils.propagateRef(this, 'userForm')}
            wrappedComponentRef={(ref) => {
              this.form = ref;
            }}
          />
        )}

        <CommonInviteModal
          handleStateChange={(state) => this.setState(state)}
          app={this.props.app}
          datasource={this}
          {...Utils.propagateRef(this, 'invitationForm')}
          wrappedComponentRef={(ref) => {
            this.form = ref;
          }}
        />
      </Layout.Content>
    );
  }

  //Private API
  //Actions
  async deleteUser(userId) {
    this.startLoading();

    const response = await this.props.app.api.v2.vaultUser.deleteUser(
      this.props.app.sharedCache().getCurrentVaultID(),
      userId
    );

    this.stopLoading();

    if (response.statusCode === 200) {
      this.fetchData();
    } else this.props.app.alertController.showAPIErrorAlert('Error!', response);
  }
  async deleteInvitation(userEmail) {
    this.startLoading();

    const response = await this.props.app.api.v2.vaultInvitation.deleteInvitation(
      this.props.app.sharedCache().getCurrentVaultID(),
      userEmail
    );

    this.stopLoading();

    if (response.statusCode === 200) {
      this.fetchData();
    } else this.props.app.alertController.showAPIErrorAlert('Error!', response);
  }
  async updateUser(user) {
    this.setState({
      isLoading: true,
      confirmLoading: true,
    });

    const request = await this.props.app.api.v2.vaultUser.updateUser(
      this.props.app.sharedCache().getCurrentVaultID(),
      user.id,
      {
        // agreedWithTermsOn: !!user.agreedWithTermsOn,
        // email: user.email,
        // firstName: user.firstName,
        // lastName: user.lastName,
        role: user.role,
      }
    );

    this.setState({
      isLoading: false,
      confirmLoading: false,
    });

    if (request.statusCode === 200) {
      this.setState({
        showUpdateUser: false,
        selectedUser: {},
      });

      this.fetchData(this.state.search);
    } else this.props.app.alertController.showAPIErrorAlert(null, request);
  }
  async createInvitation(invitation) {
    this.setState({
      isLoading: true,
      confirmLoading: true,
    });

    const emails = invitation.email.split(',');
    const account = this.props.app.sharedCache().getCurrentVaultAccount();
    const promises = await Promise.all(
      emails.map((email) =>
        this.props.app.api.v2.vaultInvitation.invite(this.props.app.sharedCache().getCurrentVaultID(), {
          email: email,
          role: account.limits.userRolesEnabled ? invitation.role : Globals.Vault_Roles.MEMBER,
        })
      )
    );

    let hasError = false;
    promises.forEach((request) => {
      if (request.statusCode !== 200) {
        hasError = true;
        this.props.app.alertController.showAPIErrorAlert(null, request);
      }
    });

    if (!hasError) {
      this.fetchData(this.state.search);
    }

    this.setState({
      isLoading: false,
      confirmLoading: false,
      showCreateInvitation: hasError,
      selectedInvitation: {},
    });
  }
}
export default class AdminUsersInvitesView extends NavigationControllerView {
  constructor(props) {
    super(props);
    autoBind(this);
    this.containerClass = _AdminUsersInvitesView;
    this.fullheight = true;
  }
}
