import React from 'react';
import { Row, Col, Typography, Skeleton, Input } from 'antd';

import Utils from '../../../components/helpers/Utils';

import CustomComponent from '../../../ui-components/CustomComponent';
import CommonAttributesSubform from '../Attributes/CommonAttributesSubform';
import CommonTagsLabel from '../Attributes/CommonTagsLabel';
import CommonTagSelect from '../Attributes/CommonTagSelect';

export default class CommonDocketDrawerInformationTab extends CustomComponent {
  /* Public methods */
  setAttributes(data) {
    if (this.attributesForm && data &&
        data.attributes && data.attributes.length) {
      this.attributesForm.setValues({
        attributes: data.attributes.reduce((acc, curr) => Object.assign(acc, { [curr.id]: curr.value }), {}),
      });
    }
  }

  resetAttributes() {
    if (this.attributesForm) {
      this.attributesForm.setValues({
        attributes: {},
      });
    }
  }

  validateAttributes() {
    if (this.attributesForm) {
      return this.attributesForm.validate();
    }

    return false;
  }

  getTags() {
    if (this.tagsSelect) {
      return this.tagsSelect.getSelectedTags();
    }

    return false;
  }

  getNotes() {
    if (this.notes) {
      return this.notes.state.value;
    }

    return '';
  }

  render() {
    const { selectedDocket, isEditing, isLoading } = this.props;

    return (
      <>
        <Row type="flex" align="middle" gutter={[16, 16]} className="docketDetails-informationTab">
          {this._renderColItem('Creation Date', Utils.getDateOnUIFormat(new Date(selectedDocket.createdOn)))}
          {this._renderColItem('Updated at', Utils.getDateOnUIFormat(new Date(selectedDocket.updatedOn)))}
          {this._renderColItem(
            'Number of Documents',
            `${selectedDocket.docketNumDocs} File${selectedDocket.docketNumDocs > 1 ? 's' : ''}`
          )}
          {this._renderColItem('Docket Size', Utils.formatSizeUnits(selectedDocket.docketSize))}
          {this._renderColItem('Notes', selectedDocket.notes, {
            isEditing,
            multiline: true,
            inputName: 'notes',
          })}
        </Row>

        <Row type="flex" align="middle" gutter={[16, 16]} className="docketDetails-informationTab">
          <Col className="filesTableColTag docketDetailsItem" style={{ width: '100%' }}>
            <Typography.Text>Tags</Typography.Text>
            <br />
            <Skeleton title={false} paragraph={{ rows: 1 }} loading={isLoading} active>
              <>
                <div style={{ display: isEditing ? 'block' : 'none' }}>
                  <CommonTagSelect
                    values={
                      selectedDocket.tags
                        ? selectedDocket.tags.map(tag => ({
                            value: tag,
                            label: tag,
                          }))
                        : []
                    }
                    app={this.props.app}
                    {...Utils.propagateRef(this, 'tagsSelect')}
                  />
                </div>

                <div style={{ display: !isEditing ? 'block' : 'none' }}>
                  <CommonTagsLabel isExpanded isMultiline tags={selectedDocket.tags} />
                </div>
              </>
            </Skeleton>
          </Col>
        </Row>

        <Row type="flex" align="middle" gutter={[16, 16]} className="docketDetails-informationTab">
          <Col className="filesTableColAttributes docketDetailsItem" style={{ width: '100%' }}>
            <Typography.Text>Custom Attributes</Typography.Text>
            <br />
            <CommonAttributesSubform
              editable={isEditing}
              app={this.props.app}
              form={this.props.form}
              isDocument={false}
              datasource={{ state: selectedDocket.attributes || [] }}
              {...Utils.propagateRef(this, 'attributesForm')}
            />
          </Col>
        </Row>
      </>
    );
  }

  /* Private Methods */

  // UI
  _renderColItem(label, value, editableOptions = { isEditing: false, multiline: false }) {
    const {
      isLoading,
      form: { getFieldDecorator },
    } = this.props;

    const InputComponent = editableOptions.multiline ? Input.TextArea : Input;

    return (
      <Col xs={12} className="docketDetailsItem">
        <Typography.Text>{label}</Typography.Text>
        <br />
        <Typography.Text strong>
          <Skeleton title={false} paragraph={{ rows: 1 }} loading={isLoading} active>
            {!editableOptions.isEditing
              ? value
              : getFieldDecorator(editableOptions.inputName, {
                  ...(editableOptions.inputOptions || {}),
                  initialValue: value,
                })(
                  <InputComponent
                    name={editableOptions.inputName}
                    ref={el => {
                      this[editableOptions.inputName] = el;
                    }}
                    {...(editableOptions.inputProps || {})}
                  />
                )}
          </Skeleton>
        </Typography.Text>
      </Col>
    );
  }
}
