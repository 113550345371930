import React from "react";
import autoBind from "react-autobind";
import { Modal, Typography, Divider, Row, Button, Icon } from 'antd';
import checkCircleIcon from '../../../../assets/images/icons/check-circle.svg';
import * as ExcelJS from 'exceljs';
//
import Globals from "../../../../config/Globals";
import Utils from "../../../../components/helpers/Utils";
//
import CommonOperationsTags from "../../Operations/CommonOperationsTags";
import CommonOperationsProgressBar from "../../Operations/CommonOperationsProgressBar";
import CommonTransferFilesTable from "../../CommonTransferFilesTable";
//
import "../../../../assets/stylesheets/CommonTransferOperationModal.scss";
//
//props: app, showConfimationModal, title, isMove, transferPhase, transferType, progressPercentage,
//       dockets, documents, nameMatches, completed, onClose, onHide, onRetry
export default class CommonTransferOperationModal extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  //actions
  handleModalClose() { this.props.onClose(); }
  handleHide() { this.props.onHide(); }
  handleRetry() { this.props.onRetry(); }

 //UI
  render() {
    return (
      <Modal
        maskClosable={false} visible={this.props.showConfimationModal} closable={false} footer={null}
        centered className="transferOperationModal" width={690} title={this.props.title}>
        {this._renderTitle()}
        {!this.props.completed && this._renderTags()}
        {this.props.completed && this._failed('all').length > 0 && this._renderFailed()}
        {this._renderList()}
        {this.props.completed && this._renderSummary()}
        <Divider />
        {this._renderButtons()}
      </Modal>
    );
  }

  // private UI
  _renderTitle() {
    if (!this.props.completed) {
      // eslint-disable-next-line no-nested-ternary
      const title = (this.props.transferPhase === Globals.Transfer_Phase.DOCKETSCREATION) ? `Please wait while the dockets are being created.` :
                    (this.props.transferPhase === Globals.Transfer_Phase.DOCKETSDELETION) ? `Please wait while the dockets are being deleted.` :
                    `Please wait while the documents are being ${this.props.isMove ? 'moved' : 'copied'}.`;
      return (
        <>
          <Typography.Text>{title}</Typography.Text>
          <Divider />
          {this._renderProgressBar()}
        </>
      )
    } else {
      return (
        <>
          <Divider />
          <div className="completeContainer">
            {this._failed('all').length ? <Icon type="warning" className="icon warning" /> : <img src={checkCircleIcon} alt="CheckCircleIcon" className="icon success" />}
            <Typography.Text>{this.props.isMove ? 'Moving' : 'Copying'} complete!</Typography.Text>
          </div>
        </>
      )
    }
  }
  _renderProgressBar() {
    // eslint-disable-next-line no-nested-ternary
    const progresslabel = (this.props.progressPercentage > 0) ? `${parseInt(this.props.progressPercentage, 10)}%` :
                          // eslint-disable-next-line no-nested-ternary
                          (this.props.transferPhase === Globals.Transfer_Phase.DOCKETSCREATION) ? `Preparing dockets to be created...` :
                          (this.props.transferPhase === Globals.Transfer_Phase.DOCKETSDELETION) ? `Preparing dockets to be deleted...` :
                          `Preparing files to be ${this.props.isMove ? 'moved' : 'copied'}...`;
    return <CommonOperationsProgressBar progress={parseInt(this.props.progressPercentage, 10)} progressLabel={progresslabel} />
  }
  _renderTags() {
    return(
      <CommonOperationsTags
        totalText={`Total ${this._isSingular(this._source().length, this.props.transferType)}: ${this._source().length}`}
        inprogress={this._source().filter(d => !d.status || d.status === 'started').length}
        inprogressText={'Enqueued'}
        success={this._source().filter(d => d.status === 'finished').length}
        successText={'Completed'}
        failures={this._source().filter(d => d.status === 'failed').length}
        failuresText={'Failed'}
        retryingText={'Retrying'}
      />
    )
  }
  _renderFailed() {
    return (
      <div className="failedText">
        <Typography.Text className="text">
          {this._failed().length} of {this._source().length} {this._isSingular(this._source().length, this.props.transferType)} failed!
        </Typography.Text>
      </div>
    )
  }
  _renderList() {
    return (
      <CommonTransferFilesTable
        app={this.props.app}
        maxSize={5}
        source={this._source()}
        type={this.props.transferType}
        transferPhase={this.props.transferPhase}
        documents={this.props.documents}
        completed={this.props.completed}
      />
    );
  }
  _renderSummary() {
    return (
      <Row className="sumary">
        <Button size="small" type="secondary" onClick={() => this._downloadSummary()}>Download summary (.xls) </Button>
      </Row>
    )
  }
  _renderButtons() {
    if (!this.props.completed) {
      return (
        <Row type="flex" justify="end" className="footerSection">
          <Button type="secondary" className="cancelButton" onClick={this.handleModalClose}>Cancel</Button>
          <Button type="primary" className="confirmationButton" onClick={this.handleHide}>Hide</Button>
        </Row>
      )
    } else if (this.props.completed && !this._failed('all').length) {
      return (
        <Row type="flex" justify="end" className="footerSection">
          <Button type="primary" className="confirmationButton" onClick={this.handleModalClose}>Close</Button>
        </Row>
      )
    } else {
      return (
        <Row type="flex" justify="end" className="footerSection">
          <Button type="secondary" className="cancelButton" onClick={this.handleModalClose}>Cancel</Button>
          <Button type="primary" className="confirmationButton" onClick={this.handleRetry}>Retry</Button>
        </Row>
      )
    }
  }

  // Helpers
  _source() { return this.props.transferType === Globals.Transfer_Type.DOCKET ? this.props.dockets : this.props.documents }
  _failed(all) {
    if (all) return [...new Set((this.props.documents.concat(this.props.dockets)).filter(d => d.status === 'failed').map(d => d.documentID || d.docketID))]
    return this.props.transferType === Globals.Transfer_Type.DOCKET
      ? [...new Set(this.props.dockets.filter(d => d.status === 'failed').map(d => d.docketID))]
      : this.props.documents.filter(d => d.status === 'failed').map(d => d.documentID)
  }
  // eslint-disable-next-line no-nested-ternary
  _isSingular(length, type) { return type === Globals.Transfer_Type.DOCKET ? length === 1 ? "Docket" : "Dockets" : length === 1 ? "Document" : "Documents"; }

  // Summary
  async _downloadSummary() {
    if (!this._source().length) return;
    const wb = new ExcelJS.Workbook();
    let ws = wb.addWorksheet('Sheet1');
    if (this.props.transferType === Globals.Transfer_Type.DOCKET) {
      ws.addRow(['Source Vault', 'Target Vault', 'Docket Name', 'Document Name', 'Document Size', 'Status', 'Repeted Name']);
      this.props.documents.forEach(document => {
        ws.addRow([
          document.sourceVaultName.trim(),
          document.destinationVaultName.trim(),
          document.sourceDocketName.trim(),
          document.metadata.name.trim(),
          Utils.formatSizeUnits(document.metadata.fileSize),
          Utils.capitalizeString(document.status),
          this.props.nameMatches.includes(document.documentID) ? 'True' : null
        ]);
      });
    } else {
      ws.addRow(['Source Vault', 'Source Docket', 'Target Vault', 'Target Docket', 'Document Name', 'Document Size', 'Status', 'Repeted Name']);
      this.props.documents.forEach(document => {
        ws.addRow([
          document.sourceVaultName.trim(),
          document.sourceDocketName.trim(),
          document.destinationVaultName.trim(),
          document.destinationDocketName.trim(),
          document.metadata.name.trim(),
          Utils.formatSizeUnits(document.metadata.fileSize),
          Utils.capitalizeString(document.status),
          this.props.nameMatches.includes(document.documentID) ? 'True' : null
        ]);
      });
    }
    const buffer = await wb.xlsx.writeBuffer();
    const fileName = `orangepiggy_${this.props.isMove ? 'move' : 'copy'}_report_${Utils.getCurrentDateAndTimeOnUIFormat().replace(" ", "_")}`;
    Utils.downloadArrayBuffer(buffer, fileName, 'xlsx');
  }
}
