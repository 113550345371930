import React from 'react';
import autoBind from 'react-autobind';
import scrollIntoView from 'scroll-into-view';
//
import { Alert, Layout, Tooltip } from 'antd';
//
import CustomComponent from '../../ui-components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonSearchBar from '../commonComponents/Search/CommonSearchBar';
import CommonSearchResultsTable from '../commonComponents/Search/CommonSearchResultsTable';
import CommonDocketDrawer from '../commonComponents/Drawer/CommonDocketDrawer';
import NavigationControllerView from '../../ui-components/NavigationControllerView';
import CommonEmptyVaultSearchView from '../commonComponents/CommonEmptyVaultSearchView';
import CommonEmptySearchView from '../commonComponents/CommonEmptySearchView';
//resources
import Globals from '../../config/Globals';
import Utils from '../../components/helpers/Utils';
//Style
import '../../assets/stylesheets/CommonSearchView.scss';
//
const INITIAL_STATE = {
  isLoading: false,
  result: null,
  total: 0,
  currentPage: 1,
  sortInfo: {},
  searchTerm: null,
  termSuggestion: null
};
const PRESERVE_STATE = (previous) => {
  return {
    isLoading: false,
    result: null,
    total: 0,
    currentPage: 1,
    sortInfo: {},
    searchTerm: previous.searchTerm,
    termSuggestion: previous.termSuggestion,
  };
};
/* eslint max-classes-per-file: ["error", 2] */
class _CommonSearchView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = INITIAL_STATE;
  }
  // Life cycle
  tabViewDidAppear() {
    //gain focus when tab changes
    if (this.searchBar) this.searchBar.focus();
  }
  //Actions
  //Filter
  handleSearch(searchTerm) {
    if (this.state.isLoading) return;
    if (!searchTerm || searchTerm.length == 0 || !searchTerm.filters || searchTerm.filters.length == 0) {
      this.setState(INITIAL_STATE);
      this.props.app.analytics.logEvent('Search', 'Simple');
    } else {
      const currentPage = (this.state.searchTerm !== searchTerm) ? 1 : this.state.currentPage;
      console.log(currentPage)
      if (currentPage === 1 && this.resultsTable) this.resultsTable.resetMultiselection();
      this.setState({ searchTerm, currentPage, isLoading: true });
      this._advancedSearchDockets(searchTerm);
      this.props.app.analytics.logEvent('Search', 'Advanced');
    }
  }
  handleSearchFilterChange() {
    //this is done to update table height after filter is changed
    this.forceUpdate();
  }
  handleFilterModeChange() { this.setState(INITIAL_STATE); }
  //Table
  handleRowSelection(record) {
    this.docketDrawer.show(
      record.docketID ? record.docketID : record.id, //docket ID
      record, //other ids (vaultID...)
      record.docketID ? record.id : null //document id to be auto pushed,
    );
  }
  handleTableChange(sortInfo, page) {
    const currentPage = (this.state.sortInfo.columnKey !== sortInfo.columnKey ||
                         this.state.sortInfo.order !== sortInfo.order) ? 1 : page;
    this.setState({ sortInfo, currentPage }, () => this.handleSearch(this.state.searchTerm));
  }
  handleReload() { this.handleSearch(this.state.searchTerm); }

  //Drag
  handleDragFile(files, parent) {
    //Check for operations in progress
    if (this.docketDrawer.inOperation()) {
      const resp = this.props.app.alertController.showQuestionAlert(
        'Attention!',
        `There are operations in progress that will not be saved if you start a upload now, do you want to continue?`
      );
      if (!resp) return;
    }
    //Check if showing docket, if so get ref
    parent.handleDragFileFromSearch(files, this.docketDrawer.getCurrentDocket());
  }
  //Tab
  handleTabWillDisappear() { this.docketDrawer.forceClose(); }
  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.isAuthenticating;
    const showDocumentCol = !!(
      this.state.result && this.state.result.find((r) => r.type != Globals.SearchTypes.DOCKET)
    );
    const isSearchLocked = this.props.app.sharedCache().isCurrentVaultAccountDataWriteSearchLocked();
    const isEmptyVault = this.props.app.sharedCache().isCurrentVaultEmpty();
    const searchBar = (
      <CommonSearchBar onSearch={this.handleSearch} isLoading={isLoading} disabled={isSearchLocked || isEmptyVault} {...Utils.propagateRef(this, 'searchBar')}
                       onModeChange={this.handleFilterModeChange} onFilterChange={this.handleSearchFilterChange} app={this.props.app}
                       termSuggestion={this.state.termSuggestion}/>
    );
    return (
      <Layout.Content className="commonTabContent commonSearchResultsView">
        <CommonLoadingView isLoading={isLoading} isFixed />
        {isSearchLocked && <Alert type='error' showIcon message='Search disabled' description={Globals.AccountLock_SearchDisabledMessage} style={{marginBottom: '20px'}}/>}
        {isSearchLocked ? <Tooltip placement='topRight' title={Globals.AccountLock_SearchDisabledMessage}> {searchBar} </Tooltip> : searchBar}
        <CommonDocketDrawer app={this.props.app} wrappedComponentRef={(ref) => { this.docketDrawer = ref; }} onReload={this.handleReload}/>
        {this.state.result && (
          <CommonSearchResultsTable isLoading={isLoading} app={this.props.app}
            results={this.state.result} onRowSelection={this.handleRowSelection}
            onTableChange={this.handleTableChange}
            showDocumentCol={showDocumentCol} onReload={this.handleReload}
            total={this.state.total} currentPage={this.state.currentPage}
            sortInfo={this.state.sortInfo} {...Utils.propagateRef(this, 'resultsTable')}
          />
        )}
        {!this.state.result && isEmptyVault && <CommonEmptyVaultSearchView/>}
        {!this.state.result && !isEmptyVault && <CommonEmptySearchView/>}     
      </Layout.Content>
    );
  }

  /*API*/
  async _advancedSearchDockets(searchTerm) {
    //sanitize
    searchTerm = {
      ...searchTerm,
      from: (this.state.currentPage - 1) * Globals.Search_PagingItemsPerPage,
      ...(this.state.sortInfo && this.state.sortInfo.order
        ? {
            sortOrder: this.state.sortInfo.order.indexOf('asc') != -1 ? 'asc' : 'desc',
            sortField: this.state.sortInfo.columnKey,
          }
        : {}),
    };
    //
    console.debug('advanced search', searchTerm);
    const searchResp = await this.props.app.api.v2.docketSearch.advancedSearch(
      searchTerm,
      this.props.app.sharedCache().getCurrentVaultID()
    );
    if (searchResp.statusCode == 200 && searchResp.body.results) {
      this.setState({ isLoading: false, result: searchResp.body.results, total: searchResp.body.total, termSuggestion: searchResp.body.suggestion }, () => {
        const element = document.querySelector('.commonSearchResultsView');
        console.log(element)
        if (element) scrollIntoView(element, { debug: false, time: 500, align: { top: 0 } });
      });
    } else {
      this.setState(PRESERVE_STATE(this.state));
      this.props.app.alertController.showAPIErrorAlert('Error!', searchResp);
    }
  }
}

export default class CommonSearchView extends NavigationControllerView {
  constructor(props) {
    super(props);
    this.containerClass = _CommonSearchView;
  }

  //forward
  handleDragFile(files) {
    this.getCurrentView().handleDragFile(files, this.props.parent);
  }
  //Tab selectors
  tabViewDidAppear() {
    if (this.getCurrentView() && this.getCurrentView().tabViewDidAppear) this.getCurrentView().tabViewDidAppear();
  }
  tabViewWillDisappear() {
    if (this.getCurrentView() && this.getCurrentView().handleTabWillDisappear) this.getCurrentView().handleTabWillDisappear();
  }
}
