import React from 'react';
import moment from 'moment';
import { Menu, Dropdown, Icon, Avatar, Typography, Tag, Spin, Button, Tooltip, Modal } from 'antd';
import { Link } from 'react-router-dom';
import autoBind from 'react-autobind';
import { PlusCircleOutlined, MenuOutlined } from '@ant-design/icons';
//
import config from '../../../config/config';
import AccountUtils from '../../../components/helpers/AccountUtils';
import Globals from '../../../config/Globals';
import Utils from '../../../components/helpers/Utils';
//
import '../../../assets/stylesheets/AppBar.scss';
import '../../../assets/stylesheets/Drawer.scss';
import logo from '../../../assets/images/logo-text.svg';
import orangepiggy from '../../../assets/images/orangepiggy.svg';
//
import AppBarAlertController from './AppBarAlertController';
import DrawerMenu from './DrawerMenu';

export default class AppBar extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }
  // Life cycle
  async componentDidMount() {
    const user = await this.props.app.idm.session.data.getUserObject();
    this.setState({ user });
  }

  // Action handlers
  async handleSwitchVault(vault) {
    this.props.app.analytics.logEvent('Vault', 'Switch');
    await this.props.app.vaultManager.redirectToVault(vault.alias);
  }

  handleMyAccount() {
    this.props.app.urlManager.openExternalPage(`https://${config.AccountAppSubdomain}.${config.ApplicationDomain}`);
  }

  async handleNavigate(vault, path, event) {
    const currentVaultID = this.props.app.sharedCache().getCurrentVaultID();

    if (vault.id != currentVaultID) {
      event.preventDefault();
      await this.props.app.vaultManager.redirectToVault(vault.alias, true, path);
    }
  }

  handleOpenDrawer() {
    this.drawer.open();
  }

  async handleResetExpiredTrial() {
    const account = this.props.app.sharedCache().getCurrentVaultAccount();
    const resp = await this.props.app.api.v2.subscription.resetExpiredTrial(account.id);
    if (resp.statusCode == 200) {
      await this.props.app.sharedCache().reloadCache();
      return true;
    }
    return false;
  }

  handleCreateNewVault(redirectToUpgrade) {
    if (redirectToUpgrade) {
      Modal.confirm({
        type: 'info',
        title: <span style={{ paddingTop: 16, display: 'block' }}>Upgrade your plan!</span>,
        content: 'Your account has reached the maximum number of Vaults but you can still upgrade your plan to get more vaults. Click in the button below to upgrade your plan.',
        centered: true,
        okText: 'Upgrade',
        onOk: () => {
          const account = this.props.app.sharedCache().getMyAccount();
          const nextPlan = AccountUtils.getNextPlan(account.subscriptionID);

          this.props.app.urlManager.openExternalPage(`https://${config.AccountAppSubdomain}.${config.ApplicationDomain}?subscribe=1&plan=${nextPlan || ''}`);
        },
      });

      return;
    }

    this.props.app.urlManager.openExternalPage(`https://${config.AccountAppSubdomain}.${config.ApplicationDomain}/vaults?action=new`);
  }

  render() {
    const account = this.props.app.sharedCache().getCurrentVaultAccount();
    const currentVault = this.props.app.sharedCache().getCurrentVault();

    return (
      <>
        <DrawerMenu
          app={this.props.app}
          myAccountHandler={this.handleMyAccount.bind(this)}
          logoutHandler={this.props.logoutHandler}
          createNewVaultHandler={this.handleCreateNewVault}
          {...Utils.propagateRef(this, 'drawer')}
        />

        <div className="new-appbar">
          <div className="new-appbar__content">
            <div className="new-appbar__content__left">
              {this._renderVaultContainer()}

              <div className="mobile-hamburger">
                <button type="button" onClick={this.handleOpenDrawer}>
                  <MenuOutlined />
                </button>
              </div>
            </div>

            <div className="new-appbar__content__center">
              <img src={logo} alt="OrangePiggy" className="logo" />

              <div className="current-vault">
                <small>Current Storage Vault</small>
                <strong>{currentVault?.name || <Spin style={{ marginTop: 5 }} />}</strong>
              </div>
            </div>

            <div className="new-appbar__content__right">
              {this._renderUserBadge()}
            </div>
          </div>
        </div>

        {account && <AppBarAlertController app={this.props.app} handleMyAccount={this.handleMyAccount} handleResetExpiredTrial={this.handleResetExpiredTrial}/>}
      </>
    );
  }

  // Private methods
  _renderVaultOption(vault) {
    const currentPathName = this.props.app.props.history.location.pathname;
    const isCurrentVault = vault.id === this.props.app.sharedCache().getCurrentVaultID();

    const active = (pathName, vaultID) => {
      return this.props.app.sharedCache().getCurrentVaultID() == vaultID && pathName == currentPathName ? 'active' : '';
    };

    const hasUsersManagementPermission = AccountUtils.hasUsersManagementPermission(vault.user.role);
    const hasVaultManagementPermission = AccountUtils.hasVaultManagementPermission(vault.user.role);

    return (
      <Menu.Item className={`vault-dd-vault-option ${isCurrentVault ? 'active' : ''}`} key={vault.id}>
        <span onClick={this.handleSwitchVault.bind(this, vault)}>
          {vault.name}
          <small className="role-name">{Globals.Vault_RolesDisplayNames[vault.user.role] || vault.user.role}</small>
        </span>
        {(hasUsersManagementPermission || hasVaultManagementPermission) && (
          <div className="options">
            {hasUsersManagementPermission && (
              <div className="button">
                <Link
                  to={config.ApplicationRoutes.adminUserInvitation}
                  onClick={this.handleNavigate.bind(this, vault, config.ApplicationRoutes.adminUserInvitation)}
                  className={active(config.ApplicationRoutes.adminUserInvitation, vault.id)}
                >
                  <Icon type="user" />
                  USERS
                </Link>
              </div>
            )}
            {hasVaultManagementPermission && (
              <div className="button">
                <Link
                  to={config.ApplicationRoutes.settings}
                  onClick={this.handleNavigate.bind(this, vault, config.ApplicationRoutes.settings)}
                  className={active(config.ApplicationRoutes.settings, vault.id)}
                >
                  <Icon type="setting" />
                  SETTINGS
                </Link>
              </div>
            )}
          </div>
        )}
      </Menu.Item>
    );
  }

  _renderVaultContainer() {
    const vaults = this.props.app.sharedCache().getVaults();
    const currentVault = this.props.app.sharedCache().getCurrentVault();
    const userID = this.props.app.idm.session.authorization.getUserID();

    const myVaults = vaults.filter((vault) => vault.account.owner == userID).sort((a, b) => a.name?.localeCompare(b.name));
    const sharedVaults = vaults.filter((vault) => vault.account.owner != userID).sort((a, b) => a.name?.localeCompare(b.name));

    const account = this.props.app.sharedCache().getMyAccount();
    const reachedMaxVaultsLimit = account.limits?.currentVaults >= account.limits?.maxVaults;
    const isBigPiggy = account.subscriptionID == Globals.SubscriptionPackagesIDs.BIGPIGGY;

    const menu = (
      <Menu style={{ paddingBottom: 0 }}>
        <Menu.Item className="vault-dd-switch-vauls-title">
          <span>My Vaults</span>
        </Menu.Item>
        {myVaults.map(this._renderVaultOption)}

        <Menu.Item className="vault-dd-switch-vauls-title">
          <span>Vaults shared with me</span>
        </Menu.Item>
        {sharedVaults.length === 0 && (
          <Menu.Item style={{ paddingTop: 16, paddingBottom: 16, opacity: 0.8 }}>
            <span>No Vaults shared with you.</span>
          </Menu.Item>
        )}
        {sharedVaults.map(this._renderVaultOption)}

        <Menu.Item style={{ padding: '16px' }}>
          <Tooltip
            title={
              reachedMaxVaultsLimit && isBigPiggy
                ? 'Your account has reached the maximum number of Vaults.'
                : ''
            }
          >
            <Button
              type="primary"
              size="large"
              className="w100"
              disabled={reachedMaxVaultsLimit && isBigPiggy}
              onClick={this.handleCreateNewVault.bind(this, reachedMaxVaultsLimit && !isBigPiggy)}
            >
              <PlusCircleOutlined /> Create new Vault
            </Button>
          </Tooltip>
        </Menu.Item>
      </Menu>
    );

    return (
      <div>
        <Dropdown overlay={menu}>
          <div className="dropdown-button">
            <div>
              <img src={orangepiggy} alt="OrangePiggy" className="piggy" />

              <div className="vault-name">
                <small>Current Storage Vault</small>
                <strong>{currentVault?.name || <Spin style={{ marginTop: 5 }} />}</strong>
              </div>
            </div>

            <Icon type="caret-down" className="arrow" />
          </div>
        </Dropdown>
      </div>
    );
  }

  _renderUserBadge() {
    const menu = (
      <Menu className="userPopoverList">
        <Menu.Item as="button" onClick={this.handleMyAccount.bind(this)}>
          <Icon type="export" /> My Account
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item as="button" onClick={this.props.logoutHandler}>
          <Icon type="poweroff" /> Logout
        </Menu.Item>
      </Menu>
    );

    let daysRemaining = this._getTrialDaysRemaining();
    let trialTag = null;
    if (typeof daysRemaining === "number") {
      // eslint-disable-next-line no-nested-ternary
      const daysRemainingMessage = daysRemaining === 0 ? ` - Ends Today` : (daysRemaining > 0 && daysRemaining <= 7) ? ` - Expiring in ${daysRemaining} days` : '';
      trialTag = <Tag color="orange" style={{ marginRight: '16px' }} className="trial-tag"><strong>Trial{daysRemainingMessage}</strong></Tag>;
    }

    return (
      <>
        {trialTag}
        <Dropdown overlay={menu} placement="bottomRight">
          <Tag className="idmBadgeContainer">
            <Avatar justify="end" size={36} className="idmBadgeUserIcon">
              <Typography.Text className="idmBadgeUserText">{this.state.user?.firstName?.substring(0, 1)}</Typography.Text>
            </Avatar>
          </Tag>
        </Dropdown>
      </>
    );
  }

  _getTrialDaysRemaining() {
    const account = this.props.app.sharedCache().getCurrentVaultAccount();
    if (account?.trialStartedOn && account?.trialStartedOn > 0) {
      const now = moment(new Date());
      const trialEndDate = Utils.getMomentByTimestamp(account.trialEndsOn);
      const daysRemaining = trialEndDate.diff(now, 'days');
      return now < trialEndDate ? daysRemaining : undefined;
    }
    return undefined;
  }
}
