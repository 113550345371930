import React from 'react';
import autoBind from 'react-autobind';
import { Tag, Col, Icon, Row, Button, Modal, Typography, Alert, message, Checkbox } from 'antd';
//
import CustomComponent from '../../../ui-components/CustomComponent';
import CommonLoadingView from '../CommonLoadingView';
import CommonUploadFilesTable from '../CommonUploadFilesTable';
//
import '../../../assets/stylesheets/CommonOperationCancelModal.scss';
//props are: app, onCancel (optional), onReload
export default class CommonRecoverModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isVisible: false, context: null, isLoading: false, shouldRecoverDocket: false, recoverAllDocuments: false, isBatch: false};
  }
  //Actions
  show(context, isBatch /* this modal support batch documents from ONE docket only*/) { 
    const isDocument = !!context?.docketID;
    this.setState({ context, isBatch, isVisible: true, isLoading: (isDocument || isBatch), shouldRecoverDocket: false, recoverAllDocuments: false }, () => {
      if (isDocument) this._checkForDocketDocument(context?.docketID, context?.vaultID);
      else if (isBatch) this._checkForDocketDocument(context?.docket?.id, context?.docket?.vaultID);
    }); 
  }
  handleCancel() { 
    if (this.props.onCancel) this.props.onCancel();
    this.setState({ isVisible: false });
  }
  handleRecover() { 
    if (this.state.isBatch) this._batchRecoverWithContext(this.state.context, this.state.shouldRecoverDocket);
    else this._singleRecoverWithContext(this.state.context, this.state.shouldRecoverDocket, this.state.recoverAllDocuments);
  }
  handleCheckRecoverAllDocumentsChange(e) {
    const value = !!e.target.checked;
    this.setState({ recoverAllDocuments: value });
  }
  //UI
  render() {
    return (
      <Modal maskClosable={false} onCancel={this.handleCancel} visible={this.state.isVisible} closable={!this.state.isLoading} footer={null} centered className='operationCancelModal'>
        <CommonLoadingView isLoading={this.state.isLoading} isFixed/>
        {this._renderSubtitle()}
        {this._renderButtons()}
      </Modal>
    );
  }
  /* UI private */
  _renderSubtitle() {
    const isDocument = !!this.state.context?.docketID;
    return (
      <Row className='subtitleLabelRow' type='flex'>
        <Row type='flex' justify='start' align='middle' style={{ marginTop: '50px' }}>
          <Col span={4} align='middle'>
            <Icon type="undo" style={{ color: 'black', fontSize: 36, marginBottom: '21px' }} />
          </Col>
          <Col span={20}>
            <Typography.Paragraph className='subtitleLabel' style={{ fontSize: '20px' }}>
              Are you sure you would like to <strong>recover</strong> 
              {(this.state.isBatch ? 
                ` the following ${this.state.context?.documents?.length || 0} file(s) from the ${this.state.context?.docket?.name} docket` : 
                ` the '${this.state.context?.name} ${isDocument ? 'file' : 'docket'}`)}?
              </Typography.Paragraph>
          </Col>
        </Row>
        {this.state.isBatch && <Tag style={{ marginBottom: '20px', width: '100%' }}>
          <CommonUploadFilesTable isLoading={this.state.isLoading} maxSize={10} files={this.state.context?.documents} app={this.props.app}/>
        </Tag>}
        {this.state.shouldRecoverDocket && <Alert style={{ marginBottom: '20px' }} description={<>
          This action will also recover the <strong>{this.state.context?.docketName || this.state.context?.docket?.docketName}</strong> docket, but not other deleted files!
        </>} type='warning' showIcon />}
        {!isDocument && !this.state.isBatch && this.state.context && <Row type='flex' justify='start' align='middle' style={{ marginBottom: '20px' }}>
          <Col span={4} align='middle'> {''} </Col>
          <Col span={20}>
            <Checkbox checked={this.state.recoverAllDocuments} onChange={this.handleCheckRecoverAllDocumentsChange}>
              <Typography.Text style={{marginLeft: '10px'}}>Recover all files from this docket? </Typography.Text> 
              <Typography.Text disabled style={{ marginLeft: '35px' }}> ({this.state?.context?.docketNumDocs || 0} files) </Typography.Text>
            </Checkbox>
          </Col>
        </Row>}
      </Row>
    );
  }
  _renderButtons() {
    return (
      <Row type='flex' justify='end'>
        <Button type="secondary" className='cancelButton' disabled={this.state.isLoading} loading={this.state.isLoading} onClick={this.handleCancel}> Cancel </Button>
        <Button type="primary" className='retryButton' disabled={this.state.isLoading} loading={this.state.isLoading} onClick={this.handleRecover}> Confirm </Button>
      </Row>
    );
  }
  
  /* API actions */
  async _singleRecoverWithContext(context, shouldRecoverDocket, shouldRecoverAllDocuments, silent) {
    this.startLoading();
    const isDocument = !!context.docketID;
    if (isDocument && shouldRecoverDocket) await this._singleRecoverWithContext({ vaultID: context.vaultID, id: context.docketID }, false, false, true);
    //Make request
    const resp = await (this.props.app.api.v2[(isDocument ? 'document' : 'vaultDocket')][(isDocument ? 'recoverDocument' : 'recoverDocket')](
      context.vaultID, context.docketID || context.id, (isDocument ? context.id : shouldRecoverAllDocuments)
    ));
    if (resp.statusCode == 200) {
      message.success(`${isDocument ? 'Document' : 'Docket'} recovered with success!`);
      if (!silent) this.setState({ isLoading: false, isVisible: false });
      if (!silent) this.props.onReload();
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error!', resp);
      if (!silent) this.stopLoading();
    }
  }
  async _batchRecoverWithContext(context, shouldRecoverDocket) {
    this.startLoading();
    //Make request
    const resp = await (this.props.app.api.v2.vaultBatch.recover(
      context.docket?.vaultID, (shouldRecoverDocket ? [ context?.docket?.id ] : []),
      context?.documents?.map((d) => ({id: d.id, docketID: d.docketID})), false
    ));
    if (resp.statusCode == 200) {
      message.success(`${context.documents.length} Documents recovered with success!`);
      this.setState({ isLoading: false, isVisible: false });
      this.props.onReload();
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error!', resp);
      this.stopLoading();
    }
  }
  async _checkForDocketDocument(docketID, vaultID) {
    //Make request
    const resp = await this.props.app.api.v2.vaultDocket.getDocketByID(vaultID, docketID);
    if (resp.statusCode == 200) {
      this.setState({ isLoading: false, shouldRecoverDocket: !!resp.body.deleted });
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while retrieving document docket!', resp);
      this.setState({ isLoading: false, isVisible: false });
      this.props.onReload();
    }
  }
}
