import React from "react";
import autoBind from "react-autobind";
//
import Globals from "../../../../config/Globals";
//
import CommonTransferCaseModalBase from "./CommonTransferCaseModalBase";
//
import "../../../../assets/stylesheets/CommonTransferModal.scss";
//
//props: app, dockets, documents, duplicatedDockets, title, step, isLoading, vaultID, onSelectVault
export default class CommonTransferCaseOneModal extends CommonTransferCaseModalBase {
  constructor(props) {
    super(props);
    autoBind(this);
    this.vaultID = this.props.app.sharedCache().getCurrentVaultID();
  }

 //UI
  render() {
    return (
      <>
        {this.props.step >= 1 && this.renderSelectVault(this.vaultID)}
        {this.props.isLoading && this.props.step !== 1 && this.renderLoading()}
        {!this.props.isLoading && this.props.step === 2 && this.props.vaultID && this.renderList(Globals.Transfer_Type.DOCKET)}
        {!this.props.isLoading && this.props.step === 3 && this.props.vaultID && this.renderConfirmation(Globals.Transfer_Type.DOCKET)}
      </>
    );
  }
}
