import React from 'react';
import autoBind from 'react-autobind';
import { Form, Col, DatePicker, Input, InputNumber } from 'antd';
//
import CustomComponent from '../../../ui-components/CustomComponent';
import CommonAttributeAutoFillInput from './CommonAttributeAutoFillInput';
//
import Utils from '../../../components/helpers/Utils';
import Globals from '../../../config/Globals';
//
export default class CommonAttributeInput extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //props: editable, app, form, isDocument (if not is Docket), datasource
    //attribute, onChange
  }
  //UI
  render() {
    const attribute = this.props.attribute;
    return (
      <Col span={12} key={attribute.id}>
        <Form.Item key={attribute.id} label={attribute.label} className="attributeForm">
          {this._renderInput(getAttrID(attribute), {
            rules: this._validationRule(attribute),
          })(this._getTypedInput(attribute))}
        </Form.Item>
      </Col>
    );
  }
  /* subforms */
  _renderInput(val, opts) {
    const { getFieldDecorator } = this.props.form;
    opts.initialValue = this.props.datasource.state[val] ? this.props.datasource.state[val] : opts.initialValue;
    return getFieldDecorator(val, opts);
  }
  //Multi data type helper
  _getTypedInput(attribute) {
    if (attribute.type == Globals.VaultSettings_CustomAttributeTypes.DATE) {
      return <DatePicker initialValue={null} {... this._dateHandler(attribute)} disabled={!this.props.editable}/>;
    } else if (attribute.type == Globals.VaultSettings_CustomAttributeTypes.NUMERIC ||
               attribute.type == Globals.VaultSettings_CustomAttributeTypes.INTEGER) {
      return <InputNumber step={1} {... this._numericHandler(attribute)} disabled={!this.props.editable}/>;
    } else if (attribute.type == Globals.VaultSettings_CustomAttributeTypes.FLOAT) {
      return <InputNumber step={0.01} {... this._numericHandler(attribute)} disabled={!this.props.editable}/>;
    } else if (attribute.autoFill) {
      return <CommonAttributeAutoFillInput app={this.props.app} attribute={attribute}
                                           {... this._autofillHandler(attribute)} editable={this.props.editable}/>
    } else return <Input {... this._stringHandler(attribute)} disabled={!this.props.editable}/>;
  }
  _validationRule(attribute) {
    const required = (this.props.isDocument ? attribute.isMandatoryOnDocuments : attribute.isMandatoryOnDockets);
    if (attribute.type == Globals.VaultSettings_CustomAttributeTypes.DATE) {
      return [{
          type: 'object', required,
          message: `${attribute.label} is required!`, whitespace: true,
      }];
    } return [{ required, message: `${attribute.label} is required!` }];
  }
  //Input helpers
  _numericHandler(attribute) {
    return (!this.props.onChange ? {} : { onChange: (val) => {
        if (this.props.onChange) {
          this.props.onChange({
            target: { name: getAttrID(attribute, true), value: val }
          });
        }
    }});
  }
  _stringHandler(attribute) {
    return (!this.props.onChange ? {} : { onChange: (val) => {
        if (this.props.onChange) {
          this.props.onChange({
            target: { name: getAttrID(attribute, true), value: val.target.value }
          });
        }
    }});
  }
  _dateHandler(attribute) {
    return (!this.props.onChange ? {} : { onChange: (val) => {
        if (this.props.onChange) {
          this.props.onChange({
            target: { name: getAttrID(attribute, true), value: Utils.timestampFromMoment(val) }
          });
        }
    }});
  }
  _autofillHandler(attribute) {
    return (!this.props.onChange ? {} : { onChange: (val) => {
        if (this.props.onChange) {
          this.props.onChange({
            target: { name: getAttrID(attribute, true), value: val }
          });
        }
    }});
  }
}

//Helpers
export function getAttrID(attr, handler) { return (handler ? `form.attributes.${attr.id}` : `form.attributes['${attr.id}']`) }
