import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
// import autoBind from 'react-autobind';
import { Row, Col, Typography } from 'antd';
//
export default class CommonEmptySearchView extends React.Component {
  // constructor(props) {
  //   super(props);
  //   autoBind(this);
  // }
  //UI
  render() {
    return (
      <Row type='flex' align='middle' justify='center' style={{marginTop: 100}}>
        <Col style={{marginRight: 20, marginTop: 10}}>
          <SearchOutlined style={{ fontSize: 30 }}/>
        </Col>
        <Col>
          <Row>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>Find document and dockets</Typography.Title>
          </Row>
          <Row style={{marginTop: -10}}>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>by name, tags or contents!</Typography.Title>
          </Row>
          <Row>
            <Typography.Text style={{ fontSize: 16, marginTop: 10 }}>Type at least 3 characters to start gettting results.</Typography.Text>
          </Row>
        </Col>
      </Row>
    );
  }
}
