import React from 'react';
import autoBind from 'react-autobind';
import { Modal, Form, Input, Row, Col, Switch, Icon, Tooltip, Select } from 'antd';

import docketIcon from '../../../assets/images/icons/docket.svg';
import documentIcon from '../../../assets/images/icons/file.svg';

import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import Globals from '../../../config/Globals';

class CustomAttributesFormModal extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isVisible: false,
      editingAttribute: null,
      isLoading: false,
      enabledOnDockets: false,
      enabledOnDocuments: false,
    };
  }

  open(editingAttribute = null) {
    this.setState({ isVisible: true, editingAttribute });

    if (editingAttribute) {
      this.props.form.setFieldsValue(editingAttribute);
    }
  }

  close(forceClose = false) {
    if (this.state.isLoading && forceClose !== true) {
      return;
    }

    this.setState({
      isVisible: false,
      editingAttribute: null,
      isLoading: false,
    });
  }

  handleChangeEnabledOn(type) {
    return (enabled) => {
      if (type === 'dockets') {
        this.setState({ enabledOnDockets: enabled })
        if (!enabled) {
          this.props.form.setFieldsValue({ isMandatoryOnDockets: false });
        }
      } else {
        this.setState({ enabledOnDocuments: enabled })
        if (!enabled) {
          this.props.form.setFieldsValue({ isMandatoryOnDocuments: false });
        }
      }
    }
  }

  validate() {
    this.props.form.validateFields(async (err, data) => {
      if (err) return;
      const labelExists = this.props.currentAttributes.find(attr => attr.label?.toLowerCase()?.trim() === data.label?.toLowerCase()?.trim() && attr.type === data.type && attr.id != this.state.editingAttribute?.id);
      if (labelExists) {
        this.props.form.setFields({
          label: {
            value: data.label,
            errors: [new Error('You already have an attribute with this name.')],
          },
        });
        return;
      }

      this.setState({ isLoading: true });

      const formData = Object.entries(data).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: value === undefined ? false : value,
      }), {});

      const success = await this.props.onSubmit({
        ...formData,
        id: this.state.editingAttribute?.id,
      });

      if (success) {
        this.props.form.resetFields();
        this.close(true);
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        visible={this.state.isVisible}
        title={!this.state.editingAttribute ? 'Create new custom attribute' : 'Edit Custom Attribute'}
        onCancel={this.close}
        onOk={this.validate}
        closable={!this.state.isLoading}
        width={650}
        okButtonProps={{
          loading: this.state.isLoading,
        }}
      >
        <CommonLoadingView isLoading={this.state.isLoading} />

        <Form colon={false}>
          <Row gutter={24} style={{ marginBottom: 16 }}>
            <Col span={16}>
              <Form.Item label="Name">
                {getFieldDecorator('label', {
                  rules: [{ required: true, message: 'Please, type the attribute name.' }],
                })(<Input autoFocus />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Type">
                {getFieldDecorator('type', {
                  rules: [{ required: true, message: 'Please, select the attribute type.' }],
                })(
                  <Select style={{ width: '100%' }} disabled={!!this.state.editingAttribute}>
                    {Object.entries(Globals.VaultSettings_CustomAttributeTypes_Labels).map(([key, label]) => {
                      return (<Select.Option key={key} value={key}>{label}</Select.Option>);
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24} style={{ background: '#eeeeee99', borderRadius: 8 }}>
            <Col span={12}>
              <Form.Item label={<><img src={docketIcon} alt="Docket" /> Enable on dockets</>}>
                {getFieldDecorator('enabledOnDockets', { valuePropName: 'checked' })(<Switch onChange={this.handleChangeEnabledOn('dockets')} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<><img src={docketIcon} alt="Docket" /> Mandatory on dockets</>}>
                {getFieldDecorator('isMandatoryOnDockets', { valuePropName: 'checked' })(<Switch disabled={!this.state.enabledOnDockets} />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24} style={{ marginTop: 8 }}>
            <Col span={12}>
              <Form.Item label={<><img src={documentIcon} alt="Docket" /> Enable on documents</>}>
                {getFieldDecorator('enabledOnDocuments', { valuePropName: 'checked' })(<Switch onChange={this.handleChangeEnabledOn('documents')} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<><img src={documentIcon} alt="Docket" /> Mandatory on documents</>}>
                {getFieldDecorator('isMandatoryOnDocuments', { valuePropName: 'checked' })(<Switch disabled={!this.state.enabledOnDocuments} />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24} style={{ background: '#eeeeee99', borderRadius: 8 }}>
            <Col span={12}>
              <Form.Item label={(
                <span>
                  Hidden
                  <Tooltip title="Hidden attributes will not be displayed on the interface but will be still searchable.">
                    <Icon theme="filled"  type="info-circle" style={{ marginLeft: 8 }} />
                  </Tooltip>
                </span>
              )}>
                {getFieldDecorator('hideFromUI', { valuePropName: 'checked' })(<Switch />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={(
                <span>
                  Auto fill
                  <Tooltip title="Auto fill works like tags.">
                    <Icon theme="filled"  type="info-circle" style={{ marginLeft: 8 }} />
                  </Tooltip>
                </span>
              )}>
                {getFieldDecorator('autoFill', { valuePropName: 'checked' })(<Switch />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(CustomAttributesFormModal);
