import React from 'react';
import { Col } from 'antd';

import autoBind from 'react-autobind';
import CustomComponent from '../CustomComponent';

//props should be: app, operationsController, onOperationDidClose, onClick...
//
const styleHidden = { style: { display: 'none' } };
export default class BasicOperationView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    // State sample
    // this.state = { isParentVisible: true, isVisible: true, completed: false };
  }
  //Public
  start() {}
  cancel() {}
    //removes operation complete from UI.. Might have the reference nullified and deallocated
    //to call it when there is nothing to be done of the operation (example, no retries, that can/should be handled)
    //by the same operation.
  close() { if (this.props.onOperationDidClose) this.props.onOperationDidClose(); } 
  //Getters and setters
  get isParentVisible() { return this.state.isParentVisible; }
  set isParentVisible(val) { //does not control nothing unless implemented by subclass (example of isVisible override of upload op.)
    if (!this._isMounted) return;
    this.setState({ isParentVisible: val }); 
  } 
  get isVisible() { return this.state.isVisible; }
  get isCompleted() { return this.state.completed; } //externally controls window unload block
  set isCompleted(val) { 
    if (!this._isMounted) return;
    this.setState({ completed: val }, () => {
      this.props.operationsController.forceUpdate(); //ask for controller reload so we remove window unload block if last op.
    }); 
  }
  //
  render(optionalChildren) { 
    return (
      <Col span={24} {... (this.isVisible ? {} : styleHidden)}>
        {optionalChildren || <></>}
      </Col>
    );
  }
}
