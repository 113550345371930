import React from 'react';
import { Switch } from 'react-router-dom';
//
import config from './config/config';
import Globals from './config/Globals';
//Common components
import CustomRoute from './ui-components/CustomRoute';
import CommonLoginView from './views/authSubviews/CommonLoginView';
//Admin views
import AdminVaultSettingsView from './views/adminSubviews/AdminVaultSettingsView';
import AdminUsersInvitesView from './views/adminSubviews/AdminUsersInvitesView';
//Shared (User/Admin) views
import CommonHomeView from './views/commonSubviews/CommonHomeView';
import CommonDocketView from './views/commonComponents/DocketView/CommonDocketView';

//props are: appRef, appType
export default class AppRoutes extends React.Component {
  //UI
  render() {
    // Unauthorized, unauthenticated, loading auth, loading launch config views
    if (!this.props.appRef.idm.isLogged() || this.props.appRef.isAuthenticating || 
        !this.props.appRef.launchConfigManager.isAvailable()) {
      return (
        <Switch>
          {/* Login here, means a simple loading page, so it's okay to use for isAuthenticating & launchConfigManager cases above */}
          <CustomRoute path={config.ApplicationRoutes.login} component={CommonLoginView} appRef={this.props.appRef} />
        </Switch>
      );
    }
    //Render routes for app type
    if (this.props.appType == Globals.LaunchConfig_AppType.DOCKET) return this._renderDocketAppRoutes();
    return this._renderVaultAppRoutes();
    
  }
  /* Private */
  _renderDocketAppRoutes() {
    return (
      <Switch>
        <CustomRoute path={config.ApplicationRoutes.homepage} component={CommonDocketView} appRef={this.props.appRef} />
      </Switch>
    )
  }
  _renderVaultAppRoutes() {
    //Vault admin
    if (this.props.appRef.isVaultAdmin()) {
      const isAccountReadLocked = this.props.appRef.sharedCache().isCurrentVaultAccountReadLocked();
      return (
        <Switch>
          {!isAccountReadLocked && <CustomRoute path={config.ApplicationRoutes.settings} component={AdminVaultSettingsView} appRef={this.props.appRef}/>}
          {!isAccountReadLocked && <CustomRoute path={config.ApplicationRoutes.adminUserInvitation} exact component={AdminUsersInvitesView} appRef={this.props.appRef}/>}
          {/* Hidden gem :p */}
          <CustomRoute path={'/docket/:id'} component={CommonDocketView} exact appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.homepage} component={CommonHomeView} appRef={this.props.appRef} />
        </Switch>
      );
    }
    //User
    if (this.props.appRef.isUser()) {
      return (
        <Switch>
          {/* Hidden gem :p */}
          <CustomRoute path={'/docket/:id'} component={CommonDocketView} exact appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.homepage} component={CommonHomeView} appRef={this.props.appRef} />
        </Switch>
      );
    }
    return <Switch />;
  }
}
