import { saveAs } from 'file-saver';
import moment from 'moment';
//
import Globals from '../../config/Globals';
//
import {docketIcon, documentIcon, iconsList} from './Icons'
//
export default class Utils { }
//Data handling helpers
Utils.setNestedObject = (obj, prop, value) => {
  let reference = obj;
  const a = prop.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    let key = a[i];
    if (i + 1 != a.length) {
      //check if is not last object
      //safe check for sub.object
      if (reference[key] == null || reference[key] == undefined) {
        if (key == 0 || key == 1) reference = [];
        else reference = reference[key] = {};
      } else reference = reference[key];
    } else {
      reference[key] = value;
    }
  }
  return obj;
};
Utils.getNestedObject = (obj, props) => {
  let a = props.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    if (obj == null || obj == undefined) return undefined;
    let key = a[i];
    if (key in obj) {
      obj = obj[key];
    } else {
      return undefined;
    }
  }
  return obj;
};
Utils.safelyGetNumericNestedObject = (obj, props) => {
  return Utils.safeNumber(Utils.getNestedObject(obj, props));
};
Utils.safelySumNumericNestedValues = (objs, props) => {
  let retValue = 0;
  for (let obj of objs) {
    retValue += Utils.safelyGetNumericNestedObject(obj, props);
  }
  return retValue;
};
Utils.safeNumber = value => {
  //safe check for booleans
  if (value === true) return 1;
  //safe check for strings
  let returnValue = parseFloat(value);
  if (isNaN(returnValue)) return 0;
  return returnValue;
};
Utils.caseInsensitiveObjectForKey = (obj, key) => {
  if (!obj) return null;
  const insensitiveKey = Object.keys(obj).find(k => k.toLowerCase() === key.toLowerCase());
  if (insensitiveKey && insensitiveKey != '') return obj[insensitiveKey];
  return null;
};
Utils.toDoubleDigit = str => {
  return String('0' + str).slice(-2);
};
Utils.toDateFormat = str => {
  if (str != undefined) {
    return str.split('-').join('/');
  }
  return '';
};
Utils.toCurrencyFormat = str => {
  if (str !== undefined) {
    str = parseFloat(str);
    if (isNaN(str)) return '0.00';
    return str.toLocaleString('en', { minimumFractionDigits: '2', maximumFractionDigits: 2 });
  }
  return '0.00';
};
Utils.capitalizeString = (str) => {
  if (!str) return '';
  return str.toLowerCase().split(' ').map(w=> w.replace(/./, m=> m.toUpperCase())).join(' ');
}
//Currency manipulation
Utils.safelyFixCurrency = (value, toFix) => {
  if (!toFix) toFix = 2;
  let rountTo = Math.pow(10, toFix);
  return parseFloat(parseFloat(Math.round(value * rountTo) / rountTo).toFixed(toFix));
};

//FORM HELPER
Utils.defaultFormChangeHandler = (event, object) => {
  const targetID = event.target.id;
  let targetValue;
  //Choose where to get value
  if (event.target.type == 'checkbox') {
    targetValue = event.target.checked;
  } else if (event.target.type == 'select') {
    targetValue = event.target.value;
  } else {
    targetValue = event.target.value;
  }
  //set state
  object.state = Utils.setNestedObject(object.state, targetID, targetValue);
  object.setState({ ...object.state });
};

//DATES
Utils.getMonthYearDescriptByDate = date => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return (
    monthNames[date.getMonth() + 1] +
    '/' +
    date
      .getFullYear()
      .toString()
      .slice(2, 4)
  );
};
Utils.getMonthName = month => {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return monthNames[month - 1];
};
Utils.setReportDateRange = obj => {
  obj.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  //
  let date = new Date();
  obj.years = [];
  for (let i = -1; i < 2; i++) {
    obj.years.push(date.getFullYear() + i);
  }
  //default for filter
  obj.currentYear = obj.years[1];
  obj.currentPreviousMonth = date.getMonth(); //leave as index 0-11
};
Utils.addSecondsIntoTimestamp = (timestamp, seconds) => {
  return timestamp + seconds * 1000;
};
//Date formatting
Utils.getCurrentDateOnUIFormat = excludeDay => {
  return Utils.getDateOnUIFormat(new Date(), excludeDay);
};
Utils.getDateOnUIFormat = (date, excludeDay) => {
  if (!date || isNaN(date.getTime())) return '-';
  //optional day field
  if (excludeDay === undefined) excludeDay = false;
  let day = excludeDay ? '' : '/' + Utils.toDoubleDigit(date.getDate());
  //month is index from 0 to 11
  let month = Utils.toDoubleDigit(date.getMonth() + 1);
  return `${month}${day}/${date.getFullYear()}`;
};
Utils.getDateOnUIFormatByTimestamp = (timestamp, excludeDay) => {
  return Utils.getDateOnUIFormat(new Date(parseInt(timestamp, 10)), excludeDay);
};
//Date and time formatting
Utils.getCurrentDateAndTimeOnUIFormat = () => {
  return Utils.getDateAndTimeOnUIFormat(new Date());
};
Utils.getDateAndTimeOnUIFormatByTimestamp = timestamp => {
  return Utils.getDateAndTimeOnUIFormat(new Date(parseInt(timestamp, 10)));
};
Utils.getDateAndTimeOnUIFormat = d => {
  if (!d || isNaN(d.getTime())) return '-';
  return (
    [Utils.toDoubleDigit(d.getMonth() + 1), Utils.toDoubleDigit(d.getDate()), d.getFullYear()].join('/') +
    ' ' +
    [Utils.toDoubleDigit(d.getHours()), Utils.toDoubleDigit(d.getMinutes())].join(':')
  );
};
Utils.getMomentByTimestamp = (timestamp) => {
  return moment(new Date(parseInt(timestamp, 10)), Globals.DefaultUIDateTimeFormat);
}
Utils.timestampFromMoment = (date) => {
  return new Date(moment(date).format(Globals.DefaultUIDateTimeFormat)).getTime();
}

//REACT stuff
//propagate ref child to get referece
Utils.propagateRef = (parent, props) => {
  return {
    ref: _ref => Utils.setNestedObject(parent, props, _ref),
  };
};
Utils.ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;

//Ant design form helpers
Utils.defaultCurrenyInputFormatter = value => {
  return `$ ${value || parseFloat(0).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};
Utils.defaultCurrentInputParser = value => {
  return value.replace(/\$\s?|(,*)/g, '');
};

//Thread helper
Utils.shortRandSleep = async () => {
  const delay = Math.random() * (100 - 50) + 50;
  return new Promise(resolve => setTimeout(resolve, delay));
};

//Connection helper
Utils.execRequests = async (reqs, failureCallback, respCallback) => {
  //cleanup
  for (let reqIdx of reqs) {
    if (!reqs[reqIdx]) reqs[reqIdx] = new Promise((res) => res({statusCode: 200}));
  }
  //Make calls concurrency
  const resps = await Promise.all(reqs);
  const failure = resps.find(resp => resp && resp.statusCode != 200);
  if (failure) {
    if (failureCallback) await failureCallback(failure);
    return false;
  }
  // eslint-disable-next-line guard-for-in
  for (let respIdx in resps) if (respCallback) await respCallback(resps[respIdx], respIdx);
  return true;
};

//File handling
Utils.formatSizeUnits = bytes => {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + ' GB';
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + ' MB';
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + ' KB';
  } else if (bytes > 1) {
    bytes += ' bytes';
  } else if (bytes == 1) {
    bytes += ' byte';
  } else {
    bytes = '0 bytes';
  }
  return bytes;
};
Utils.readFile = async (file, _sendProgress) => {
  return new Promise((resolve, reject) => {
    function sendProgress(p) {
      if (_sendProgress) _sendProgress(p);
    }
    //
    const reader = new FileReader();
    reader.addEventListener('loadstart', () => sendProgress(0));
    reader.addEventListener('load', async () => {
      resolve(reader.result);
    });
    reader.addEventListener('loadend', () => sendProgress(100));
    reader.addEventListener('progress', e => sendProgress((e.total / e.loaded) * 100));
    reader.addEventListener('error', e => reject(e));
    reader.addEventListener('abort', e => reject(e));
    reader.readAsDataURL(file);
  });
};

Utils.copyFullObject = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};
Utils.truncateString = (string = '', limit = 20, ellipsis = '...') => {
  if (typeof string !== 'string') return string;

  const str = string.trim();
  return str.length > limit ? `${str.substr(0, limit)}${ellipsis}` : str;
}

//BROWSER stuff
Utils.downloadArrayBuffer = (data, fileName, fileType) => {
  const byteArray = new Uint8Array(data);
  const blob = new Blob([byteArray]);
  saveAs(blob, `${fileName}.${fileType}`);
}

Utils.getExtension = (filename) => filename.split(".").pop().toLocaleLowerCase();
Utils.GetIcon = (type, contentType, fileName) => {
  if (type === Globals.FileTypes.DOCKET) return docketIcon;
  const normalizeContentType = contentType.split(";")[0];
  const ext = Utils.getExtension(fileName);
  let icon = iconsList.find(i => Utils.matchWithWildcards(normalizeContentType, i.contentType));
  if (icon?.extensions.length) icon = icon.extensions.find(e => e.name === ext);
  return icon?.icon || documentIcon;
};

//Match strings with * (asterisk) as wildcards and return true/false
Utils.matchWithWildcards = (str, rule) => {
  const escapeRegex = (string) => string.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
  return new RegExp("^" + rule.split("*").map(escapeRegex).join(".*") + "$").test(str);
};
