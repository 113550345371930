import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import { Layout, Row, Col, Typography, Button, Table, Input, DatePicker,
         Radio, Divider, Checkbox, Form } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
//
import CustomComponent from '../../../ui-components/CustomComponent';
import CommonUploadNavigationView from './CommonUploadNavigationView';
import CommonAttributesSubform from '../../commonComponents/Attributes/CommonAttributesSubform';
import CommonTagSelect from '../../commonComponents/Attributes/CommonTagSelect';
import CommonConfirmationModal from '../../commonComponents/Modals/CommonConfirmationModal';
//
import Utils from '../../../components/helpers/Utils';
import Globals from '../../../config/Globals';
//
import '../../../assets/stylesheets/CommonEditAttributesNavigationView.scss';
//
const TabTypes = {
  FILES: 'file',
  ATTRS: 'attrs'
};
/* eslint max-classes-per-file: ["error", 2] */
class _CommonEditAttributesNavigationView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      selectedDocket: this.props.docket,
      files: this.props.files,
      isUnmounting: false,
      selectedFile: null,
      groupAttributes: TabTypes.FILES,
    };
  }

  //Life cycle
  componentDidUpdate() {
    if (this.state.files.length === 0 && !this.state.isUnmounting) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isUnmounting: true });
      this.props.navigationController.popToRootView();
    }
  }

  //Main actions
  handleCancelView() {
    this.props.navigationController.popView({
      popProps: { files: this.state.files }
    });
  }
  handleUpload() {
    if (!this.state.selectedDocket) return
    this.props.navigationController.pushView(
      <CommonUploadNavigationView app={this.props.app} controller={this.props.controller}
        files={this.state.files} docket={this.state.selectedDocket}/>
    );
  }
    //Table
  handleSelectFile(record) {
    this.setState({ selectedFile: { ...record, allFiles: false }}, () => {
      //Set default values after first render
      this.props.form.setFieldsValue({
        document_title: record.document_title,
        date: moment(new Date(record.date), Globals.DefaultUIDateTimeFormat),
      });
      this.attributesForm.setValues(record);
    });
  };
    //Modal actions
  handleFileDeleteModalOk() {
    const index = this.state.files.findIndex(file => file.rowKey === this.state.selectedFile.rowKey);
    this.setState({ selectedFile: null }, () => {
      this._removeFileAtIndex(index);
    });
  }
  handleFileDeleteModalCancel() {}
  handleFileDelete(file) {
    this.setState({
      selectedFile: { ...file, allFiles: false }
    }, () => {
      this.confirmationModal.show('Remove File', `Are you sure you want to remove “${file.document_title}” from your upload queue?`);
    });
  }
    //Drag and drop
  handleDragFile(dndEvent, docket) {
    if (dndEvent.approvals.length > 0) this._appendFiles(dndEvent.approvals, docket);
  }
    //Files form
  handleChangeSelectedFile(e) {
    if (e.preventDefault) e.preventDefault();

    let key = e.target.name;
    let value = e.target.value;
    const fileIndex = this.state.files.findIndex(file => file.rowKey === this.state.selectedFile.rowKey);
    let files = this.state.files;

    if (key.indexOf('form.attributes.') >= 0) {
      //regex should make a better job
      key = key.replace('form.attributes.', '');

      //check where to apply all attributes,
      //if no value remove the keys
      if (this.state.selectedFile.allFiles === true) {
        files = this.state.files.map(file => {
          file.attributes[key] = value;
          return (value || null);
        }).filter( f => (!!f));
      } else {
        if (value) files[fileIndex].attributes[key] = value;
        else delete files[fileIndex].attributes[key];
      }
      this.setState(oldValue => ({
        selectedFile: {
          ...oldValue.selectedFile,
          attributes: {
            ...oldValue.selectedFile.attributes,
            [key]: value,
          },
        },
        files,
      }));
    } else {
      files[fileIndex][key] = value;
      this.setState(oldValue => ({
        selectedFile: {
          ...oldValue.selectedFile,
          [key]: value,
        },
        files,
      }));
    }
  }
  handleDateChangeSelectedFile(date) {
    this.handleChangeSelectedFile({
      target: {
        name: 'date',
        value: new Date(moment(date).format(Globals.DefaultUIDateTimeFormat)).getTime(),
      }
    });
  }
  handleChangeSelectedFileTags(tags) {
    const safeTags = tags ? tags.map(t => t.value) : [];
    let files = this.state.files;
    const fileIndex = this.state.files.findIndex(file => file.rowKey === this.state.selectedFile.rowKey);
    files[fileIndex].tags = safeTags;
    //
    this.setState(oldValue => ({
      selectedFile: {...oldValue.selectedFile, tags: safeTags},
      files,
    }));
  }
  handleReplicateSelectedFile(e) {
    const value = !!e.target.checked;
    let files = this.state.files;
    if (value === true) {
      files = files.map(file => {
        file.attributes = Utils.copyFullObject(this.state.selectedFile.attributes);
        file.tags = Utils.copyFullObject(this.state.selectedFile.tags);
        return file;
      });
    }
    this.setState(oldValue => ({
      selectedFile: { ...oldValue.selectedFile, allFiles: value },
      files,
    }));
  }
  //Tab
  handleToggleGroupAttributes(e) {
    this.setState({ groupAttributes: e.target.value });
  }
  // UI
  render() {
    return (
      <Layout.Content className="commonTabContent EditAttributesNavigationView-content">
        {this._renderConfirmationModal()}
        {this._renderHeader()}
        {this._renderFiles()}
        <Row type="flex" justify="end" gutter={16}>
          <Col>
            <Button onClick={this.handleCancelView}>Cancel</Button>
          </Col>
          <Col>
            <Button type="primary" disabled={!!this.state.selectedDocket === false} onClick={this.handleUpload}>
              Upload
            </Button>
          </Col>
        </Row>
      </Layout.Content>
    );
  }

  /* private UI */
  _renderHeader() {
    return (
      <Row className="EditAttributesNavigationView-header">
        <Col span={24}>
          <Typography.Title level={3} style={{ marginBottom: 0 }}>
            Edit file(s)
          </Typography.Title>
          <Typography.Text disabled>
            {this.state.files.length} file(s) queued ({this._getUITotalSize(this.state.files)})
          </Typography.Text>
        </Col>
        <Col span={24} style={{ marginBottom: 24 }}>
          <Button type="ghost" onClick={this.handleCancelView} size="small">
            Change Docket
          </Button>
        </Col>
      </Row>
    );
  }
  _renderFiles() {
    return (
      <>
        <Row className="EditAttributesNavigationView-files">
          <Col span={24}>
            <Typography.Text>Edit files&apos; attributes or custom attributes:</Typography.Text>
          </Col>
          <Col span={24}>
            <Table rowKey="rowKey" rowSelection={{ type: 'radio', onSelect: this.handleSelectFile }}
              pagination={{ pageSize: 100, hideOnSinglePage: true }} dataSource={this.state.files}
              columns={this._getTableColumns()}/>
          </Col>
          {!!this.state.selectedFile && this._renderFileTab()}
        </Row>
        <Row>
          <Col span={24}>
            <Button onClick={this.props.app.dropController.openDialog}>Add Document</Button>
          </Col>
        </Row>
      </>
    );
  }
  //Files Tab
  _renderFileTab() {
    const vaultAttributes = this.props.app.sharedCache()?.getCurrentVaultAttributes();
    const account = this.props.app.sharedCache().getCurrentVaultAccount();

    return (
      <Col span={24} key={this.state.selectedFile}>
        <Row gutter={[5, 5]}><Divider/></Row>
        <Row type="flex" justify="space-between">
          <Col>
            <Typography.Text className="editFilesFileNameLabel">
              Editing {this.state.selectedFile.document_title}
            </Typography.Text>
          </Col>
          <Col>
            <Radio.Group buttonStyle="solid" className="editFilesRadioButton"
              value={this.state.groupAttributes}
              onChange={this.handleToggleGroupAttributes}>
              <Radio.Button value={TabTypes.FILES}>File Attributes</Radio.Button>
              {account.customAttributesEnabled && <Radio.Button value={TabTypes.ATTRS} disabled={!(vaultAttributes && vaultAttributes.length)}>
                Custom Attributes
              </Radio.Button>}
            </Radio.Group>
          </Col>
        </Row>
        {this._renderFileTabView()}
        {this._renderAttributesTabView()}
      </Col>
    );
  }
  _renderFileTabView() {
    const isVisible = (this.state.groupAttributes === TabTypes.FILES);
    const { getFieldDecorator } = this.props.form;
    return (
      <div style={{display: (isVisible ? 'block' : 'none')}}>
        <Row gutter={[16, 16]} type="flex" justify="space-between">
          <Col span={24}>
            <Form.Item label='Document Title' className="tagTitle">
              {getFieldDecorator('document_title', {
                rules: [{ required: true, message: 'Please, insert a name for the document!' }],
              })(<Input placeholder="Document Title" name="document_title"
                        onChange={this.handleChangeSelectedFile}
              />)}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label='Date' className="tagTitle">
              {getFieldDecorator('date', {
                rules: [{ required: true, message: 'Please, insert a date on the document!' }],
              })(<DatePicker style={{ width: '100%' }} name="date" onChange={this.handleDateChangeSelectedFile}
              />)}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label='Tag' className="tagTitle">
              <CommonTagSelect app={this.props.app}
                {...Utils.propagateRef(this, 'tagsSelect')}
                onChange={this.handleChangeSelectedFileTags}
                values={ this.state.selectedFile.tags ? this.state.selectedFile.tags.map(t => {
                      return { value: t, label: t };
                }) : []}/>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
  _renderAttributesTabView() {
    const isVisible = (this.state.groupAttributes === TabTypes.ATTRS);
    return (
      <div style={{display: (isVisible ? 'block' : 'none')}}>
      <CommonAttributesSubform editable app={this.props.app} form={this.props.form}
                               isDocument datasource={this}
                               {...Utils.propagateRef(this, 'attributesForm')}
                               onChange={this.handleChangeSelectedFile}/>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Checkbox name="allFiles"
              checked={!!this.state.selectedFile.allFiles}
              onClick={this.handleReplicateSelectedFile}>
              <Typography.Text strong>Apply to all files</Typography.Text>{' '}
              <Typography.Text disabled>Custom attributes will be applied to all files</Typography.Text>
            </Checkbox>
          </Col>
        </Row>
      </div>
    );
  }
  _renderConfirmationModal() {
    return (
      <CommonConfirmationModal onConfirmation={this.handleFileDeleteModalOk} confirmationText="Remove File"
                               cancellationText="Close" onCancel={this.handleFileDeleteModalCancel}
                               {... Utils.propagateRef(this, 'confirmationModal')}/>
                           );
  }
  //table
  _getTableColumns() {
    let columns = [{ title: 'Document Title', dataIndex: 'document_title' }];
    //Add attributes
    if (this.props.app.sharedCache()?.getCurrentVaultAttributes()) {
      this.props.app.sharedCache()?.getCurrentVaultAttributes()?.forEach(attribute => {
        if (attribute.hideFromUI !== true && attribute.enabledOnDocuments !== false && !!attribute.isMandatoryOnDocuments) {
          columns.push({ title: attribute.label, dataIndex: `attributes['${attribute.id}']` });
        }
      });
    }
    //Mobile support
    if (this.props.app.layout.isMobile) {
      columns.push({ title: 'Size', dataIndex: 'size', render: size => Utils.formatSizeUnits(size) });
      columns.push({ title: 'Date', dataIndex: 'date', render: date => Utils.getDateOnUIFormat(new Date(date)) });
    }
    //Actions
    columns.push({ title: 'Actions', render: (text, record) => (
      <Button type="ghost" onClick={() => this.handleFileDelete(record)}>
        <DeleteOutlined />
      </Button>
    )});
    //
    return columns;
  }
  //unit helper
  _getUITotalSize(files) {
    let total = 0;
    files.forEach(file => { total += file.size });
    return Utils.formatSizeUnits(total);
  }
  _removeFileAtIndex(index) {
    const files = this.state.files;
    files.splice(index, 1);
    this.setState({ files });
  }
  //DnD Helper
  _appendFiles(newFiles, docket) {
    newFiles = newFiles.filter((f) => !this.state.files.find((_f) => _f.path == f.path));
    let files = this.state.files.concat(newFiles);
    const safeDocket = docket || this.state.selectedDocket;
    if (safeDocket) {
      files = this._setFilesAttributes(this.state.files.concat(newFiles), safeDocket);
    }
    this.setState({files, selectedDocket: safeDocket});
  }
  _setFilesAttributes(files, docket) {
    return files.map(file => {
      file.attributes = {};
      if (docket.attributes) {
        docket.attributes.forEach(elem => {
          file.attributes[elem.id] = elem.value;
        });
      }
      file.tags = docket.tags;
      return file;
    });
  }
}
//Form drag and drop receive
const EditForm = Form.create()(_CommonEditAttributesNavigationView);
export default class CommonEditAttributesNavigationView extends React.Component {
  render() {
    return (<EditForm {...this.props} wrappedComponentRef={(ref) => {
      this.ref = ref;
    }}/>);
  }
  handleDragFile(files, docket) { this.ref.handleDragFile(files, docket); }
}
