import React from "react";
import autoBind from "react-autobind";
import { Row, Typography, Progress } from 'antd';
//Style
import '../../../assets/stylesheets/CommonOperationsProgressBar.scss';
//
//props: progress, progressLabel
export default class CommonOperationsProgressBar extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

 //UI
  render() {
    return (
      <Row className="progressBarContainer">
        <Progress percent={this.props.progress} showInfo={false} className="progressBar" />
        <Typography.Text className="progressBarLabel">{this.props.progressLabel}</Typography.Text>
      </Row>
    );
  }
}
