import React from 'react';
import autoBind from 'react-autobind';
import { Tooltip, Row, Layout, Table, Typography, Icon, Popover } from 'antd';
//
import Utils from '../../components/helpers/Utils';
//
import '../../assets/stylesheets/CommonUploadFilesTable.scss';
//props are: isLoading, files, maxSize (optional), app
//optional uploading behaviour props: statuses (optional), progress (optional), willAutoRetry (optional)
export default class CommonUploadFilesTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
    this.currentPage = 1;
    this.disablePageAutoJumping = false;
  }
  //UI
  render() {
    return (
      <Layout.Content className='commonUploadFilesTableContainer'
                      {...Utils.propagateRef(this, 'container')}>
        <Table className="uploadFilesTable"
               columns={this._tableColumns()} {...this._tableProps()}
               dataSource={(this.props.files ? this.props.files : [])}/>
      </Layout.Content>
    );
  }

  /* private */
  _tableProps() {
    return { rowKey: 'rowKey', loading: this.props.isLoading,
             onChange: this.handleFilterChange,
             locale: {emptyText: 'No files in the operations, this might be an error.'},
             pagination: this._getPagination(),
             bordered: false, size: 'small', onHeaderRow: this._tableHeaderProps,
             fixed: true, rowClassName: 'uploadFilesTableRow',
             //scroll: { y: 'calc(50vh)' }
    };
  }
  _getPagination() {
    const pageSize = this.props.maxSize || 100;

    if (!this.disablePageAutoJumping) {
      const files = this.props.files ? this.props.files : [];
      const pagedFiles = files.slice((this.currentPage - 1) * pageSize, this.currentPage * pageSize);

      const processedFiles = pagedFiles.map(file => this._getStatus(file))
        .filter(status => !['history', 'loading'].includes(status));

      if (processedFiles.length == pagedFiles.length) {
        this.currentPage += 1;
      }
    }

    return {
      pageSize,
      onChange: (page) => {
        this.disablePageAutoJumping = true;
        this.currentPage = page;

        this.forceUpdate();
      },
      hideOnSinglePage: true,
      current: this.currentPage,
    };
  }
  _tableHeaderProps() {
    return {
      className: 'uploadFilesTableHeader',
    }
  }
  _tableColumns() {
    return [{
      title: 'Document Title', key: 'document_title',  width: '75%',
      render: this._renderColFileName
     }, {
      title: 'Size', key: 'size',
      width: '120px', render: this._renderColFileSize
      }, ...(this.props.statuses ? [{
       title: 'Status', key: 'status',
       width: '80px', render: this._renderColOperationStatus
     }] : [])];
  }
  _onRow(/*record*/) {
    return {
      // onClick: () => {
      //   this.onRowSelection(record);
      // }, // click row
      // onDoubleClick: () => {
      //   this.onRowSelection(record);
      // }, // double click row
    };
  }
  /* ui private */
  _renderColFileName(props) {
    const maxChars = 20;
    const name = props.document_title || props.name;

    return (
      <Row className='uploadTableColFileName'>
        <Tooltip title={name?.length > maxChars ? name : ''}>
          <Typography.Text className='label'>{Utils.truncateString(name, maxChars)}</Typography.Text>
        </Tooltip>
      </Row>
    );
  }
  _renderColFileSize(props) {
    return (
      <Row className='uploadTableColFileSize'>
        <Typography.Text className='label'>{props && (props.size || props.fileSize) && Utils.formatSizeUnits(props.size || props.fileSize)}</Typography.Text>
      </Row>
    );
  }
  _renderColOperationStatus(props) {
    return (
      <Row className='uploadTableColStatus'>
        {this._getWrapperTooltip(props, <Icon type={this._getStatusIcon(props)} className='icon' />)}
      </Row>
    );
  }
  _getStatus(props) {
    const idx = props.operationID;
    const status = (this.props.statuses ? this.props.statuses[idx] : undefined);
    const progress = (this.props.progress ? this.props.progress[idx] : undefined);
    //checks
    if (status == undefined && progress == undefined) return 'history';
    else if (status && status.statusCode == 200) return 'check';
    else if (status && status.statusCode == 204) return 'warning';
    else if (status && (status.statusCode || status.status)) return (this.props.willAutoRetry ? 'question' : 'close');
    else return 'loading';
  }
  _getStatusIcon(props) {
    return this._getStatus(props);
  }
  _getTooltipText(props) {
    const idx = props.operationID;
    const status = (this.props.statuses ? this.props.statuses[idx] : undefined);
    const progress = (this.props.progress ? this.props.progress[idx] : undefined);
    //checks
    if (status == undefined && progress == undefined) return 'Queued';
    else if (status && status.statusCode == 200) return 'Completed!';
    else if (status && status.statusCode == 204) return 'Skipped!';
    else if (status && (status.statusCode || status.status)) return (this.props.willAutoRetry ? 'File upload failed. Don’t worry, we’ll retry the upload.' : 'Failed!');
    else return 'Uploading..';
  }
  _getWrapperTooltip(props, child) {
    const status = this._getStatus(props);
    if (status == 'close' || status == 'question') {
      const idx = props.operationID;
      const resp = this.props.statuses[idx];
      let errMessage = this.props.app.alertController._extractInternalAPIError(resp) || this.props.app.alertController._extractAxiosError(resp) || 'Unrecognized error!';
      return (<Popover title={this._getTooltipText(props)} content={errMessage}>{child}</Popover>);
    } else {
      return ( <Tooltip title={this._getTooltipText(props)} placement="left"> {child} </Tooltip> );
    }
  }
}
