import autoBind from 'react-autobind';
//
const AsyncQueue = require('run-queue');
//Prioruty Enum
const CMQueuePriority = {
  LOW: 9,
  DEFAULT: 1,
  HIGH: 0,
};
//
export default class ConnectionManager {
  constructor(auth) {
    autoBind(this);
    this.auth = auth;
    //this is defined at instance level to allow future customization, 
    //or even an increase whenever needed (desktop app for example)
    this.maxConcurrentQueues = 2;
    this.maxOperationsInQueue = 2;
    //Queue of queues level
    this.runningQueues = [];//ids
    this.startedQueuesPromises = {};//promise by ID
    //Queue level
    this.queues = {}; //operations queue
    this.queueObjects = {}; //operations
    this.queueCbs = {};//callbacks - sig. (operation, op.resp, index in queue)
  }
  getOperations(queueID) { return this.queueObjects[queueID] }
  enqueue(operation, queueID, operationID, optionPriority) {
    this._setupQueue(queueID);
    if (optionPriority == undefined) optionPriority = CMQueuePriority.DEFAULT;
    operation.identifier = queueID;
    operation.operationID = operationID || Date.now();
    //add into queue and keep object reference
    this.queueObjects[queueID].push(operation);
    //
    const args = [operation, (this.queueObjects[queueID].length - 1)];
    this.queues[queueID].add(optionPriority, this._runOperation, args);
  }
  async runQueue(id, startCb, endCb) {
    return new Promise((resolve) => {
      //Setup and save
      this._setupQueue(id);
      this.queueCbs[id] = [startCb, endCb];
      this.startedQueuesPromises[id] = resolve;
      //Attempt to deuque on another 'thread' by not
      //waiting this promise on the current returned promise
      this._attemptQueueDequeue();
    });
  }
  async abortQueue(id) {
    this._setupQueue(id);
    for (let ops of this.queueObjects[id]) ops.cancel();
    this._resetQueue(id);
    //Attempt to dequeue other queues when 
    //cancellations happens
    this._attemptQueueDequeue();
  }

  /* privates queue operations */
  _setupQueue(id) {
    if (!this.queues[id]) this._resetQueue(id);
  }
  _resetQueue(id) {
    //Cleanup - GBC
    this.queues[id] = null;
    this.queueCbs[id] = null;
    //
    this.queues[id] = new AsyncQueue({ maxConcurrency: this.maxOperationsInQueue });
    this.queueObjects[id] = [];
    this.queueCbs[id] = null;
  }
  _runOperation(operation) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise( async (resolve, reject) => {
      const queueID = operation.identifier;
      const opID = operation.operationID;
      if (!this.queueCbs[queueID]) {
        //Queue reset while promise ongoing
        //eslint-disable-next-line prefer-promise-reject-errors
        reject('Aborted!');
        return;
      } 
      const callbackReference = this.queueCbs[queueID][1];
      //Start delegate
      if (this.queueCbs[queueID] && this.queueCbs[queueID][0]) this.queueCbs[queueID][0](operation, opID);
      //Execute
      let resp = null;
      try { resp = await operation.start(); }
      catch (e) { 
        console.log(e);
        resp = { statusCode: -1, body: { message: e.message }} 
      }
      console.debug('Operation resp', resp, queueID, opID);
      //Remove from queue
      this._dequeue(operation, queueID);
      //Error handling is left with callback
      if (callbackReference) {
        const cbResp = await (callbackReference(operation, resp, opID));
        if (cbResp || cbResp == undefined) resolve(resp);
        else reject(resp);
      } else resolve(resp);
    });
  }
  _dequeue(operation, queueID) {
    const objs = this.queueObjects[queueID];
    const index = objs.indexOf(objs.find((op) => op.operationID == operation.operationID));
    if (index && index < 0) return;
    objs[index].onProgress = null; //this will remove the handler from the operation - GBC
    if (objs) this.queueObjects[queueID].splice(index, 1);
  }
  /* private - queue of queues */
  async _attemptQueueDequeue() {
    //eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      if (this.runningQueues.length >= this.maxConcurrentQueues) {
        console.debug(`Max running queues. Started count: ${this.runningQueues.length}/${Object.keys(this.startedQueuesPromises).length}`);
        resolve();
        return;
      }
      //need to dequeue?
      const dequeueID = Object.keys(this.startedQueuesPromises)[this.runningQueues.length];
      if (this.runningQueues.length == Object.keys(this.startedQueuesPromises).length || !dequeueID) {
        console.debug(`No messages to be dequeued! ${this.runningQueues.length}/${Object.keys(this.startedQueuesPromises).length}`);
        resolve();
        return;
      }
      //dequeue
      this.runningQueues.push(dequeueID);
      console.debug('Dequeued: ', dequeueID);
      console.debug(`Running queues: ${this.runningQueues.length}/${Object.keys(this.startedQueuesPromises).length}`);
      //exec dequeued queue
      const queueResp = await this.queues[dequeueID].run();
      console.debug('Queue Resp', dequeueID, queueResp);
      this.startedQueuesPromises[dequeueID](queueResp);
      //cleanup
      delete this.startedQueuesPromises[dequeueID];
      this.runningQueues.splice(this.runningQueues.indexOf(dequeueID), 1);
      //
      console.debug(`Queue completed! Running queues: ${this.runningQueues.length}/${Object.keys(this.startedQueuesPromises).length}`);
      //dequeue possible queues that entered on 'enqueued' state while
      //this execution
      this._attemptQueueDequeue();
      //
      resolve();
    })
  }
}
