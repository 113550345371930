import { Component } from 'react';
import autoBind from 'react-autobind';
//props are: mobileBreakpoint
export default class BreakpointListener extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isMobile: window.innerWidth <= (props.mobileBreakpoint || 600) };
  }

  //Getters
  get isMobile() { return this.state.isMobile; }
  
  //Life cycle
  componentDidMount() {
    this._startListeners();
  }
  componentWillUnmount() {
    this._stopListeners();
  }

  //UI
  render() {
    return this.props.children;
  }

  /* private */
  _handleWindowResize() {
    const isMobile = window.innerWidth <= (this.props.mobileBreakpoint || 600);
    if (isMobile && !this.state.isMobile) {
      this.setState({ isMobile: true });
    } else if (!isMobile && this.state.isMobile) {
      this.setState({ isMobile: false });
    }
  }
  _startListeners() {
    window.addEventListener('resize', this._handleWindowResize);
  }
  _stopListeners() {
    window.removeEventListener('resize', this._handleWindowResize);
  }
}
