import React from 'react';
import autoBind from 'react-autobind';
import { Divider, Layout, Row, Col, Typography, Icon, Button, Badge, Progress, Tag, Tooltip, notification, message, Spin } from 'antd';
//
import CommonUploadFileErrorModal from '../../../views/commonComponents/Modals/CommonUploadFileErrorModal';
import CommonOperationCancelModal from '../../../views/commonComponents/Modals/CommonOperationCancelModal';
import BasicOperationView from '../BasicOperationView';
//
import Utils from '../../../components/helpers/Utils';
import IPCManager from '../../../components/subcomponents/IPCManager';
//
import '../../../assets/stylesheets/UploadOperationView.scss';
//
//props are: app, docket, files, onStartOver, operationsController, onParentUpdate, onOperationDidClose
//optional: viewless, onOperationDidFinished
export default class UploadOperationView extends BasicOperationView {
  constructor(props) {
    super(props);
    autoBind(this);
    this.errorAlertKey = null; //Assigned at the start upload moment
    this.state = {
      //Operation
        completed: false, autoRetryInProgress: false, uploadStarted: false,
        cancelModalVisible: false,
        totalBytes: 0, //total bytes to be uploaded
        //file responses - index keyed in an object
        //so when concurrency is > 1 we still deal
        //with the statuses properly :p
        statuses: {},
        uploadedBytes: {},
        isUnmounting: false,
      //View
      isParentVisible: !this.props.viewless,
      parentGotVisibleAfterCompletion: false,
      isVisible: true
    };
    this.completionTimeout = null; //used after success completion
    this.queueID = null; //assigned dynamically
  }

  //Life cycle
  componentWillUpdate() { if (this.props.onParentUpdate) this.props.onParentUpdate(); }

  //Public actions
  start() {
    this.props.app.analytics.logEvent('Upload', 'Started');
    this.props.app.analytics.logEvent('Upload', 'Number of Documents', { value: this.props.files.length });
    this._enqueueUploads();
    this._startUpload();
  }
  cancel(e) {
    if (e) { e.preventDefault(); e.stopPropagation(); }
    if (!this.state.completed) this.setState({ cancelModalVisible: true });
  }

  //Public upload specific
  get isUploadCompleted() { return this.state.completed; }
  get isAutoRetryInProgress() { return this.state.autoRetryInProgress; }
  get isUnmounting() { return this.state.isUnmounting; }
  set isUnmounting(val) { this.setState({ isUnmounting: val }); }
  get failedFiles() { return this._getFailedFiles(); }
  get filesStatuses() { return this.state.statuses; }
  get totalUploadBytes() { return this.state.totalBytes; }
  get totalUploadedBytes() { return this.state.uploadedBytes; }
  get totalUploadProgressPercentage() { return this._getTotalProgress(); }
    //overrides to BasicOperationView
  get isVisible() { return (super.isVisible && !this.state.isParentVisible && !this.state.parentGotVisibleAfterCompletion); }
  set isParentVisible(val) { this.setState({ isParentVisible: val, ...(val && this.state.completed ? { parentGotVisibleAfterCompletion: true } : {})}); }
    //methods
  retryFailedFiles() { this.retryModal.show(this._getFailedFiles()); }


  //UI
  render() {
    const progressPercentage = this.totalUploadProgressPercentage;
    const failedFiles = this.failedFiles;
    const hasFailedFiles = (failedFiles && failedFiles.length > 0 && this.isUploadCompleted);
    return super.render(
      <Layout.Content className="uploadOperationView">
        {/* Modals */}
        {this._renderCancelModal()}
        {this._renderRetryModal()}
        {/* Upload components (In Upload) */}
        <div onClick={this.props.onClick || (() => { })}>
          {this._renderUploadContainerRow(progressPercentage)}
          {(hasFailedFiles || !this.isUploadCompleted) && <Row type="flex" justify="center" className="uploadProgressButtonsRow">
            <Divider />
            {!this.isUploadCompleted && <Button type="secondary" className='noStyle' size="small" onClick={this.cancel}>Cancel Upload</Button>}
            {hasFailedFiles && <Button size='small' type='primary' onClick={this.retryFailedFiles}>Retry All</Button>}
          </Row>}
        </div>
      </Layout.Content>
    );
  }

  /* Helpers */
  _getTotalProgress() {
    const total = this.state.totalBytes || 0;
    const totalUploaded = Object.values(this.state.uploadedBytes).reduce((acc, val) => (acc + parseFloat(val)), 0);
    let totalProg = parseFloat((totalUploaded * 100) / total).toFixed(2);
    if (isNaN(totalProg)) return 0;
    return Math.max(Math.min(totalProg, 100), 0);
  }
  _getFailedFiles() {
    let failedFiles = [];
    for (let key of Object.keys(this.state.statuses)) {
      if (this.state.statuses[key] && (this.state.statuses[key].status != 200 && this.state.statuses[key].statusCode != 200 && this.state.statuses[key].statusCode != 204)) failedFiles.push(this.props.files.find((f) => f.operationID == key));
    } return failedFiles;
  }
  /* Private UI */
  _renderProgressLabel(progressPercentage) {
    if (!this.state.uploadStarted || Object.keys(this.state.uploadedBytes).length == 0) {
      if (this.state.uploadStarted) return 'Preparing upload...';
      else return 'Waiting on queue...';
    } else {
      const filesLabel = `file${(this.props.files?.length || 0) > 1 ? 's' : ''}`;
      if (progressPercentage == 0) return 'Starting upload...';
      else if (this.state.isUnmounting) return 'Cancelling upload...';
      else if (this.isUploadCompleted) {
        const failedFiles = this.failedFiles;
        const hasFailedFiles = (failedFiles && failedFiles.length > 0);
        return (
          <Typography.Text>
            {hasFailedFiles && <> <Icon type="warning" theme="filled" />  Upload completed with {failedFiles.length} <strong>failed</strong> uploads of {this.props.files.length} {filesLabel}!</>}
            {!hasFailedFiles && <> <Icon type="check-circle" theme="filled" /> Upload completed ({this.props.files.length - (failedFiles || []).length} {filesLabel}) </>}
          </Typography.Text>
        );
      } else return (
        <Typography.Text>
          Uploading {this.props.files?.length || 0} {filesLabel}{'.. '}
          {this._renderProgressBadgesRow(progressPercentage)}
        </Typography.Text>
      );
    }
  }
  _renderProgressBadgesRow(progressPercentage) {
    if (progressPercentage <= 0 || this.isUploadCompleted) return (<></>);
    return (
      <span className='uploadProgressBadges'>
        {(() => {
          const inprogress = (this.props.files?.length || 0) - (Object.values(this.filesStatuses || {}).length || 0);
          if (inprogress > 0) return <Tooltip title='Enqueued uploads'><Badge color='blue' text={inprogress} />{' '}</Tooltip>
          else return '';
        })()}
        <Tooltip title='Completed uploads'><Badge color='green' text={Object.values(this.filesStatuses || {}).filter((s) => s.statusCode == 200 || s.statusCode == 204).length || 0} /></Tooltip>
        {(() => {
          const failures = this.failedFiles.length;
          if (failures > 0) return <Tooltip title='Failed uploads'>{' '}<Badge color='red' text={failures} /></Tooltip>
          else return '';
        })()}
      </span>
    );
  }
  _renderUploadContainerRow(progressPercentage) {
    const failures = this.failedFiles.length > 0 && this.state.completed;
    return (
      <Row type='flex' className="uploadContainerRow" justify="start" align="middle">
        <Col span={3}>
          <Row justify='center' type='flex'><Icon className='upload-icon' type='cloud-upload' /></Row>
          {(this.totalUploadBytes > 0) && <Row justify='center' type='flex'>
            <Col> <Typography.Text className="uploadSizeLabel">{Utils.formatSizeUnits(this.totalUploadBytes)}</Typography.Text> </Col>
          </Row>}
        </Col>
        <Col span={17} offset={1}>
          <Row type='flex' align='middle'>
            <Col>
              <Typography.Text className="uploadTitleMain">Docket:</Typography.Text>
              <Typography.Text className="uploadTitleSub">{this.props.docket.name}</Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col>
              {(this.isAutoRetryInProgress && !this.state.completed) && <Tag color='#F88B0B' className='uploadAutoRetryTag'>Auto Retrying</Tag>}
              <Typography.Text className="uploadProgressLabel">{this._renderProgressLabel(progressPercentage)}</Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col justify="center" align="middle" span={3}>
          {progressPercentage <= 0 ?
            <Spin className='uploadProgressCircle' indicator={<Icon type="loading" style={{ fontSize: 34 }} spin />} /> :
            <Progress type="circle" className="uploadProgressCircle"
              strokeColor={(failures ? '#E00000' : { '0%': '#FECA9C', '100%': '#F88B0B' })}
              /* eslint-disable-next-line no-nested-ternary*/
              status={(failures ? 'exception' : progressPercentage < 100 ? 'active' : 'success')}
              percent={Number(parseFloat(progressPercentage).toFixed(0))} width={40}
            />}
        </Col>
      </Row>
    );
  }
  /* Modals */
  _renderCancelModal() {
    return (
      <CommonOperationCancelModal isVisible={this.state.cancelModalVisible}
        title={'Cancel Upload'}
        subtitle={'Are you sure you would like to cancel the upload? You will not lose any completed operation.'}
        cancelText={'No, continue uploading'} retryText={'Yes, stop upload'}
        onHide={() => { this.setState({ cancelModalVisible: false }); }}
        onCancel={this._abortOperations}/>
    );
  }
  _renderRetryModal() {
    return (
      <CommonUploadFileErrorModal {...Utils.propagateRef(this, 'retryModal')}
        onRetry={this._retryFailedFiles} onLastFileRemoval={this.props.onStartOver}
      />
    );
  }
  /* private queue */
  async _retryFailedFiles(failedFiles, autoRetry) {
    console.debug('Running retry, auto: ', autoRetry, failedFiles);
    this.props.app.analytics.logEvent('Upload', (autoRetry ? 'AutoRetry' : 'Retry'));
    this.isCompleted = false;
    this.setState({ autoRetryInProgress: autoRetry }, () => {
      if (!autoRetry) this.state.uploadedBytes = {}; //reset uploaded, otherwise just reset some
      this._enqueueUploads(failedFiles, autoRetry); //enqueue just failed files
      //reset statuses (if inside failedFiles, otherwise set as skipped)
      for (const failedFile of this._getFailedFiles()) {
        const retryFailedFile = failedFiles.find((f) => f.operationID == failedFile.operationID);
        if (retryFailedFile) {
          delete this.state.statuses[failedFile.operationID];
          delete this.state.uploadedBytes[failedFile.operationID];
        } else {
          this.state.statuses[failedFile.operationID] = { statusCode: 204, body: { message: 'Skipped' } };
          this.state.uploadedBytes[failedFile.operationID] = failedFile.size; //might fake this to complete the upload bar (progress is not 100% accurate now :/)
        }
      }
      //Start upload
      this._startUpload();
    });
  }
  _enqueueUploads(overrideFiles, doNotResetProgress) {
    const userID = this.props.app.idm.session.authorization.getUserID();
    //Reset this docket queue
    this.props.app.operationsController.connectionManager.abortQueue(this.queueID);
    let totalBytes = 0;
    this.queueID = `docket_upload_${this.props.docket.id}+${Date.now()}`;
    //enqueue operations for each specified file
    /*eslint guard-for-in: "off"*/
    for (const fileIdx in (overrideFiles || this.props.files)) {
      const file = (overrideFiles || this.props.files)[fileIdx];
      //Assign operation to file
      if (!file.operationID) file.operationID = Date.now() + '-' + fileIdx;
      //Create operation
      const attrs = Object.keys(file.attributes).map((key) => ({ id: key, value: file.attributes[key] })) || [];
      const operation = this.props.app.api.newDocumentUploadOperation({
        ...this.props.docket, docketID: this.props.docket.id, userID
      }, file, attrs, file.tags || []);
      //Set delegate call to progress
      operation.onProgress = (p) => this._onOperationProgress(operation, file, p);
      //enqueue
      this.props.app.operationsController.connectionManager.enqueue(operation, this.queueID, file.operationID);
      //sum total bytes
      totalBytes += file.size;
    }
    this.setState({ totalBytes: (doNotResetProgress ? this.state.totalBytes : totalBytes), uploadStarted: false });
  }
  async _startUpload() {
    //Setup
    this.errorAlertKey = Date.now() + '';
    if (!this.state.autoRetryInProgress) this.props.app.ipcManager.sendEvent(IPCManager.WebEvents.EVENT_UPLOAD_STARTED);
    //Start queue

    await this.props.app.operationsController.connectionManager.runQueue(this.queueID, this._uploadOperationDidStart, this._uploadOperationDidFinish);
    if (!this._isMounted) return;
    //make sure we close since we will produce a modal with the error
    notification.close(this.errorAlertKey);
    //Check for auto retry if any failed and not auto retrying already
    const failedFiles = this._getFailedFiles();
    if (!this.state.isUnmounting && !this.state.autoRetryInProgress && failedFiles.length > 0 &&
      failedFiles.length < (this.props.files.length / 2) /* failure rate is below 50% of the files*/) {
      message.warn('Auto retrying failed upload!');
      this._retryFailedFiles(this._getFailedFiles(), true);
    } else { //Normal completion flow
      const failures = this._getFailedFiles().length > 0;
      //set as completed
      this.isCompleted = true;
      //Send ipc
      this.props.app.ipcManager.sendEvent(failures ? IPCManager.WebEvents.EVENT_UPLOAD_FAILED : IPCManager.WebEvents.EVENT_UPLOAD_COMPLETED);
      //If viewless start countdown
      if (this.props.viewless && !failures) this._startCompletionTimeout();
      //Completed with success, someone might be listening (before the operation is closed)
      if (!failures && this.props.onOperationDidFinished) this.props.onOperationDidFinished();
    }
  }
  _abortOperations() {
    if (this.state.isUnmounting) return;
    this.setState({ cancelModalVisible: false, isUnmounting: true }, () => {
      this.props.onStartOver();
    });
    this.props.app.operationsController.connectionManager.abortQueue(this.queueID);
  }

  /* viewless functionlity */
  _startCompletionTimeout() {
    if (this.completionTimeout) return;
    this.completionTimeout = setTimeout(this.close.bind(this), 3500);
  }

  /* Upload callbacks (queue & operation) */
  _uploadOperationDidStart(_operation, operationID) {
    console.debug('operation started', operationID);
    if (!this._isMounted) return;
    this.setState({
      uploadStarted: true,
      uploadedBytes: {
        ...this.state.uploadedBytes,
        [operationID]: 0,
      },
    });
  }
  // eslint-disable-next-line no-unused-vars
  _uploadOperationDidFinish(operation, resp, operationID) {
    if (!this._isMounted) return false;
    this.setState({ statuses: { ...this.state.statuses, [operationID]: resp } }, () => {
      if (resp.statusCode == 200) {

        this.props.app.ipcManager.sendEvent(IPCManager.WebEvents.EVENT_FILE_UPLOADED, { ...this.props.files.find((f) => f.operationID == operationID) || {} });
        this.props.app.analytics.logEvent('Upload', 'FileOperationSucceeded');
      } else {
        if (resp.error && resp.error.message !== 'User cancelled the upload!') {
          const errorCount = this._getFailedFiles().length;
          notification.error({
            message: 'Upload failed', key: this.errorAlertKey, duration: 0,
            description: (errorCount > 1 ? `${errorCount} Files have failed to be uploaded! You will be able to retry them at the end.` : '1 File have failed to be uploaded! You will be able to retry it at the end.')
          });
          this.props.app.analytics.logEvent('Upload', 'FileOperationFailed');
        } else {
          this.props.app.analytics.logEvent('Upload', 'FileOperationCanceled');
        }
      }
    });
    return true; //continue with fail
  }
  // eslint-disable-next-line no-unused-vars
  _onOperationProgress(operations, file, event) {
    if (!this._isMounted) return;
    this.setState({
      uploadedBytes: {
        ...this.state.uploadedBytes,
        [file.operationID]: event.event.loaded,
      },
    });
  }
}
