import React from 'react';
import autoBind from 'react-autobind';
import { Row, Col, Select, Checkbox, Typography, Popover, Icon } from 'antd';
//
import Utils from '../../../components/helpers/Utils';
import Globals from '../../../config/Globals';
//
import CommonSearchFilterRules from './CommonSearchFilterRules';
//
import '../../../assets/stylesheets/CommonSearchFilter.scss';
//
export default class CommonSearchFilter extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      type: this.props.parsedQueryParams[Globals.URLQueryParam_SearchType] || Globals.SearchTypes.ALL,
      quantifier: this.props.parsedQueryParams[Globals.URLQueryParam_SearchQuantifier] || Globals.SearchQuantifier.ALL,
    };
  }
  hasFilters() { return (this.filterRules && this.filterRules.hasFilters()); }
  getSearchFilter() {
    const haveFilters = this.hasFilters();
    if (!haveFilters) return null;
    //
    return {
      type: this.state.type,
      quantifier: (haveFilters ? this.state.quantifier : Globals.SearchQuantifier.ALL),
      filters: this.filterRules.getFilters()
    };
  }
  //Actions
  handleTypeChange(id, value) {
    this.props.persistentSearch.setParams({ [Globals.URLQueryParam_SearchType]: value });
    this.setState({[id]: value}, this.props.onChange);
  }
  handleQuantifierChange(value) {
    const newValue = (value.target.checked ? Globals.SearchQuantifier.ALL : Globals.SearchQuantifier.ANY);
    this.props.persistentSearch.setParams({ [Globals.URLQueryParam_SearchQuantifier]: newValue });
    this.setState({quantifier: newValue}, this.props.onChange);
  }
  //UI
  render() {
    return (
      <Row style={this._defaultStyle()} className="commonSearchFilter">
        {this._renderMainRow()}
        <CommonSearchFilterRules quantifier={this.state.quantifier} app={this.props.app} parsedQueryParams={this.props.parsedQueryParams}
                                 {... Utils.propagateRef(this, 'filterRules')}/>
      </Row>
    );
  }

  /* private UI */
  _defaultStyle() {
    return { display: this.props.visible ? 'block' : 'none' };
  }
  _renderMainRow() {
    return (
      <Row className='mainOptionsRow' type='flex' align='middle'>
        <Col offset={1} className='prefixLabelCol'> <Typography.Text>Search: </Typography.Text> </Col>
        <Col className='optionsCol'>
          <Select value={this.state.type} onChange={this.handleTypeChange.bind(this,'type')}>
            {Object.keys(Globals.SearchTypes).map( (key, index) => {
                return (<Select.Option key={index} value={key}>
                  {Globals.GetTextSearchByType(Globals.SearchTypes[key])}
                </Select.Option>);
            })}
          </Select>
        </Col>
        <Col className='checkboxCol'>
          <Checkbox checked={this.state.quantifier == Globals.SearchQuantifier.ALL}
                    onChange={this.handleQuantifierChange}/>
        </Col>
        <Col className='checkboxLabelCol'>
          <Typography.Text>Search results match all rules</Typography.Text>
        </Col>
        {this._renderTooltipCol()}
      </Row>
    );
  }
  _renderTooltipCol() {
    const content = (
      <div className='searchFilterTooltipContent'>
        <Typography.Text>
          When this option is not selected, search results will be returned if any of the rules matches entries in the database.
          If selected, the search will only return results that match all rules defined.
        </Typography.Text>
      </div>
    );
    return (
      <Col className='tooltipCol'>
        <Popover content={content}> <Icon type="info-circle"/> </Popover>
      </Col>
    )
  }
}
