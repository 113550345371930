import { Layout } from 'antd';
import React from 'react';
import autoBind from 'react-autobind';
//
import AppRoutes from './AppRoutes';
import config from './config/config';
import IPCManager from './components/subcomponents/IPCManager';
//
import CommonLoadingView from './views/commonComponents/CommonLoadingView';
import CustomComponent from './ui-components/CustomComponent';
//
import './assets/stylesheets/AppViewController.scss';
import CommonInvitationModal from './views/commonComponents/Modals/CommonInvitationModal';
import AppBar from './views/commonComponents/AppBar/AppBar';
//props are: app, history
export default class AuthorizedViewController extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    // reset history state if restarting app
    this.props.app.props.history.location.state = undefined;
    this.handleLogout = this.handleLogout.bind(this);
    document.title = config.ApplicationName;
    this.state = { isLoading: false };
  }

  // Actions
  async handleLogout() {
    this.setState({ isLoading: true }, async () => {
      this.props.app.ipcManager.sendEvent(IPCManager.WebEvents.EVENT_SESSION_ENDED);
      this.props.app.urlManager.redirectToLogout();
    });
  }
  handleChangePassword() { this.props.app.idm.user.changePassword(); }

  // Helpers
  checkForValidAuthorization() {
    if (!(this.props.app.isVaultAdmin() || this.props.app.isUser())) {
      //Changing this to address admin cases and redirect then to launch control
      this.props.app.urlManager.redirectToLogin();
      return true;
    } return false;
  }

  // UI
  render() {
    // this check will redirect user out of application, if authorization is not valid for some reason
    if (this.checkForValidAuthorization()) return <></>;
    //Default app render
    return this._renderPage();
  }

  /* private methods */
  _renderPage() {
    const { hideAppHeader, appType } = this.props.app.launchConfigManager.launchConfig || {};
    return (
      <>
        {!hideAppHeader ?
          <AppBar app={this.props.app} changePasswordHandler={this.handleChangePassword} logoutHandler={this.handleLogout}/> :
          <section style={{height: 20, width: '100%'}}>{' '}</section>
        }
        <Layout className={hideAppHeader ? "container-layout-headerless" : "container-layout"}>
          <Layout>
            <CommonInvitationModal app={this.props.app} />
            <Layout.Content className="main-content">
              <CommonLoadingView isLoading={this.state.isLoading} />
              <AppRoutes appRef={this.props.app} appType={appType}/>
            </Layout.Content>
          </Layout>
        </Layout>
      </>
    );
  }
}
