import React from "react";
import autoBind from "react-autobind";
import { Typography } from "antd";
//
import Globals from "../../../../config/Globals";
//
import CommonTransferCaseModalBase from "./CommonTransferCaseModalBase";
//
import "../../../../assets/stylesheets/CommonTransferModal.scss";
//
//props: app, dockets, documents, duplicatedDockets, title, step, isLoading, vaultID, docketID, selectedDocket
// onSelectDocket, showCreateDocket, onCreateDocket, onCreateNewDocket, onCreateDocketCancel
export default class CommonTransferCaseTwoModal extends CommonTransferCaseModalBase {
  constructor(props) {
    super(props);
    autoBind(this);
  }

 //UI
  render() {
    return (
      <>
        <Typography.Text>{this.props.title}</Typography.Text>
        {this.props.step >= 1 && this.renderSelectDocket()}
        {this.props.isLoading && this.props.step !== 1 && this.renderLoading()}
        {!this.props.isLoading && this.props.step === 2 && this.props.docketID && this.renderList(Globals.Transfer_Type.DOCUMENT)}
      </>
    );
  }
}
