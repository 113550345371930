import React from 'react';
import autoBind from 'react-autobind';
//Views
import SettingUpVaultView from './SettingUpVaultView';
import WizardView from './WizardView';
import NoAccountAccessView from './NoAccountAccessView';
//props are: app
export default class CommonAppControlView extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //UI
  render() {
    const isAccountReadLocked = this.props.app.sharedCache().isCurrentVaultAccountReadLocked();
    return (
      <>
        {!isAccountReadLocked && this.props.app.showWizard && <WizardView app={this.props.app} />}
        {!isAccountReadLocked && !this.props.app.showWizard && this.props.app.isSettingUp && <SettingUpVaultView app={this.props.app} />}
        {!isAccountReadLocked && !this.props.app.showWizard && !this.props.app.isSettingUp && this.props.children}
        {isAccountReadLocked && <NoAccountAccessView app={this.props.app}/>}
      </>
    );
  }
}
