import React from 'react';
import { Route } from 'react-router-dom';
//
export default ({ component: C, appRef, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return <C {...props} app={appRef}/>;
      }}
    />
  );
};
