import React from 'react';
import autoBind from 'react-autobind';
import { Row, Button, Modal, Typography } from 'antd';
//
import CustomComponent from '../../../ui-components/CustomComponent';
//
import '../../../assets/stylesheets/CommonOperationCancelModal.scss';
//
// PROPS: title, subtitle, onHide, onCancel
export default class CommonOperationCancelModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  //Actions
  handleHide() { this.props.onHide(); }
  handCancel() { this.props.onCancel() }
  //UI
  render() {
    return (
      <Modal maskClosable={false} title={this.props.title}
             visible={this.props.isVisible} closable={false}
             footer={null} centered className='operationCancelModal'>
        {this._renderSubtitle()}
        {this._renderButtons()}
      </Modal>
    );
  }
  /* UI private */
  _renderSubtitle() {
    return (
      <Row className='subtitleLabelRow'>
        <Typography.Text className='subtitleLabel'>{this.props.subtitle}</Typography.Text>
      </Row>
    );
  }
  _renderButtons() {
    return (
      <Row type='flex' justify='end' style={{ marginTop: 24 }}>
        <Button type="primary" className='cancelButton'
                onClick={this.handleHide}>{this.props.cancelText ? this.props.cancelText : 'No'}</Button>
        <Button type="secondary" className='retryButton'
                onClick={this.handCancel}>{this.props.retryText ? this.props.retryText : 'Yes'}</Button>
      </Row>
    );
  }
}
