import React from 'react';
import autoBind from 'react-autobind';
import { Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
//
import vaultIcon from '../../../assets/images/icons/vault.svg';
//
//Props: app, onSelectVault, icon, search
export default class CommonVaultSearch extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.vaultID = this.props.vaultID;
    this.vaults = this.props.app.sharedCache().getVaults().filter(vault => !vault.isSettingUp && vault.bucketID);
  }
  handleSelect(vaultID) {
    const vault = this.vaults.find(v => v.id === vaultID);
    this.props.onSelectVault({id: vault.id, name: vault.name});
  }
  render() {
    return (
      <Select
        suffixIcon={this.props.search ? <SearchOutlined /> : null}
        showSearch={!!this.props.search}
        filterOption={(f, o) => o.props.children[o.props.children.length -1].toLowerCase().indexOf(f.toLowerCase()) >= 0}
        onChange={this.handleSelect}
        style={{width: '100%'}}
        placeholder="Search for a Vault"
      >
      {this._renderOptions()}
      </Select>
    );
  }
  _renderOptions() {
    return this.vaults.map((vault, i) => (
      vault.id !== this.vaultID && (
        <Select.Option key={i} value={vault.id}>
          {this.props.icon && <img src={vaultIcon} alt="Vault" style={{marginRight: '5px'}} />}{vault.name.trim()}
        </Select.Option>
      )
    ))
  }
}
