import autoBind from 'react-autobind';
import LightningIPC from './Lightning-IPC';
import LightningIPCPacket from './Lightning-IPCPacket';
//
export default class LightningIPCController {
  constructor(auth) {
    autoBind(this);
    this.auth = auth;
    this.ipc = new LightningIPC();
    this.ipc.didReceiveMessage = this.handleDidReceiveMessage;
  }
  isAvailable() { return this.ipc.isAvailable(); }
  /* public */
  sendEvent(eventType, metadata) {
    const msg = new LightningIPCPacket();
    msg.appendMessage({ name: eventType, value: metadata || {} });
    this.ipc.sendMessage(msg);
  }
  
  /* private */
  handleDidReceiveMessage(message) {
    if (message.message && message.message.name) {
      if (message.message.name == LightningIPC.LightningIPC_HostActions.CONFIG_APP) {
        const config = message.message.value;
        this.auth.launchConfigManager.setLaunchConfig(config);
      }
    }
  }
}
