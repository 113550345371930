import autoBind from 'react-autobind';
import React from 'react';
import { Col, Dropdown, Menu, Row, Table, Typography, Tooltip, Icon, Button, Skeleton } from 'antd';
import scrollIntoView from 'scroll-into-view';
import mime from 'mime-types';
//
import Utils from '../../../components/helpers/Utils';
import Globals from '../../../config/Globals';
import AccountUtils from '../../../components/helpers/AccountUtils';
//
import CustomComponent from '../../../ui-components/CustomComponent';
import CommonAttributesLabel from '../Attributes/CommonAttributesLabel';
import CommonTagsLabel from '../Attributes/CommonTagsLabel';
import CommonDocketDrawerFileDrawer from './CommonDocketDrawerFileDrawer';
import CommonDocketFilterDocumentsBar from '../DocketFilterDocuments/CommonDocketFilterDocumentsBar';

//props are: app, isLoading, selectedDocket, focusedDocumentID, additionalIDs, onPageChanged, onRecover, onHardDelete, onDelete
export default class CommonDocketDrawerFilesTab extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      focusedDocumentID: [], selectedFile: {}, sortedInfo: {},
      multiselectionIDs: [], multiselectionDocs: [], downloadingFileIDs: [],
      currentPage: 1, pageSize: Globals.Search_PagingItemsPerPage, filterValue: ''
    };
  }
  /* Public methods */
  forceClose() {
    this.resetState();
    if (this.filterBar) this.filterBar.reset();
    this.fileDrawer.closeAndReload(true);
  }
  inOperation() {
    return (
      this.fileDrawer.state.isEditing ||
      this.fileDrawer.state.isDeleting ||
      this.fileDrawer.state.isSaving ||
      this.state.downloadingFileIDs.length > 0 ||
      this.state.isLoading
    );
  }
  resetState() {
    this.setState({
      focusedDocumentID: [], selectedFile: {}, sortedInfo: {},
      multiselectionIDs: [], multiselectionDocs: [], downloadingFileIDs: [],
      currentPage: 1, pageSize: Globals.Search_PagingItemsPerPage, filterValue: ''});
  }
  setSelectedFile(focusedDocumentID) {
    const { selectedDocket } = this.props;
    const selectedFile = selectedDocket.documents.filter((elem) => elem.id === focusedDocumentID)[0];
    //check for valid file
    if (!selectedFile) {
      this.props.app.alertController.showErrorAlert('Error!', 'File not found!');
      return;
    }
    this.setState({ focusedDocumentID, selectedFile }, this._scrollToFile);
    if (this.fileDrawer) this.fileDrawer.show(selectedFile);
  }

  // Action handlers
  handleRow(record) {
    return {
      onClick: (e) => {
        if (!['BUTTON', 'LI'].includes(e.target.tagName)) {
          this.setState({ selectedFile: record, focusedDocumentID: [record.id] }, this._scrollToFile);
          if (this.fileDrawer) this.fileDrawer.show(record);
        }
      }
    };
  }
  async handleFilter(filterValue) {
    if (this.props.isLoading) return;
    if (filterValue.length > 0) await this.handlePageChanged(0, 'desc', 'name.keyword', filterValue);
    else await this.handlePageChanged(0, null, null, filterValue)
    this.setState({ filterValue, currentPage: 0 });
  }
  async handleTableChange(pagination, filters, sorter) {
    const currentPage = (this.state.sortedInfo.columnKey !== sorter.columnKey ||
                         this.state.sortedInfo.order !== sorter.order) ? 1 : pagination.current;
    await this.handlePageChanged(
      currentPage === 1 ? 0 : (currentPage -1) * pagination.pageSize,
      (sorter.order === "ascend" ? "asc" : "desc"),
      sorter.columnKey || this.state.sortedInfo.columnKey, this.state.filterValue
    );
    this.setState({ sortedInfo: sorter, currentPage });
  }
  async handlePageChanged(currentPage, order, columnKey, filterValue) {
    await this.props.onPageChanged(
      this.props.selectedDocket.id, { vaultID: this.props.selectedDocket.vaultID },
      currentPage, order, columnKey, null, filterValue
    );
  }
  handleFileDrawerReload(docketID, additionalIDs) {
    const currentPage = this.state.currentPage;
    this.props.onPageChanged(
      docketID, additionalIDs, currentPage === 1 ? 0 : (currentPage -1) * 25,
      (this.state.sortedInfo.order === "ascend" ? "asc" : "desc"),
      this.state.sortedInfo.columnKey || this.state.sortedInfo.columnKey, this.state.filterValue
    );
  }
  handleActionsDropdownClick(key, record) {
    const actions = { recover: this.props.onRecover, permanentlyDelete: this.props.onHardDelete };
    const fn = actions[key];
    if (fn) fn(record);
  }
  handleFilePreview(record) { this.props.app.openFilePreview(record); }
  async handleDownloadFile(record) {
    const originalDIDs = this.state.downloadingFileIDs;
    const newDIDs = this.state.downloadingFileIDs.concat([ record.id ]);
    this.setState({ downloadingFileIDs: newDIDs }, async () => {
      await this.props.onDownload(record);
      this.setState({ downloadingFileIDs: originalDIDs });
    });
  }
  handleBatchDownload() {
    const originalDIDs = this.state.downloadingFileIDs;
    const newDIDs = this.state.downloadingFileIDs.concat(this.state.multiselectionIDs);
    this.setState({ downloadingFileIDs: newDIDs }, async () => {
      await this.props.onDownload(this.state.multiselectionIDs, true);
      this.setState({ downloadingFileIDs: originalDIDs });
    });
  }
  handleTransfer(isMove) {
    this.props.onTransfer(this.state.multiselectionIDs, isMove);
  }
  handleBatchOperation(operation) {
    this.props[operation]({ docket: this.props.selectedDocket, documents: this.state.multiselectionDocs }, true);
  }
  handleCloseDrawer() { this.setState({ focusedDocumentID: [] }); }

  handleMultiSelection(multiselectionIDs) {
    let multiselectionDocs = [...this.state.multiselectionDocs];
    const removedIDs = this.state.multiselectionIDs.filter(id => !multiselectionIDs.includes(id));
    if (!removedIDs.length) {
      const newDocumentsIDs = multiselectionIDs.filter(id => !multiselectionDocs.map(d => d.id).includes(id));
      newDocumentsIDs.map(id => this.props.selectedDocket.documents.find((d) => d.id === id)).forEach(document => multiselectionDocs.push(document));
    } else {
      multiselectionDocs = multiselectionDocs.filter(d => !removedIDs.includes(d.id));
    }
    this.setState({ multiselectionIDs, multiselectionDocs });
  }
  handleMultiSelectionMenu({ key }) {
    if (key == 'move') this.handleTransfer(true);
    else if (key == 'copy') this.handleTransfer(false);
    else if (key == 'delete') this.handleBatchOperation('onDelete');
    else if (key == 'recover') this.handleBatchOperation('onRecover');
    else if (key == 'permaDelete') this.handleBatchOperation('onHardDelete');
  }

  /* UI */
  render() {
    const { selectedDocket, isLoading, additionalIDs } = this.props;
    const { focusedDocumentID, downloadingFileIDs } = this.state;
    const filterBar = (
      <CommonDocketFilterDocumentsBar app={this.props.app} isLoading={isLoading} onFilter={this.handleFilter}
                                      {...Utils.propagateRef(this, 'filterBar')} />
    );
    return (
      <Row type="flex" align="middle" gutter={[16, 16]}>
        <div style={{ width: '100%' }}>
          {filterBar}
          <Skeleton title={false} paragraph={{ rows: 8 }} loading={isLoading} active>
            <Row type='flex' justify='end' style={{ marginTop: '10px', marginBottom: '10px'}}> <Col> {this._renderMultiSelection()} </Col> </Row>
            <Table className={(focusedDocumentID.length > 0 ? 'filesTable filesTableSelectedRows' : 'filesTable') + ' multiselection'}
              onRow={this.handleRow} columns={this._getTableColumnsDocketFiles()} dataSource={selectedDocket.documents}
              {...this._getTableProps()}
            />
          </Skeleton>
          <CommonDocketDrawerFileDrawer app={this.props.app}
            onReload={this.handleFileDrawerReload} isLoading={isLoading} downloadingFileIDs={downloadingFileIDs} onClose={this.handleCloseDrawer}
            onFileDownload={this.handleDownloadFile} onFilePreview={this.handleFilePreview} selectedDocket={selectedDocket}
            additionalIDs={additionalIDs} wrappedComponentRef={(ref) => { this.fileDrawer = ref; }}
            onRecover={this.props.onRecover} onHardDelete={this.props.onHardDelete}
          />
        </div>
      </Row>
    );
  }

  /* Private UI helpers */
  _scrollToFile() {
    const element = document.querySelector('.filesTableRow.ant-table-row-selected');
    if (element) scrollIntoView(element, { debug: false, time: 500, align: { top: 0 } });
  }
  _getTableColumnsDocketFiles() {
    let { sortedInfo } = this.state;
    let columns = [ { title: '', key: 'icon', width: '30px', render: this._renderTypeIcon }];
    return columns.concat([{
        title: 'Name', key: 'name.keyword',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name.keyword' && sortedInfo.order,
        filterDropdownVisible: false, render: this._renderColFileName,
      }, {
        title: 'Creation Date', key: 'createdOn', width: '100px',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
        filterDropdownVisible: false, render: this._renderColCreationDate,
      }, {
        title: 'Type', key: 'fileType.keyword', width: '50px',
        sorter: (a, b) => (mime.extension(a.fileType) || a.fileType).localeCompare(mime.extension(b.fileType) || b.fileType),
        sortOrder: sortedInfo.columnKey === 'fileType.keyword' && sortedInfo.order, filterDropdownVisible: false, render: this._renderColType,
      }, {
        title: 'Custom Attributes', key: 'attributes', render: this._renderColAttributesHover, width: '80px',
      }, {
        title: 'Tags', key: 'tags', render: this._renderColTagsHover, width: '40px',
      }, {
        title: 'Action', key: 'action', width: '60px', render: this._renderActionButton,
      },
    ]);
  }
  _getTableProps() {
    return {
      rowKey: 'id', onChange: this.handleTableChange, locale: { emptyText: 'No documents attached into this docket!' },
      pagination: { current: this.state.currentPage, pageSize: this.state.pageSize,
                    total: this.props.selectedDocket.docketNumDocs, hideOnSinglePage: true},
      bordered: false, size: 'small', onHeaderRow: () => ({ className: 'filesTableHeader' }),
      fixed: true, rowClassName: 'filesTableRow', rowSelection: this._getTableRowSelectionProps(),
    };
  }
  _getTableRowSelectionProps() {
    return {
      getCheckboxProps: () => { return ({ style: { paddingLeft: 10, paddingRight: 4 } }); }, columnWidth: 14,
      onChange: this.handleMultiSelection, selectedRowKeys: this.state.multiselectionIDs, hideDefaultSelections: true,
      type: 'checkbox'
    };
  }

  _getAppendDeletedClass(props) { return props.deleted ? 'deletedItemColumn' : ''; }
  /* Private Multi-Selection */
  _renderMultiSelection() {
    const anyDeleted = !!this.state.multiselectionDocs.find(d => d.deleted);
    const anyNotDeleted = !!this.state.multiselectionDocs.find(d => !d.deleted);
    const anySelected = this.state.multiselectionIDs.length > 0;
    const account = this.props.app.sharedCache().getCurrentVaultUser();
    const allowDeletion = AccountUtils.hasDeletePermission(account?.role);
    return (
      anySelected && <Dropdown overlay={
        <Menu onClick={this.handleMultiSelectionMenu}>
          <Menu.Item key="1" disabled> {this.state.multiselectionIDs?.length || 0} items selected </Menu.Item>
          <Menu.Divider/>
          <Menu.Item key="move" disabled={!this._isValidTransfer(true) || anyDeleted}> <Icon type="vertical-align-top"/> Move </Menu.Item>
          <Menu.Item key="copy" disabled={!this._isValidTransfer(false) || anyDeleted}> <Icon type="copy"/> Copy </Menu.Item>
          <Menu.Item key="delete" disabled={anyDeleted || !allowDeletion}> <Icon type="delete"/> Delete </Menu.Item>
          <Menu.Item key="recover" disabled={anyNotDeleted}> <Icon type="undo"/> Recover </Menu.Item>
          <Menu.Item key="permaDelete" disabled={anyNotDeleted || !allowDeletion}> <Icon type="undo"/> Permanently Delete </Menu.Item>
        </Menu>}>
        <Button className='actionButton' style={{ width: 150 }}>
          <Row type='flex' justify='space-between'>
            <Col>Actions...</Col>
            <Col><Icon type="down" /> </Col>
          </Row>
        </Button>
      </Dropdown>
    );
  }
  /* Private Renders */
  _renderTypeIcon(props) {
    const icon = Utils.GetIcon(props.type, props?.fileType, props?.fileName);
    return (
      <Row className={`filesTableColIcon ${this._getAppendDeletedClass(props)}`}>
        <img src={icon} width="30px" height="30px" alt={Utils.capitalizeString(props.type.toLowerCase())} />
      </Row>
    );
  }
  _renderColFileName(props) {
    return (
      <Row className={`filesTableColName ${this._getAppendDeletedClass(props)}`}>
        <Typography.Text className="label">{props.name || props.fileName}</Typography.Text>
      </Row>
    );
  }
  _renderColCreationDate(props) {
    return (
      <Row className={`filesTableColCreationDate ${this._getAppendDeletedClass(props)}`}>
        <Typography.Text className="label">
          {props && Utils.getDateOnUIFormatByTimestamp(props.createdOn)}
        </Typography.Text>
      </Row>
    );
  }
  _renderColType(props) {
    return (
      <Row className={`filesTableColType ${this._getAppendDeletedClass(props)}`}>
        <Typography.Text className="label">
          {mime.extension(props.fileType) ? mime.extension(props.fileType) : props.fileType}
        </Typography.Text>
      </Row>
    );
  }
  _renderColAttributesHover(props) {
    return (
      <Row className={`filesTableColAttributes ${this._getAppendDeletedClass(props)}`}>
        <CommonAttributesLabel app={this.props.app} isExpanded={false} attributes={props.attributes} />
      </Row>
    );
  }
  _renderColTagsHover(props) {
    return (
      <Row className={`filesTableColTag ${this._getAppendDeletedClass(props)}`}>
        <CommonTagsLabel isExpanded={false} tags={props.tags} />
      </Row>
    );
  }
  _renderActionButton(text, props) {
    if (props.deleted) {
      return (
        <Row className="filesTableColDeleted">
          <Tooltip overlayStyle={{maxWidth: '316px'}} placement="leftBottom" title={`This file is marked for deletion and will be permanently removed after ${Utils.getDateOnUIFormatByTimestamp(props.ttl * 1000)}.`}>
            <Dropdown overlay={
              <Menu onClick={(e) => this.handleActionsDropdownClick(e.key, props)}>
                <Menu.Item key="recover">
                  <Icon type="undo" style={{ color: 'black', fontSize: 18 }} /> Recover
                </Menu.Item>
                <Menu.Item key="permanentlyDelete">
                  <Icon type="delete" style={{ color: 'black', fontSize: 18 }} /> Permanently Delete
                </Menu.Item>
              </Menu>}>
              <Button icon="ellipsis" className="filesTableDeleteButton noStyle" type="secondary"/>
            </Dropdown>
            <Icon type="warning" theme='filled' style={{ marginLeft: '8px', color: 'orange', fontSize: 18 }} />
          </Tooltip>
        </Row>
      );
    }

    const isDownloadingFile = this.state.downloadingFileIDs.includes(props.id);
    return (
      <Button icon="arrow-down" className="filesTableDownloadButton noStyle" type="secondary"
              loading={isDownloadingFile} onClick={() => this.handleDownloadFile(props)}/>
    );
  }

  // Helper
  _isValidTransfer(isMove) {
    const account = this.props.app.sharedCache().getCurrentVaultUser();
    if (!isMove) if (!AccountUtils.hasUploadPermission(account.role)) return false;
    if (isMove) if (!AccountUtils.hasDeletePermission(account.role)) return false;
    return true;
  }
}
