import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Row, Typography, Tooltip, Icon, Button } from 'antd';
//
import CustomComponent from '../../../ui-components/CustomComponent';
import NavigationControllerView from '../../../ui-components/NavigationControllerView';
//
import DocketNavigationView from './DocketNavigationView';
//
import Globals from '../../../config/Globals';
//
import '../../../assets/stylesheets/EmptyUploadNavigationView2.scss';

/* eslint max-classes-per-file: ["error", 2] */
export default class EmptyUploadNavigationView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      filesStack: [], isDragging: false,
      selectingDocket: null, noPushTransition: false,
    };
  }
  //Actions
  handleDragFile(dndEvent, docket, noTransition) {
    if (dndEvent.approvals.length > 0) {
      console.log('accepting!', dndEvent)
      this.setState({ isDragging: false },
         () => { this._pushViewIfRequired(dndEvent.approvals, docket, noTransition); }
      );
    }
  }
  handleDragFileStart() { this.setState({isDragging: true}); }
  handleDragFileStop() { this.setState({isDragging: false}); }
  //UI
  render() {
    const vault = this.props.app.sharedCache().getCurrentVault();
    const isWriteLocked = this.props.app.sharedCache().isCurrentVaultAccountDataWriteLocked();
    const isDisabled = isWriteLocked || vault.isPurging;
    //
    const classes = `${(this.state.isDragging ? 'targetBoxHover' : 'targetBox')} ${isDisabled ? 'targetDisabled' : ''}`;
    const content = (
      <div className={classes}>
        <Row>
          <Typography.Title level={3}>
            <Icon type={isDisabled ? 'close-circle' : 'cloud-upload'} style={{ fontSize: 72, ...(isDisabled ? {color: 'red'} : {}) }} />
            {!this.props.app.layout?.isMobile && (
              <div>
                <br />
                {isDisabled ? Globals.AccountLock_UploadDisabledMessage : 'DROP DOCUMENTS TO UPLOAD'}
              </div>
            )}
            </Typography.Title>
        </Row>
        {!this.props.app.layout.isMobile && (
          <Row>
            <Typography.Text>{isDisabled ? '' : 'or...'}</Typography.Text>
          </Row>
        )}
        <Row className='buttonRow'>
          {this.props.app.dropController?.openDialog && (
            <Button type="primary" disabled={isDisabled} onClick={this.props.app.dropController.openDialog}>
              {this.props.app.layout.isMobile ? 'Select Documents to Upload...' : 'Select Documents...'}
            </Button>
          )}
        </Row>
      </div>
    );
    return (
      <Layout.Content className='commonTabContent'>
        {this.props.app.sharedCache().isCurrentVaultAccountDataWriteLocked() ?
          <Tooltip placement='topRight' title={Globals.AccountLockActionErrorMessage}> {content} </Tooltip> : content}
      </Layout.Content>
    );
  }
  //Life cycle
  receiveProps(props) {
    this.setState({ filesStack: props.files });
  }
  navigationControllerDidShowView() {
    //This is to handle cases where we are animating to this view
    //but user has dragged already and push view didn't work.
    //-- we delay a bit to wait navigation to complete it's animation
    setTimeout( () => {
      const files = this.state.filesStack;
      this.setState({filesStack: []}, () => {
        this._pushViewIfRequired(files);
      });
    }, 100);
  }
  /* private UI */
  _pushViewIfRequired(files, docket, noTransition) {
    if (files.length > 0) {
      const pushed = this.props.navigationController.pushView(
        <DocketNavigationView app={this.props.app}
          files={files} controller={this.props.controller}
          docket={this.state.selectingDocket || docket}/>,
        {...(this.state.noPushTransition ? { transition: 0} : {})}
      );
      if (!pushed) this.setState({filesStack: files, selectingDocket: docket, noPushTransition: noTransition}); //enqueue
    }
  }
}

export class UploadNavigationViewController extends NavigationControllerView {
  constructor(props) {
    super(props);
    autoBind(this);
    this.containerClass = EmptyUploadNavigationView;
  }
  //Public contoller method
  selectCurrentTab() { this.props.parent.handleUploadFocus(); }
  //UI
  render() {
    return (
      <>
        {super.render()}
      </>
    );
  }
  //Tab selectors
  tabViewDidAppear() {
    if (this.getCurrentView() && this.getCurrentView().tabViewDidAppear) this.getCurrentView().tabViewDidAppear();
  }
  tabViewWillDisappear() {
    if (this.getCurrentView() && this.getCurrentView().tabViewWillDisappear) this.getCurrentView().tabViewWillDisappear();
  }
  //Drag stuff
  handleDragFile(files, docket, noTransition) {
    if (this.getCurrentView() && this.getCurrentView().handleDragFile) {
      console.debug('propagating to navigation child', this.getCurrentView());
      this.getCurrentView().handleDragFile(files, docket, noTransition);
    } else {
      console.debug('NOT propagating to navigation child');
    }
  }
  handleDragFileStart() {
    if (this.getCurrentView() && this.getCurrentView().handleDragFileStart) this.getCurrentView().handleDragFileStart();
  }
  handleDragFileStop() {
    if (this.getCurrentView() && this.getCurrentView().handleDragFileStop) this.getCurrentView().handleDragFileStop();
  }
  handleSelectDocket(docket) {
    if (this.getCurrentView() && this.getCurrentView().handleSelectDocket) this.getCurrentView().handleSelectDocket(docket);
  }
  handleAcceptDrag() {
    if (this.getCurrentView() && this.getCurrentView().handleAcceptDrag) return this.getCurrentView().handleAcceptDrag();
    return true;
  }
}
