import React from 'react';
import autoBind from 'react-autobind';
import Dropzone from 'react-dropzone';
import Globals from '../config/Globals';
//
import '../assets/stylesheets/CommonDragController.scss';
import Utils from '../components/helpers/Utils';

const Reasons = Globals.DnDRejectionReasons;
//props are: maxSize, onDidDrag, onDidStartDrag, onDidStopDrag, onAcceptDrag, disabled
export default class DragAndDropController extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isDragging: false };
  }

  /* Public */
  openDialog() {
    if (this.dropzone) this.dropzone.open();
  }
  /* Dropzone callbacks */
  onAcceptDrag() {
    if (this.props.onAcceptDrag) {
      return this.props.onAcceptDrag();
    }
    return false;
  }
  onDidDrag(files) {
    console.log('receive drag', files);
    if (this.props.onDidDrag && files && files.length > 0) {
      this.props.onDidDrag(this._validatedFiles(files));
    }
  }
  onDidStartDrag() {
    if (!this.state.isDragging) {
      this.setState({ isDragging: true }, () => {
        if (this.props.onDidStartDrag) this.props.onDidStartDrag();
      });
    }
  }
  onDidStopDrag() {
    if (this.state.isDragging) {
      this.setState({ isDragging: false }, () => {
        if (this.props.onDidStopDrag) this.props.onDidStopDrag();
      });
    }
  }

  // UI
  render() {
    //Docs at: https://react-dropzone.js.org/#!/Dropzone
    return (
      <Dropzone onDropAccepted={this.onDidDrag} multiple noClick noKeyboard disabled={this.props.disabled}
                onDragEnter={this.onDidStartDrag} onDragLeave={this.onDidStopDrag}
                {...Utils.propagateRef(this, 'dropzone')}>
        {({ getRootProps, getInputProps }) => {
          return (
            <section className='dragAndDropSection'>
              <input {...getInputProps()} />
              <div {...getRootProps()} className='dragAndDropContainer'>{this.props.children}</div>
            </section>
          );
        }}
      </Dropzone>
    );
  }

  //Validations
  _validatedFiles(files) {
    const normFiles = files.map((file, i) => {
      file.rowKey = file.name.toLowerCase().replace(/\s/g, '-') + Date.now() + i;
      file.document_title = file.name;
      file.date = Date.now();
      file.tags = [];
      file.attributes = [];
      return file;
    });
    //
    let rejections = [];
    let approvals = [];
    const maxFileSize = this.props.maxSize || Globals.Documents_MAX_Size;
    //Rules
    //#1 min size
    //#2 max total size per document
    for (let file of normFiles) {
      if (file.size <= 0) rejections.push({ reason: Reasons.MIN_SIZE, files: [file] });
      else if (file.size > maxFileSize) rejections.push({ reason: Reasons.MAX_TOTAL_SIZE, files: [file] });
      else approvals.push(file);
    }
    console.log({ approvals, rejections });
    return { approvals, rejections };
  }
}
