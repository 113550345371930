import Globals from "../../config/Globals";

export default class AccountUtils {}

AccountUtils.hasUploadPermission = (role) => {
  if (typeof role !== 'string') return false;

  return [
    Globals.Vault_Roles.OWNER,
    Globals.Vault_Roles.MANAGER,
    Globals.Vault_Roles.EDITOR,
    Globals.Vault_Roles.CONTRIBUTOR,
  ].includes(role.toUpperCase());
};

AccountUtils.hasDeletePermission = (role) => {
  if (typeof role !== 'string') return false;

  return [
    Globals.Vault_Roles.OWNER,
    Globals.Vault_Roles.MANAGER,
    Globals.Vault_Roles.EDITOR,
  ].includes(role.toUpperCase());
};

AccountUtils.hasUsersManagementPermission = (role) => {
  if (typeof role !== 'string') return false;

  return [
    Globals.Vault_Roles.OWNER,
    Globals.Vault_Roles.MANAGER,
  ].includes(role.toUpperCase());
};

AccountUtils.hasVaultManagementPermission = (role) => {
  if (typeof role !== 'string') return false;

  return [
    Globals.Vault_Roles.OWNER,
    Globals.Vault_Roles.MANAGER,
  ].includes(role.toUpperCase());
};

AccountUtils.isFreePlan = (planID) => {
  return planID == Globals.SubscriptionPackagesIDs.FREE;
};

AccountUtils.getNextPlan = (planID) => {
  const map = {
    [Globals.SubscriptionPackagesIDs.FREE]: Globals.SubscriptionPackagesIDs.LITTLEPIGGY,
    [Globals.SubscriptionPackagesIDs.LITTLEPIGGY]: Globals.SubscriptionPackagesIDs.BIGPIGGY,
  };

  return map[planID];
};
