import React from 'react';
import autoBind from 'react-autobind';
import { Row, Button, Modal, Typography } from 'antd';
//
import CustomComponent from '../../../ui-components/CustomComponent';
//
import '../../../assets/stylesheets/CommonConfirmationModal.scss';
//
//required props: onCancel, onConfirmation
//optional props: confirmationText, cancellationText
export default class CommonConfirmationModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { shouldHide: true, title: "", content: "" };
  }
  async show(title, content, promiseBased) { 
    this.setState({ title, content, shouldHide: false });
    if (!promiseBased) {
      return null;
    } else return new Promise((resolve) => {
      this.internalCallback = ((confirm) => {
        this.internalCallback = null;
        resolve(confirm);
      });
    });
  }
  //Actions
  handleCancel() {
    this.setState({shouldHide: true});
    if (this.props.onCancel) this.props.onCancel();
    else if (this.internalCallback) this.internalCallback(false);
  }
  handleConfirmation() {
    this.setState({shouldHide: true});
    if (this.props.onConfirmation) this.props.onConfirmation();
    else if (this.internalCallback) this.internalCallback(true);
  }
  //UI
  render() {
    return (
      <Modal maskClosable={false} title={this.state.title}
             visible={!this.state.shouldHide} closable={false}
             footer={null} centered className='confirmationModal'
             okText={this.props.confirmationText || 'OK'}
             cancelText={this.props.cancellationText || 'Cancel'}>
             <Typography.Text>{this.state.content}</Typography.Text>
             {this._renderButtons()}
      </Modal>
    );
  }
  /* UI private */
  _renderButtons() {
    return (
      <Row type='flex' justify='end' className='footerSection'>
        {(!this.props.hideCancel &&
        <Button type="secondary" className='cancelButton'
                onClick={this.handleCancel}> {this.props.cancellationText || 'Cancel'} </Button>)}
        <Button type="primary" className='confirmationButton'
                onClick={this.handleConfirmation}> { this.props.confirmationText || 'OK' } </Button>
      </Row>
    );
  }
}
