import React from 'react';
import autoBind from 'react-autobind';
import { Col, Tag, Icon, Row, Button, Modal, Typography, Alert, message } from 'antd';
//
import CustomComponent from '../../../ui-components/CustomComponent';
import CommonLoadingView from '../CommonLoadingView';
import CommonUploadFilesTable from '../CommonUploadFilesTable';
//
import '../../../assets/stylesheets/CommonOperationCancelModal.scss';
//props are: app, onCancel (optional), onReload
//Attention: this modal is only supported for files batch operations of the same docket for now
export default class CommonSoftDeleteModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isVisible: false, context: null, isLoading: false };
  }
  //Actions
  show(context, isBatch /* this modal support batch documents from ONE docket only*/) {
    this.setState({ context, isVisible: true, isBatch });
  }
  handleCancel() {
    if (this.props.onCancel) this.props.onCancel();
    this.setState({ isVisible: false });
  }
  handleDelete() {
    if (this.state.isBatch) this._batchSoftDeleteWithContext(this.state.context);
    // else this._singleSoftDeleteWithContext(this.state.context);
  }
  //UI
  render() {
    return (
      <Modal maskClosable={false} onCancel={this.handleCancel} visible={this.state.isVisible} closable={!this.state.isLoading} footer={null} centered className='operationCancelModal'>
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        {this._renderSubtitle()}
        {this._renderButtons()}
      </Modal>
    );
  }
  /* UI private */
  _renderSubtitle() {
    const isDocument = !!this.state.context?.docketID;
    return (
      <Row className='subtitleLabelRow' type='flex'>
        <Row type='flex' justify='start' align='middle' style={{ marginTop: '50px' }}>
          <Col span={4} align='middle'>
            <Icon type="delete" style={{ color: 'orange', fontSize: 36, marginBottom: '21px' }} />
          </Col>
          <Col span={20}>
            <Typography.Paragraph className='subtitleLabel' style={{ fontSize: '20px' }}>
              Are you sure you would like to <strong>delete</strong>
              {(this.state.isBatch ?
                ` the following ${this.state.context?.documents?.length || 0} file(s) from the ${this.state.context?.docket?.name} docket` :
                ` the '${this.state.context?.name} ${isDocument ? 'file' : 'docket'}`)}?
            </Typography.Paragraph>
          </Col>
        </Row>
        {this.state.isBatch && <Tag style={{ marginBottom: '20px', width: '100%' }}>
          <CommonUploadFilesTable isLoading={this.state.isLoading} maxSize={10} files={this.state.context?.documents} app={this.props.app}/>
        </Tag>}
        {this.state.isBatch && this.state.context && <Alert style={{ marginBottom: '20px' }} description={<>All selected files will be flagged to be deleted and <strong>can be recovered within {this.props.app.sharedCache().getCurrentVault().hardDeleteAfter} days</strong>!</>} type='warning' showIcon />}
      </Row>
    );
  }
  _renderButtons() {
    return (
      <Row type='flex' justify='end'>
        <Button type="secondary" className='cancelButton' disabled={this.state.isLoading} loading={this.state.isLoading} onClick={this.handleCancel}> Cancel </Button>
        <Button type="danger" className='retryButton' disabled={this.state.isLoading} loading={this.state.isLoading} onClick={this.handleDelete}> Delete It!</Button>
      </Row>
    );
  }

  /* API actions */
  // async _singleSoftDeleteWithContext(context) {
  //   this.startLoading();
  //   const isDocument = !!context.docketID;
  //   //Make request
  //   const resp = await (this.props.app.api.v2[(isDocument ? 'document' : 'vaultDocket')][(isDocument ? 'deleteDocument' : 'deleteDocket')](
  //     context.vaultID, context.docketID || context.id, context.id /* disreguarded on docket calls */
  //   ));
  //   if (resp.statusCode == 200) {
  //     message.success(`${isDocument ? 'Document' : 'Docket'} deleted with success!`);
  //     this.setState({ isLoading: false, isVisible: false });
  //     this.props.onReload();
  //   } else {
  //     this.props.app.alertController.showAPIErrorAlert('Error!', resp);
  //     this.stopLoading();
  //   }
  // }
  async _batchSoftDeleteWithContext(context) {
    this.startLoading();
    //Make request
    const resp = await (this.props.app.api.v2.vaultBatch.delete(
      context.docket?.vaultID, [], context?.documents?.map((d) => ({ id: d.id, docketID: d.docketID }))
    ));
    if (resp.statusCode == 200) {
      message.success(`${context?.documents?.length} Documents deleted with success!`);
      this.setState({ isLoading: false, isVisible: false });
      this.props.onReload();
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error!', resp);
      this.stopLoading();
    }
  }
}
