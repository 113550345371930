// import URLQuery from 'query-string-manipulator';
// //
// import config from '../../config/config';
// import Globals from '../../config/Globals';
import autoBind from 'react-autobind';
//IPC
import ElectronIPCController from './IPC/Electron-IPC/Electron-IPCController';
import ElectronIPC from './IPC/Electron-IPC/Electron-IPC';
import LightningIPCController from './IPC/Lightning-IPC/Lightning-IPCController';
import LightningIPC from './IPC/Lightning-IPC/Lightning-IPC';
import CapacitorIPCController from './IPC/Capacitor-IPC/Capacitor-IPCController';
import CapacitorIPC from './IPC/Capacitor-IPC/Capacitor-IPC';
//
export default class IPCManager {
  constructor(auth) {
    autoBind(this);
    this.auth = auth;
    //ipc
    this.electron = new ElectronIPCController(this.auth);
    this.lightning = new LightningIPCController(this.auth);
    this.capacitor = new CapacitorIPCController(this.auth);
  }
  /* Public */
  getFileTransferIPC() {
    if (this.electron.isAvailable()) return this.electron;
    return null;
  }
  anyIPCHost() { return this.isDesktopHost() || this.isLightningHost() || this.isMobileHost(); }
  isDesktopHost() { return this.electron.isAvailable(); }
  isLightningHost() { return this.lightning.isAvailable(); }
  isMobileHost() { return this.capacitor.isAvailable(); }
  /* Events */
  sendEvent(event, ctx) {
    if (this.electron.isAvailable()) {
      if (event == IPCManager.WebEvents.EVENT_SESSION_LOADING) this.electron.sendEvent(ElectronIPC.ElectronIPC_WebActions.EVENT_SESSION_LOADING, ctx);
      else if (event == IPCManager.WebEvents.EVENT_SESSION_ERR) this.electron.sendEvent(ElectronIPC.ElectronIPC_WebActions.EVENT_SESSION_ERR, ctx);
      else if (event == IPCManager.WebEvents.EVENT_SESSION_STARTED) this.electron.sendEvent(ElectronIPC.ElectronIPC_WebActions.EVENT_SESSION_STARTED, ctx);
      else if (event == IPCManager.WebEvents.EVENT_FILE_UPLOADED) this.electron.sendEvent(ElectronIPC.ElectronIPC_WebActions.EVENT_FILE_UPLOADED, ctx);
      else if (event == IPCManager.WebEvents.EVENT_UPLOAD_STARTED) this.electron.sendEvent(ElectronIPC.ElectronIPC_WebActions.EVENT_UPLOAD_STARTED, ctx);
      else if (event == IPCManager.WebEvents.EVENT_UPLOAD_FAILED) this.electron.sendEvent(ElectronIPC.ElectronIPC_WebActions.EVENT_UPLOAD_FAILED, ctx);
      else if (event == IPCManager.WebEvents.EVENT_UPLOAD_COMPLETED) this.electron.sendEvent(ElectronIPC.ElectronIPC_WebActions.EVENT_UPLOAD_COMPLETED, ctx);
      else if (event == IPCManager.WebEvents.EVENT_SESSION_ENDED) this.electron.sendEvent(ElectronIPC.ElectronIPC_WebActions.EVENT_SESSION_ENDED, ctx);
    } else if (this.lightning.isAvailable()) {
      if (event == IPCManager.WebEvents.EVENT_SESSION_LOADING) this.lightining.sendEvent(LightningIPC.LightningIPC_WebActions.EVENT_SESSION_LOADING, ctx);
      else if (event == IPCManager.WebEvents.EVENT_SESSION_STARTED) this.lightining.sendEvent(LightningIPC.LightningIPC_WebActions.EVENT_SESSION_STARTED, ctx);
      else if (event == IPCManager.WebEvents.EVENT_SESSION_ENDED) this.lightining.sendEvent(LightningIPC.LightningIPC_WebActions.EVENT_SESSION_ENDED, ctx);
      else if (event == IPCManager.WebEvents.EVENT_SESSION_ERR) this.lightining.sendEvent(LightningIPC.LightningIPC_WebActions.EVENT_SESSION_ERR, ctx);
    } else if (this.capacitor.isAvailable()) {
      if (event == IPCManager.WebEvents.EVENT_SESSION_LOADING) this.capacitor.sendEvent(CapacitorIPC.CapacitorIPC_WebActions.EVENT_SESSION_LOADING, ctx);
      else if (event == IPCManager.WebEvents.EVENT_SESSION_STARTED) this.capacitor.sendEvent(CapacitorIPC.CapacitorIPC_WebActions.EVENT_SESSION_STARTED, ctx);
      else if (event == IPCManager.WebEvents.EVENT_SESSION_ENDED) this.capacitor.sendEvent(CapacitorIPC.CapacitorIPC_WebActions.EVENT_SESSION_ENDED, ctx);
      else if (event == IPCManager.WebEvents.EVENT_SESSION_ERR) this.capacitor.sendEvent(CapacitorIPC.CapacitorIPC_WebActions.EVENT_SESSION_ERR, ctx);
    }
  }
}

IPCManager.WebEvents = {
  EVENT_SESSION_LOADING: 0,
  EVENT_SESSION_ERR: 1,
  EVENT_SESSION_STARTED: 2,
  EVENT_FILE_UPLOADED: 3,
  EVENT_UPLOAD_STARTED: 4,
  EVENT_UPLOAD_FAILED: 5,
  EVENT_UPLOAD_COMPLETED: 6,
  EVENT_SESSION_ENDED: 7,
}
