import autoBind from 'react-autobind';
import React from 'react';
import { Divider, Input, Tooltip, Icon, Form, Row, Col, Skeleton, Typography } from 'antd';
import moment from 'moment';
//
import Utils from '../../../components/helpers/Utils';
import Globals from '../../../config/Globals';
//
import EditableTitle from '../../../ui-components/EditableTitle';
import CommonAttributesSubform from '../Attributes/CommonAttributesSubform';
import CommonTagsLabel from '../Attributes/CommonTagsLabel';
import CommonTagSelect from '../Attributes/CommonTagSelect';
//Style
import '../../../assets/stylesheets/CommonDocketViewHeaderForm.scss';

//props are: app, actions (component), docket, isEditing
class _CommonDocketViewHeaderForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  //Lifecycle
  componentDidUpdate(previousProps) {
    if (this.props.docket != previousProps.docket) { //docket updated? update form
      this._resetForm();
    } else if (this.props.isEditing != previousProps.isEditing) { //change editing
        if (this.docketTitle) this.docketTitle.setIsEditing(this.props.isEditing); //stop/start title editing
        if (!this.props.isEditing) this._resetForm(); //reset form if stopped editing
    }
  }

  // Action handlers
  async validate() {
    //Validate attrs
    let attributes = await this.attributesForm.validate();
    if (!attributes) return false;
    attributes = this._getAttributesFromForm(
      attributes.form && attributes.form.attributes ? attributes.form.attributes : null
    );
    //Validate tags
    const tags = this.tagsSelect?.getSelectedTags() || false;
    if (!tags) return false;
    //Validate title
    await this.docketTitle.validate();
    const docketTitle = await this.docketTitle.getValue();
    if (!docketTitle) return false;
    //Validate notes
    let notes = this.notes?.state?.value || false;
    if (!notes) notes = '';
    //
    return { attributes, tags, name: docketTitle, notes };
  }

  /* UI */
  render() {
    return (
      <div className='docketHeader'>
        {this._renderTitleRow()}
        <Divider/>
        {this._renderStandardInfoRow()}
        {this._renderAttributesRows()}
      </div>
    );
  }

  /* Private UI */
  _renderTitleRow() {
    const isLoading = !this.props.docket;
    return (
      <Row type="flex" justify="space-between" align="middle" gutter={[16, 16]}>
          <Col xs={12}>
            <Skeleton title={false} paragraph={{ rows: 1 }} loading={isLoading} active>
              <EditableTitle
                value={this.props.docket?.name} level={4}
                inputName="docket_title"
                inputOptions={{ rules: [{ required: true, message: 'Please, insert a name for the docket!' }] }}
                form={this.props.form}
                inputProps={{ placeholder: '* Docket Title' }}
                {...Utils.propagateRef(this, 'docketTitle')}
              />
              {this.props.docket?.deleted && <Tooltip placement="top" title={`This docket is marked for deletion and will be permanently removed after ${Utils.getDateOnUIFormatByTimestamp(this.props.docket?.ttl * 1000)}.`}>
                <Icon type="warning" theme='filled' style={{ marginLeft: '8px', color: 'orange', fontSize: 18 }} />
              </Tooltip>}
            </Skeleton>
          </Col>
          <Col>{this.props.actions || <></>}</Col>
        </Row>
    );
  }
  _renderStandardInfoRow() {
    const { docket, isEditing } = this.props;
    const isLoading = !this.props.docket;
    return (
        <Row type="flex" align="top" gutter={[16, 16]} className="infoRow">
          <Skeleton title={false} paragraph={{ rows: 2 }} loading={isLoading} active>
            {this._renderColItem('Creation Date', Utils.getDateOnUIFormat(new Date(docket?.createdOn)))}
            {this._renderColItem('Updated at', Utils.getDateOnUIFormat(new Date(docket?.updatedOn)))}
            {this._renderColItem('Number of Documents', `${docket?.docketNumDocs} File${docket?.docketNumDocs > 1 ? 's' : ''}`)}
            {this._renderColItem('Docket Size', Utils.formatSizeUnits(docket?.docketSize))}
            {this._renderColItem('Notes', docket?.notes, { isEditing, multiline: true, inputName: 'notes', optionalColSize: 12 })}
            {this._renderTagsCol()}
          </Skeleton>
        </Row>
    );
  }
  _renderTagsCol() {
    const { docket, isEditing } = this.props;
    const isLoading = !this.props.docket;
    return (
      <Col className="infoCol" span={12}>
        <Typography.Text className='title'>Tags</Typography.Text>
        <br />
        <Skeleton title={false} paragraph={{ rows: 1 }} loading={isLoading} active>
          <>
            <div style={{ display: isEditing ? 'block' : 'none' }}>
              <CommonTagSelect app={this.props.app}
                values={docket?.tags?.map(tag => ({value: tag, label: tag})) || []}
                {...Utils.propagateRef(this, 'tagsSelect')}
              />
            </div>
            <div style={{ display: !isEditing ? 'block' : 'none' }}>
              <CommonTagsLabel isExpanded isMultiline tags={docket?.tags} />
            </div>
          </>
        </Skeleton>
      </Col>
    );
  }
  _renderAttributesRows() {
    const { docket, isEditing } = this.props;
    const isLoading = !this.props.docket;
    return (
      <Row type="flex" align="middle" gutter={[16, 16]} className="infoRow ">
        <Col className="infoCol attributes" style={{ width: '100%' }}>
          <Typography.Text className='title'>Custom Attributes</Typography.Text>
          <br />
          <Skeleton title={false} paragraph={{ rows: 3 }} loading={isLoading} active>
            <CommonAttributesSubform
              editable={isEditing}
              app={this.props.app}
              form={this.props.form}
              isDocument={false}
              datasource={{ state: docket?.attributes || [] }}
              {...Utils.propagateRef(this, 'attributesForm')}
            />
          </Skeleton>
        </Col>
      </Row>
    );
  }

  /* Private UI helpers */
  _renderColItem(label, value, editableOptions = { isEditing: false, multiline: false }) {
    const { isLoading, form: { getFieldDecorator } } = this.props;
    const InputComponent = editableOptions.multiline ? Input.TextArea : Input;
    return (
      <Col xs={editableOptions.optionalColSize || 6} className="infoCol">
        <Typography.Text className='title'>{label}</Typography.Text>
        <br />
        <Typography.Text strong>
          <Skeleton title={false} paragraph={{ rows: 1 }} loading={isLoading} active>
            {!editableOptions.isEditing ? value : getFieldDecorator(editableOptions.inputName, {
                ...(editableOptions.inputOptions || {}), initialValue: value,
              })(
                  <InputComponent name={editableOptions.inputName}
                    ref={el => { this[editableOptions.inputName] = el; }}
                    {...(editableOptions.inputProps || {})}
                  />
                )}
          </Skeleton>
        </Typography.Text>
      </Col>
    );
  }

  /* Private Utils */
  _getAttributesFromForm(attributes) {
    if (attributes && Object.keys(attributes).length) {
      return Object.keys(attributes).map((key) => {
        const attribute = this.props.app.sharedCache().getCurrentVaultAttributeByID(key);
        return { id: key, value: attribute.type.toLowerCase() === 'date' ?
                 new Date(moment(attributes[key]).format(Globals.DefaultUIDateTimeFormat)).getTime() :
                 attributes[key],
        };
      });
    }
    return [];
  }
  _resetForm() {
    //Tags
    const tagsSelect = this.props.docket?.tags ? this.props.docket?.tags.map((tag) => ({ value: tag, label: tag })) : [];
    if (this.tagsSelect) this.tagsSelect.setValues(tagsSelect);
    //Attrs
    const attrs = this.props.docket?.attributes || [];
    if (this.attributesForm && attrs && attrs.length != undefined) {
      this.attributesForm.setValues({ attributes: attrs.reduce((acc, curr) => Object.assign(acc, { [curr.id]: curr.value }), {}) });
    }
  }
}

const CommonDocketViewHeaderForm = Form.create()(_CommonDocketViewHeaderForm);
export default CommonDocketViewHeaderForm;
