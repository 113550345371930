import React, { Component } from 'react';
import { Typography, Input } from 'antd';

export default class EditableTitle extends Component {
  constructor(props) {
    super(props);
    this.state = { isEditing: false };
  }

  setIsEditing = isEditing => {
    this.setState({ isEditing });
  };

  validate = () => {
    return this.props.form.validateFields([this.props.inputName]);
  };

  getValue = () => {
    if (this.input) {
      return this.input.state.value;
    }

    return '';
  };

  render() {
    const { value, level, titleProps, inputProps, form, inputName, inputOptions } = this.props;
    const { getFieldDecorator } = form;
    const { isEditing } = this.state;

    if (!isEditing) {
      return (<Typography.Title style={{ display: 'inline' }} level={level} {...titleProps}>{value}</Typography.Title>);
    }

    return getFieldDecorator(inputName, {
      ...inputOptions,
      initialValue: value,
    })(
      <Input name={inputName} ref={el => { this.input = el; }} {...inputProps}/>
    );
  }
}
