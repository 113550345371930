import React from 'react';
import autoBind from 'react-autobind';
import { Row, Col, Layout, Table, Typography, Button, Icon, Tooltip, Menu, Dropdown, Divider } from 'antd';
//
import Utils from '../../../components/helpers/Utils';
import Globals from '../../../config/Globals';
import AccountUtils from '../../../components/helpers/AccountUtils';
//
import CommonAttributesLabel from '../Attributes/CommonAttributesLabel';
import CommonTagsLabel from '../Attributes/CommonTagsLabel';
import CommonHardDeleteModal from '../Modals/CommonHardDeleteModal';
import CommonRecoverModal from '../Modals/CommonRecoverModal';
//
import '../../../assets/stylesheets/CommonSearchResultsTable.scss';
//
//props are: app, isLoading, results, showDocumentCol, total, currentPage, sortInfo,
// onRowSelection, onFilterChange, onPageChange, onReload
export default class CommonSearchResultsTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      multiselection: true, multiselectionIDs: [], multiselectionDeleted: [],
      downloadingIDs: [], multiselectionObjects: [],
      width: 0
    };
    this.handleDownload = this.handleDownload.bind(this);
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
  }
  resetMultiselection() {
    this.setState({ multiselection: true,  multiselectionIDs: [], multiselectionObjects: [], multiselectionDeleted: [] });
  }
  //Life cycle
  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }
  componentDidUpdate(previousProps, previousState) {
    if (previousProps.results != this.props.results && (this.state.multiselection || this.state.multiselectionIDs.length > 0)) {
      this.setState({
        multiselection: true,
        multiselectionIDs: previousState.multiselectionIDs,
        multiselectionObjects: previousState.multiselectionObjects,
        multiselectionDeleted: previousState.multiselectionDeleted
      });
    }
  }
  updateWindowWidth() {
    this.setState({ width: window.innerWidth });
  }
  //Actions
  //Default table
  handleTableChange(pagination, filters, sorter) {
    this.props.onTableChange(sorter, pagination.current);
  }
  handleActionsDropdownClick(key, record) {
    const actions = { preview: this.handlePreview, recover: this.handleRecover, permanentlyDelete: this.handlePermanentlyDelete };
    const fn = actions[key];
    if (fn) fn(record);
  }

  //Multiselection
  handleMultiSelectionToogle() { this.setState({ multiselection: !this.state.multiselection }); }
  handleMultiSelection(objectIDs) {
    const objects = objectIDs.map((objectID) => this.props.results.find((f) => f.id == objectID)).filter(x => x !== undefined);
    const multiselectionDeletedCopy = JSON.parse(JSON.stringify(this.state.multiselectionDeleted));
    const newMulti = objects.map((object) => {
      if (object?.deleted) multiselectionDeletedCopy.push(object.id);
      return ((object?.type?.toLowerCase() === 'document') ? { docketID: object.docketID, id: object.id } : object.id);
    });
    const uniqDocketsObjects = [ ...new Set(newMulti.concat(this.state.multiselectionObjects).filter(o => !o.id))];
    const uniqDocumentsObjects = [...new Set(newMulti.concat(this.state.multiselectionObjects).filter(o => o.id).map(JSON.stringify))].map(JSON.parse);
    const multiselectionObjects = (uniqDocketsObjects.concat(uniqDocumentsObjects)).map(object => {
      for (const id of objectIDs) {
        if (object.id === id) return object
        else if (object === id) return object
      }
      return null;
    }).filter(x => x);
    const multiselectionDeleted = [...new Set(multiselectionDeletedCopy.map(dID => {
      for (const oID of objectIDs) if (dID === oID) return dID
      return null;
    }).filter(x => x))];
    this.setState({ multiselectionIDs: objectIDs, multiselectionObjects, multiselectionDeleted });
  }
  handleMultiSelectionMenu({ key }) {
    if (key == 'download') this.handleDownload(this.state.multiselectionObjects, true)
    else if (key == 'copy') this.handleTransfer(this.state.multiselectionObjects, false);
    else if (key == 'move') this.handleTransfer(this.state.multiselectionObjects, true)
  }
  //Table actions
  handlePreview(record) { this.props.app.openFilePreview(record); }
  handleRecover(record) { this.modalRecover.show(record); }
  handlePermanentlyDelete(record) { this.modalHardDelete.show(record); }
  async handleDownload(record, isBatch) {
    if (isBatch) {
      this.setState((prevState) => ({ downloadingIDs: [...prevState.downloadingIDs, ...this.state.multiselectionIDs] }));
      this.props.app.operationsController.newBatchDownloadOperationView(record.filter((d) => !d.docketID), record.filter((d) => !!d.docketID), () => {
        this.setState((prevState) => ({ downloadingIDs: prevState.downloadingIDs.filter((downloadingID) => !this.state.multiselectionIDs.includes(downloadingID)) }));
      });
    } else if (record.type == Globals.SearchTypes.DOCKET) { //Docket
      this.setState((prevState) => ({ downloadingIDs: [...prevState.downloadingIDs, record.id] }));
      this.props.app.operationsController.newDocketDownloadOperationView(record, () => {
        this.setState((prevState) => ({ downloadingIDs: prevState.downloadingIDs.filter((downloadingID) => downloadingID !== record.id) }));
      });
    } else { //DOCUMENT
      this.setState((prevState) => ({ downloadingIDs: [...prevState.downloadingIDs, record.id] }));
      this.props.app.operationsController.newDocumentDownloadOperationView(record, () => {
        this.setState((prevState) => ({ downloadingIDs: prevState.downloadingIDs.filter((downloadingID) => downloadingID !== record.id) }));
      });
    }
  }
  handleTransfer(objects, isMove) {
    const documents = objects.filter((d) => !!d.docketID);
    const dockets = objects.filter((d) => !d.docketID);
    this.props.app.operationsController.newTransferOperationView(dockets, documents, isMove);
  }

  //UI
  render() {
    return (
      <Layout.Content className="commonSearchResultsContainer" {...Utils.propagateRef(this, 'container')}>
        <CommonHardDeleteModal app={this.props.app} onReload={this.props.onReload} {...Utils.propagateRef(this, 'modalHardDelete')}/>
        <CommonRecoverModal app={this.props.app} onReload={this.props.onReload} {...Utils.propagateRef(this, 'modalRecover')} />
        <Row className="resultsHeader" type='flex' justify='space-between'>
          <Col> <Typography.Text strong style={{marginLeft: '10px'}}>Search Results</Typography.Text> </Col>
          <Col> {this._renderMultiSelection()} </Col>
        </Row>
        <Divider className="resultsHeaderDivider"/>
        <Table className="resultsTable" columns={this._getTableColumnsSearchResults()} {...this._getTableProps(false)}
               dataSource={this.props.results ? this.props.results : []}/>
      </Layout.Content>
    );
  }

  /* private */
  _getTableProps() {
    return {
      rowKey: 'id', loading: this.props.isLoading, size: 'small', onChange: this.handleTableChange,
      locale: { emptyText: !this.props.results ? 'Search dockets' : 'No dockets found!' },
      onHeaderRow: this._getTableHeaderProps, fixed: true, bordered: false, rowClassName: 'resultsTableRow',
      onRow: this._onRowResultsTable,
      ...(this.state.multiselection ? { rowSelection: this._getTableRowSelectionProps() } : {}),
      pagination: {
        pageSize: Globals.Search_PagingItemsPerPage, hideOnSinglePage: true, position: 'bottom',
        total: this.props.total, current: this.props.currentPage,
      },
    };
  }
  _getTableRowSelectionProps() {
    return {
      getCheckboxProps: () => { return ({ style: { paddingLeft: 6, paddingRight: 4 } }); }, columnWidth: 14,
      onChange: this.handleMultiSelection, selectedRowKeys: this.state.multiselectionIDs || [], hideDefaultSelections: true,
    };
  }
  _getTableColumnsSearchResults() {
    let { sortInfo } = this.props;
    sortInfo = sortInfo || {};
    let columns = [
      { title: '', key: 'icon', width: '30px', render: this._renderTypeIcon },
      {
        title: this.props.showDocumentCol ? 'Name' : 'Name',
        key: 'name.keyword', width: '32%', sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortInfo.columnKey === 'name.keyword' && sortInfo.order, filterDropdownVisible: false,
        render: this._renderColName,
      },
      ...(this.props.showDocumentCol ? [{
        title: 'Docket', key: 'docketName.keyword', width: '13%',
        sorter: (a, b) => (a.docketName ? a.docketName : '').localeCompare(b.docketName ? b.docketName : ''),
        sortOrder: sortInfo.columnKey === 'docketName.keyword' && sortInfo.order,
        render: this._renderColDocket,
      }] : []),
      {
        title: 'Creation Date', key: 'createdOn', width: '100px',
        sorter: (a, b) => a.createdOn - b.createdOn, sortOrder: sortInfo.columnKey === 'createdOn' && sortInfo.order,
        filterDropdownVisible: false, render: this._renderColCreationDate,
      }, {
        title: 'Type', key: 'type', width: '80px', filterDropdownVisible: false, render: this._renderColType,
      }, {
        title: 'Custom Attributes', key: 'attributes', width: '50px', render: this._renderColAttributes,
      }, {
        title: 'Tags', key: 'tags', width: '40px', render: this._renderColTags, align: 'center'
      }, {
        title: '', key: 'action', width: '75px', render: this._renderActionButton,
      },
    ];
    if (this.state.width < 480) columns = this._handleSmallScreen(columns);
    return columns;
  }
  _handleSmallScreen(columns) {
    const toRemove = ['icon', 'attributes', 'tags', 'createdOn', 'type'];
    columns = columns.filter(column => !toRemove.includes(column.key));
    return columns.map(column => {
      if (column.title === 'Name') {
        column.title = 'Name';
        column.className = 'small-screen-document';
      }
      if (column.title === "Docket") {
        column.title = "Docket";
        column.className = 'small-screen-docket';
      };
      return column;
    });
  }
  _onRowResultsTable(record) {
    return {
      onClick: (e) => {
        // Open drawer only if clicked element is not a button or LI
        // (download button and dropdown actions)
        if (!['BUTTON', 'LI'].includes(e.target.tagName)) this.props.onRowSelection(record);
      },
      onDoubleClick: () => {}, // double click row
    };
  }
  /* ui private style */
  _getTableHeaderProps() { return { className: 'resultsTableHeader' }; }
  _appendDeletedClass(props) { return props.deleted ? 'deletedItemColumn' : ''; }
  /* ui mutliselection private */
  _renderMultiSelection() {
    const anySelected = this.state.multiselectionIDs.length > 0;
    const actionsAvailable = (anySelected && this.state.multiselection);
    return (
      actionsAvailable && <Dropdown overlay={
        <Menu onClick={this.handleMultiSelectionMenu}>
          <Menu.Item key="1" disabled> {this.state.multiselectionIDs?.length || 0} items selected </Menu.Item>
          <Menu.Divider/>
          <Menu.Item key="download"> <Icon type="arrow-down"/> Download </Menu.Item>
          <Menu.Item key="move" disabled={!this._isValidTransfer(true)}> <Icon type="vertical-align-top"/> Move </Menu.Item>
          <Menu.Item key="copy" disabled={!this._isValidTransfer(false)}> <Icon type="copy"/> Copy </Menu.Item>
        </Menu>}>
        <Button className='actionButton' style={{ width: 150 }}>
          <Row type='flex' justify='space-between'>
            <Col>Actions...</Col>
            <Col><Icon type="down" /> </Col>
          </Row>
        </Button>
      </Dropdown>
    );
  }
  /* ui table private */
  _renderTypeIcon(props) {
    const icon = Utils.GetIcon(props.type, props?.fileType, props?.fileName);
    return (
      <Row className={`resultsTableColIcon ${this._appendDeletedClass(props)}`}>
        <img src={icon} width="30px" height="30px" alt={Utils.capitalizeString(props.type.toLowerCase())} />
      </Row>
    );
  }
  _renderColDocket(props) {
    return (
      <Row className={`resultsTableColDocket ${this._appendDeletedClass(props)}`}>
        <Typography.Text className="label">{props.docketName ? props.docketName : ''}</Typography.Text>
      </Row>
    );
  }
  _renderColName(props) {
    return (
      <Tooltip title={props.name || ''} getPopupContainer={(e) => {
        //if width is different than visible width, show tooltip
        return (e.offsetWidth < e.scrollWidth) ? document.querySelector('#root') : document.querySelector('#hidden');
      }}>
        <Row className={`resultsTableColName ${this._appendDeletedClass(props)}`}> 
          <Typography.Text className="label">{props.name || ''}</Typography.Text>
        </Row>
      </Tooltip>
    );
  }
  _renderColFileName(props) {
    return (
      <Row className={`resultsTableColName ${this._appendDeletedClass(props)}`}>
        <Typography.Text className="label">{props.fileName}</Typography.Text>
      </Row>
    );
  }
  _renderColCreationDate(props) {
    return (
      <Row className={`resultsTableColCreationDate ${this._appendDeletedClass(props)}`}>
        <Typography.Text className="label">
          {props && Utils.getDateOnUIFormatByTimestamp(props.createdOn)}
        </Typography.Text>
      </Row>
    );
  }
  _renderColType(props) {
    return (
      <Row className={`resultsTableColType ${this._appendDeletedClass(props)}`}>
        <Typography.Text className="label">{Utils.capitalizeString(props.type)}</Typography.Text>
      </Row>
    );
  }
  _renderColAttributes(props) {
    return (
      <Row className={`resultsTableColAttributes ${this._appendDeletedClass(props)}`}>
        <CommonAttributesLabel app={this.props.app} isExpanded={false} attributes={props.attributes} />
      </Row>
    );
  }
  _renderColTags(props) {
    return (
      <Row className={`resultsTableColTag ${this._appendDeletedClass(props)}`}>
        <CommonTagsLabel isExpanded={false} tags={props.tags} />
      </Row>
    );
  }
  _renderActionButton(text, props) {
    const isDocument = (props.type.toLowerCase() === 'document');
    const isDownloading = this.state.downloadingIDs.includes(props.id);
    const isDocket = (props.type.toLowerCase() === 'docket');

    const dropDown = (<Dropdown overlay={
      <Menu onClick={(e) => this.handleActionsDropdownClick(e.key, props)}>
        <Menu.Item key="preview" disabled={!isDocument}> <Icon type="eye" style={{ color: 'black', fontSize: 18 }} /> Preview </Menu.Item>
        {props.deleted && <Menu.Item key="recover"> <Icon type="undo" style={{ color: 'black', fontSize: 18 }} /> Recover </Menu.Item>}
        {props.deleted && <Menu.Item key="permanentlyDelete"> <Icon type="delete" style={{ color: 'black', fontSize: 18 }} /> Permanently Delete </Menu.Item>}
      </Menu>}>
      <Button type="secondary" icon="ellipsis" className='noStyle' />
    </Dropdown>);
    return (
      <Row className='resultsTableColAction'>
        {isDocket && (
          <Button icon="arrow-down" data-id={props.id} className="resultsTableDownloadButton noStyle" loading={isDownloading} disabled={isDownloading} type="secondary" onClick={(e) => { e.stopPropagation(); this.handleDownload(props)}} />
        )}
        {isDocument && (
          <Button icon="arrow-down" data-id={props.id} className="resultsTableDownloadButton noStyle" loading={isDownloading} disabled={isDownloading} type="secondary" onClick={(e) => { e.stopPropagation(); this.handleDownload(props)}} />
        )}
        {props.deleted ? <Tooltip placement="top" title={`This ${isDocument ? 'file' : 'docket'} is marked for deletion and will be permanently removed after ${Utils.getDateOnUIFormatByTimestamp(props.ttl * 1000)}.`}>
          {dropDown} {props.deleted && <Icon type="warning" theme='filled' style={{ marginLeft: '8px', color: 'orange', fontSize: 18 }} />}
        </Tooltip> : dropDown}
      </Row>
    );
  }

  // Helper
  _isValidTransfer(isMove) {
    const account = this.props.app.sharedCache().getCurrentVaultUser();
    if (!isMove) if (!AccountUtils.hasUploadPermission(account.role)) return false;
    if (isMove) if (!AccountUtils.hasDeletePermission(account.role)) return false;
    const anyDeleted = this.state.multiselectionDeleted.find((dID) => dID && this.state.multiselectionIDs.indexOf(dID) != -1);
    if (anyDeleted) return false;
    const dockets = this.state.multiselectionObjects.filter(o => !o.id).length;
    const documents = this.state.multiselectionObjects.filter(o => o.id).length;
    if ((dockets === 0 && documents > 0) || (dockets > 0 && documents === 0)) return true;
    return false;
  }
}
