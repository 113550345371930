import React from "react";
import autoBind from "react-autobind";
//
import Globals from "../../../../config/Globals";
//
import CommonTransferCaseModalBase from "./CommonTransferCaseModalBase";
//
import "../../../../assets/stylesheets/CommonTransferModal.scss";
//
//props: app, dockets, documents, duplicatedDockets, title, step, isLoading, vaultID, onSelectVault, docketID,
// selectedDocket, onSelectDocket, showCreateDocket, onCreateDocket, onCreateNewDocket, onCreateDocketCancel
export default class CommonTransferCaseThreeModal extends CommonTransferCaseModalBase {
  constructor(props) {
    super(props);
    autoBind(this);
  }

 //UI
  render() {
    return (
      <>
        {this.props.step >= 1 && this.renderSelectVault()}
        {this.props.step > 1 && this.props.step >= 2 && this.props.vaultID && this.renderSelectDocket()}
        {this.props.isLoading && this.props.step > 2 && this.renderLoading()}
        {!this.props.isLoading && this.props.step === 3 && this.props.vaultID && this.props.docketID && this.renderList(Globals.Transfer_Type.DOCUMENT)}
      </>
    );
  }
}
