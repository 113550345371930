import React from 'react';
// import autoBind from 'react-autobind';
import { Row, Col, Typography } from 'antd';
import documentIcon from '../../assets/images/icons/file.svg';
//
export default class CommonEmptyVaultSearchView extends React.Component {
  // constructor(props) {
  //   super(props);
  //   autoBind(this);
  // }
  //UI
  render() {
    return (
      <Row type='flex' align='middle' justify='center' style={{marginTop: '30vh'}}>
        <Col style={{marginRight: 20, marginTop: -10}}>
          <img src={documentIcon} alt="Docket" style={{width: 40}} />
        </Col>
        <Col>
          <Row>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>No documents here.</Typography.Title>
          </Row>
          <Row style={{marginTop: -10}}>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>Use the Upload tab to start archiving now.</Typography.Title>
          </Row>
        </Col>
      </Row>
    );
  }
}
