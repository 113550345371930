//Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";
import * as ponyfill from 'web-streams-polyfill/ponyfill';
// DataDog
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
//
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
// Compoenents
import App from './app/App';
import Globals from './app/config/Globals';
//
import './app/assets/stylesheets/index.scss';
import 'antd/dist/antd.less';
// DataDog
import config from './app/config/config';
import packageJSON from '../package.json';
// DEVEL
import * as serviceWorker from './serviceWorker';
//Polyfill for streamsaver -- TODO: move this logic to API client
//eslint-disable-next-line import/newline-after-import
const StreamSaver = require('streamsaver');
StreamSaver.WritableStream = ponyfill.WritableStream;
StreamSaver.mitm = (window.location.hostname == 'localhost' ?
    `https://${Globals.Offline_VaultAlias}.dakeryn-apps.com` : //ugly fix for local dev
    window.location.origin
  ) + '/wb/mitm.html';
//Init Datadog RUM & Logs
if (!process.env.REACT_APP_OFFLINE) {
  const datadogOptions = { ...config.DatadogOptions, version: `${packageJSON.version}@${process.env.COMMIT_HASH}` };
  datadogRum.init(datadogOptions);
  datadogLogs.init({ ...datadogOptions, trackSessionAcrossSubdomains: true, forwardConsoleLogs: "all", forwardReports: "all" });
  datadogRum.startSessionReplayRecording();
}
// Render router with App
ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
// DEVEL
serviceWorker.unregister();
