import React from 'react';
import autoBind from 'react-autobind';
//
import Utils from '../../components/helpers/Utils';
//
import CustomComponent from '../../ui-components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import TabViewController from '../../ui-components/TabViewController';
import PersistentSearch from '../../components/helpers/PersistentSearch';
//Views
import { UploadNavigationViewController } from './UploadStack/EmptyUploadNavigationView';
import CommonSearchView from './CommonSearchView';
import CommonAppControlView from '../commonComponents/AppControl/CommonAppControlView';
//
export default class CommonHomeView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //
    const account = this.props.app.sharedCache().getCurrentVaultUser();
    const vault = this.props.app.sharedCache().getCurrentVault();
    //Check for auto open search
    const persistentSearch = new PersistentSearch();
    this.autoOpenSearchTab = !account.uploadEnabled || persistentSearch.hasPersistedSearch();
    //Build tab children
    this.tabChildren = [
      { component: UploadNavigationViewController, label: 'Upload', icon: 'cloud-upload', disabled: !account.uploadEnabled },
      { component: CommonSearchView, label: 'Search', icon: 'search', disabled: vault?.isPurging },
    ];

    this.state = { isLoading: false };
  }
  //Life cycle
  componentDidMount() {
    this.props.app.appAcceptsDrag = this.handleAcceptDrag;
    this.props.app.appDidReceiveDragFile = this.handleDragFile;
    this.props.app.appDidStartDragFile = this.handleDragFileStart;
    this.props.app.appDidStopDragFile = this.handleDragFileStop;
    this._checkDragFromPush();
  }
  //Actions
  handleUploadFocus() { this.tabController.selectTabAtIndex(0); }
  //Drag
  handleDragFile(files, context, noTransition) {
    //forward -- more logic may be required on the future
    if (this.tabController && this.tabController.getSelectedTabComponent())
      this.tabController.getSelectedTabComponent().handleDragFile(files, context, noTransition);
  }
  handleDragFileStart() {
    if (this.tabController && this.tabController.getSelectedTabComponent().handleDragFileStart)
      this.tabController.getSelectedTabComponent().handleDragFileStart();
  }
  handleDragFileStop() {
    if (this.tabController && this.tabController.getSelectedTabComponent().handleDragFileStop)
      this.tabController.getSelectedTabComponent().handleDragFileStop();
  }
  handleAcceptDrag() {
    if (this.tabController && this.tabController.getSelectedTabComponent().handleAcceptDrag)
      return this.tabController.getSelectedTabComponent().handleAcceptDrag();
    return true;
  }
  //Children
  handleDragFileFromSearch(files, context) { //This is called by the search with context asking to change to the other tab
    this.tabController.selectTabAtIndex(0);
    const tabItem = this.tabController.getTabComponentAtIndex(0);
    tabItem.handleDragFile(files, context, true);
  }

  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.isAuthenticating;
    return (
      <>
        <CommonLoadingView isLoading={isLoading} />
        <CommonAppControlView app={this.props.app}>
          <TabViewController
            defaultSelected={this.autoOpenSearchTab ? 1 : 0}
            config={this.tabChildren} mobileLayout={this.props.app.layout.isMobile}
            parent={this} app={this.props.app}
            {...Utils.propagateRef(this, 'tabController')}/>
        </CommonAppControlView>
      </>
    );
  }

  //Drag and drop
  _checkDragFromPush() {
    if (this.props.location.state && this.props.location.state.files) {
      const files = this.props.location.state.files;
      //Assume EmptyUpload due the nature of the event
      setTimeout(() => {
        this.handleDragFile(files);
      }, 100);
    }
  }
}
