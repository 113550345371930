import autoBind from 'react-autobind';
import LCC from 'lightning-container';
import LightningIPCPacket from './Lightning-IPCPacket';
//
export default class LightningIPC {
  constructor() {
    autoBind(this);
    this.didReceiveMessage = null;
    this._listenToIPC();
  }
  isAvailable() { return (typeof window.LCC !== "undefined" && typeof window.LCC.onlineSupport !== "undefined"); }
  sendMessage(request) {
    if (this.isAvailable()) {
      console.log('[LightningIPC] - sending message', request);
      LCC.sendMessage(request.encodedMessage());
    }
  }
  /* private */
  _listenToIPC() {
    if (this.isAvailable()) {
      console.debug('[LightningIPC] - LightningIPC is available, listening to RPCs');
      //Listen
      LCC.addErrorHandler(this.handleCOMError);
      LCC.addMessageHandler(this.handleCOMMessage);
      //Notify loaded
      setTimeout(() => {
        const msg = new LightningIPCPacket();
        msg.appendMessage({ name: LightningIPC.LightningIPC_WebActions.EVENT_IPC_LOADED, value: {} });
        this.sendMessage(msg);
      }, 1250);
    } else console.log('[LightningIPC] - LightningIPC not available')
  }
  handleCOMMessage(event) {
    console.debug('[LightningIPC] - msg', event)
    if (this.didReceiveMessage) {
      const packet = new LightningIPCPacket();
      const err = packet.decodeMessage(event);
      if (!err) this.didReceiveMessage(packet);
      else console.error(`[LightningIPC] - Error while decoding message ${err}`);
    }
  }
  handleCOMError(error) { console.error(`[LightningIPC] - COM Error ${error}`); }
}
LightningIPC.LightningIPC_HostActions = {
  CONFIG_APP: 'CONFIG_APP',
};
LightningIPC.LightningIPC_WebActions = {
  //load
  EVENT_IPC_LOADED: 'event_ipc_loaded',
  //authorization
  EVENT_SESSION_LOADING: 'event_session_load_started_v2',
  EVENT_SESSION_STARTED: 'event_session_started_v2',
  EVENT_SESSION_ENDED: 'event_session_ended_v2',
  EVENT_SESSION_ERR: 'event_session_error_v2',
};