import Globals from '../../config/Globals';
import config from '../../config/config';
//
const urlParse = require('url-parse');
//
export default class VaultManager {
  constructor(auth) {
    this.auth = auth;
    this.cookieKey = 'op_vaultinfo';
    this.currentVaultLogoLink = null;
  }
  // Life cycle
  async load(overrideAlias) {
    const vaultAlias = overrideAlias || this._getCurrentVaultAlias() || this.auth.urlManager.getQueryParam(Globals.URLQueryParam_VaultAlias) || null;
    if (vaultAlias) {
      await this.setRedirectVault(vaultAlias);
      this._clearQueryParams();
    }
  }

  /* Public */
  async setRedirectVault(vaultAlias) {
    const vaultInfo = { vaultAlias };
    await this._setCookie(vaultInfo);
  }
  async getRedirectVaultAlias() {
    try {
      const vaultInfo = await this._getCookie();
      return vaultInfo ? vaultInfo.vaultAlias : null;
    } catch (err) {
      return null;
    }
  }
  async getRedirectVaultName() {
    try {
      const redirectVaultAlias = await this.getRedirectVaultAlias();
      if (redirectVaultAlias) {
        const resp = await this.auth.api.v2.vaults.getNameByAlias(redirectVaultAlias);
        if (resp.statusCode === 200 && resp.body && resp.body.name) {
          return resp.body.name;
        }
      }
      return null;
    } catch (err) {
      return null;
    }
  }
  getRedirectVaultURL(vaultAlias) { return `https://${vaultAlias}.${config.ApplicationDomain}`; }
  async clearRedirectVaultAndRedirectToAuth() {
    await this._setCookie({ vaultAlias: null });
    this.auth.urlManager.redirectToLogin();
  }
  //Redirect vault generic
  async getRedirectVaultLogoLink() {
    if (!this.currentVaultLogoLink) {
      const alias = await this.getRedirectVaultAlias();
      this.currentVaultLogoLink = `${config.ApplicationThemeEndpoint}/${alias}/logo.png?${Date.now()}`;
    } return this.currentVaultLogoLink;
  }
  //Generic
  async redirectToVault(vaultAlias, saveOnCache = true, path = null) {
    if (saveOnCache) await this.setRedirectVault(vaultAlias);
    this.auth.urlManager.openPage(`${this.getRedirectVaultURL(vaultAlias)}${path || ''}`);
  }

  /* Private */
  async _getCookie() {
    try {
      const userObject = await this.auth.idm.session.data.storage.getValue(this.cookieKey);
      return JSON.parse(userObject);
    } catch (err) {
      return null;
    }
  }
  async _setCookie(data) {
    const vaultInfo = JSON.stringify(data) || '';
    await this.auth.idm.session.data.storage.setLongLiveValue(this.cookieKey, vaultInfo);
  }
  _clearQueryParams() {
    this.auth.urlManager.updateQueryStringParam(Globals.URLQueryParam_VaultAlias, null);
  }
  _getCurrentVaultAlias() {
    const curr = urlParse(window.location.href);
    let alias = curr.hostname.split('.')[0];
    console.debug('Using vault alias ', alias);
    // Development hosts
    if (location.hostname == 'local.dakeryn-apps.com' || location.hostname == 'localhost') {
      console.log(`Overwriting local vaults alias to ${Globals.Offline_VaultAlias}`);
      alias = Globals.Offline_VaultAlias;
    } return alias;
  }
}
