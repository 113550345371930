import React from 'react';
import autoBind from 'react-autobind';
import { Spin, Typography, Select, AutoComplete, Row, Col, Input, Button, Form, Modal } from 'antd';
import { SearchOutlined, SettingOutlined } from '@ant-design/icons';
import Animate from 'rc-animate';
import velocity from 'velocity-animate';
//
import docketIcon from "../../../assets/images/icons/docket.svg";
import documentIcon from "../../../assets/images/icons/file.svg";
//
import Utils from '../../../components/helpers/Utils';
import Globals from '../../../config/Globals';
import config from '../../../config/config';
//
import CommonSearchFilter from './CommonSearchFilter';
//
import '../../../assets/stylesheets/CommonSearchBar.scss';
import PersistentSearch from '../../../components/helpers/PersistentSearch';
//props are: app, disabled, isLoading, onModeChange, onFilterChange, onSearch, termSuggestion
export default class CommonSearchBar extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.debounce = null; //search debouncer
    this.autocompleteDebounce = null; //autocomplete search debounce
    this.persistentSearch = new PersistentSearch();
    this.parsedQueryParams = this.persistentSearch.getAll();
    const account = this.props.app.sharedCache().getCurrentVaultAccount();
    const noAdvancedAccess = account.limits.searchType != Globals.Limits_SearchTypes.ADVANCED;
    this.state = {
      //advanced search control
      advancedVisible: (!noAdvancedAccess && this.parsedQueryParams?.filters),
      noAdvancedAccess, noAdvancedAccessModalVisible: false,
      //
      searchValue: this.persistentSearch.getParam(Globals.URLQueryParam_SearchTerm) || '',
      searchType: this.persistentSearch.getParam(Globals.URLQueryParam_SearchType) || Globals.SearchTypes.ALL,
      autocompleteItems: [], loadingAutocomplete: false
    };
  }
  //Lifecycle
  componentDidMount() {
    if (this.state.searchValue) this.handleSearch(this.state.searchValue);
  }
  //Actions
  focus() {
    // This timeout is required because without this the input is focused only
    // on the first tab change
    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.focus();
      }
    }, 300);
  }
    //Search bar
  handleSearch(newValue) {
    let searchValue = null;
    //Fetch safe search value
    if (typeof newValue === 'string') searchValue = newValue;
    else if (this.searchInput) searchValue = this.searchInput.input.state.value;
    //Check for minimum chars
    if (searchValue && searchValue.length < Globals.Search_MinimumCharacters) return;
    else if (!searchValue && !this.state.advancedVisible) return;
    //Check fi advanced is visible
    if (this.state.advancedVisible) {
      searchValue = this.filter.getSearchFilter();
      this.persistentSearch.setAdvancedSearch(searchValue);
      this.setState({ searchTerm: '' });
    } else {
      this.persistentSearch.setSearchTerm(searchValue, this.state.searchType);
      searchValue = {
        type: this.state.searchType,
        quantifier: Globals.SearchQuantifier.ALL,
        filters: [{ field: Globals.SearchFields.ALL, qualifier: Globals.SearchQualifiers.CONTAINS, value: [searchValue] }]
      };
    }
    //
    this._cleanupAutocompletion();
    this._scheduleSearch(searchValue);
  }
  handleSearchChange(newValue) {
    const searchValue = newValue ;
    const isEmpty = !searchValue || searchValue.length == 0;
    if (isEmpty) this._scheduleSearch(null);
    this.setState({ searchValue: searchValue, autocompleteItems: []});
    //has text, generate auto complete, else, cleanup above
    if (!isEmpty) this._scheduleAutocompletion(newValue, this.state.searchType);
  }
  handleSearchSelect(v) {
    let item = this.state.autocompleteItems.find((i) => i.id == v);
    if (!item) item = { name: this.state.searchValue }; //auto selection of the search value
    this.setState({ searchValue: item.name });
    this.handleSearch(item.name);
  }
  handleSuggestionSelection(item) {
    if (item) {
      this.setState({ searchValue: item }, this.handleSearch);
      this.handleSearch(item);
    }
  }
  handleSearchTypeChange(searchType) {
    this.setState({ searchType }, this.handleSearch);
  }
    //Filter
  handleFilterToogle() {
    this.setState({
      advancedVisible: !this.state.advancedVisible,
      noAdvancedAccessModalVisible: !this.state.advancedVisible ? this.state.noAdvancedAccess : false,
    }, this.props.onModeChange);
  }
  handleFilterChanged() {
    //just called when new filters are added or removed
    this.setState(this.state, this.props.onFilterChange);
  }
  handleSubscribe() {
    this.props.app.urlManager.openPage(`https://${config.AccountAppSubdomain}.${config.ApplicationDomain}?${Globals.URLQueryParam_Subscribe}=1`);
  }

  //UI
  render() {
    const anim = { enter: this.animateEnter, leave: this.animateLeave };
    return (
      <>
        {this._renderUpgradeModal()}
        <div className="commonSearchBar">
          {/* Advanced */}
          <Animate component="" showProp="visible" animation={anim}>
            <CommonSearchFilter key="1" visible={this.state.advancedVisible} {...Utils.propagateRef(this, 'filter')}
              onChange={this.handleFilterChanged} app={this.props.app} parsedQueryParams={this.parsedQueryParams}
              persistentSearch={this.persistentSearch}/>
          </Animate>
          {/* Simple */}
          {this._renderSimpleSearch()}
          {this._renderSuggestionRow()}
        </div>
      </>
    );
  }

  /* sub renders */
  _renderSimpleSearch() {
    const { searchValue } = this.state;
    const error = !(!searchValue || searchValue.length >= Globals.Search_MinimumCharacters);
    const autocompleteItems = (this.state.loadingAutocomplete ?
      [ this._renderAutocompleteOption(null) ] : //loading state
      ((!searchValue || searchValue.length == 0) ? [] : [{ id: Date.now() + '', name: searchValue, search: true }]) //append to valid state the current value (when valid)
        .concat(this.state.autocompleteItems).map(this._renderAutocompleteOption)); //append server side autocomplete items + map to UI option
    return (
      <Row type="flex" align="middle" justify="space-between">
        {/* Search bar */}
        <Col className="searchInputColumn">
          <Form.Item validateStatus={error ? 'error' : 'success'} help={error && !this.state.advancedVisible ? `Minimum number of characters is ${Globals.Search_MinimumCharacters}!` : ''}>
            {!this.state.advancedVisible && (
              <AutoComplete value={this.state.searchValue} onChange={this.handleSearchChange} onSelect={this.handleSearchSelect}
                            style={{ width: '100%' }} dataSource={autocompleteItems} disabled={this.state.advancedVisible || this.state.isLoading || this.props.disabled}>
                <Input.Search className="searchBarInput" onSearch={this.handleSearch} placeholder="Search term" {...Utils.propagateRef(this, 'searchInput')}/>
              </AutoComplete>
            )}
          </Form.Item>
        </Col>
        {/* Paid feature tag */}
        {/* {(this.state.advancedVisible && this.state.noAdvancedAccess) && ( <strong className="paid-feature-flag"> <em>paid feature</em> </strong> )} */}
        {/* Type selection */}
        {!this.state.advancedVisible && <Col className="buttonColumn">
          <Select value={this.state.searchType} onChange={this.handleSearchTypeChange} className="searchTypeSelect"
                  disabled={this.state.advancedVisible || this.state.isLoading || this.props.disabled}>
            {Object.keys(Globals.SearchTypes).map( (key, index) => {
                return (<Select.Option key={index} value={key}> {Globals.GetTextSearchByType(Globals.SearchTypes[key])} </Select.Option>);
            })}
          </Select>
        </Col>}
        {/* Search button */}
        <Col className="buttonColumn">
          <Button type="primary" className="searchButton" onClick={this.handleSearch} loading={this.props.isLoading}
            disabled={this.props.isLoading || this.props.disabled || (this.state.noAdvancedAccess && this.state.advancedVisible)}>
            Search
          </Button>
        </Col>
        {/* Advanced search button */}
        <Col className="buttonColumn">
          <Button
            type="ghost"
            className={this.state.advancedVisible ? 'filterButton selected' : 'filterButton'}
            disabled={this.props.isLoading || this.props.disabled}
            onClick={this.handleFilterToogle}
          >
            <SettingOutlined style={{ fontSize: 19, marginLeft: -6, marginTop: -3 }}/>
          </Button>
        </Col>
      </Row>
    );
  }
  _renderSuggestionRow() {
    if (!this.props.termSuggestion || this.props.termSuggestion.length <= 0) return (<></>);
    const { a, preTag, postTag } = { a: this.props.termSuggestion, preTag: '<strong>', postTag: '</strong>' };
	  const pre = a.substring(0, a.indexOf(preTag), a.length - a.indexOf(preTag));
	  const suggestion = a.substring(a.indexOf(preTag), a.indexOf(postTag), a.indexOf(preTag) - a.indexOf(postTag)).replace(preTag, '');
	  const post = a.substring(a.indexOf(postTag), a.length, a.length - a.indexOf(postTag)).replace(postTag, '');
    return (
      <Row type='flex' justify='start'>
        <Typography.Text className='suggestionMainLabel'>
          Did you mean
            <Button type='link' className='suggestionButton' onClick={this.handleSuggestionSelection.bind(this, (pre + suggestion + post))}>
              {"\""}
              {pre?.length > 0 && <Typography.Text className='suggestionPreLabel'>{pre}</Typography.Text>}
              {suggestion?.length > 0 && <Typography.Text className='suggestionHighlightedLabel'>{suggestion}</Typography.Text>}
              {post?.length > 0 && <Typography.Text className='suggestionPostLabel'>{post}</Typography.Text>}
              {"\""}
            </Button>
          ?
        </Typography.Text>
      </Row>
    )
  }
  _renderAutocompleteOption(item) {
    if (!item) return ( <AutoComplete.Option disabled key='loading' value='averystrangekey12345@dd'> <Spin/> </AutoComplete.Option> );
    return (
      <AutoComplete.Option key={item.id} value={item.id}>
        <Typography.Text style={{ marginRight: 15 }}>
          {item.search ?
            <SearchOutlined style={{marginRight: 5}}/> :
            <img src={item.fileName ? documentIcon : docketIcon} alt="icon" style={{marginRight: 5}}/>}
          {item.name}
        </Typography.Text>
      </AutoComplete.Option>
    );
  }
  _renderUpgradeModal() {
    return (
      <Modal visible={this.state.noAdvancedAccessModalVisible} title="Attention!" okText=" Upgrade now!" cancelText="Later"
             onCancel={() => this.setState({ noAdvancedAccessModalVisible: false })} onOk={this.handleSubscribe}>
        This feature is not available with your subscription. Will you be able to create advanced searches but not see any results.
        <p> Please upgrade your plan so you can perform advanced searches. </p>
      </Modal>
    );
  }

  /* animation */
  animateEnter = (node, done) => {
    let ok = false;
    function complete() {
      if (ok) return;
      else ok = 1;
      done();
    }

    node.style.display = 'none';
    velocity(node, 'slideDown', { duration: 500, easing: 'easeIn', complete });
    return {
      stop() {
        velocity(node, 'finish');
        complete();
      },
    };
  };
  animateLeave = (node, done) => {
    let ok = false;
    function complete() {
      if (ok) return;
      else ok = 1;
      done();
    }
    //
    node.style.display = 'block';
    velocity(node, 'slideUp', { duration: 500, easing: 'easeOut', complete });
    return {
      stop() {
        velocity(node, 'finish');
        // velocity complete is async
        complete();
      },
    };
  };

  /* debouncer */
  _scheduleSearch(term) {
    if (this.debounce) clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      if (this.props.onSearch) this.props.onSearch(term);
      this.debounce = null;
    }, 100);
  }
  _scheduleAutocompletion(term, type) {
    if (this.autocompleteDebounce) clearTimeout(this.autocompleteDebounce);
    this.autocompleteDebounce = setTimeout(async () => {
      this.setState({ loadingAutocomplete: true });
      const items = await this._getAutocompletionItems(term, type);
      if (!this.autocompleteDebounce) return; //cancelled during request?
      this.setState({ loadingAutocomplete: false, autocompleteItems: items || [] })
      this.autocompleteDebounce = null;
    }, 300);
  }
  _cleanupAutocompletion() {
    if (this.autocompleteDebounce) clearTimeout(this.autocompleteDebounce);
    this.setState({ loadingAutocomplete: false });
    if (this.searchInputAutocomplete) this.searchInputAutocomplete.blur();
    this.autocompleteDebounce = null;
  }

  /* private API */
  async _getAutocompletionItems(term, type) {
    const searchResp = await this.props.app.api.v2.docketSearch.searchAutocomplete(
      { value: term, type }, this.props.app.sharedCache().getCurrentVaultID()
    );
    if (searchResp.statusCode == 200 && searchResp.body.results) return searchResp.body.results;
    return false;
  }
}
