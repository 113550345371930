import React from 'react';
import autoBind from 'react-autobind';
import NavigationController from '@ikonintegration/react-navigation-controller';
//
import CustomComponent from './CustomComponent';
import Utils from '../components/helpers/Utils';
//
import '../assets/stylesheets/NavigationController.scss';

export default class NavigationControllerView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.fullheight = false;
  }
  getCurrentView() {
    return this.nav.refs[`view-1`] || this.nav.refs[`view-0`];
  }
  render() {
    const props = {
      views: [
        <this.containerClass app={this.props.app} controller={this}/>
      ],
      preserveState: true,
      transitionTension: 200,
      transition: 13, //FADE
      transitionFriction: 20
    };
    return (<NavigationController className={this.fullheight ? 'fullheight' : ''} {...props} {...Utils.propagateRef(this, 'nav')}/>);
  }
}
