/* eslint-disable no-unused-vars */
import React from 'react';
import autoBind from 'react-autobind';
import { WarningOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Icon, Row, Popover, Button, Spin } from 'antd';
//
import Globals from '../../../config/Globals';
//
import CustomComponent from '../../../ui-components/CustomComponent';

//Types
const MessageTypes = { //higher number are higher priority
  EXPIRED_TRIAL: 0,
  VAULT_PURGING: 1
}
const MessageTypeColors = {
  [MessageTypes.EXPIRED_TRIAL]: '#FF7C2D',
  [MessageTypes.VAULT_PURGING]: '#FF7C2D'
};
const MessageTypeIcons = {
  [MessageTypes.EXPIRED_TRIAL]: WarningOutlined,
  [MessageTypes.VAULT_PURGING]: WarningOutlined
};

//props are: app, handleMyAccount, handleResetExpiredTrial
export default class AppBarAlertController extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  /* UI */
  render() {
    const { content, priority } = this._getHighestPriorityAggregatedAlert();
    if (priority < 0) return (<></>);
    return (
      <div className="wrapper alert-banner-wrapper" style={{ background: MessageTypeColors[priority] }}>
        <Row className="alert-banner">
          <ul> {content} </ul>
        </Row>
      </div>
    );
  }

  /* Private UI */
  _getHighestPriorityAggregatedAlert() {
    const messages = this._checkForAlerts();
    //Find higher priority
    const higherPriority = messages.reduce((acc, val) => (val.type > acc ? val.type : acc), -1);
    //Get all messages from this higher priority
    const highPMessages = messages.filter((val) => val.type == higherPriority).map((val, i) => {
      const MessageIcon = MessageTypeIcons[val.type];
      const callout = <>
        <div>
          <MessageIcon style={{ fontSize: 18 }} className='callout-icon'/>
          <span>{val.message}{val.hasLoading && <Spin spin indicator={<Icon type="loading" style={{ color: 'white', fontSize: 20, marginLeft: 10 }}/>}/>}</span>
        </div>
        {val.hasCloseButton && <Button type="link" onClick={this._close}><CloseCircleOutlined style={{ fontSize: 18 }} /></Button>}
      </>;
      return (
        <li key={i}> {callout}
          {val.details && <Popover title={callout} content={ <div style={{maxWidth: 450}}> {val.details} </div>}>
            <span className='alert-banner-item-details'>( Why? )</span>
          </Popover>}
        </li>
      );
    });
    //
    return { content: highPMessages, priority: higherPriority }
  }

  /* Private */
  _checkForAlerts() {
    const currentUserID = this.props.app.idm.session.authorization.getUserID();
    const account = this.props.app.sharedCache().getCurrentVaultAccount();
    const vault = this.props.app.sharedCache().getCurrentVault();
    let messages = [];

    //Trial expired check
    if (account?.expiredTrialInfo && account?.owner === currentUserID) {
      messages.push({
        message: (
          <>
            {`Your trial expired! Click below to subscribe to a paid plan.`}
            <button
              type="button"
              className='alert-banner-item-details'
              style={{ background: 'transparent', border: 0, cursor: 'pointer', padding: 0 }}
              onClick={this.props.handleMyAccount.bind(this)}
            >
              Subscribe
            </button>
          </>
        ),
        type: MessageTypes.EXPIRED_TRIAL,
        hasCloseButton: true
      });
    }
    //Purging vault check
    if (vault?.isPurging) {
      messages.push({
        message: 'This vault is currently undergoing maintenance and all its documents are being removed. Please come back later or select another vault.',
        type: MessageTypes.VAULT_PURGING,
        hasLoading: true
      });
      this._rescheduleAutoRefresh();
    }
    return messages;
  }

  async _close() {
    const currentUserID = this.props.app.idm.session.authorization.getUserID();
    const account = this.props.app.sharedCache().getCurrentVaultAccount();
    if (account?.expiredTrialInfo && account?.owner === currentUserID) {
      const resp = await this.props.handleResetExpiredTrial();
      if (resp) this.forceUpdate();
    }
  }

    /* refresh */
  _rescheduleAutoRefresh() {
    this._cleanTimer();
    //Check for still purging conditional
    const vault = this.props.app.sharedCache().getCurrentVault();
    if (!vault.isPurging) return; //no refresh
    //Setup timer
    this.autoRefresh = setTimeout(async () => {
      console.debug('Auto refresh triggered from vault purge!', new Date());
      this._cleanTimer();
      //reload vault with app refresh
      await this.props.app.sharedCache().reloadCurrentVault();
      this.props.app.endLoading(true); //ask for the 
    }, Globals.VaultPurge_AutoRefreshDelay);
  }
  _cleanTimer() {
    if (this.autoRefresh) clearTimeout(this.autoRefresh);
    this.autoRefresh = null;
  }
}
