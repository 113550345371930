import autoBind from 'react-autobind';
import React from 'react';
import { Dropdown, Menu, Row, Table, Typography, Tooltip, Icon, Button, Skeleton } from 'antd';
import mime from 'mime-types';
import scrollIntoView from 'scroll-into-view';
//
import Utils from '../../../../components/helpers/Utils';
import Globals from '../../../../config/Globals';
//
import CommonAttributesLabel from '../../Attributes/CommonAttributesLabel';
import CommonTagsLabel from '../../Attributes/CommonTagsLabel';

//props are: app, isLoading, documents, docketNumDocs, onPageChange, multiselectionIDs, downloadingIDs
//onDocumentSelect, onDocumentMultiselection, onRecover, onPermanentlyDelete, onDownload
export default class CommonDocumentsViewTypeList extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {  sortedInfo: {}, currentPage: 1, pageSize: Globals.Search_PagingItemsPerPage };
  }

  // Table actions
  handleRow(record) {
    return {
      onClick: (e) => {
        if (!['BUTTON', 'LI'].includes(e.target.tagName)) {
          if (this.props.onDocumentSelect) this.props.onDocumentSelect(record);
          this._scrollToFile();
        }
      }
    };
  }
  async handleFilterChange(pagination, filters, sorter) {
    const currentPage = (this.state.sortedInfo.columnKey !== sorter.columnKey ||
                         this.state.sortedInfo.order !== sorter.order) ? 1 : pagination.current;
    await this.props.onPageChange(
    currentPage === 1 ? 0 : (currentPage -1) * pagination.pageSize,
    (sorter.order === "ascend" ? "asc" : "desc"),
    sorter.columnKey
    );
    this.setState({ sortedInfo: sorter, currentPage });
  }
  handleActionsDropdownClick(key, record) {
    const actions = { recover: this.props.onRecover, permanentlyDelete: this.props.onPermanentlyDelete };
    const fn = actions[key];
    if (fn) fn(record);
  }
  handleMultiSelection(selection) { this.props.onDocumentMultiselection(selection); }

  /* UI */
  render() {
    const { documents } = this.props;
    return (
      <Skeleton title={false} paragraph={{ rows: 8 }} loading={this.props.isLoading} active>
        <Table className={'filesTable multiselection'} onRow={this.handleRow}
              columns={this._getTableColumnsDocketFiles()} dataSource={documents || []}
              {...this._getTableProps()}/>
      </Skeleton>
    );
  }

  /* Private UI helpers */
  _scrollToFile() {
    const element = document.querySelector('.filesTableRow.ant-table-row-selected');
    if (element) scrollIntoView(element, { debug: false, time: 500, align: { top: 0 } });
  }
  _getTableColumnsDocketFiles() {
    let { sortedInfo } = this.state;
    let columns = [];
    return columns.concat([{
        title: 'Name', key: 'name.keyword',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name.keyword' && sortedInfo.order,
        filterDropdownVisible: false, render: this._renderColFileName,
      }, {
        title: 'Creation Date', key: 'createdOn', width: '100px',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
        filterDropdownVisible: false, render: this._renderColCreationDate,
      }, {
        title: 'Type', key: 'fileType.keyword', width: '50px',
        sorter: (a, b) => (mime.extension(a.fileType) || a.fileType).localeCompare(mime.extension(b.fileType) || b.fileType),
        sortOrder: sortedInfo.columnKey === 'fileType.keyword' && sortedInfo.order, filterDropdownVisible: false, render: this._renderColType,
      }, {
        title: 'Custom Attributes', key: 'attributes', render: this._renderColAttributesHover, width: '80px',
      }, {
        title: 'Tags', key: 'tags', render: this._renderColTagsHover, width: '40px',
      }, {
        title: 'Action', key: 'action', width: '60px', render: this._renderActionButton,
      },
    ]);
  }
  _getTableProps() {
    return {
      rowKey: 'id', onChange: this.handleFilterChange, locale: { emptyText: 'No documents attached into this docket!' },
      pagination: { current: this.state.currentPage, pageSize: this.state.pageSize,
                    total: this.props.docketNumDocs, hideOnSinglePage: true },
      bordered: false, size: 'small', onHeaderRow: () => ({ className: 'filesTableHeader' }),
      fixed: true, rowClassName: 'filesTableRow', rowSelection: this._getTableRowSelectionProps(),
    };
  }
  _getTableRowSelectionProps() {
    return {
      getCheckboxProps: () => { return ({ style: { paddingLeft: 10, paddingRight: 8 } }); }, columnWidth: 14,
      onChange: this.handleMultiSelection, selectedRowKeys: this.props.multiselectionIDs || [],
      hideDefaultSelections: true, type: 'checkbox'
    };
  }

  _getAppendDeletedClass(props) { return props.deleted ? 'deletedItemColumn' : ''; }

  /* Private Renders */
  _renderColFileName(props) {
    return (
      <Row className={`filesTableColName ${this._getAppendDeletedClass(props)}`}>
        <Typography.Text className="label">{props.name || props.fileName}</Typography.Text>
      </Row>
    );
  }
  _renderColCreationDate(props) {
    return (
      <Row className={`filesTableColCreationDate ${this._getAppendDeletedClass(props)}`}>
        <Typography.Text className="label">
          {props && Utils.getDateOnUIFormatByTimestamp(props.createdOn)}
        </Typography.Text>
      </Row>
    );
  }
  _renderColType(props) {
    return (
      <Row className={`filesTableColType ${this._getAppendDeletedClass(props)}`}>
        <Typography.Text className="label">
          {mime.extension(props.fileType) ? mime.extension(props.fileType) : props.fileType}
        </Typography.Text>
      </Row>
    );
  }
  _renderColAttributesHover(props) {
    return (
      <Row className={`filesTableColAttributes ${this._getAppendDeletedClass(props)}`}>
        <CommonAttributesLabel app={this.props.app} isExpanded={false} attributes={props.attributes} />
      </Row>
    );
  }
  _renderColTagsHover(props) {
    return (
      <Row className={`filesTableColTag ${this._getAppendDeletedClass(props)}`}>
        <CommonTagsLabel isExpanded={false} tags={props.tags} />
      </Row>
    );
  }
  _renderActionButton(text, props) {
    if (props.deleted) {
      const { allowDeletion } = this.props.app.launchConfigManager.launchConfig;
      return (
        <Row className="filesTableColDeleted">
          <Tooltip overlayStyle={{maxWidth: '316px'}} placement="leftBottom" title={`This file is marked for deletion and will be permanently removed after ${Utils.getDateOnUIFormatByTimestamp(props.ttl * 1000)}.`}>
            <Dropdown overlay={
              <Menu onClick={(e) => this.handleActionsDropdownClick(e.key, props)}>
                {allowDeletion && <Menu.Item key="recover">
                  <Icon type="undo" style={{ color: 'black', fontSize: 18 }} /> Recover
                </Menu.Item>}
                {allowDeletion && <Menu.Item key="permanentlyDelete">
                  <Icon type="delete" style={{ color: 'black', fontSize: 18 }} /> Permanently Delete
                </Menu.Item>}
              </Menu>}>
              <Button icon="ellipsis" className="filesTableDeleteButton noStyle" type="secondary"/>
            </Dropdown>
            <Icon type="warning" theme='filled' style={{ marginLeft: '8px', color: 'orange', fontSize: 18 }} />
          </Tooltip>
        </Row>
      );
    }
    const isDownloadingFile = this.props.downloadingIDs.includes(props.id);
    return (
      <Button icon="arrow-down" className="filesTableDownloadButton noStyle" type="secondary"
              loading={isDownloadingFile} onClick={() => this.props.onDownload(props)}/>
    );
  }
}
