import React from "react";
import autoBind from 'react-autobind';
import { Layout, Row, Col } from 'antd';
// Styles
import "../../assets/stylesheets/CommonFullOrangeScreen.scss";
// Images
import logo from '../../assets/images/logo-white.svg';
import defaultSquare from '../../assets/images/squares/default.svg';
//
import CustomComponent from "../../ui-components/CustomComponent";
//props are: app, hideLogo, title, subtitle, squares
//children (react), renderOutsiteContainer
export default class CommonFullOrangeScreenLayout extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  // UI
  render() {
    const shouldStopAnimations = false;
    const squareLarge1Opts = this.props.squares ? (this.props.squares.large1 || {}) : {};
    const squareLarge2Opts = this.props.squares ? (this.props.squares.large2 || {}) : {};

    return (
      <Layout.Content className={`full-orange-screen-layout-container ${shouldStopAnimations ? 'stop-animations' : ''}`}>
        {!this.props.hideLogo && <Row>
          <Col span={24}>
            <img src={logo} alt="Orange Piggy" height="40" />
          </Col>
        </Row>}

        <div className={`content ${this.props.centered ? 'centered' : ''}`}>
          <div className="up-content">
            <Row>
              <Col span={24} className="head">
                <h1>{this.props.title}</h1>
                <h3>{this.props.subtitle}</h3>
              </Col>
            </Row>

            {this.props.children}
          </div>

          <img src={defaultSquare} alt='square1' className="square large1" {...squareLarge1Opts} />
          <img src={defaultSquare} alt='square2' className="square large2" {...squareLarge2Opts} />
        </div>

        {this.props.renderOutsiteContainer || null}
      </Layout.Content>
    );
  }
}