import React from "react";
import autoBind from "react-autobind";
import { Row, Button, Modal, Divider } from "antd";
//
import Utils from "../../../../components/helpers/Utils";
import Globals from "../../../../config/Globals";
//
import CustomComponent from "../../../../ui-components/CustomComponent";
//
import CommonTransferCaseOneModal from "./CommonTransferCaseOneModal";
import CommonTransferCaseTwoModal from "./CommonTransferCaseTwoModal";
import CommonTransferCaseThreeModal from "./CommonTransferCaseThreeModal";
//
import "../../../../assets/stylesheets/CommonTransferModal.scss";
//
//props: app, onCancel, onConfirmation
export default class CommonTransferModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      shouldHide: true,
      case: undefined,
      isLoading: false,
      isDisabled: true,
      step: 1,
      isMove: undefined,
      title: "",
      confirmationText: "",
      cancellationText: "",
      selectedDocket: null,
      showCreateDocket: false,
      destination: { vaultID: undefined, vaultName: undefined, docketID: undefined },
    };
    this.vaults = this.props.app.sharedCache().getVaults().filter(vault => !vault.isSettingUp && vault.bucketID);
    this.vault = undefined;
    this.dockets = [];
    this.documents = [];
    this.transferType = undefined;
    this.duplicatedDockets = [];
  }

  show(isMove, source, destination) {
    this.setState({ shouldHide: false }, () => {
      this.vault = this.vaults.find(vault => vault.id === source.vaultID);
      source.objects.forEach((obj) => { if (!obj.id) this.dockets.push(obj); });
      source.objects.forEach((obj) => { if (obj.id) this.documents.push(obj); });
      // to test case 2 need to have just one vault
      // this.vaults = this.vaults.slice(0, 1);
      const transferCase = this.getTransferCase();
      console.log("Transfer Case:", transferCase);
      /*eslint-disable-next-line no-nested-ternary*/
      this.transferType = transferCase === 2 ? Globals.Transfer_Type.DOCUMENT : this.dockets.length > 0 ? Globals.Transfer_Type.DOCKET : Globals.Transfer_Type.DOCUMENT;

      this.getAllData(transferCase);

      const total = this.transferType === Globals.Transfer_Type.DOCKET ? this.dockets.length : this.documents.length;
      const title = transferCase === 2 ? `Preparing ${this._isMove(isMove)} Operation` : this.getTitle(transferCase, total, isMove);
      const confirmationText = `${this._isMove(isMove)} ${this.transferType === Globals.Transfer_Type.DOCKET ? this._isSingular(this.dockets.length, Globals.Transfer_Type.DOCKET) : this._isSingular(this.documents.length, Globals.Transfer_Type.DOCUMENT)}`;
      this.setState({ case: transferCase, isMove, destination, title, confirmationText });
    });
  }

  //Setup
  getTransferCase() {
    let transferCase = null;
    if (this.vaults.length > 1 && this.dockets.length > 0 && this.documents.length === 0) transferCase = 1;
    if (this.vaults.length === 1) transferCase = 2;
    if (this.vaults.length > 1 && (this.dockets.length === 0 && this.documents.length > 0)) transferCase = 3;
    if (!transferCase) this.props.app.alertController.showErrorAlert("Error defining transfer case!");
    return transferCase;
  }
  async getAllData(transferCase) {
    this.setState({ isLoading: true, isDisabled: !!this.state.isDisabled });
    const dockets = [];
    const documents = [];
    const docketsIDs = [...new Set((this.dockets.concat(this.documents)).map(document => document.docketID))];
    for (const docketID of docketsIDs) {
      const { respDocket, respDocuments } = await this.getDocketData(docketID);
      if (!Object.keys(respDocket).length || (this.documents.length && !respDocuments.length)) {
        this.setState({ shouldHide: true });
        return;
      }
      const documentsTotal = this.documents.length ? this.documents.filter(d => d.docketID === docketID) : respDocuments;
      for (const document of documentsTotal) {
        const respDocument = respDocuments.find(d => d.id === document.id);
        documents.push({ destDocketID: null, skipping: respDocument.deleted, docketID: respDocument.docketID, docketName: respDocket.docketName, id: respDocument.id,
          metadata: { createdOn: respDocument.createdOn, fileSize: respDocument.fileSize, name: respDocument.name }
        });
      }
      respDocket.metadata.docketNumDocs = documents.filter(document => !document.skipping && document.docketID === respDocket.docketID).length;
      dockets.push(respDocket);
    }
    if (transferCase === 2) {
      const total = documents.filter(document => !document.skipping).length;
      this.setState({ title: this.getTitle(transferCase, total, this.state.isMove) });
    }
    this.dockets = dockets;
    this.documents = documents;
    this.setState({ isLoading: false });
  }
  getTitle(transferCase, total, isMove) {
    let title = `${this._isMove(isMove)} ${total} `;
    if (transferCase === 1) {
      title += `${this._isSingular(this.dockets.length, Globals.Transfer_Type.DOCKET)}`;
    } else if (transferCase === 2) {
      title += `${this._isSingular(this.documents.length, Globals.Transfer_Type.DOCUMENT)} in ${this.vault.name}`;
    } else if (transferCase === 3) {
      title += `${this._isSingular(this.documents.length, Globals.Transfer_Type.DOCUMENT)}`;
    }
    return title;
  }

  // API CALLS
  async getDocketData(docketID) {
    const resp = await this.props.app.api.v2.vaultDocket.getDocketExpandedByID(this.vault.id, docketID);
    if (resp.statusCode == 200) {
      return {
        respDocket: { destDocketID: null, docketID: resp.body.id, docketName: resp.body.name, skipping: resp.body.deleted, metadata: { name: resp.body.name } },
        respDocuments: resp.body?.documents || []
      }
    } else {
      this.props.app.alertController.showAPIErrorAlert("Error while retrieving docket!", resp);
      return { respDocket: {}, respDocuments: [] };
    }
  }
  // used in case of not call the docket to get the list of files and metadatas.
  //
  // async getDocumentMetadata(document) {
  //   const resp = await this.props.app.api.v2.document.getDocument(this.vault.id, document.docketID, document.id);
  //   if (resp.statusCode == 200) return { createdOn: resp.body.createdOn, fileSize: resp.body.fileSize, name: resp.body.name };
  //   else this.props.app.alertController.showAPIErrorAlert("Error while retrieving document!", resp);
  //   return undefined;
  // }
  async getDocketByName(name, vaultID) {
    const resp = await this.props.app.api.v2.docketSearch.simpleDocketsSearch(name, vaultID);
    if (resp.statusCode == 200) {
      const verifyDocket = resp.body.results.filter(res => res.type === 'DOCKET' && res.name === name);
      if (verifyDocket.length !== 0) return { docketID: verifyDocket[0].id, metadata: { name: verifyDocket[0].name } };
    } else this.props.app.alertController.showAPIErrorAlert("Error while retrieving docket info!", resp);
    return null;
  }

  //Actions
  handleSelectVault(vault) {
    this.setState({ destination: { vaultID: vault.id,  vaultName: vault.name, docketID: undefined }, selectedDocket: null, showCreateDocket: false, isDisabled: !!this.state.destination.vaultID });
  }
  handleSelectDocket(selectedDocket) {
    console.log(selectedDocket)
    if (selectedDocket) (this.documents.concat(this.dockets)).forEach(obj => { obj.destDocketID = selectedDocket.id; obj.destDocketName = selectedDocket.name });
    this.setState({ selectedDocket, destination: { ...this.state.destination, docketID: selectedDocket?.id }, isDisabled: !selectedDocket });
  }
  handleCreateDocket() {
    this.setState({ selectedDocket: null, destination: { ...this.state.destination, docketID: undefined }, showCreateDocket: true, isDisabled: true, step: this.state.case === 2 ? 1 : 2 });
  }
  handleCreateDocketCancel() {
    this.setState({ showCreateDocket: false });
  }
  handleCreateNewDocket(docket, docketID) {
    const selectedDocket = {
      ...docket,
      id: docketID,
      userID: this.props.app.idm.session.authorization.getUserID(),
      vaultID: this.state.destination.vaultID || this.props.app.sharedCache().getCurrentVaultID(),
    };
    this.handleSelectDocket(selectedDocket);
    this.handleCreateDocketCancel();
  }
  handleCancel() {
    this.setState({ shouldHide: true });
    if (this.props.onCancel) this.props.onCancel();
    else if (this.internalCallback) this.internalCallback(false);
  }
  handleChangeStep() {
    const nextStep = ++this.state.step;
    if (this.state.case === 1 && this.state.destination.vaultID) {
      if (nextStep === 3 && this.state.destination.vaultID) this.handleDuplicateDockets();
      if (nextStep !== 1 && nextStep < 4) this.setState({ step: nextStep });
      else this.handleConfirmation();
    }
    if (this.state.case === 2 && this.state.destination.docketID) {
      if (nextStep !== 1 && nextStep < 3) this.setState({ step: nextStep });
      else this.handleConfirmation();
    }
    if (this.state.case === 3 && this.state.destination.vaultID) {
      if (nextStep === 2) this.setState({ step: 2, isDisabled: true });
      else if (nextStep !== 4 && this.state.destination.docketID) this.setState({ step: nextStep });
      else this.handleConfirmation();
    }
  }
  async handleDuplicateDockets() {
    this.setState({ isLoading: true, isDisabled: !!this.state.isDisabled });
    for (const docket of this.dockets) {
      // test duplicated dockets
      // if (docket.metadata.name === 'docket_1') docket.metadata.name = 'Teste 001';
      const verifyDocket = await this.getDocketByName(docket.metadata.name, this.state.destination.vaultID);
      if (verifyDocket) this.duplicatedDockets.push(verifyDocket);
    }
    if (this.duplicatedDockets.length) this.setState({ confirmationText: 'Combine Dockets', isLoading: false});
    else this.handleConfirmation();
  }
  async handleConfirmation() {
    const objects = this.dockets.concat(this.documents);
    const source = { vaultID: this.vault.id, vaultName: this.vault.name, objects };
    const destination = {
      ...this.state.destination,
      vaultID: this.state.destination.vaultID || this.props.app.sharedCache().getCurrentVaultID(),
      vaultName: this.state.destination.vaultName || this.vaults.find(v => v.id === this.props.app.sharedCache().getCurrentVaultID()).name
    }
    this.setState({shouldHide: true});
    if (this.props.onConfirmation) this.props.onConfirmation(source, destination, this.state.isMove, this.transferType );
    else if (this.internalCallback) this.internalCallback(true);
  }

  //UI
  render() {
    return (
      <Modal maskClosable={false} title={this.state.title} visible={!this.state.shouldHide} closable={false}
        footer={null} centered className="transferModal" width={690}>
          {this.state.case && this.state.case === 1 && this._renderCaseOne()}
          {this.state.case && this.state.case === 2 && this._renderCaseTwo()}
          {this.state.case && this.state.case === 3 && this._renderCaseThree()}
          {this.state.case && this._renderButtons()}
      </Modal>
    );
  }

  // private UI
  _renderButtons() {
    return (
      <>
        <Divider />
        <Row type="flex" justify="end" className="footerSection">
          {!this.props.hideCancel && (<Button type="secondary" className="cancelButton" onClick={this.handleCancel}>{this.state.cancellationText || "Cancel"}</Button>)}
          <Button type="primary" className="confirmationButton" onClick={this.handleChangeStep} disabled={this.state.isDisabled}>{this.state.confirmationText || "OK"}</Button>
        </Row>
      </>
    );
  }
  _renderCaseOne() {
    return (
      <CommonTransferCaseOneModal
        app={this.props.app}
        dockets={this.dockets}
        documents={this.documents}
        duplicatedDockets={this.duplicatedDockets}
        title={`The selected ${this._isSingular(this.dockets.length, Globals.Transfer_Type.DOCKET)} will be moved to:`}
        step={this.state.step}
        isLoading={this.state.isLoading}
        vaultID={this.state.destination.vaultID}
        onSelectVault={this.handleSelectVault}
      />
    );
  }
  _renderCaseTwo() {
    return (
      <CommonTransferCaseTwoModal
        app={this.props.app}
        dockets={this.dockets}
        documents={this.documents}
        duplicatedDockets={this.duplicatedDockets}
        title={`Select a Docket to move the selection to:`}
        step={this.state.step}
        isLoading={this.state.isLoading}
        vaultID={this.state.destination.vaultID}
        docketID={this.state.destination.docketID}
        selectedDocket={this.state.selectedDocket}
        onSelectDocket={this.handleSelectDocket}
        showCreateDocket={this.state.showCreateDocket}
        onCreateDocket={this.handleCreateDocket}
        onCreateNewDocket={this.handleCreateNewDocket}
        onCreateDocketCancel={this.handleCreateDocketCancel}
      />
    );
  }
  _renderCaseThree() {
    return (
      <CommonTransferCaseThreeModal
        app={this.props.app}
        dockets={this.dockets}
        documents={this.documents}
        duplicatedDockets={this.duplicatedDockets}
        title={`Select a Vault and Docket to move the selection to:`}
        step={this.state.step}
        isLoading={this.state.isLoading}
        vaultID={this.state.destination.vaultID}
        onSelectVault={this.handleSelectVault}
        docketID={this.state.destination.docketID}
        selectedDocket={this.state.selectedDocket}
        onSelectDocket={this.handleSelectDocket}
        showCreateDocket={this.state.showCreateDocket}
        onCreateDocket={this.handleCreateDocket}
        onCreateNewDocket={this.handleCreateNewDocket}
        onCreateDocketCancel={this.handleCreateDocketCancel}
      />
    );
  }

  // Helpers
  _isMove(isMove) { return Utils.capitalizeString(isMove ? Globals.Transfer_Operation.MOVE : Globals.Transfer_Operation.COPY); }
  // eslint-disable-next-line no-nested-ternary
  _isSingular(length, type) { return type === Globals.Transfer_Type.DOCKET ? length === 1 ? "Docket" : "Dockets" : length === 1 ? "Document" : "Documents"; }
}
