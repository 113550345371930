import React from "react";
import autoBind from "react-autobind";
import { Col, Typography, Tag, Tooltip } from 'antd';
//Style
import '../../../assets/stylesheets/CommonOperationsTags.scss';
//
//props: totalText, inprogress, inprogressText, success, successText, failures, failuresText, retryingText
export default class CommonOperationsTags extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  //UI
  render() {
    return (
      <Col className="CommonOperationsTags">
        <Typography.Text className="tagsContainer">
          <span className="tagsTitle">{this.props.totalText}</span>
          <span>
          {(() => {
            if (this.props.inprogress > 0) return <Tag> <Tooltip title={this.props.inprogressText}>{this.props.inprogress} 🔵</Tooltip> </Tag>
            else return '';
          })()}
          <Tag> <Tooltip title={this.props.successText}>{this.props.success} 🟢</Tooltip> </Tag>
          {(() => {
            if (this.props.failures > 0) return <Tag> <Tooltip title={this.props.failuresText}>{this.props.failures} 🔴</Tooltip> </Tag>
            else return '';
          })()}
          {this.props.isAutoRetryInProgress && <Tag color='#F88B0B'>{this.props.retryingText}</Tag>}
          </span>
        </Typography.Text>
      </Col>
    );
  }
}
