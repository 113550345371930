import React from 'react';
import autoBind from 'react-autobind';
import { Row, Col, Divider, Button, Modal, Typography } from 'antd';
//
import CustomComponent from '../../../ui-components/CustomComponent';
//
import Utils from '../../../components/helpers/Utils';
import Globals from '../../../config/Globals';
//
import '../../../assets/stylesheets/CommonDragErrorModal.scss';
//
export default class CommonDragErrorModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { shouldHide: true, title: `Files are not accepted`, rejections: null };
  }

  //Actions
  show(rejections) { this.setState({rejections, shouldHide: false}); }
    //Actions
  handleCancel() {
    this.setState({shouldHide: true});
    if (this.props.onCancel) this.props.onCancel();
  }
  //UI
  render() {
    return (
      <Modal maskClosable={false} title={this.state.title}
             visible={!this.state.shouldHide} closable={false}
             footer={null} centered className='dragRejectionModal'>
        {this._renderSubtitle()}
        {this._renderFilesTable()}
        {this._renderButtons()}
      </Modal>
    );
  }
  /* UI private */
  _renderSubtitle() {
    return (
      <Row className='subtitleLabelRow'>
        <Typography.Text className='subtitleLabel'>One or more of the selected files are not accepted, please, check the errors below:</Typography.Text>
      </Row>
    );
  }
  _renderFilesTable() {
    return (
      <Row className="filesTable">
        <Col span={24}>
          <ul>
            {this.state.rejections && (
              <>
                <Divider />
                <Row>
                  <Col span={24}>
                    <Typography.Text type="danger" strong style={{fontSize: 16}}>
                      The following files have presented errors:
                    </Typography.Text>
                    {this.state.rejections.map(reason => {
                      return reason.files.map(file => {
                        return (
                          <li key={file.lastModifiedDate}>
                            <Typography.Text>
                              {file.path} ({Utils.formatSizeUnits(file.size)}) - {Globals.DnDRejectionReasonsString(reason.reason)}
                            </Typography.Text>
                          </li>
                        );
                      })
                    })}
                  </Col>
                </Row>
              </>
            )}
          </ul>
        </Col>
      </Row>
    );
  }
  _renderButtons() {
    return (
      <Row type='flex' justify='end'>
        <Button type="primary" className='cancelButton'
                onClick={this.handleCancel}> OK </Button>
      </Row>
    );
  }
}
