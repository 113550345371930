import Globals from '../../config/Globals';
import Utils from '../helpers/Utils';
import AccountUtils from '../helpers/AccountUtils';
//
export default class Cache {
  constructor(auth) {
    this.auth = auth;
    this.isLoading = false;
    this.selectedVault = null;
    this.myAccount = null;
    this.vaultUser = null;
    this.vaults = [];
    this.invitations = [];
  }

  /* Public */
  async loadCache() {
    console.log('Loading cache');

    // Avoid double cache loading
    if (this.isLoading) return false;
    this.isLoading = true;
    //Load authorized user vaults
    if (!(await this._loadVaults())) {
      this.isLoading = false;
      return false;
    }
    //Load select vault
    const allowFallback = this.auth.ipcManager.anyIPCHost(); //this is used on IPC, to avoid redirecting to auth and instead redirect to a valid vault
    const hasLoadedVault = await this._loadSelectedVault(allowFallback);
    if (!hasLoadedVault) {
      await this.auth.vaultManager.clearRedirectVaultAndRedirectToAuth();
      // Return true because we don't want to logout the user
      return true;
    }
    // Further requests
    const respAll = await Utils.execRequests([this._loadCurrentVaultUser()]);
    this.isLoading = false;
    console.log('Cache Loaded');
    return respAll;
  }
  clearCache() {
    this.selectedVault = null;
    this.vaultUser = null;
    this.myAccount = null;
    this.vaults = [];
    this.invitations = [];
  }
  async reloadCache() {
    this.clearCache();
    return await this.loadCache();
  }
  async reloadVaults() { 
    await this._loadVaults();
    await this._loadSelectedVault(false);
  }
  async reloadCurrentVault() {
    return this._loadCurrentVault();
  }
  // Getters Helpers
  isCurrentVaultAccountDataWriteLocked() { return this.selectedVault?.account?.accountFlags?.accountManualLock?.lockType >= Globals.AccountManualLockTypes.DATAWRITELOCK; }
  isCurrentVaultAccountDataWriteSearchLocked() { return this.selectedVault?.account?.accountFlags?.accountManualLock?.lockType >= Globals.AccountManualLockTypes.DATAWRITESEARCHLOCK; }
  isCurrentVaultAccountWriteLocked() { return this.selectedVault?.account?.accountFlags?.accountManualLock?.lockType >= Globals.AccountManualLockTypes.ALLWRITESEARCHLOCK; }
  isCurrentVaultAccountReadLocked() { return this.selectedVault?.account?.accountFlags?.accountManualLock?.lockType >= Globals.AccountManualLockTypes.READWRITELOCK; }
  // Getters
  getCurrentVaultUser() { return this.vaultUser ? this.vaultUser : null; }
  getCurrentVault() { 
    if (!this.selectedVault) return null;
      //Check for vault Purging
    const { COMPLETED, FAILED  } = Globals.Vault_PurgeStatuses;
    const isPurging = this.selectedVault?.purgeStatus?.status && ![COMPLETED, FAILED].includes(this.selectedVault?.purgeStatus?.status);
      //
    return { ...this.selectedVault, isPurging };
  }
  getCurrentVaultAccount() {
    if (this.selectedVault?.account) {
      return {
        ...this.selectedVault?.account,
        customAttributesEnabled: this.selectedVault?.account?.limits.maxCustAttr > 0,
      };
    }

    return null;
  }
  getCurrentVaultID() { return this.selectedVault.id; }
  getCurrentVaultAttributeByID(attributeID) {
    if (this.selectedVault) {
      return this.selectedVault.attributes.find((attr) => {
        return attr.id == attributeID;
      });
    } return null;
  }
  getCurrentVaultAttributes() { return this.selectedVault ? this.selectedVault.attributes : []; }
  getCurrentVaultOwnerID() { return this.selectedVault ? this.selectedVault.owner : null; }
  isCurrentVaultEmpty() { return this.selectedVault ? this.selectedVault.vaultNumDockets == 0 : false; }
  getVaults() { return this.vaults; }
  getInvitations() { return this.invitations; }
  getMyAccount() { return this.myAccount; }

  /* Private API */
  // Load logged user Vaults
  async _loadVaults() {
    const alias = await this.auth.vaultManager.getRedirectVaultAlias();
    const resp = await this.auth.api.v2.user.getMyVaults(alias);
    if (resp.statusCode == 200 && resp.body) {
      this.vaults = resp.body.myvaults;
      this.invitations = resp.body.invitationVaults;
      this.myAccount = this.vaults.find((vault) => vault.user.role == Globals.Vault_Roles.OWNER)?.account;
    }
    return resp;
  }
  // Check and set user selected vault
  async _loadSelectedVault(allowFallback) {
    const alias = await this.auth.vaultManager.getRedirectVaultAlias();
    let selectedVault = this.vaults.find((vault) => vault.alias == alias);
    if (!selectedVault && !allowFallback) {
      return false;
    } else if (!selectedVault && this.vaults.length > 0) {
      selectedVault = this.vaults[0];
      this.auth.vaultManager.setRedirectVault(selectedVault.alias);
    } else if (!selectedVault) { //no valid vaults
      return false;
    }
    this.selectedVault = selectedVault;
    return true;
  }
  // Reaload the current vault
  async _loadCurrentVault() {
    const vaultID = this.getCurrentVaultID();
    const { statusCode, body } = await this.auth.api.v2.vaults.getByID(vaultID);
    if (statusCode == 200) {
      this.selectedVault = body;
      return true;
    }
    return false;
  }
  // Load current Vault logged user
  async _loadCurrentVaultUser() {
    // Cleanup vault user
    this.vaultUser = null;

    const vaultID = this.getCurrentVaultID();
    const userID = this.auth.idm.session.authorization.getUserID();

    const userResp = await this.auth.api.v2.vaultUser.getVaultUser(vaultID, userID);
    if (userResp.statusCode == 200) {
      const account = {
        ...(userResp.body || {}),
        uploadEnabled: AccountUtils.hasUploadPermission(userResp.body?.role)
      };

      this.vaultUser = account;
    }

    return { statusCode: 200, body: {} };
  }
}
