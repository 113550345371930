import autoBind from 'react-autobind';
import CapacitorIPCPacket from './Capacitor-IPCPacket';
//
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Storage } from '@capacitor/storage';
//
const IDM_SESSION_KEY = 'IDM_SESSION';
//
export default class CapacitorIPC {
  constructor(app) {
    autoBind(this);
    this.app = app;
    this.didReceiveMessage = null;
    this._listenToIPC();
  }
  isAvailable() { return Capacitor.isNativePlatform(); }
  sendMessage(request) {
    if (this.isAvailable()) {
      console.log('[CapacitorIPC] - sending message', request);
      this._handleMessage(request);

    }
  }
  /* private */
  _listenToIPC() {
    if (this.isAvailable()) {
      console.debug('[CapacitorIPC] - CapacitorIPC is available, listening to RPCs');
      this._listenNativeEvents();
      this._listenAppEvents();
      this._loadAppConfig();
    } else console.log('[CapacitorIPC] - CapacitorIPC not available')
  }

  /* event handling */
  _handleMessage(request) {
    if (!request.message) return;
    const { actionType, metadata } = request.message;
    //
    if (actionType == CapacitorIPC.CapacitorIPC_WebActions.EVENT_OPEN_IN_APP_BROWSER) Browser.open(metadata);
    else if (actionType == CapacitorIPC.CapacitorIPC_WebActions.LOAD_SSO) {
      Browser.close(); //close in app browser
      window.location.assign(metadata.params);
    } else if (actionType == CapacitorIPC.CapacitorIPC_WebActions.EVENT_SESSION_STARTED ||
              actionType == CapacitorIPC.CapacitorIPC_WebActions.EVENT_SESSION_ENDED ||
              actionType == CapacitorIPC.CapacitorIPC_WebActions.EVENT_SESSION_ERR) {
                this._savedCurrentSession();
    }
  }

  /* event listeners */
  _listenNativeEvents() {
    //Install window opener to open on default browser
    window.open = (url) => { 
      const packet = new CapacitorIPCPacket();
      packet.appendMessage({ actionType: CapacitorIPC.CapacitorIPC_HostActions.WINDOW_URL_OPEN, metadata: { url } });
      if (this.didReceiveMessage) this.didReceiveMessage(packet);
    };
  }
  async _loadAppConfig() {
    //Check for previous saved idm session
    let idmSession = await Storage.get({ key: IDM_SESSION_KEY });
    if (idmSession && idmSession.value) {
      try { idmSession =  JSON.parse(idmSession.value); } catch (e) { console.error(e); }
    }
    //Attempt to load idm session
    if (!(idmSession && idmSession.additionalMeta && idmSession.token && idmSession.resourcesToken && idmSession.renewalToken)) idmSession = null;
    else console.log('Valid IDM session recognized');
    //Send config load request
    const packet = new CapacitorIPCPacket();
    packet.appendMessage({ actionType: CapacitorIPC.CapacitorIPC_HostActions.CONFIG_APP, metadata: { session: idmSession } });
    if (this.didReceiveMessage) this.didReceiveMessage(packet);
  }
  async _listenAppEvents() {
    //Cleanup previous
    if (this.appUrlListener) {
      await App.removeListener(this.appUrlListener);
      this.appUrlListener = null;
    }
    //Install window opener to open on default browser
    this.appUrlListener = await App.addListener('appUrlOpen', data => {
      console.debug('App opened with URL:', data);
      const packet = new CapacitorIPCPacket();
      packet.appendMessage({ actionType: CapacitorIPC.CapacitorIPC_HostActions.DEEPLINK_URL_OPEN, metadata: { url: data.url } });
      if (this.didReceiveMessage) this.didReceiveMessage(packet);
    });
  }
  /* extra functions */
  async _savedCurrentSession() {
    const userObj = await this.app.idm.session.data.getUserObject();
    const token = await this.app.idm.session.data.getToken();
    const resourcesToken = await this.app.idm.session.data.getResourcesToken();
    const renewalToken = await this.app.idm.session.data.getRenewalToken();
    await Storage.set({ key: IDM_SESSION_KEY, value: JSON.stringify({additionalMeta: { userObj }, token, resourcesToken, renewalToken}) });
  }
}
CapacitorIPC.CapacitorIPC_HostActions = {
  WINDOW_URL_OPEN: 'WINDOW_URL_OPEN',
  DEEPLINK_URL_OPEN: 'DEEPLINK_URL_OPEN',
  CONFIG_APP: 'CONFIG_APP',
};
CapacitorIPC.CapacitorIPC_WebActions = {
  EVENT_OPEN_IN_APP_BROWSER: 'EVENT_OPEN_IN_APP_BROWSER',
  LOAD_SSO: 'LOAD_SSO',
  //authorization
  EVENT_SESSION_LOADING: 'event_session_load_started_v2',
  EVENT_SESSION_STARTED: 'event_session_started_v2',
  EVENT_SESSION_ENDED: 'event_session_ended_v2',
  EVENT_SESSION_ERR: 'event_session_error_v2',
};
CapacitorIPC.LaunchSSOURL = 'orangepiggy-mobile://login?';