import autoBind from 'react-autobind';
import React from 'react';
import { Row, Button } from 'antd';
import { Prompt } from 'react-router-dom';
//
import CommonDocketViewHeaderForm from './CommonDocketViewHeaderForm';

//props are: app, docket, isLoading,
//onUpload, onRecover, onSave, onDownload, onPermanentlyDelete, onSoftDelete
export default class CommonDocketViewHeader extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.unloadMessage = {
      download: 'If you leave this page, your download will be interrupted. Are you sure you want to stop your download?',
      editing: 'If you leave this page, your changes will not be saved. Are you sure you want to discard your unsaved changes?',
      saving: 'If you leave this page, your operation will be interrupted and we can not guarantee it\'s completion. Are you sure you want do it?',
    };

    this.state = { isEditing: false, isDownloading: false, isSaving: false };
  }

  //Getters
  inOperation(considerDownloads = true) {
    return (this.state.isSaving || this.state.isEditing || (!considerDownloads || this.state.isDownloading));
  }

  //Actions
    //Actions proxy
  async handleDownload() {
    //Attempt to save
    this.setState({ isDownloading: true });
    await this.props.onDownload(this.props.docket);
    this.setState({ isDownloading: false });
  }
    //Edit support
  handleEditDocket() { this.setState({ isEditing: true }); }
  handleCancelEdit() { this.setState({ isEditing: false }); }
  async handleSaveChanges() {
    //Validate
    const validationData = await this.headerForm?.validate();
    if (!validationData) return;
    //Attempt to save
    this.setState({ isSaving: true });
    const saved = await this.props.onSave(validationData);
    this.setState({ isEditing: !saved, isSaving: false });
  }

  /* UI */
  render() {
    return (
      <Row>
        {this.inOperation() && <Prompt when message={this._getUnloadMessage()} />}
        <CommonDocketViewHeaderForm app={this.props.app} docket={this.props.docket}
          isEditing={this.state.isEditing} actions={this._renderButtons()}
          wrappedComponentRef={(ref) => { this.headerForm = ref; }}/>
      </Row>
    );
  }

  /* Private UI */
  _renderButtons() {
    const { isEditing, isSaving, isDownloading } = this.state;
    const vault = this.props.app.sharedCache().getCurrentVault();
    const { allowMetadataEditing, allowDeletion, allowUpload } = this.props.app.launchConfigManager.launchConfig;
    const { docket, isLoading } = this.props;
    if (!docket) return (<></>);
    if (isEditing) {
      return (
        <>
          <Button disabled={isSaving || isLoading} onClick={this.handleCancelEdit}> Cancel </Button>{' '}
          <Button type="primary" onClick={this.handleSaveChanges} loading={isSaving || isLoading}> Save Changes </Button>
        </>
      );
    }
    return (
      <>
        {!docket.deleted && allowUpload && !vault.isPurging && <Button disabled={isLoading || docket.deleted} onClick={this.props.onUpload}> Add Document </Button>}
        {docket.deleted && allowDeletion && <Button disabled={isLoading || !docket.deleted} onClick={this.props.onRecover.bind(this, this.props.docket)}> Recover </Button>}{' '}
        <Button disabled={isLoading} loading={isDownloading} icon="arrow-down" onClick={this.props.handleDownload} />{' '}
        {!docket.deleted && allowMetadataEditing && <Button disabled={isLoading || docket.deleted} onClick={this.handleEditDocket}> Edit </Button>}{' '}
        {docket.deleted && allowDeletion && <Button disabled={isLoading || !docket.deleted} onClick={this.props.onPermanentlyDelete.bind(this, this.props.docket)}> Permanently Delete </Button>}{' '}
        {!docket.deleted && allowDeletion && <Button disabled={isLoading || docket.deleted} onClick={this.props.onSoftDelete}> Delete </Button>}
      </>
    );
  }

  /* Private */
  _getUnloadMessage() {
    if (this.state.isDownloading) return this.unloadMessage.download;
    else if (this.state.isEditing) return this.unloadMessage.editing;
    return this.unloadMessage.saving;
  }
}
