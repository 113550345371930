import React from 'react';
import autoBind from 'react-autobind';
//
import { Layout, Tabs, Icon, Row, Tooltip } from 'antd';
//
import '../assets/stylesheets/TabViewController.scss';
//
import Utils from '../components/helpers/Utils';
//
export const TabPrefix = 'tab_';
export const TabComponentPrefix = 'tab_content_';
//
export default class TabViewController extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      selectedIndex: (props.defaultSelected ? props.defaultSelected : 0)
    };
  }

  // Getters
  getSelectedTabComponent() {
    return this.getTabComponentAtIndex(this.getSelectedTabIndex());
  }

  getTabComponentAtIndex(index) {
    return this[`${TabComponentPrefix}${index}`];
  }

  getSelectedTabIndex() {
    return this.state.selectedIndex;
  }

  // Setters
  selectTabAtIndex(index) {
    this.handleTabChange(`${TabPrefix}${index}`);
  }

  // Handlers
  handleTabChange(key) {
    const index = key.replace(TabPrefix, '');
    const objectKey = `${TabComponentPrefix}${index}`;
    const prevObjectKey = `${TabComponentPrefix}${this.state.selectedIndex}`;
    //Delegates if available
    if (this[objectKey] && this[objectKey].tabViewWillAppear) this[objectKey].tabViewWillAppear();
    if (this[prevObjectKey] && this[prevObjectKey].tabViewWillDisappear) this[prevObjectKey].tabViewWillDisappear();
    //save selected index to state
    this.setState({ selectedIndex: index }, () => {
      //Delegates if available
      if (this[objectKey] && this[objectKey].tabViewDidAppear) this[objectKey].tabViewDidAppear();
      if (this[prevObjectKey] && this[prevObjectKey].tabViewDidDisappear) this[prevObjectKey].tabViewDidDisappear();
      //Parent is the last
      if (this.props.tabDidChange) this.props.tabDidChange(index);
    });
  }

  // UI
  render() {
    return (
      <Layout.Content className="tabContainer">
        <Tabs type="card" animated
        activeKey={`${TabPrefix}${this.state.selectedIndex}`}
        onChange={this.handleTabChange}
        className="tabView" tabPosition={this.props.mobileLayout ? "bottom" : "top"}>
          {this.props.config.map( (item, i) => {
            const Component = item.component;
            return (
              <Tabs.TabPane
                disabled={item.disabled}
                tab={this._tabTagForItem(item)}
                key={`${TabPrefix}${i}`}>
                <Component key={`${TabComponentPrefix}${i}`}
                  app={this.props.app}
                  parent={this.props.parent}
                  {...Utils.propagateRef(this, `${TabComponentPrefix}${i}`)}
                />
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </Layout.Content>
    );
  }
  _tabTagForItem(item) {
    return (
      <Tooltip title={item.disabled ? 'You are no allowed to perform this action or the vault is undergoing maintenance.' : ''}>
        <Row className='tabTag' type='flex'
            justify='center' align='middle'>
          {item.icon && <Icon type={item.icon}/>}
          {item.label}
        </Row>
      </Tooltip>
    );
  }
}
