import React from 'react';
import { GridLoader } from 'react-spinners';
import '../../assets/stylesheets/CommonLoadingView.css';
//

export default function CommonLoadingView({ isLoading, isFixed }) {
  const loadingClass = `${isLoading ? '' : 'hide '}loading${isFixed != undefined ? ' loadingLogin' : ''}`;
  return (
    <div className={loadingClass}>
      <div className="loadingWheel">
        <GridLoader sizeUnit="px" size={20} color="#F88B0B" loading={isLoading} />
      </div>
    </div>
  );
}
