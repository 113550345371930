import autoBind from 'react-autobind';
import CapacitorIPC from './Capacitor-IPC';
import CapacitorIPCPacket from './Capacitor-IPCPacket';
//
export default class CapacitorIPCController {
  constructor(auth) {
    autoBind(this);
    this.auth = auth;
    this.ipc = new CapacitorIPC(this.auth);
    this.ipc.didReceiveMessage = this.handleDidReceiveMessage;
  }
  isAvailable() { return this.ipc.isAvailable(); }
  /* public */
  sendEvent(eventType, metadata) {
    const msg = new CapacitorIPCPacket();
    msg.appendMessage({ actionType: eventType, metadata: metadata || {} });
    this.ipc.sendMessage(msg);
  }
  
  /* private */
  handleDidReceiveMessage(message) {
    if (message.message && message.message.actionType) {
      const { actionType, metadata } = message.message;
      if (actionType == CapacitorIPC.CapacitorIPC_HostActions.WINDOW_URL_OPEN) {
        //ask host to open in app browser instead
        this.sendEvent(CapacitorIPC.CapacitorIPC_WebActions.EVENT_OPEN_IN_APP_BROWSER, metadata);
      } else if (actionType == CapacitorIPC.CapacitorIPC_HostActions.CONFIG_APP) {
        const config = message.message.metadata;
        this.auth.launchConfigManager.setLaunchConfig(config);
      } else if (actionType == CapacitorIPC.CapacitorIPC_HostActions.DEEPLINK_URL_OPEN) {
        //Check for code
        const url = metadata.url;
        //Is SSO login URL?
        if (url.indexOf(CapacitorIPC.LaunchSSOURL) == 0) {
          const params = '/?' + url.replace(CapacitorIPC.LaunchSSOURL, '');
          this.sendEvent(CapacitorIPC.CapacitorIPC_WebActions.LOAD_SSO, { params });
        } else console.error(`Unrecognized URL`, url);
      }
    }
  }
}
