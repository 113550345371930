import qs from 'qs';
import Globals from '../../config/Globals';

export default class PersistentSearch {
  getAll() {
    const query = window.location.search?.substr(1); // remove ?
    return qs.parse(query);
  }

  hasPersistedSearch() {
    const allParams = this.getAll();
    return !!(allParams[Globals.URLQueryParam_SearchTerm] || allParams[Globals.URLQueryParam_SearchFilters]);
  }

  getParam(paramName) {
    const params = this.getAll();
    return params[paramName];
  }

  setParams(object) {
    const currentParams = this.getAll();

    this._setQueryParams({ ...currentParams, ...object });

    return this;
  }

  setSearchTerm(searchValue, searchType) {
    this._cleanAdvancedSearch()
        .setParams({ [Globals.URLQueryParam_SearchTerm]: searchValue })
        .setParams({ [Globals.URLQueryParam_SearchType]: searchType });
  }

  setAdvancedSearch(searchObject) {
    this._cleanSearchTerm().setParams(searchObject);
  }

  // Private
  _setQueryParams(params) {
    const stringifiedParams = qs.stringify(params);

    const baseUrl = [location.protocol, '//', location.host, location.pathname].join('');
    window.history.replaceState({}, "", `${baseUrl}?${stringifiedParams}`);
  }

  _removeParams(paramsToDelete) {
    const params = this.getAll();

    paramsToDelete.forEach(paramName => {
      delete params[paramName];
    });

    this._setQueryParams(params);

    return this;
  }

  _cleanAdvancedSearch() {
    this._removeParams([
      Globals.URLQueryParam_SearchFiltersQuantifier,
      Globals.URLQueryParam_SearchFilters,
      Globals.URLQueryParam_SearchQuantifier,
      Globals.URLQueryParam_SearchType,
    ]);

    return this;
  }

  _cleanSearchTerm() {
    this._removeParams([Globals.URLQueryParam_SearchTerm,]);
    return this;
  }
}
