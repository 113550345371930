import autoBind from 'react-autobind';
import React from 'react';
import { Radio, Tooltip, Col, Divider, Row, Typography, Icon, Button, Dropdown, Menu } from 'antd';
//
import Globals from '../../../config/Globals';
import AccountUtils from '../../../components/helpers/AccountUtils';
//
import CommonDocumentsViewTypeList from './DocumentsViewTypes/CommonDocumentsViewTypeList';
import CommonDocumentViewDrawer from '../Drawer/CommonDocumentViewDrawer';

//props are: app, isLoading, docket, onReload, onRecover, onPermanentlyDelete,
//onSoftDelete, onDownload, onTransfer
export default class CommonDocketViewDocumentsView extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    const { viewType } = this.props.app.launchConfigManager.launchConfig;
    this.state = {
      openedDocument: null, multiselectionIDs: [], downloadingIDs: [],
      viewType: viewType || Globals.LaunchConfig_DocketViewTypes.LIST
    };
  }
  /* Public methods */
  inOperation() {
    return (
      this.documentDrawer.state.isEditing || this.documentDrawer.state.isDeleting ||
      this.documentDrawer.state.isSaving || this.state.downloadingIDs.length > 0
    );
  }
  // Actions
    // Drawer actions
  handleDocPreview(record) { this.props.app.openFilePreview(record); }
  async handleDocDownload(record) {
    const originalDIDs = this.state.downloadingIDs;
    const newDIDs = this.state.downloadingIDs.concat([ record.id ]);
    this.setState({ downloadingIDs: newDIDs }, async () => {
      await this.props.onDownload(record);
      this.setState({ downloadingIDs: originalDIDs });
    });
  }
  handleCloseDrawer() { this.setState({ openedDocument: null }); }
    //Batch actions
  handleBatchDownload() {
    const originalDIDs = this.state.downloadingIDs;
    const newDIDs = this.state.downloadingIDs.concat(this.state.multiselectionIDs);
    this.setState({ downloadingIDs: newDIDs }, async () => {
      await this.props.onDownload(this.state.multiselectionIDs, true);
      this.setState({ downloadingIDs: originalDIDs });
    });
  }
  handleTransfer(isMove) { this.props.onTransfer(this.state.multiselectionIDs, isMove); }
  handleBatchOperation(operation) {
    this.props[operation]({ //forward
      docket: this.props.docket,
      documents: this.state.multiselectionIDs.map((id) => this.props.docket.documents.find((d) => d.id == id)),
    }, true);
  }
    //Documents view
  handleDocumentsViewSelection(record) {
    //Check if can show
    const { showMetadata } = this.props.app.launchConfigManager.launchConfig;
    if (!showMetadata) return;
    //
    this.setState({ openedDocument: record }, () => {
      if (this.documentDrawer) this.documentDrawer.show(record);
    });
  }
  handleDocumentsViewMultiselection(selection) { this.setState({ multiselectionIDs: selection }); }
  handleMultiSelectionMenu({ key }) {
    if (key == 'download') this.handleBatchDownload();
    else if (key == 'move') this.handleTransfer(true);
    else if (key == 'copy') this.handleTransfer(false);
    else if (key == 'delete') this.handleBatchOperation('onSoftDelete');
    else if (key == 'recover') this.handleBatchOperation('onRecover');
    else if (key == 'permaDelete') this.handleBatchOperation('onPermanentlyDelete');
  }
    //Doc. View type
  handleChangeViewType(event) { this.setState({ viewType: event.target.value }); }

  /* UI */
  render() {
    const { docket, isLoading, onReload } = this.props;
    const { showDocketHeader } = this.props.app.launchConfigManager.launchConfig;
    const { downloadingIDs } = this.state;
    return (
      <Row type="flex" align="middle" gutter={[16, 16]}>
        <div style={{ width: '100%' }}>
          {showDocketHeader && <Divider/>}
          {/* Top row */}
          <Row type='flex' justify="space-between">
            <Col> <Typography.Text underline>Documents</Typography.Text> </Col>
            <Col> {this._renderViewTypeSelection()} </Col>
          </Row>
          <Row type='flex' justify='end' style={{ marginTop: '10px', marginBottom: '10px'}}>
            <Col> {this._renderMultiSelection()} </Col>
          </Row>
          {/* Docs. View */}
          {this._renderDocumentsByViewType()}
          <CommonDocumentViewDrawer app={this.props.app}
            onReload={onReload} isLoading={isLoading} downloadingIDs={downloadingIDs} onClose={this.handleCloseDrawer}
            onDownload={this.handleDocDownload} onPreview={this.handleDocPreview} docket={docket}
            wrappedComponentRef={(ref) => { this.documentDrawer = ref; }}
            onRecover={this.props.onRecover} onPermanentlyDelete={this.props.onPermanentlyDelete}
          />
        </div>
      </Row>
    );
  }

  /* Private UI View types selection */
  _renderViewTypeSelection() {
    const { showViewType } = this.props.app.launchConfigManager.launchConfig;
    if (!showViewType) return (<></>);
    //
    return (
      <Radio.Group value={this.state.viewType} size="small" onChange={this.handleChangeViewType}>
        <Radio.Button value={Globals.LaunchConfig_DocketViewTypes.LIST}>
          <Tooltip title="List"> <Icon type="unordered-list" /> </Tooltip>
        </Radio.Button>
        <Radio.Button value={Globals.LaunchConfig_DocketViewTypes.THUMBNAIL}>
          <Tooltip title="Thumbnail"> <Icon type="file-image" /> </Tooltip>
        </Radio.Button>
        <Radio.Button value={Globals.LaunchConfig_DocketViewTypes.PREVIEW}>
          <Tooltip title="Preview"> <Icon type="eye" /> </Tooltip>
        </Radio.Button>
      </Radio.Group>
    );
  }

  /* Private UI View types */
  _renderDocumentsByViewType() {
    if (this.state.viewType == Globals.LaunchConfig_DocketViewTypes.LIST) return this._renderDocumentsListViewType();
    else if (this.state.viewType == Globals.LaunchConfig_DocketViewTypes.THUMBNAIL) return this._renderDocumentThumbnailViewType();
    else return this._renderDocumentPreviewViewType();
  }
  _renderDocumentsListViewType() {
    const { docket, isLoading } = this.props;
    const { multiselectionIDs, downloadingIDs } = this.state;
    return (
      <CommonDocumentsViewTypeList app={this.props.app} isLoading={isLoading}
        documents={docket?.documents} docketNumDocs={docket?.docketNumDocs}
        onPageChange={this.props.onReload}
        multiselectionIDs={multiselectionIDs} downloadingIDs={downloadingIDs}
        onDocumentSelect={this.handleDocumentsViewSelection}
        onDocumentMultiselection={this.handleDocumentsViewMultiselection}
        onRecover={this.props.onRecover} onPermanentlyDelete={this.props.onPermanentlyDelete}
        onDownload={this.props.onDownload}/>
    );
  }
  _renderDocumentThumbnailViewType() {
    return (<>Thumbnail view type not implemented!</>);
  }
  _renderDocumentPreviewViewType() {
    return (<>Preview view type not implemented!</>);
  }

  /* Private Multi-Selection */
  _renderMultiSelection() {
    const anyDeleted = (this.props.docket?.documents || []).find((obj) => obj.deleted && this.state.multiselectionIDs.indexOf(obj.id) != -1);
    const anyNotDeleted = (this.props.docket?.documents || []).find((obj) => !obj.deleted && this.state.multiselectionIDs.indexOf(obj.id) != -1);
    const anySelected = this.state.multiselectionIDs.length > 0;
    const { allowDeletion, allowMetadataEditing } = this.props.app.launchConfigManager.launchConfig;
    const actionsAvailable = (anySelected);
    return (
      actionsAvailable && <Dropdown overlay={
        <Menu onClick={this.handleMultiSelectionMenu}>
          <Menu.Item key="1" disabled> {this.state.multiselectionIDs?.length || 0} items selected </Menu.Item>
          <Menu.Divider/>
          <Menu.Item key="download"> <Icon type="arrow-down"/> Download </Menu.Item>
          {allowMetadataEditing && <Menu.Item key="move" disabled={!this._isValidTransfer(true) || anyDeleted}> <Icon type="vertical-align-top"/> Move </Menu.Item>}
          {allowMetadataEditing && <Menu.Item key="copy" disabled={!this._isValidTransfer(false) || anyDeleted}> <Icon type="copy"/> Copy </Menu.Item>}
          {allowDeletion && <Menu.Item key="delete" disabled={anyDeleted}> <Icon type="delete"/> Delete </Menu.Item>}
          {allowDeletion && <Menu.Item key="recover" disabled={anyNotDeleted}> <Icon type="undo"/> Recover </Menu.Item>}
          {allowDeletion && <Menu.Item key="permaDelete" disabled={anyNotDeleted}> <Icon type="undo"/> Permanently Delete </Menu.Item>}
        </Menu>}>
        <Button className='actionButton' style={{ width: 150 }}>
          <Row type='flex' justify='space-between'>
            <Col>Actions...</Col>
            <Col><Icon type="down" /> </Col>
          </Row>
        </Button>
      </Dropdown>
    );
  }

  // helper
  _isValidTransfer(isMove) {
    const account = this.props.app.sharedCache().getCurrentVaultUser();
    if (!isMove) if (!AccountUtils.hasUploadPermission(account.role)) return false;
    if (isMove) if (!AccountUtils.hasDeletePermission(account.role)) return false;
    return true;
  }
}
