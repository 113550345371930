import React from 'react';
import autoBind from 'react-autobind';
import { Col, Row, Button, Modal, Typography } from 'antd';
//
import CustomComponent from '../../../ui-components/CustomComponent';
//
//import config from '../../config/config';
import Utils from '../../../components/helpers/Utils';
//
import '../../../assets/stylesheets/CommonUploadFileErrorModal.scss';
//onCancel, onRetry, onLastFileRemoval
export default class CommonUploadFileErrorModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { removedFiles: [], isVisible: false, failedFiles: [] };
  }
  //Public
  show(failedFiles) { this.setState({ isVisible: true, failedFiles: failedFiles, removedFiles: [] }); }

  //Actions
  handleCancel() {
    this.setState({isVisible: false});
    if (this.props.onCancel) this.props.onCancel();
  }
  handleRetry() {
    this.setState({ isVisible: false });
    this.props.onRetry(this._filteredOutFiles())
  }
  handleFileRemoval(file) {
    if (this.state.removedFiles.indexOf(file) != -1 || !this.state.isVisible) return;
    this.setState({ removedFiles: this.state.removedFiles.concat([file]) }, () => {
      //Redirect user to root view after one file is removed, so he can add or remove more files there
      if (this.state.removedFiles.length == this.state.failedFiles?.length && this.state.isVisible) {
        if (this.props.onLastFileRemoval) this.props.onLastFileRemoval();
        this.setState({ isVisible: false });
      }
    });
  }
  //UI
  render() {
    return (
      <Modal maskClosable={false} title={`Upload Failed`}
             visible={this.state.isVisible} closable={false}
             footer={null} centered className='uploadFileErrorModal'>
        {this._renderSubtitle()}
        {this._renderFilesTable()}
        {this._renderFooter()}
        {this._renderButtons()}
      </Modal>
    );
  }
  /* UI private */
  _renderSubtitle() {
    return (
      <Row className='subtitleLabelRow'>
        <Typography.Text className='subtitleLabel'>There has been an issue uploading one or more files:</Typography.Text>
      </Row>
    );
  }
  _renderFilesTable() {
    return (
      <Row className="CommonUploadFileErrorModal-files">
        <Col span={24}>
          <ul>
            {this._filteredOutFiles().map((file, index) => {
              return (
                <li key={index}>
                  <Typography.Text>
                    {file.document_title} ({Utils.formatSizeUnits(file.size)})
                  </Typography.Text>
                  <Button type="primary" shape="circle"
                    icon="close" size="small" onClick={this.handleFileRemoval.bind(this, file)}/>
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    );
  }
  _renderFooter() {
    return (
      <Row type='flex'>
        <Typography.Text className='retryLabel'>Would you like to retry?</Typography.Text>
      </Row>
    );
  }
  _renderButtons() {
    return (
      <Row type='flex' justify='end'>
        <Button type="secondary" className='cancelButton'
                onClick={this.handleCancel}> Cancel </Button>
        <Button type="primary" className='retryButton'
                onClick={this.handleRetry}> Retry Upload </Button>
      </Row>
    );
  }

  /* private */
  _filteredOutFiles() {
    let files = [];
    for (let file of (this.state.failedFiles || [])) {
      if (this.state.removedFiles.indexOf(file) == -1) {
        files.push(file);
      }
    } return files;
  }
}
