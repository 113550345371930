import React from 'react';
import autoBind from 'react-autobind';
//
import { Layout, Button, PageHeader, Form, Tooltip } from 'antd';
//
import CustomComponent from '../../ui-components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import NavigationControllerView from '../../ui-components/NavigationControllerView';
import AdminVaultSettingsForm from './AdminVaultSettingsForm';
//resources
import config from '../../config/config';
import Globals from '../../config/Globals';
//
const { Content } = Layout;
const VaultSettingsForm = Form.create()(AdminVaultSettingsForm);

/* eslint max-classes-per-file: ["error", 2] */
class _AdminVaultSettingsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { firstLoad: true, isLoading: false, vaultSettings: [], vaultAttributes: [] };
  }
  async componentDidMount() {
    super.componentDidMount();
    if (this.state.firstLoad) this.fetchData();
    //Listen to drag stuff
    this.props.app.appDidReceiveDragFile = this.handleDragFile;
    this.props.app.appDidReceiveDragFile = this.handleDragFile;
  }

  //API
  async fetchData() {
    this.startLoading();
    if (!this._isMounted) return;
    //
    if (this.state.firstLoad || (await this.props.app.sharedCache().reloadCache())) {
      const currVault = this.props.app.sharedCache().getCurrentVault();
      let attrs = currVault?.attributes.map((value) => ({
        ...value,
        enabledOnDocuments: String(value.enabledOnDocuments) === 'true',
        hideFromUI: String(value.hideFromUI) === 'true',
        isMandatoryOnDockets: String(value.isMandatoryOnDockets) === 'true',
        autoFill: String(value.autoFill) === 'true',
        key: value.id,
        rowKey: value.id,
      }));
      const vaultAttributes = attrs.sort((a, b) => a.label - b.label);
      this.setState({ isLoading: false, firstLoad: false, vaultSettings: currVault.settings, vaultAttributes, currVault: currVault });
    } else {
      this.setState({ isLoading: false, firstLoad: false, vaultSettings: [], vaultAttributes: [], currVault: {} });
    }
  }
  loadResponse(resp) {
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      //valid data state
    } else {
      //non-valid data state
      let err = resp.body.err || resp.error || resp.error.message;
      this.props.app.alertController.showErrorAlert('Error!', err);
    }
    //Check for id
    this.state.firstLoad = false;
    this.stopLoading(false);
    this.setState(this.state);
  }

  //Actions handler
  handleEditProfile() { this.props.app.urlManager.pushExternalPage(config.IDMClientOptions.externalAuthDomain); }
  handleSave() {
    this.form.props.form.validateFields((err, values) => {
      if (err) return;
      //Check for different `hardDeleteAfter` value
      // hardDeleteAfter disable, so do not need to alert user
      // if (values.currVault.hardDeleteAfter != this.state.currVault.hardDeleteAfter) {
      //   const a = this.props.app.alertController.showQuestionAlert('Attention!', 'This change will only apply to documents deleted from now on. Previously deleted documents will be permanently removed according to the settings in vigor when they were removed.');
      //   if (!a) return;
      // }

      //Set state and save user!
      // colorSchema default, hardDeleteAfter disable, so do not need to change
      this.setState(prevState => ({
        ...values,
        currVault: {
          ...prevState.currVault,
          ...values.currVault,
        },
        colorSchema: this.state.currVault.colorSchema,
        hardDeleteAfter: this.state.currVault.hardDeleteAfter,
      }), () => {
        this._saveVaultSettings();
      });
    });
  }
  async handleCustomAttributesFormSave(customAttribute) {
    const currentVaultID = this.props.app.sharedCache().getCurrentVaultID();

    const { id: customAttributeID, ...customAttrRest } = customAttribute;
    customAttrRest.label = customAttrRest.label.trim();

    const APICall = customAttributeID
      ? this.props.app.api.v2.vaultAttribute.updateAttribute(currentVaultID, customAttributeID, customAttrRest)
      : this.props.app.api.v2.vaultAttribute.createAttribute(currentVaultID, customAttrRest);

    const resp = await APICall;
    if (resp.statusCode == 200) {
      await this.fetchData();
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  handleDragFile(files) {
    //forward -- more logic may be required on the future
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.homepage, null, null, null, { files });
  }
  handleBack() {
    if (window.history.length > 1) {
      window.history.back()
    } else {
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.homepage);
    }
  }
  handleAcceptDrag() {
    return true;
  }
  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.isAuthenticating;
    const isAccountWriteLocked = this.props.app.sharedCache().isCurrentVaultAccountWriteLocked();
    return (
      <Content className="pageContent">
        <CommonLoadingView isLoading={isLoading} isFixed />
        <PageHeader
          className="pageHeader"
          onBack={this.handleBack}
          title="Vault Settings"
          extra={[
            (isAccountWriteLocked ?
              <Tooltip placement='topRight' title={Globals.AccountLockActionErrorMessage}>
                <Button key="1" type="primary" disabled> Save </Button>
              </Tooltip>
              : <Button key="1" type="primary" onClick={this.handleSave}> Save </Button>)
          ]}
        />
        <Content>
          <VaultSettingsForm
            handleStateChange={(state) => this.setState(state)}
            handleCustomAttributesFormSave={this.handleCustomAttributesFormSave}
            app={this.props.app}
            datasource={this}
            alias={this.state.currVault?.alias}
            wrappedComponentRef={(ref) => {
              this.form = ref;
            }}
          />
        </Content>
      </Content>
    );
  }

  //API Calls
  async _saveVaultSettings() {
    this.startLoading();
    const currentVault = this.props.app.sharedCache().getCurrentVault();
    let resp = await this._updateVault(currentVault);
    if (!resp) {
      this.stopLoading();
    } else {
      this.props.app.alertController.showSuccessAlert('', 'Vault settings updated!');
      if (currentVault.alias.trim() !== this.state.currVault.alias.trim()) {
        this.props.app.vaultManager.redirectToVault(this.state.currVault.alias.trim());
      } else {
        await this.fetchData();
      }
    }
  }
  async _updateVault(currentVault) {
    console.log({ currentVault });

    const resp = await this.props.app.api.v2.vaults.updateVault(currentVault.id, {
      // Form values
      // default color schema while disabled
      colorSchema: currentVault.colorSchema,
      alias: this.state.currVault.alias.trim(),
      name: this.state.currVault.name.trim(),
      // default hardDeleteAfter while disabled
      hardDeleteAfter: currentVault.hardDeleteAfter,
      // Cached values
      defaultRegion: currentVault.region,
      docketHasAttributes: currentVault.docketHasAttributes,
      autoPurgeEmptyDockets: currentVault.autoPurgeEmptyDockets,
      autoPurgeThreshold: currentVault.autoPurgeThreshold,
      maxDocSize: currentVault.maxDocSize,
    });
    if (resp.statusCode != 200) {
      console.log('ERROR while updating vault');
      this.props.app.alertController.showAPIErrorAlert('Error!', resp);
      return false;
    }
    return true;
  }
}
export default class AdminVaultSettingsView extends NavigationControllerView {
  constructor(props) {
    super(props);
    autoBind(this);
    this.containerClass = _AdminVaultSettingsView;
    this.fullheight = true;
  }
}
