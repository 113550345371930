import React from 'react';
import autoBind from 'react-autobind';
import { Row, Tag } from 'antd';
import VirtualizedSelect from 'react-virtualized-select';
import Select from 'react-select';
//
import 'react-virtualized-select/styles.css';
import "react-select/dist/react-select.css";
import '../../../assets/stylesheets/CommonAttributeAutoFillInput.scss';
//docs:
// - https://github.com/bvaughn/react-virtualized-select
// - https://github.com/JedWatson/react-select/tree/v1.3.0
// - http://bvaughn.github.io/react-virtualized-select/
//
export default class CommonAttributeAutoFillInput extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {selectedValue: null};
    //value, attribute, onChange, editable, app
  }
  render() {
    const attribute = this.props.attribute;
    return (
      <VirtualizedSelect labelKey="label" className='attrSelect'
        valueKey="value" isSearchable clearable optionHeight={30}
        loadOptions={this._loadAttributeValues}
        selectComponent={Select.AsyncCreatable}
        onChange={this._updateValue} disabled={!this.props.editable}
        value={this.props.value ? {
          value: this.props.value,
          label: this.props.value,
        } : this.state.selectedValue}
        optionRenderer={this._renderItem}
        clearValueText='Clear attribute' noResultsText={`No ${attribute.label} found!`}
        searchPromptText='Type to search' placeholder={`Select a ${attribute.label}..`}/>
    );
  }
  getAttribute() { return this.state.selectedValue.value; }
  /* privates */
  //Actions
  _updateValue (newValue) {
    if (!newValue) newValue = { value: null };
    this.setState({ selectedValue: newValue.value }, () => {
      if (this.props.onChange) {
        this.props.onChange(newValue.value);
      }
    });
  }
  // UI
  _renderItem(val) {
    const isExistingItem = (val.option.count != undefined);
    return (
      <Row key={val.key} type='flex' align='middle'
           className={isExistingItem ? 'attrSelectExistingItem' : 'attrSelectItem'}
           onClick={() => val.selectValue(val.option)}
           style={val.style}>
        {val.option.value}
        {(val.option.count &&
          <Tag color={this.getBadgeColorByCount(val.option.count)}
              className='attrSelectCountBadge'>
              {val.option.count}
          </Tag>)}
      </Row>
    );
  }
  // API
  async _loadAttributeValues() {
   const vaultID = this.props.app.sharedCache().getCurrentVaultID();
   const attributeID = this.props.attribute.id;
   const resp = await this.props.app.api.v2.vaultAttribute.getAllVaultAttributeValues(vaultID, attributeID);
   if (resp.statusCode == 200 && resp.body && resp.body.values) {
     return {options: resp.body.values.map( attr => {
       return {value: attr.value, label: attr.value, count: attr.count};
     }).sort( (a, b) => b.count - a.count)};
   } else return [];
  }
  //Color helpers
  getBadgeColorByCount(count) {
    const colours = [
      { count: 0.0, color: { r: 0x9d, g: 0xca, b: 0xfe } },
      { count: 100.0, color: { r: 0x17, g: 0x83, b: 0xfc } }
    ];
    let selectedColour = 1;
    for (let i = 1; i < colours.length - 1; i++) {
        if (count < colours[i].count) {
          selectedColour = i;
          break;
        }
    }
    let lower = colours[selectedColour - 1];
    let upper = colours[selectedColour];
    let rangeCount = (count - lower.count) / (upper.count - lower.count);
    let countLower = 1 - rangeCount;
      let color = {
        r: Math.floor(lower.color.r * countLower + upper.color.r * rangeCount),
        g: Math.floor(lower.color.g * countLower + upper.color.g * rangeCount),
        b: Math.floor(lower.color.b * countLower + upper.color.b * rangeCount)
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
  }
}
