import React from 'react';
import autoBind from 'react-autobind';
import { Divider, Row, Button, Modal, Col, Typography, message } from 'antd';
//
import CustomComponent from '../../../ui-components/CustomComponent';
//
//import config from '../../config/config';
//
import '../../../assets/stylesheets/CommonInvitationModal.scss';
//
class CommonInvitationModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.invitations = this.props.app.sharedCache().getInvitations() || [];

    this.state = {
      isVisible: false,
      isLoading: false,
      currentInvitationIndex: 0,
    };
  }

  componentDidMount() {
    if (this.invitations.length > 0) {
      this.setState({ isVisible: true });
    }
  }

  resetState() {
    this.invitations = [];

    this.setState({
      isVisible: false,
      isLoading: false,
      currentInvitationIndex: 0,
    });
  }

  // Actions
  handleCancel() {
    this.setState({ isVisible: false });
  }

  handleReject() {
    const invite = this.invitations[this.state.currentInvitationIndex];
    this._reject(invite);
  }

  handleAccept() {
    const invite = this.invitations[this.state.currentInvitationIndex];
    this._accept(invite);
  }

  // Private methods
  _checkForNextVault() {
    if (this.state.currentInvitationIndex === (this.invitations.length - 1)) {
      this.resetState();
    } else {
      this.setState(prev => ({ currentInvitationIndex: prev.currentInvitationIndex + 1 }));
    }
  }

  // UI
  render() {
    return (
      <Modal
        centered
        closable={false}
        visible={this.state.isVisible}
        confirmLoading={this.state.isLoading}
        footer={null}
      >
        {this._renderInvitation()}
        {this._renderButtonsFooter()}
      </Modal>
    );
  }

  _renderInvitation() {
    const invite = this.invitations[this.state.currentInvitationIndex];
    if (!invite) return null;

    return (
      <>
        <Row type="flex" justify="center">
          <Col span={24} style={{ textAlign: 'center', marginTop: 32 }}>
            <Typography.Paragraph>
              <Typography.Title level={4}>{`You're`} invited to join Vault: {invite.name}.</Typography.Title>
              {invite.invitation?.createdByName && (
                <Typography.Text style={{ opacity: 0.6 }}>You were invited by {invite.invitation.createdByName}.</Typography.Text>
              )}
            </Typography.Paragraph>
          </Col>
        </Row>
      </>
    );
  }

  _renderButtonsFooter() {
    return (
      <Row type="flex" justify="center">
        <Divider />
        <Button
          key="submit"
          loading={this.state.isLoading}
          onClick={this.handleReject}
          disabled={this.state.isLoading}
          style={{ marginRight: 8 }}
        >
          Reject Invite
        </Button>
        <Button
          key="submit"
          type="primary"
          loading={this.state.isLoading}
          onClick={this.handleAccept}
          disabled={this.state.isLoading}
        >
          Accept Invite
        </Button>
      </Row>
    );
  }

  // API
  async _accept(invitation) {
    this.startLoading();
    const resp = await this.props.app.api.v2.vaultInvitation.acceptInvitation(invitation.id);
    if (resp.statusCode == 200) {
      this.stopLoading();
      message.success('Invite successfully accepted!');
      this._checkForNextVault();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }

  async _reject(invitation) {
    this.startLoading();
    const resp = await this.props.app.api.v2.vaultInvitation.rejectInvitation(invitation.id);
    if (resp.statusCode == 200) {
      this.stopLoading();
      message.success('Invite successfully rejected!');
      this._checkForNextVault();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
}

export default CommonInvitationModal;
