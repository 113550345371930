import React from 'react';
import autoBind from 'react-autobind';
import { Row, Col, Form, Typography, Input, Button, AutoComplete } from 'antd';
//
import Globals from '../../../config/Globals';
//
import docketIcon from "../../../assets/images/icons/docket.svg";
//
import CommonAttributesLabel from '../Attributes/CommonAttributesLabel';
import CommonTagsLabel from '../Attributes/CommonTagsLabel';
//
import '../../../assets/stylesheets/DocketController.scss';
// props: app, files, selectedDocket, onSelectDocket, onCreateDocket,
// vaultID, docketsIDs, hideSummary, icon
export default class CommonDocketSearch extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      searchField: this.props.selectedDocket ? this.props.selectedDocket.name.trim() : '',
      searchRequest: null,
      searchRequestIsLoading: null,
      searchResults: [],
      selectedDocket: this.props.selectedDocket || null,
      vaultID: this.props.vaultID || this.props.app.sharedCache().getCurrentVaultID()
    };
  }

  //Life cycle
  componentDidUpdate(prevProps) {
    //only apply behaviour when vault is specified
    if (this.props.selectedDocket !== prevProps.selectedDocket && this.props.vaultID) 
      this.setState({ selectedDocket: this.props.selectedDocket || null, searchField: this.props.selectedDocket ? this.props.selectedDocket.name.trim() : '' });
    if (this.props.vaultID !== prevProps.vaultID)
      this.setState({ vaultID: this.props.vaultID || this.props.app.sharedCache().getCurrentVaultID(), searchResults: [] });
  }

  //Actions
  handleSearchDocket(search) {
    if (this.state.searchRequest) clearTimeout(this.state.searchRequest);
    if (search) {
      //reset selected docket at any editing
      if (this.state.selectedDocket) this.props.onSelectDocket(null);
      //schedule new search
      this.setState({ searchField: search, selectedDocket: null, //while editing, reset
                      searchRequest: this._scheduledSearch(search) });
    } else {
      this.setState({ searchField: '', searchRequest: null, searchRequestIsLoading: false, searchResults: [], selectedDocket: null });
      this.props.onSelectDocket(null);
    }
  }

  handleCreateDocket() { this.props.onCreateDocket(); }
  handleOnOptionSelect(value) {
    const selectedDocket = this.state.searchResults.filter((elem) => elem.id === value)[0];
    this.setState({ selectedDocket, searchField: selectedDocket.name.trim() });
    this.props.onSelectDocket(selectedDocket);
  }

  //UI
  render() {
    const error = !(!this.state.searchField || this.state.searchField.length >= Globals.Search_MinimumCharacters);
    return (
      <div className="DocketController-docket">
        <Row gutter={this.props.hasCheckedIcon ? 4 : 16}>
          <Col xs={24} sm={24} md={this.props.hasCheckedIcon ? 16 : 18} className="DocketController-autocomplete">
            <Form.Item validateStatus={error ? 'error' : 'success'}
              help={error ? `Minimum number of characters is ${Globals.Search_MinimumCharacters}!` : ''}>
              <AutoComplete style={{ width: '100%' }} dataSource={this.state.searchResults.map(this._renderDocketOption)}
                onSelect={this.handleOnOptionSelect} value={this.state.searchField} onChange={this.handleSearchDocket}>
                <Input.Search loading={this.state.searchRequestIsLoading} placeholder="Search for docket name by typing at least 3 characters" />
              </AutoComplete>
            </Form.Item>
          </Col>
          <Col className="orLabelCol" xs={24} sm={24} md={1} style={{ textAlign: 'center' }}>
            <Typography.Text>or</Typography.Text>
          </Col>
          <Col xs={24} sm={24} md={5} style={{ textAlign: 'center' }}>
            <Button className="createNewDocketButton" type="primary" onClick={this.handleCreateDocket} style={{ width: '100%' }}>
              Create new Docket
            </Button>
          </Col>
        </Row>
        {(!!this.state.selectedDocket && !this.props.hideSummary) && (
          <>
            <Row type="flex" justify="start">
              <Col
                span={24}
                style={{
                  marginTop: 15,
                }}
              >
                <Typography.Text>Your files will inherit the following attributes from this docket:</Typography.Text>
              </Col>
            </Row>
            <Row type="flex" justify="start">
              <Col span={24} className="docketColAttributes">
                <CommonAttributesLabel
                  app={this.props.app}
                  isExpanded
                  attributes={this.state.selectedDocket.attributes}
                />
              </Col>
            </Row>
            <Row type="flex" justify="start">
              <Col span={24} className="docketColTags">
                <CommonTagsLabel isExpanded tags={this.state.selectedDocket.tags} />
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }

  _renderDocketOption(item) {
    const disabled = (this.props.docketsIDs || []).includes(item.id);
    return (
      <AutoComplete.Option key={item.id} disabled={disabled} style={{ opacity: disabled ? '0.35' : 1}}>
        <Typography.Text style={{ marginRight: 15 }}>
          {this.props.icon && <img src={docketIcon} alt="Docket" style={{marginRight: '5px'}} />}{item.name}
        </Typography.Text>
        <span className="docketColAttributes">
          <CommonAttributesLabel app={this.props.app} isExpanded attributes={item.attributes} />
        </span>
        <span className="docketColTags">
          {' '}
          <CommonTagsLabel isExpanded tags={item.tags} />{' '}
        </span>
      </AutoComplete.Option>
    );
  }

  //private
  _scheduledSearch(search) {
    return setTimeout(async () => {
          if (search.length < Globals.Search_MinimumCharacters) return;

          this.setState({
            searchRequestIsLoading: true,
          });

          const docketsList = await this.props.app.api.v2.docketSearch.simpleDocketsSearch(
            this.state.searchField,
            this.state.vaultID
          );

          let searchResults = [];

          if (docketsList.statusCode === 200) {
            searchResults = docketsList.body.results
              .filter((docket) => !docket.deleted)
              .map((docket) => {
                docket.key = docket.id;
                docket.rowKey = docket.id;

                return docket;
              });
          } else this.props.app.alertController.showAPIErrorAlert(null, docketsList);
          this.setState({ isLoadingDocketSearch: false, searchRequestIsLoading: false, searchResults, showDocketList: true });
        }, 500)
  }
}
