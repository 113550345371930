import React from 'react';
import autoBind from 'react-autobind';
import { Row } from 'antd';
//
import CustomComponent from '../../../ui-components/CustomComponent';
//
import Utils from '../../../components/helpers/Utils';
import Globals from '../../../config/Globals';
//
import CommonAttributeInput, { getAttrID } from './CommonAttributeInput';
//
import '../../../assets/stylesheets/CommonAttributesSubform.scss';
//
export default class CommonAttributesSubform extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //props: editable, app, form, isDocument (if not is Docket), datasource, onChange
  }
  //Validation
  async validate() {
    const attrIDs = this._availableAttributes().map(attribute => getAttrID(attribute));
    return await this.props.form.validateFields(attrIDs);
  }
  //
  setValues(object) {
    const acc = {};
    this._availableAttributes().forEach( attr => {
      const attrValue = (object ? object.attributes[attr.id] : null);
      acc[getAttrID(attr)] = this._getSafeValue(attrValue, attr);
      return acc;
    });
    this.props.form.setFieldsValue(acc);
  }

  //UI
  render() {
    const vaultAttributes = this._availableAttributes();
    const account = this.props.app.sharedCache().getCurrentVaultAccount();

    return (
      <Row gutter={[16,0]}>
        {vaultAttributes.map((attribute) => {
          return (
            <CommonAttributeInput editable={this.props.editable && account.customAttributesEnabled} app={this.props.app}
                                  form={this.props.form} isDocument={this.props.isDocument}
                                  datasource={this.props.datasource} attribute={attribute}
                                  onChange={this.props.onChange} key={attribute.id}/>
          );
        })}
      </Row>
    );
  }
  /* subforms */
  _renderInput(val, opts) {
    const { getFieldDecorator } = this.props.form;
    opts.initialValue = this.props.datasource.state[val] ? this.props.datasource.state[val] : opts.initialValue;
    return getFieldDecorator(val, opts);
  }
  //Multi data type helper
  _getSafeValue(object, attribute) {
    if (attribute.type == Globals.VaultSettings_CustomAttributeTypes.DATE) {
      if (object) return Utils.getMomentByTimestamp(object);
      else return null;
    } else if (attribute.type == Globals.VaultSettings_CustomAttributeTypes.NUMERIC ||
               attribute.type == Globals.VaultSettings_CustomAttributeTypes.INTEGER) {
      if (object) return parseInt(object, 10);
      else return '';
    } else if (attribute.type == Globals.VaultSettings_CustomAttributeTypes.FLOAT) {
      if (object) return parseFloat(object);
      return '';
    } else if (object) return object;
    return '';
  }
  //Helpers
  _availableAttributes() {
    return this.props.app.sharedCache()?.getCurrentVaultAttributes()?.filter((attr) => {
      if (attr.hideFromUI) return false;
      else if (this.props.isDocument) return (attr.enabledOnDocuments || attr.isMandatoryOnDocuments);
      else return (attr.enabledOnDockets || attr.isMandatoryOnDockets);
    }) || [];
  }
  _attrID(attr, handler) { return (handler ? `form.attributes.${attr.id}` : `form.attributes['${attr.id}']`) }
}
