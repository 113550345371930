import React from 'react';
import autoBind from 'react-autobind';
//Components
import { Form, Row, Col, Modal, Radio } from 'antd';
import Globals from '../../../config/Globals';
import Utils from '../../../components/helpers/Utils';

//
export default class CommonEditUserModal extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      role: null,
    };
  }

  preventDefault(e) {
    e.preventDefault();
  }

  handleChangeRole(e) {
    this.setState({ role: e.target.value });
  }

  async updateUser() {
    this.props.datasource.updateUser({
      ...this.props.datasource.state.selectedUser,
      role: this.state.role,
    });
  }

  _renderUpdateUserModal() {
    return (
      <Modal
        title={`Change ${this.props.datasource.state.selectedUser.firstName}'s Role`}
        visible={this.props.datasource.state.showUpdateUser}
        onOk={this.updateUser}
        okText="Update User"
        confirmLoading={this.props.datasource.state.confirmLoading}
        onCancel={this.props.datasource.handleCancelModalUser}
      >
        <Row style={{ marginTop: 24 }}>
          <Col span={12}>
            <Form onSubmit={this.preventDefault} {...Utils.propagateRef(this, 'form')}>
              <Form.Item name="test">
                <Radio.Group
                  value={this.state.role || this.props.datasource.state.selectedUser.role}
                  onChange={this.handleChangeRole}
                >
                  <Row>
                    {Object.keys(Globals.AvailableRegistrationRoles).map((key) => (
                      <Col span={24} key={key}>
                        <Radio value={Globals.AvailableRegistrationRoles[key]}>
                          {Globals.AvailableRegistrationRoles[key]}

                          <div style={{ opacity: 0.6 }}>
                            <small>{Globals.Vault_RolesDescriptions[key]}</small>
                          </div>
                        </Radio>
                      </Col>
                    ))}
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    );
  }

  //UI
  render() {
    return <>{this._renderUpdateUserModal()}</>;
  }

  //Helper
  _renderInput(val, opts) {
    const { getFieldDecorator } = this.props.form;
    opts.initialValue = this.props.datasource.state[val] ? this.props.datasource.state[val] : opts.initialValue;
    return getFieldDecorator(val, opts);
  }
}
