import React from 'react';
import autoBind from 'react-autobind';
import { Popover, Tag, Row, Typography } from 'antd';
//
import Utils from '../../../components/helpers/Utils';
// import Globals from '../../config/Globals';
//
import '../../../assets/stylesheets/CommonAttributesLabels.scss';
//
export default class CommonAttributesLabel extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    //props: app, isMultiline, isExpanded, attributes
  }
  render() {
    return (
      <div className='commonAttributesLabels'>
        {this.props.isExpanded ? this._renderExpandedLabel() : this._renderCompactedLabel()}
      </div>
    );
  }

  /* private UI */
  _renderExpandedLabel(multiline) {
    const attributes = this._getValidAttributes();
    //className need to be set here because popover is rendered outside our div
    const isMultiline = (this.props.isMultiline || multiline);
    return (
      <div className='commonAttributesLabels'>
        {attributes.length > 0 ? attributes.map((attr, i) => {
            const attribute = this.props.app.sharedCache().getCurrentVaultAttributeByID(attr.id);
            if (!attribute || !attr.value || attr.value == '') return null;
            //
            if (isMultiline) {
              return (<Row key={i}> {this._renderAttributeLabel(attribute, attr, i)} </Row>);
            } else return this._renderAttributeLabel(attribute, attr, i);
          }) : this._renderNoAttributesLabel() }
      </div>
    );
  }
  _renderCompactedLabel() {
    const attributes = this._getValidAttributes();
    return (
      attributes.length > 0 ?
        <Popover placement="bottom" title="" content={this._renderExpandedLabel(true)}>
          <Tag className="tag">
            <Typography.Text className="tagPrefix">{attributes.length} attribute(s)</Typography.Text>
          </Tag>
        </Popover> :
        this._renderNoAttributesLabel()
    );
  }
  //Sub renders
  _renderAttributeLabel(attribute, attributeValue, index) {
    return (
      <Tag key={index} className="tag">
        <Typography.Text className="tagPrefix">{attribute && attribute.label}</Typography.Text>
        <Typography.Text className="tagSuffix">
          {attribute.type.toLowerCase() === 'date' ? Utils.getDateOnUIFormatByTimestamp(attributeValue.value) : attributeValue.value}
        </Typography.Text>
      </Tag>
    );
  }
  _renderNoAttributesLabel() {
    return (
      <Tag className="tag">
        <Typography.Text className="tagPrefix">No attributes</Typography.Text>
      </Tag>
    );
  }
  //Helpers
  _getValidAttributes() {
    return (this.props.attributes ? this.props.attributes.filter( (value) => {
        return (value && value.value);
    }) : []);
  }
}
