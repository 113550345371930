import autoBind from 'react-autobind';
import ElectronIPC from './Electron-IPC';
import ElectronIPCPacket from './Electron-IPCPacket';
import ElectronIPCStream from './Electron-IPCStream';
//
export default class ElectronIPCController {
  constructor(auth) {
    autoBind(this);
    this.auth = auth;
    this.StreamHelper = ElectronIPCStream; //OP-API support 
    this.ipc = new ElectronIPC();
    this.ipc.didReceiveMessage = this.handleDidReceiveMessage;
    this.ipc.didReceiveReply = this.handleDidReceiveReply;
  }
  /* public */
  isAvailable() { return this.ipc.isAvailable(); }
  sendEvent(eventType, metadata) {
    const msg = new ElectronIPCPacket(ElectronIPC.ElectronIPCChannels.MAIN);
    msg.appendMessage({ actionType: eventType, metadata });
    this.ipc.sendMessage(msg);
  }
  sendRequest(eventType, metadata) {
    const msg = new ElectronIPCPacket(ElectronIPC.ElectronIPCChannels.MAIN);
    msg.appendMessage({ actionType: eventType, metadata });
    this.ipc.sendMessage(msg);
  }
  newIPCStream(filePath) { return this.ipc.getFSStream(filePath); }
  /* private */
  handleDidReceiveMessage(message) {
    if (message.message && message.message.actionType) {
      if (message.message.actionType == ElectronIPC.ElectronIPC_DesktopActions.REQ_DRAG_AVIABILITY) {
        this._replyDragAviability();
      } else if (message.message.actionType == ElectronIPC.ElectronIPC_DesktopActions.UPLOAD) {
        this._simulateDragOperation(message.message);
      } else if (message.message.actionType == ElectronIPC.ElectronIPC_DesktopActions.REQ_FILE_DELETED) {
        this.auth.setFileDeletedWarning(true);
      }
    }
  }
  handleDidReceiveReply() {}
  /* private */
  _replyDragAviability() {
    const acceptsDrag = (this.auth.dropController ? this.auth.dropController.onAcceptDrag() : false);
    const msg = new ElectronIPCPacket(ElectronIPC.ElectronIPCChannels.MAIN);
    msg.appendMessage({
      actionType: ElectronIPC.ElectronIPC_WebReplies.RESP_DRAG_AVIABILITY,
      metadata: { acceptsDrag }
    });
    this.ipc.sendMessage(msg);
  }
  _simulateDragOperation(message) {
    console.log('LOAD', message)
    if (this.auth.dropController) this.auth.dropController.onDidDrag(message.metadata.files);
  }
}
