import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Result, Button, Typography } from 'antd';
//
import CustomComponent from '../../../ui-components/CustomComponent';
import Globals from '../../../config/Globals';
import IPCManager from '../../../components/subcomponents/IPCManager';
//
export default class NoAccountAccessView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false };
  }

  // Actions
  async handleLogout() {
    this.setState({ isLoading: true }, async () => {
      this.props.app.ipcManager.sendEvent(IPCManager.WebEvents.EVENT_SESSION_ENDED);
      this.props.app.urlManager.redirectToLogout();
    });
  }

  // UI
  render() {
    return (
      <Layout.Content className="pageContentFullScreen">
      <Result status="403" title="Vault is locked!" subTitle={(
        <>
            <Typography.Paragraph>{Globals.AccountLock_VaultLockedMessage}.</Typography.Paragraph>
            {this.props.app.sharedCache().getCurrentVaultAccount()?.accountFlags?.accountManualLock?.lockReason && <Typography.Paragraph>Lock reason: {this.props.app.sharedCache().getCurrentVaultAccount()?.accountFlags?.accountManualLock?.lockReason}</Typography.Paragraph>}
        </>
      )} extra={(
        <div>
          <Button type="primary" onClick={this.handleLogout}>Logout</Button>
        </div>
      )}/>
      </Layout.Content>
    );
  }
}
