/* eslint-disable no-unused-vars */
import React from 'react';
import autoBind from 'react-autobind';
import { Layout} from 'antd';
//
import CustomComponent from '../../../ui-components/CustomComponent';
import CommonLoadingView from '../CommonLoadingView';
//Views
import CommonAppControlView from '../AppControl/CommonAppControlView';
//Modals
import CommonConfirmationModal from '../Modals/CommonConfirmationModal';
import CommonHardDeleteModal from '../Modals/CommonHardDeleteModal';
import CommonRecoverModal from '../Modals/CommonRecoverModal';
import CommonSoftDeleteModal from '../Modals/CommonSoftDeleteModal';
//resources
import Utils from '../../../components/helpers/Utils';
import Globals from '../../../config/Globals';
import CommonDocketViewHeader from './CommonDocketViewHeader';
import CommonDocketViewDocumentsView from './CommonDocketViewDocumentsView';
//
export default class CommonDocketView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      //Support view launchConfig or URL or props
      docketID: this.props.app.launchConfigManager.launchConfig.docketID || this.props.match.params[Globals.URL_Path_ID_Placeholder] || this.props.docketID,
      //Data
      docket: null,
    };
  }
  //Life cycle
  componentDidMount() {
    //Assign drag handlers
    this.props.app.appAcceptsDrag = this.handleAcceptDrag;
    this.props.app.appDidReceiveDragFile = this.handleDragFile;
    this.props.app.appDidStartDragFile = this.handleDragFileStart;
    this.props.app.appDidStopDragFile = this.handleDragFileStop;
    //Start loading
    this.loadDocketInfo();
  }

  //Public
  inOperation() {
    return (this.header && this.header.inOperation()) || (this.documents && this.documents.inOperation());
  }

  //Actions
    //Drag and drop
  handleDragFile(dndEvent) {
    if (dndEvent.approvals.length > 0) {
      //Generate documents from files, inheriting the docket stuff
      const documents = this._getInheritedDocumentsFromFiles(dndEvent.approvals, this.state.docket);
      //Initialize operation on the operation controller
      console.log('operation started');
      this.props.app.operationsController.newViewlessUploadOperationView(documents, this.state.docket, () => {
        console.log('operation done');
        this.loadDocketInfo();
      });
    }
  }
  handleDragFileStart() { this.setState({isDragging: true}); }
  handleDragFileStop() { this.setState({isDragging: false}); }
  handleAcceptDrag() {
    const vault = this.props.app.sharedCache().getCurrentVault();
    const { allowUpload } = this.props.app.launchConfigManager.launchConfig;
    return (!!this.state.docket) && allowUpload && !vault?.isPurging;
  }
    //Multi-actions (batch, document or docket)
  handleRecover(record, isBatch) { this.modalRecover.show(record, isBatch); }
  handlePermanentlyDelete(record, isBatch) { this.modalHardDelete.show(record, isBatch); }
  handleSoftDelete(record, isBatch) {
    if (record && isBatch) {
      this.modalSoftDelete.show(record, isBatch);
    } else {
      const title = `Attention: This docket contains ${this.state.docket?.documents?.length || 0} documents. All documents will also be removed along with the docket.`;
      this.confirmationModal.show('Remove Docket', title);
    }
  }
  async handleDownload(record, isBatch) {
    return new Promise((resolve) => {
      if (isBatch && !isBatch.target) { //BATCH
        const { docket } = this.state;
        this.setState({ isDownloading: true });
        this.props.app.operationsController.newBatchDownloadOperationView([], record.map(f => ({ id: f, docketID: docket.id })), () => {
          this.setState({ isDownloading: false });
          resolve();
        });
      } else if (!record.docketID) { //DOCKET
        const { docket } = this.state;
        this.setState({ isDownloading: true });
        this.props.app.operationsController.newDocketDownloadOperationView(docket, () => {
          this.setState({ isDownloading: false });
          resolve();
        });
      } else { //DOCUMENT
        this.setState({ isDownloading: true });
        this.props.app.operationsController.newDocumentDownloadOperationView(record, () => {
          this.setState({ isDownloading: false });
          resolve();
        });
      }
    });
  }
  handleTransfer(multiselectionIDs, isMove) {
    console.log('*-*-*- inside handleTransfer - multiselectionIDs is ', multiselectionIDs);
    this.props.app.operationsController.newTransferOperationView(
      [],
      multiselectionIDs.map(id => this.state.docket.documents.find(d => d.id === id && !!d.docketID)),
      isMove
    );
  }
    //Docket actions
  handleUpload() { this.props.app.dropController.openDialog(); }
  async handleConfirmDeleteDocket() {
    this.startLoading();
    const deleted = await this._deleteDocket();
    if (deleted) await this.loadDocketInfo();
    else this.stopLoading();
  }
  async handleSave(newData) {
    this.startLoading();
    const saved = await this._updateDocket(newData.attributes, newData.tags, newData.name, newData.notes);
    if (saved) await this.loadDocketInfo();
    else this.stopLoading();
    return saved;
  }

  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.isAuthenticating;
    const { showDocketHeader } = this.props.app.launchConfigManager.launchConfig;
    const commonActions = {
      onUpload: this.handleUpload, onRecover: this.handleRecover, onSave: this.handleSave,
      onDownload:this.handleDownload, onPermanentlyDelete: this.handlePermanentlyDelete,
      onSoftDelete: this.handleSoftDelete, onReload: this.loadDocketInfo, onTransfer: this.handleTransfer
    };
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={isLoading} />
        <CommonAppControlView app={this.props.app}>
          {showDocketHeader &&
            <CommonDocketViewHeader app={this.props.app} docket={this.state.docket} isLoading={isLoading}
              {...commonActions} {...Utils.propagateRef(this, 'header')}/>
          }
          <CommonDocketViewDocumentsView app={this.props.app} isLoading={isLoading} docket={this.state.docket}
            {...commonActions} {...Utils.propagateRef(this, 'documents')}/>
          {this._renderModals()}
        </CommonAppControlView>
      </Layout.Content>
    );
  }

  /* Private UI */
  _renderModals() {
    return (
      <>
        <CommonHardDeleteModal app={this.props.app} onReload={this.loadDocketInfo} {...Utils.propagateRef(this, 'modalHardDelete')} />
        <CommonRecoverModal app={this.props.app} onReload={this.loadDocketInfo} {...Utils.propagateRef(this, 'modalRecover')} />
        <CommonSoftDeleteModal app={this.props.app} onReload={this.loadDocketInfo} {...Utils.propagateRef(this, 'modalSoftDelete')} />
        <CommonConfirmationModal onConfirmation={this.handleConfirmDeleteDocket} confirmationText="Remove" cancellationText="Cancel" onCancel={() => { }} {...Utils.propagateRef(this, 'confirmationModal')} />
      </>
    );
  }

  /* Private */
  _getInheritedDocumentsFromFiles(files, docket) {
    return files.map(file => {
      file.attributes = {};
      if (docket.attributes) {
        docket.attributes.forEach(elem => {
          file.attributes[elem.id] = elem.value;
        });
      }
      file.tags = docket.tags;
      return file;
    });
  }

  /* Private API calls */
  async _deleteDocket() {
    const docket = this.state.docket;
    const resp = await this.props.app.api.v2.vaultDocket.deleteDocket(docket.vaultID, docket.id);
    if (resp.statusCode != 200) {
      this.props.app.alertController.showAPIErrorAlert('Error!', resp);
      return false;
    } return true;
  }
  async _updateDocket(attributes, tags, docketTitle, notes) {
    const docket = this.state.docket;
    const resp = await this.props.app.api.v2.vaultDocket.updateDocket(docket.vaultID, docket.id, {
      name: docketTitle, tags: tags || [], attributes, notes,
    });
    if (resp.statusCode != 200) {
      this.props.app.alertController.showAPIErrorAlert('Error!', resp);
      return false;
    } return true;
  }
  async loadDocketInfo(from, sortOrder, sortField) {
    this.setState({ isLoading: true, docket: null }); //reset docket
    const docketID = this.state.docketID;
    const vaultID = this.props.app.sharedCache().getCurrentVaultID();
    const documents = [];
    let docketInfo = {};
    const fails = (resp) => this.props.app.alertController.showAPIErrorAlert(null, resp);
    const success = ({body}) => {
      if (body.id) docketInfo = body;
      if (body.results) body.results.forEach(d => documents.push(d))
    }
    await Utils.execRequests([
      this.props.app.api.v2.vaultDocket.getDocketByID(vaultID, docketID),
      this.searchDocketDocuments(vaultID, docketID, from, sortOrder, sortField)
    ], fails, success);
    docketInfo.documents = documents;
    if (this.informationTab) this.informationTab.setAttributes(docketInfo);
    this.setState({ docket: docketInfo });
    this.stopLoading();
  }
  async searchDocketDocuments(vaultID, docketID, from, sortOrder, sortField) {
    return this.props.app.api.v2.docketSearch.advancedSearch(
      {
        type: "DOCUMENT", quantifier: "ALL", from: from || 0,
        sortOrder: sortOrder || 'desc', sortField: sortField || 'createdOn',
        filters: [{field: "docketID", qualifier: "EQ", value: [docketID]}]
      },
      vaultID
    );
  }
}
