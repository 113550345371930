import React from 'react';
import autoBind from 'react-autobind';
import { Popover, Tag, Row, Typography } from 'antd';
//
// import Utils from '../../components/helpers/Utils';
// import Globals from '../../config/Globals';
//
import '../../../assets/stylesheets/CommonTagsLabels.scss';
//
export default class CommonTagsLabel extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    //props: isMultiline, isExpanded, tags
  }
  render() {
    return (
      <div className='commonTagsLabels'>
        {this.props.isExpanded ? this._renderExpandedLabel() : this._renderCompactedLabel()}
      </div>
    );
  }

  /* private UI */
  _renderExpandedLabel(multiline) {
    const tags = this._getValidTags();
    //className need to be set here because popover is rendered outside our div
    const isMultiline = (this.props.isMultiline || multiline);
    return (
      <div className='commonTagsLabels'>
        {tags.length > 0 ? tags.map((tag, i) => {
            if (!tag) return null;
            if (isMultiline) {
              return (<Row key={i}> {this._renderTagLabel(tag, i)} </Row>);
            } else return this._renderTagLabel(tag, i);
          }) : this._renderNoTagsLabel() }
      </div>
    );
  }
  _renderCompactedLabel() {
    const tags = this._getValidTags();
    return (
        tags.length > 0 ?
          <Popover placement="bottom" title="" content={this._renderExpandedLabel(true)}>
            <Tag className="tag">
              <Typography.Text className="tag">{tags.length} tag(s)</Typography.Text>
            </Tag>
          </Popover> : this._renderNoTagsLabel()
    );
  }
  //Sub renders
  _renderTagLabel(tag, index) {
    return ( <Tag key={index} className="tag"> {tag} </Tag> );
  }
  _renderNoTagsLabel() {
    return (
      <Tag className="tag">
        <Typography.Text className="tag">No tags</Typography.Text>
      </Tag>
    );
  }
  //Helpers
  _getValidTags() {
    return (this.props.tags ? this.props.tags.filter( (value) => {
        return (!!value);
    }) : []);
  }
}
