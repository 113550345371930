import config from '../../config/config';
import crypto from 'crypto';

//
export default class SlaaskConfig {
  constructor(auth) {
    this.auth = auth;
    this.slaask = ({ key: config.Slaask.widgetKey });
  }
  async load() {
    if (process.env.REACT_APP_OFFLINE) return;
    let slaaskUserInfo = {};
    const userObject = await this.auth?.idm?.session?.data?.getUserObject();
    if(userObject?.id) {
      const userHash = crypto.createHmac('sha256',
                                         config.Slaask.secretKey)
                                         .update(userObject.id)
                                         .digest('hex');
      slaaskUserInfo = {
        user_hash: userHash,
        id: userObject.id,
        name: userObject.firstName + '' + userObject.lastName,
        email: userObject.email,
      };
    }
    this.slaask.userInfo = slaaskUserInfo;
    console.log(this.slaask);
  }
}
