/* eslint-disable no-nested-ternary */
/* eslint global-require: "off" */
import React from 'react';
import autoBind from 'react-autobind';
import { datadogRum } from '@datadog/browser-rum';
import IDM from '@ikonintegration/idmclient';
//subcomps
import Cache from './subcomponents/Cache';
import AlertController from './subcomponents/AlertController';
import URLManager from './subcomponents/URLManager';
import VaultManager from './subcomponents/VaultManager';
import LaunchConfigManager from './subcomponents/LaunchConfig/LaunchConfigManager';
import IPCManager from './subcomponents/IPCManager';
import SlaaskConfig from './subcomponents/SlaaskConfig';
//helpers
import Analytics from './helpers/Analytics';
import Utils from './helpers/Utils';
//
import config from '../config/config';
import packageJSON from '../../../package.json';
import Globals from '../config/Globals';
//
export default class Authenticator extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    console.debug(`OrangePiggy FE - version: ${packageJSON.version}@${process.env.COMMIT_HASH}`);
    //Props
    this.isAuthenticating = false;
    this._sharedCache = null; //singleton, will be set
    this.alertController = null; //UI ref
    this.fileDeletedWarning = false; //desktop app functionality
    this.showWizard = false; //Vault setup/wizard specific
    this.isSettingUp = false;
    //IDM
    this.idm = new IDM(config.IDMClientTokenKey, config.IDMClientTokenSecret, config.IDMClientOptions);
    //Managers
    this.ipcManager = new IPCManager(this);
    this.launchConfigManager = new LaunchConfigManager(this.ipcManager);
    this.urlManager = new URLManager(this);
    this.vaultManager = new VaultManager(this);
    this.slaaskConfig = new SlaaskConfig(this);
    this.analytics = new Analytics();
      // -> this is initialized over demand so datadog client is already observing for
      // fetch requests before we initialize OP client which uses nodejs request and 
      // subsequently fetch API under the hood.
    this.api = new (require('@ikonintegration/orangepiggy-api-client'))({
      endpoint: config.ApplicationAPIEndpoint,
      authorizationToken: this._getIDMToken.bind(this),
      ipc: () => this.ipcManager.getFileTransferIPC(),
    });
    //Callbacks
    this.idm.sessionLoadedHandler = this.sessionLoadedHandler.bind(this);
    this.launchConfigManager.launchConfigDidBecomeAvailable = this.launchConfigDidBecomeAvailable.bind(this);
    this.sessionWillLoadHandler = null; //Authenticator delegate protocol callbacks
    this.sessionDidLoadHandler = null;
    this.sessionDidFailLoadHandler = null;
  }
  //Life cycle
  async componentDidMount() {
    this.startLoading(true);
    this.launchConfigManager.detectConfig();
  }
  /* Public */
  //Singleton
  sharedCache() {
    if (this._sharedCache == null) this._sharedCache = new Cache(this);
    return this._sharedCache;
  }
  //Desktop app support (ideally, this will be removed at some point)
  setFileDeletedWarning(val) {
    this.fileDeletedWarning = val;
    this.forceUpdate();
  }
  // Shortcuts on application roles
  isUser() { return this.idm.session.authorization ? this.idm.session.authorization.hasClaim('USER') && !this.isSysAdmin() && !this.isBillingAdmin() && !this.isHelpDesk(): false; }
  isSysAdmin() { return this.idm.session.authorization ? this.idm.session.authorization.hasClaim('SYSADMIN') : false; }
  isBillingAdmin() { return this.idm.session.authorization ? this.idm.session.authorization.hasClaim('SYSADMIN') : false; }
  isHelpDesk() { return this.idm.session.authorization ? this.idm.session.authorization.hasClaim('SYSADMIN') : false; }
  isFullyAuthorized() { return !!(this.idm.isLogged() && !this.isAuthenticating); }
  // Shorcuts on vaults roles
  isVaultAdmin() {
    const user = this.sharedCache().getCurrentVaultUser();
    if (user && (user.role == Globals.Vault_Roles.OWNER || user.role == Globals.Vault_Roles.MANAGER)) return true;
    return false;
  }
  // Loading
  startLoading(reload) {
    this.isAuthenticating = true;
    if (reload) this.forceUpdate();
  }
  endLoading(reload) {
    this.isAuthenticating = false;
    if (reload) this.forceUpdate();
  }
  // Vault setup/wizard
  async completeVaultWizard() {
    this.showWizard = false;
    await this.checkForVaultSetupCompletion('Welcome to your Vault!');
    this.forceUpdate();
  }
  async checkForVaultSetupCompletion(readyMessage = null) {
    await this.sharedCache().reloadVaults();
    const vault = this.sharedCache().getCurrentVault();
    this.isSettingUp = vault.isSettingUp;
    if (!this.isSettingUp) {
      this.alertController.showSuccessAlert(readyMessage || 'Your vault is done and you are ready to go!', '');
      this.forceUpdate();
      return true;
    } return false;
  }
  // IDM delegate calls
  async sessionLoadedHandler() {
    //Prevent double session load!
    if (this.isLoadingSession) return;
    this.isLoadingSession = true;
    if (this.sessionWillLoadHandler) this.sessionWillLoadHandler();
    this.ipcManager.sendEvent(IPCManager.WebEvents.EVENT_SESSION_LOADING);
    //session will be loaded by this.idm.load or by login call, both should be
    //loading while this is called, so we dont update state when starting loading for session load
    //reloading state will cause authorized area to be called while we haven't fully loaded the session
    this.startLoading(false);
    //Log rocket
    this._identifyUser();
    await this.slaaskConfig.load();
    //Check if could load cache properly, if not we can't proceed :/
    //Since the user has already logged it worth trying 2/3 times so
    //we dont loose user retetion :p
    let retries = 0;
    const maxAttempts = 3;
    while (retries < maxAttempts) {
      if (await this.sharedCache().loadCache()) break;
      await Utils.shortRandSleep();
      retries++;
    }
    //Check if reach max attempts
    if (maxAttempts == retries) {
      this.sharedCache().clearCache();
      if (this.sessionDidFailLoadHandler) this.sessionDidFailLoadHandler();
      this.ipcManager.sendEvent(IPCManager.WebEvents.EVENT_SESSION_ERR);
      this.urlManager.redirectToLogout();
    } else {
      if (this.sessionDidLoadHandler) this.sessionDidLoadHandler();
      this.ipcManager.sendEvent(IPCManager.WebEvents.EVENT_SESSION_STARTED);
      //Check for vault setup state (eg: wizard)
      this._checkVaultSetupState();
    }
    //End loading, unblock other session load
    this.isLoadingSession = false;
    this.endLoading(true);
  }
  // LaunchConfig delegate calls
  async launchConfigDidBecomeAvailable() {
    //Load vault manager (set current vault by alias)
    await this.vaultManager.load(this.launchConfigManager.launchConfig.vaultAlias /* optional override */);
    //Check if launch config has custom session (sent by host)
    if (this.launchConfigManager.launchConfig.session) {
      await this.idm.session.clearSession();
      await this.idm.session.setSessionWithData(this.launchConfigManager.launchConfig.session);
    }
    //Load IDM session if no session is detected or explicity session override
    if (!this.idm.isLogged() || this.launchConfigManager.launchConfig.session) {
      // ask for revalidation if have session - will redirect
      const isAuthorized = await this.idm.load();
      if (!isAuthorized) this.urlManager.redirectToLogin();
      else this.endLoading(true);
    }
  }
  //UI
  render() {
    return (
      <>
        <AlertController
          {...Utils.propagateRef(this, 'alertController')}
          onAppReload={window.location.reload.bind(location)}
          fileDeletedWarning={this.fileDeletedWarning}
        />
        {this.launchConfigManager.isAvailable() ?
          // Launch config available
          (this.isFullyAuthorized() ? this.renderAuthorizedView() : this.renderUnauthorizedView()) :
          // Launch config not available (due error or still loading)
          this.renderLaunchConfigUnavailableView()}
      </>
    );
  }

  /* vault setup logic */
  async _checkVaultSetupState() {
    const vault = this.sharedCache().getCurrentVault();
    const userID = this.idm.session.authorization.getUserID();
    //show wizard only for owners
    if (vault.isSettingUp && vault.account.owner == userID) this.showWizard = true;
    //Setting up is for everyone
    this.isSettingUp = vault.isSettingUp;
  }
  /* private helpers */
  async _getIDMToken() {
    if (this.idm.session.authorization) {
      const token = await this.idm.session.getToken(true);
      return `Bearer ${token}`;
    } return null;
  }
  async _identifyUser() {
    if (process.env.REACT_APP_OFFLINE) return;
    //Get user
    const userObj = await this.idm.session.data.getUserObject();
    const userID = this.idm.session.authorization.getUserID();
    //Identify GA
    this.analytics.setUserID(userID);
    //Identify DataDog
    if (!userObj || !userID) return;
    datadogRum.setUser({
      id: userID,
      name: userObj.firstName + ' ' + userObj.lastName,
      email: userObj.email,
      // eslint-disable-next-line no-nested-ternary
      userType: this.isUser() ? 'User' : this.isSysAdmin() ? 'SysAdmin' : this.isHelpDesk() ? 'HelpDesk' : 'Billing Admin'
    });
  }
}
