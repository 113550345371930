/* eslint-disable no-useless-escape */
/* eslint-disable one-var */
import URLQuery from 'query-string-manipulator';
//
import config from '../../config/config';
import Globals from '../../config/Globals';
//
const urlParse = require('url-parse');
//
export default class URLManager {
  constructor(auth) {
    this.auth = auth;
  }
  //OP specific shortcuts
  redirectToLogin() {
    //We do not redirect when App is being used by IPC
    if (this.auth.ipcManager.anyIPCHost()) this._redirectBehaviourForIPC();
    else this.openPage(`https://${config.ApplicationAuthDomain}/a/login`);
  }
  redirectToLogout() {
    //We do not redirect when App is being used by IPC
    if (this.auth.ipcManager.anyIPCHost()) this._redirectBehaviourForIPC();
    else this.openPage(`https://${config.ApplicationAuthDomain}/a/logout`);
  }

  //Params
  getQueryParam(paramName) {
    return this.auth.idm.urlmanager.getParam(paramName);
  }
  getPathParam(paramName, ref) {
    return ref?.props?.match?.params[paramName];
  }

  //Navigation stack
  parseUrl(pagePath, args, id, id2) {
    //replace id
    if (id2 != undefined) pagePath = pagePath.replace(":" + Globals.URL_Path_ID2_Placeholder, id2);
    if (id != undefined) pagePath = pagePath.replace(":" + Globals.URL_Path_ID_Placeholder, id);
    //build url
    const reqURL = URLQuery(pagePath, { set: (args || {}) });
    return reqURL;
  }
  pushPage(pagePath, args, id, id2, state) {
    const reqURL = this.parseUrl(pagePath, args, id, id2);
    if (state) {
      console.log("Pushing page with state", reqURL);
      this.auth.props.history.push(reqURL, state)
    } else {
      console.log("Pushing page", reqURL);
      this.auth.props.history.push(reqURL)
    }
    this.auth.analytics.logPageView(reqURL);
    if (this.auth.handleFirstNavigation) this.auth.handleFirstNavigation();
  }
  openPage(url) { //Do not log pageviews here, it will duplicate page views on other OP apps
    //Desktop behaviour: If going to any app that is not a vault or auth -> openExternalPage logic
    const tmpURL = urlParse(url);
    if ((this.auth.ipcManager.isDesktopHost() || this.auth.ipcManager.isMobileHost()) && 
        !(tmpURL.hostname.includes(config.ApplicationDomain) && tmpURL.pathname.includes('/a/')) && //not auth
        !(tmpURL.hostname.includes(config.ApplicationDomain) && tmpURL.hostname.split('.').length > 2 && tmpURL.hostname.split('.')[0] != config.AccountAppSubdomain)) {
      this.openExternalPage(url);
    } else window.location.assign(url); //default behaviour
  }
  async openExternalPage(url, appendProtocol = false) { //Do not log pageviews here, it will duplicate page views on other OP apps
    //Generate URL
    if (appendProtocol) {
      const curr = urlParse(window.location.href);
      // Capacitor/cordova does use the urlScheme being "cordova://...." -- we fix that by using this check
      url = `${this.auth.ipcManager.isMobileHost() ? 'https' : curr.protocol.replace(':', '')}://${url}`;
    }
    //Determine if opening myaccount or any other vault, if so, SSO it.
    //There is no point in SSOing to auth (it does not recognize it)
    const tmpURL = urlParse(url);
    //SSO it (if any vault or my account and desktop)
      //### Safari and probably more browsers on the future will just allow
      //new tabs/windows to be openeded at the same cycle we had the user event
      //possible approaches for enabling this on the web are: open modal with the 
      //generated URL and let the user click it or open the tab before generating
      //the link and the assigning the link after all.
    if ((this.auth.ipcManager.isDesktopHost() || this.auth.ipcManager.isMobileHost()) && tmpURL.hostname.includes(config.ApplicationDomain) && !tmpURL.hostname != config.ApplicationDomain) url = await this._appendSSOIntoURL(url);
    //If desktop, electron will capture it and open on the browser
    window.open(url, '_blank');
    window.focus();
  }

  //URL
  updateQueryStringParam(key, value) { // - https://gist.github.com/excalq/2961415
    let baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
      urlQueryString = document.location.search,
      newParam = key + '=' + value,
      params = '?' + newParam;
    // If the "search" string exists, then build params from it
    if (urlQueryString) {
      let updateRegex = new RegExp('([\?&])' + key + '[^&]*');
      let removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');
      if (typeof value == 'undefined' || value == null || value == undefined || value == '') { // Remove param if value is empty
        params = urlQueryString.replace(removeRegex, "$1");
        params = params.replace(/[&;]$/, "");
      } else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
        params = urlQueryString.replace(updateRegex, "$1" + newParam);
      } else { // Otherwise, add it to end of query string
        params = urlQueryString + '&' + newParam;
      }
    } else if (!value) return; //nothing to add
    // no parameter was set so we don't need the question mark
    params = params == '?' ? '' : params;
    window.history.replaceState({}, "", baseUrl + params);
  }

  //private
  async _appendSSOIntoURL(url) {
    if (!this.auth.isFullyAuthorized()) return url;
    //SSO it
    const ssoResp = await this.auth.idm.api.authorization.generateSSOCode();
    if (ssoResp.statusCode === 200 && ssoResp.body.code) {
      const code = encodeURIComponent(ssoResp.body.code);
      if (url.includes('?')) url += `&scode=${code}`;
      else url += `?scode=${code}`;
    } else console.error(`SSO error`, ssoResp);
    return url;
  }
  _redirectBehaviourForIPC() {
    this.auth.idm.session.clearSession();
    this.auth.sharedCache().clearCache();
    this.auth.endLoading(true);
  }
}
