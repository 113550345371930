import React, { Component } from 'react';
import { Menu, Icon, Drawer, message } from 'antd';
import { HomeOutlined, UserOutlined, SettingOutlined, SwitcherOutlined, LinkOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import config from '../../../config/config';
import Globals from '../../../config/Globals';

export default class DrawerMenu extends Component {
  constructor(props) {
    super(props);

    this.state = { isDrawerVisible: false };
  }

  open = () => {
    this.setState({ isDrawerVisible: true });
  };

  handleCloseDrawer = () => {
    this.setState({ isDrawerVisible: false });
  };

  handleCreateNewVault = () => {
    const account = this.props.app.sharedCache().getCurrentVaultAccount();
    const reachedMaxVaultsLimit = account.limits?.currentVaults >= account.limits?.maxVaults;
    const isBigPiggy = account.subscriptionID == Globals.SubscriptionPackagesIDs.BIGPIGGY;

    if (reachedMaxVaultsLimit && isBigPiggy) {
      message.info('Your account has reached the maximum number of Vaults.');
      return;
    }

    this.props.createNewVaultHandler(reachedMaxVaultsLimit && !isBigPiggy)
  };

  render() {
    const vaults = this.props.app.sharedCache().getVaults();
    const userID = this.props.app.idm.session.authorization.getUserID();
    const account = this.props.app.sharedCache().getCurrentVaultAccount();

    const myVaults = vaults.filter((vault) => vault.account.owner == userID).sort((a, b) => a.name?.localeCompare(b.name));
    const sharedVaults = vaults.filter((vault) => vault.account.owner != userID).sort((a, b) => a.name?.localeCompare(b.name));

    const currentPathName = this.props.app.props.history.location.pathname;
    const mapPathsToKey = {
      [config.ApplicationRoutes.homepage]: 'Home',
      [config.ApplicationRoutes.adminUserInvitation]: 'Users',
      [config.ApplicationRoutes.settings]: 'VaultSettings',
    };

    return (
      <Drawer
        title={
          <div className="drawer-title">
            <small>Hello,</small>
            <strong>{account.firstName}</strong>
          </div>
        }
        placement="left"
        onClose={this.handleCloseDrawer}
        visible={this.state.isDrawerVisible}
        footer={<h1>Footer</h1>}
      >
        <Menu
          mode="inline"
          style={{ border: 'none' }}
          selectedKeys={[mapPathsToKey[currentPathName]]}
        >
          <Menu.Item key="Home" data-testid="Home" onClick={this.handleCloseDrawer}>
            <HomeOutlined /> Home
            <Link data-testid="HomeLink" to={config.ApplicationRoutes.homepage} />
          </Menu.Item>

          <Menu.Item key="Users" data-testid="Users" onClick={this.handleCloseDrawer}>
            <UserOutlined /> Users
            <Link data-testid="UsersLink" to={config.ApplicationRoutes.adminUserInvitation} />
          </Menu.Item>

          <Menu.Item key="VaultSettings" data-testid="VaultSettings" onClick={this.handleCloseDrawer}>
            <SettingOutlined /> Vault settings
            <Link data-testid="VaultSettingsLink" to={config.ApplicationRoutes.settings} />
          </Menu.Item>

          <Menu.SubMenu key="SwitchVaults" title={<><SwitcherOutlined /> Switch Vaults</>}>
            <Menu.ItemGroup key="MyVaults" title="My Vaults">
              {myVaults.map((vault) => (
                <Menu.Item
                  key={vault.id}
                  data-testid={`menuitem-${vault.id}`}
                  onClick={() => this.props.app.vaultManager.redirectToVault(vault.alias, true)}
                >
                  {vault.name}
                </Menu.Item>
              ))}
            </Menu.ItemGroup>

            {sharedVaults.length > 0 && (
              <Menu.ItemGroup key="SharedVaults" title="Vaults shared with me">
                {sharedVaults.map((vault) => (
                  <Menu.Item
                    key={vault.id}
                    data-testid={`menuitem-${vault.id}`}
                    onClick={() => this.props.app.vaultManager.redirectToVault(vault.alias, true)}
                  >
                    {vault.name}
                  </Menu.Item>
                ))}
              </Menu.ItemGroup>
            )}
          </Menu.SubMenu>

          <Menu.Item
            key="CreateVault"
            data-testid="CreateVault"
            onClick={this.handleCreateNewVault}
          >
            <PlusOutlined /> Create new Vault
          </Menu.Item>

          <Menu.Item key="MyAccount" onClick={this.props.myAccountHandler}>
            <LinkOutlined /> My account
          </Menu.Item>

          <Menu.Item key="Logout" onClick={this.props.logoutHandler}>
            <Icon type="poweroff" /> Logout
          </Menu.Item>
        </Menu>
      </Drawer>
    );
  }
}
