import React from 'react';
import autoBind from 'react-autobind';
import { Button, Table, Tag, Icon } from 'antd';

import docketIcon from '../../../assets/images/icons/docket.svg';
import documentIcon from '../../../assets/images/icons/file.svg';
import Globals from '../../../config/Globals';

export default class CustomAttributesTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {};
  }

  render() {
    const columns = [
      { title: 'Name', dataIndex: 'label' },
      {
        title: 'Type',
        dataIndex: 'type',
        render: type => {
          const icons = {
            [Globals.VaultSettings_CustomAttributeTypes.DATE]: <Icon type="calendar" />,
            [Globals.VaultSettings_CustomAttributeTypes.NUMERIC]: <Icon type="number" />,
            [Globals.VaultSettings_CustomAttributeTypes.INTEGER]: <Icon type="number" />,
            [Globals.VaultSettings_CustomAttributeTypes.FLOAT]: <Icon type="number" />,
            [Globals.VaultSettings_CustomAttributeTypes.STRING]: <strong style={{ fontSize: 16, fontFamily: 'serif' }}>T</strong>,
          };

          const label = Globals.VaultSettings_CustomAttributeTypes_Labels[type] || type;
          const icon = icons[type] || null;

          return (
            <>{icon} {label}</>
          );
        }
      },
      {
        title: 'Enabled on',
        render: record => {
          const components = [];

          if (record.enabledOnDockets) {
            components.push(
              <div>
                <img src={docketIcon} alt="Dockets" style={{ marginRight: 4 }} />
                Dockets
                {record.isMandatoryOnDockets && <Tag color="red" style={{ marginLeft: 8 }}>Mandatory</Tag>}
                {!record.isMandatoryOnDockets && <Tag color="green" style={{ marginLeft: 8 }}>Optional</Tag>}
              </div>
            );
          }

          if (record.enabledOnDocuments) {
            components.push(
              <div style={{ marginTop: components.length > 0 ? 16 : 0 }}>
                <img src={documentIcon} alt="Documents" style={{ marginRight: 4 }} />
                Documents
                {record.isMandatoryOnDocuments && <Tag color="red" style={{ marginLeft: 8 }}>Mandatory</Tag>}
                {!record.isMandatoryOnDocuments && <Tag color="green" style={{ marginLeft: 8 }}>Optional</Tag>}
              </div>
            );
          }

          if (components.length > 0) {
            return components;
          }

          return 'Not enabled';
        }
      },
      {
        title: 'Hidden',
        dataIndex: 'hideFromUI',
        render: hidden => (
          hidden
            ? <Tag><Icon type="eye-invisible" /> Hidden </Tag>
            : <Tag><Icon type="eye" /> Visible </Tag>
        )
      },
      {
        title: 'Actions',
        key: 'actions',
        align: 'right',
        render: record => (
          <>
            {/* <Popconfirm title={`Are you sure you want to delete ${record.label}?`} onConfirm={() => this.props.onDelete(record)}>
              <Button icon="delete" style={{ marginRight: 8 }} />
            </Popconfirm> */}
            <Button icon="setting" onClick={() => this.props.onEdit(record)} />
          </>
        ),
      }
    ];

    console.log(this.props.data);

    return (
      <Table
        rowKey="id"
        dataSource={this.props.data}
        columns={columns}
        pagination={{ hideOnSinglePage: true }}
      />
    );
  }
}
