import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Row, Col, Icon, Divider, Button, Typography, Spin, Progress } from 'antd';
//
import BasicOperationView from '../BasicOperationView';
import CommonConfirmationModal from '../../../views/commonComponents/Modals/CommonConfirmationModal';
//
import Utils from '../../../components/helpers/Utils';
//
import '../../../assets/stylesheets/DownloadOperationView.scss';
//
//props are: app, docket, operationsController, onOperationDidClose
export default class DownloadDocketOperationView extends BasicOperationView {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      //Operation
      started: false,
      completed: false,
      //UI
      percentCompleted: 0,
      totalBytes: 0,
      downloadedBytes: 0,
      //View
      isParentVisible: true,
      isVisible: true
    };
    this.completionTimeout = null; //used after success completion
    this.downloadOperation = null; //core operation
    this.queueID = null; //assigned dynamically
  }
  //Public actions
  start() {
    this.props.app.analytics.logEvent('Download', 'Docket');
    this._startDownload();
  }
  async cancel(e) {
    if (e) { e.preventDefault(); e.stopPropagation(); }
    if (!(await this.confirmationModal.show('Cancel Download', 'Are you sure you would like to cancel the download?', true))) return;
    if (!this.state.completed && !this.downloadOperation.cancelled) {
      this.props.app.operationsController.connectionManager.abortQueue(this.queueID);
      this.close();
    }
  }

  //UI
  render() {
    return super.render(
      <Layout.Content className="downloadOperationView">
        <div onClick={this.props.onClick || (() => { })}>
          {this._renderDownloadContainerRow()}
          {(!this.state.completed) && <Row type="flex" justify="center" className="downloadProgressButtonsRow">
            <Divider />
            {!this.state.completed && <Button type="secondary" size="small" className='noStyle' onClick={this.cancel}>Cancel Download</Button>}
          </Row>}
        </div>
        {this._renderConfirmationModal()}
      </Layout.Content>
    );
  }

  /* Private UI */
  _renderProgressLabel() {
    if (!this.state.started) return 'Waiting on queue...';
    else {
      if (this.state.percentCompleted == 0) return 'Starting download...';
      if (!this.state.completed && this.downloadOperation.cancelled) return 'Cancelling download...';
      if (this.state.completed) {
        const hasFailed = this.downloadOperation.aborted;
        return (
          <Typography.Text>
            {hasFailed && <> <Icon type="warning" theme="filled" />  Download {this.downloadOperation && this.downloadOperation.cancelled ? 'cancelled' : 'failed'}!</>}
            {!hasFailed && <> <Icon type="check-circle" theme="filled" /> Download completed!</>}
          </Typography.Text>
        );
      } else return (
        <Typography.Text> Downloading... </Typography.Text>
      );
    }
  }
  _renderDownloadContainerRow() {
    return (
      <Row type='flex' className="downloadContainerRow" justify="start" align="middle">
        <Col span={3}>
          <Row justify='center' type='flex'><Icon className='download-icon' type='cloud-download' /></Row>
          {(this.state.totalBytes > 0) && <Row justify='center' type='flex'>
            <Col> <div className="downloadSizeLabel">{Utils.formatSizeUnits(this.state.totalBytes)}</div> </Col>
          </Row>}
        </Col>
        <Col span={17} offset={1}>
          <Row type='flex' align='middle'>
            <Col>
              <Typography.Text className="downloadTitleMain">Docket:</Typography.Text>
              <Typography.Text className="downloadTitleSub">{this.props.docket.name}</Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography.Text className="downloadProgressLabel">{this._renderProgressLabel()}</Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col justify="center" align="middle" span={3}>
          {this.state.percentCompleted <= 0 ?
            <Spin className='downloadProgressCircle' indicator={<Icon type="loading" style={{ fontSize: 34 }} spin />} /> :
            <Progress type="circle" className="downloadProgressCircle"
              strokeColor={(this.downloadOperation.cancelled ? '#E00000' : { '0%': '#FECA9C', '100%': '#F88B0B' })}
              /* eslint-disable-next-line no-nested-ternary*/
              status={(this.downloadOperation.cancelled ? 'exception' : !this.state.completed ? 'active' : 'success')}
              percent={this.state.percentCompleted} width={40}
            />}
        </Col>
      </Row>
    );
  }
  _renderConfirmationModal() {
    return (
      <CommonConfirmationModal confirmationText="Yes, stop the download" cancellationText="No, continue downloading" {...Utils.propagateRef(this, 'confirmationModal')}/>
    );
  }
  
  /* private */
  async _startDownload() {
    this.downloadOperation = this.props.app.api.newDocketDownloadOperation(this.props.docket, this._updateHandler);
    this.queueID = `docket_download_${this.props.docket.id}+${Date.now()}`;
    this.props.app.operationsController.connectionManager.enqueue(this.downloadOperation, this.queueID);
    //Run
    await this.props.app.operationsController.connectionManager.runQueue(this.queueID, () => { 
      if (!this._isMounted) return;
      this.setState({ started: true });
    }, (_op, resp) => {
      if (resp && resp.statusCode && resp.statusCode != 200) this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.isCompleted = true; ///custom setter
      this._startCompletionTimeout();
    });
  }
  _startCompletionTimeout() {
    if (this.completionTimeout) return;
    this.completionTimeout = setTimeout(this.close.bind(this), 3500);
  }
  _updateHandler(event) {
    const { percentCompleted, totalBytes, downloadedBytes } = event;
    if (!this._isMounted) return;
    this.setState({ percentCompleted: parseFloat(percentCompleted.toFixed(1)), totalBytes, downloadedBytes })
  }
}
