import autoBind from 'react-autobind';
import React from 'react';
import { Icon, Tooltip, Drawer, Row, Col, Button, Typography, Skeleton, Form, message, Badge, Popover } from 'antd';
import moment from 'moment';
//
import '../../../assets/stylesheets/CommonDocumentViewDrawer.scss';
//
import Utils from '../../../components/helpers/Utils';
import Globals from '../../../config/Globals';
//
import EditableTitle from '../../../ui-components/EditableTitle';
import CustomComponent from '../../../ui-components/CustomComponent';
import CommonAttributesSubform from '../Attributes/CommonAttributesSubform';
import CommonTagsLabel from '../Attributes/CommonTagsLabel';
import CommonTagSelect from '../Attributes/CommonTagSelect';
import CommonConfirmationModal from '../Modals/CommonConfirmationModal';
//props are: app, isLoading, downloadingIDs, docket, onReload, onRecover, onHardDelete
//onDownload, onPreview, onClose
class _CommonDocumentViewDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isEditing: false, isVisible: false, isDeleting: false, isSaving: false, file: {} };
  }

  // Public
  show(file) {
    this.setState({ file, isVisible: true });
    if (this.attributesForm && file && file.attributes && file.attributes.length) {
      this.attributesForm.setValues({
        attributes: (file.attributes || []).reduce((acc, curr) => Object.assign(acc, { [curr.id]: curr.value }), {}),
      });
    }
    if (this.tagsSelect && file && file.tags && file.tags.length) {
      const tagsSelect = file.tags ? file.tags.map((tag) => ({ value: tag, label: tag })) : [];
      this.tagsSelect.setValues(tagsSelect);
    }
  }
  closeAndReload(noReload) {
    if (this.fileName) this.fileName.setIsEditing(false);
    this.setState({ isEditing: false, isVisible: false, isDeleting: false, isSaving: false, file: {} }, () => {
      if (!noReload) {
        this.props.onClose();
        this.props.onReload();
      }
    });
  }

  // Actions
  handleClose(force = false) {
    const { isDeleting, isSaving } = this.state;
    if (!force && (isDeleting || isSaving)) return;
    this.setState({ isEditing: false, isVisible: false, isDeleting: false, isSaving: false, file: {} });
    this.props.onClose();
  }
  handleCancelEdit() {
    this.setState({ isEditing: false });
    if (this.fileName) this.fileName.setIsEditing(false);
  }
  handleEdit() {
    this.setState({ isEditing: true });
    if (this.fileName) this.fileName.setIsEditing(true);
  }
  async handleSaveChanges() {
    //Get values
    let attributes = await this.attributesForm.validate();
    if (!attributes) return;
    attributes = this._treatAttributes(
      attributes.form && attributes.form.attributes ? attributes.form.attributes : null
    );
    //
    const tags = await this.tagsSelect.getSelectedTags();
    if (!tags) return;
    //
    await this.fileName.validate();
    const fileName = await this.fileName.getValue();
    if (!fileName) return;
    //save
    const { docket } = this.props;
    this.setState({ isSaving: true });
    const saved = await this._updateFile(docket, attributes, tags, fileName);
    if (saved) this.closeAndReload();
    else this.setState({ isSaving: false });
  }
  handleDeleteFile() {
    const { file } = this.state;
    if (this.confirmationModal) {
      this.confirmationModal.show('Remove File', `Are you sure you want to remove the file “${file.name}”?`);
    }
  }
  async handleConfirmDeleteFile() {
    this.setState({ isDeleting: true });
    const { file } = this.state;
    const resp = await this.props.app.api.v2.document.deleteDocument(file.vaultID, file.docketID, file.id);
    if (resp.statusCode == 200) {
      this.handleClose();
      message.success('File successfully removed');
      this.setState({ isDeleting: false });
      this.closeAndReload();
    } else {
      this.setState({ isDeleting: false });
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }

  /* UI */
  render() {
    const { isLoading } = this.props;
    const { isVisible, file, isEditing } = this.state;
    const { extractionStatus, previewStatus } = file;
    const popoverContent = (
      <div style={{marginBottom: -15}}>
        <p>Preview: {Utils.capitalizeString(previewStatus)}</p>
        <p>Content Extraction: {Utils.capitalizeString(extractionStatus)}</p>
      </div>
    );
    return (
      <>
        <Drawer visible={isVisible} closable={false} onClose={this.handleClose} placement="top" className="documentDetails">
          <Row type="flex" justify="space-between" align="middle" gutter={[16, 16]}>
            <Col>
              <EditableTitle inputName="file_name" value={file.name} level={4} form={this.props.form}
                inputOptions={{ rules: [{ required: true, message: 'Please, insert a name for the docket!' }] }}
                inputProps={{ style: { marginBottom: 8 }, placeholder: '* File name' }}
                {...Utils.propagateRef(this, 'fileName')}
              />
              <Popover content={popoverContent}>
                <Badge status={Globals.GetBadgeStatusByDocumentState(extractionStatus, previewStatus)} style={{ marginLeft: '10px' }} />
              </Popover>
              {file?.deleted && <Tooltip placement="top" title={`This file is marked for deletion and will be permanently removed after ${Utils.getDateOnUIFormatByTimestamp(file?.ttl * 1000)}.`}>
                <Icon type="warning" theme='filled' style={{ marginLeft: '8px', color: 'orange', fontSize: 18 }} />
              </Tooltip>}
            </Col>
            <Col>{this._renderButtons()}</Col>
          </Row>
          <Row>
            <Row type="flex" align="middle" gutter={[16, 16]}>
              {this._renderColItem('Creation Date', Utils.getDateOnUIFormat(new Date(file.createdOn)))}
              {this._renderColItem('Updated at', Utils.getDateOnUIFormat(new Date(file.updatedOn)))}
              {this._renderColItem('File Type', file.fileType)}
              {this._renderColItem('File Size', Utils.formatSizeUnits(file.fileSize))}
            </Row>
            <Row type="flex" align="middle" gutter={[16, 16]}>
              <Col className="filesTableColTag" style={{ width: '100%' }}>
                <Typography.Text>Tags</Typography.Text>
                <br />
                <Skeleton title={false} paragraph={{ rows: 1 }} loading={isLoading} active>
                  <>
                    <div style={{ display: isEditing ? 'block' : 'none' }}>
                      <CommonTagSelect app={this.props.app} {...Utils.propagateRef(this, 'tagsSelect')} />
                    </div>
                    <div style={{ display: !isEditing ? 'block' : 'none' }}>
                      <CommonTagsLabel isExpanded isMultiline tags={file.tags} />
                    </div>
                  </>
                </Skeleton>
              </Col>
            </Row>
            <Row type="flex" align="middle" gutter={[16, 16]}>
              <Col className="filesTableColAttributes docketDetailsItem" style={{ width: '100%' }}>
                <Typography.Text>Custom Attributes</Typography.Text>
                <br />
                <CommonAttributesSubform isDocument form={this.props.form} editable={isEditing}
                  app={this.props.app} datasource={{ state: file.attributes || [] }}
                  {...Utils.propagateRef(this, 'attributesForm')}
                />
              </Col>
            </Row>
          </Row>
        </Drawer>
        <CommonConfirmationModal onConfirmation={this.handleConfirmDeleteFile} confirmationText="Remove File"
          cancellationText="Cancel" onCancel={() => {}} {...Utils.propagateRef(this, 'confirmationModal')}/>
      </>
    );
  }

  /* Private Methods */
  _treatAttributes(attributes) {
    if (attributes && Object.keys(attributes).length) {
      return Object.keys(attributes).map((key) => {
        const attribute = this.props.app.sharedCache().getCurrentVaultAttributeByID(key);
        return { id: key, value: attribute.type.toLowerCase() === 'date' ?
                 new Date(moment(attributes[key]).format(Globals.DefaultUIDateTimeFormat)).getTime() : attributes[key],
        };
      });
    } return [];
  }

  // Api calls
  async _updateFile(docket, attributes, tags, fileName) {
    const resp = await this.props.app.api.v2.document.updateDocument(docket.vaultID, docket.id, this.state.file.id, {
      id: this.state.file.id, fileName: this.state.file.fileName,
      fileSize: this.state.file.fileSize, fileType: this.state.file.fileType,
      name: fileName, description: '', tags: tags || [], attributes,
    });
    if (resp.statusCode != 200) {
      this.props.app.alertController.showAPIErrorAlert('Error!', resp);
      return false;
    } return true;
  }

  // UI
  _renderColItem(label, value) {
    const { isLoading } = this.props;
    return (
      <Col xs={12} className="docketDetailsItem">
        <Typography.Text>{label}</Typography.Text>
        <br />
        <Typography.Text strong>
          <Skeleton title={false} paragraph={{ rows: 1 }} loading={isLoading} active> {value} </Skeleton>
        </Typography.Text>
      </Col>
    );
  }
  _renderButtons() {
    const { isEditing, file, isSaving, isDeleting } = this.state;
    const { allowMetadataEditing, allowDeletion } = this.props.app.launchConfigManager.launchConfig;
    if (isEditing) {
      return (
        <>
          <Button onClick={this.handleCancelEdit}>Cancel</Button>{' '}
          <Button type="primary" onClick={this.handleSaveChanges} loading={isSaving}> Save Changes </Button>
        </>
      );
    }
    return (
      <>
        <Button disabled={this.props.isLoading || isDeleting} icon="eye" onClick={() => this.props.onPreview(file)} />{' '}
        <Button disabled={this.props.isLoading || isDeleting} loading={this.props.downloadingIDs.includes(file.id)} icon="arrow-down" onClick={() => this.props.onDownload(file)} />{' '}
        {!file.deleted && allowMetadataEditing && <Button disabled={this.props.isLoading || isDeleting || file.deleted} onClick={this.handleEdit}> Edit </Button>}{' '}
        {file.deleted && allowDeletion && <Button disabled={this.props.isLoading || isDeleting || !file.deleted} onClick={this.props.onRecover.bind(this, file)}> Recover </Button>}{' '}
        {!file.deleted && allowDeletion && <Button disabled={this.props.isLoading || file.deleted} onClick={this.handleDeleteFile} loading={isDeleting}> Delete </Button>}
        {file.deleted && allowDeletion && <Button disabled={this.props.isLoading || isDeleting || !file.deleted} onClick={this.props.onHardDelete.bind(this, file)}> Permanently Delete </Button>}
      </>
    );
  }
}

const CommonDocumentViewDrawer = Form.create()(_CommonDocumentViewDrawer);
export default CommonDocumentViewDrawer;
