import React from 'react';
import { withRouter } from 'react-router-dom';
import Slaask from '@ikonintegration/react-slaask';
//
import Utils from './components/helpers/Utils';
//
import Authenticator from './components/Authenticator';
import UnauthorizedViewController from './UnauthorizedViewController';
import AuthorizedViewController from './AuthorizedViewController';
import LaunchConfigUnavalaibleViewController from './LaunchConfigUnavalaibleViewController';
//UI
import CommonFileViewer from './views/commonComponents/Drawer/CommonFileViewer';
import DragAndDropController from './ui-components/DragAndDropController';
import CommonDragErrorModal from './views/commonComponents/Modals/CommonDragErrorModal';
import OperationsControllerView from './ui-components/OperationsView/OperationsControllerView';
import BreakpointListener from './ui-components/BreakpointListener';
import Globals from './config/Globals';
//
class App extends Authenticator {
  constructor(props) {
    super(props);
    this.dropController = null; //will be set
    this.operationsController = null; //ref
    this.fileviewer = null; //ref
    this.layout = {}; //ref
    //Drag and Drop delegate protocol callbacks
    this.appDidReceiveDragFile = null;
    this.appDidReceiveDragRejectedFile = null;
    this.appDidStartDragFile = null;
    this.appDidStopDragFile = null;
    this.appAcceptsDrag = null;
  }

  /* public */
  openFilePreview(file) {
    if (this.fileviewer) { this.fileviewer.show(file); }
  }

  /* Drag and Drop controller delegate */
  async handleFileDrop(files) {
    //Is authorized?
    if (this.isFullyAuthorized()) {
      //Display rejections as a default behaviour
      if (files.rejections.length > 0 && this.dragErrorModal) this.dragErrorModal.show(files.rejections);
      //Someone listening? Forward
      if (this.appDidReceiveDragFile) this.appDidReceiveDragFile(files);
    } else { //renew session
      if (this.idm.session.authorization) {
        console.debug('Drag not authorized, attempting to renew session');
        if (await this.idm.session.getToken(true)) this.handleFileDrop(files);
      } else console.debug('Drag not authorized, NOT attempting to renew session, authorization is not valid!');
    }
  }
  handleFileDropStart() {
    if (this.isFullyAuthorized() && this.appDidStartDragFile) this.appDidStartDragFile();
  }
  handleFileDropStop() {
    if (this.isFullyAuthorized() && this.appDidStopDragFile) this.appDidStopDragFile();
  }
  handleAcceptDrag() {
    if (this.isFullyAuthorized()) {
      if (this.appAcceptsDrag) return this.appAcceptsDrag();
    } return false;
  }

  //Renders
  renderAuthorizedView() { //Layout + core functionlity
    const cache = this.sharedCache();
    const vault = cache.getCurrentVault();
    const launchConfig = this.launchConfigManager.launchConfig || {};
    return (
      <Utils.ConditionalWrapper condition={!launchConfig.hideSupport}
          wrapper={(children) => <Slaask app={this} widgetKey={this.slaaskConfig.slaask.key} userInfo={this.slaaskConfig.slaask.userInfo}>{children}</Slaask>}>
          {/* Conditional drag and drop */}
          <Utils.ConditionalWrapper condition={launchConfig.allowUpload && !vault.isPurging}
            wrapper={(children) =>
              <DragAndDropController
                maxSize={cache.getCurrentVault().settings?.maxDocSize}
                disabled={cache.isCurrentVaultAccountDataWriteLocked() || !cache.getCurrentVaultUser().uploadEnabled}
                onDidDrag={this.handleFileDrop} onDidStartDrag={this.handleFileDropStart}
                onDidStopDrag={this.handleFileDropStop} onAcceptDrag={this.handleAcceptDrag}
                {...Utils.propagateRef(this, 'dropController')}>{children}</DragAndDropController>
            }>
            <BreakpointListener mobileBreakpoint={Globals.MobileBreakpoint} {...Utils.propagateRef(this, 'layout')}>
              <AuthorizedViewController app={this} history={this.props.history} />
              <CommonFileViewer app={this} {...Utils.propagateRef(this, 'fileviewer')} />
              <OperationsControllerView app={this} {...Utils.propagateRef(this, 'operationsController')}/>
            </BreakpointListener>
        </Utils.ConditionalWrapper>
      </Utils.ConditionalWrapper>
    );
  }
  renderLaunchConfigUnavailableView() {
    return <LaunchConfigUnavalaibleViewController app={this} history={this.props.history} />;
  }
  renderUnauthorizedView() {
    return <UnauthorizedViewController app={this} history={this.props.history}/>;
  }

  /* UI Components */
  _renderDragErrorModal() {
    return <CommonDragErrorModal {...Utils.propagateRef(this, 'dragErrorModal')} />;
  }
}
export default withRouter(App);
