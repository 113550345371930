import React from 'react';
import autoBind from 'react-autobind';
import { Tooltip, Row, Layout, Table, Typography, Icon } from 'antd';
//
import Utils from '../../components/helpers/Utils';
import Globals from "../../config/Globals";
//
import '../../assets/stylesheets/CommonTransferFilesTable.scss';
//props: app, maxSize, source, type, transferPhase, documents, completed
export default class CommonTransferFilesTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
    this.currentPage = 1;
    this.disablePageAutoJumping = false;
  }
  //UI
  render() {
    return (
      <Layout.Content className='TransferFilesTableContainer' {...Utils.propagateRef(this, 'container')}>
        <Table
          className="transferFilesTable"
          columns={this._tableColumns()}
          {...this._tableProps()}
          rowKey={r => this.props.type === Globals.Transfer_Type.DOCKET ? r.docketID : r.documentID}
          dataSource={this._tableDatasource()}
        />
      </Layout.Content>
    );
  }

  /* private */
  _tableProps() {
    return {
      rowKey: 'rowKey',
      locale: { emptyText: 'No documents or dockets in the operations, this might be an error.' },
      pagination: this._getPagination(),
      bordered: false,
      size: 'small',
      onHeaderRow: this._tableHeaderProps,
      fixed: true,
      rowClassName: 'transferFilesTableRow',
    };
  }
  _getPagination() {
    const pageSize = this.props.maxSize || 100;
    if (!this.disablePageAutoJumping) {
      const pagedDocuments = this.props.source.slice((this.currentPage - 1) * pageSize, this.currentPage * pageSize);
      const processedDocuments = pagedDocuments.map(d => this._getStatus(d)).filter(status => !['history', 'loading'].includes(status));
      if (processedDocuments.length == pagedDocuments.length) this.currentPage += 1;
    }
    return {
      pageSize,
      onChange: (page) => {
        this.disablePageAutoJumping = true;
        this.currentPage = page;
        this.forceUpdate();
      },
      hideOnSinglePage: true,
      current: this.currentPage,
    };
  }
  _tableHeaderProps() { return { className: 'transferFilesTableHeader' } }
  _tableColumns() {
    return this.props.type === Globals.Transfer_Type.DOCKET ? [
      { title: 'Docket Name', key: 'name',  width: '75%', render: this._renderColFileName },
      { title: 'Documents', key: 'documents', width: '120px', align: 'center', render: this._renderColDocuments },
      { title: 'Status', key: 'status', width: '80px', align: 'center', render: this._renderColDocketsStatus }
    ] : [
      { title: 'Document Name', key: 'name',  width: '75%', render: this._renderColFileName },
      { title: 'Size', key: 'size', width: '120px', render: this._renderColFileSize },
      { title: 'Status', key: 'status', width: '80px', align: 'center', render: this._renderColDocumentsStatus }
    ];
  }
  _tableDatasource() {
    const failed = this.props.source.filter(d => d.status === 'failed');
    return (!this.props.completed || (this.props.completed && !failed.length)) ? this.props.source : failed;
  }

  // private UI
  _renderColFileName(props) {
    const name = this.props.type === Globals.Transfer_Type.DOCKET ? props.docketName : props.metadata.name;
    return (
      <Row className='transferTableColFileName'>
        <Tooltip title={name.length > 20 ? name : ''}>
          <Typography.Text className='label'>{Utils.truncateString(name, 20)}</Typography.Text>
        </Tooltip>
      </Row>
    );
  }
  _renderColFileSize(props) {
    return (
      <Row className='transferTableColFileSize'>
        <Typography.Text className='label'>{Utils.formatSizeUnits(props.metadata.fileSize)}</Typography.Text>
      </Row>
    );
  }
  _renderColDocuments(props) {
    return (
      <Row className='transferTableColFileSize'>
        <Typography.Text className='label'>{this.props.documents.filter(d => d.docketID === props.docketID).length}</Typography.Text>
      </Row>
    );
  }
  _renderColDocumentsStatus(props) {
    return (
      <Row className='transferTableColStatus'>
        {this._getWrapperTooltip(props, <Icon type={this._getStatus(props)} className='icon' />)}
      </Row>
    );
  }
  _renderColDocketsStatus(props) {
    const documents = this.props.documents.filter(d => d.docketID === props.docketID);
    if (documents.length) {
      const failed = documents.filter(d => d.status === 'failed');
      // eslint-disable-next-line no-nested-ternary
      props.status = documents.filter(d => d.status === 'started' || !d.status).length ? 'started' : failed.length ? 'failed' : 'finished';
      props.err = props.err || [...new Set(failed.map(d => d.err))].join(", ");
    }
    return (
      <Row className='transferTableColStatus'>
        {this._getWrapperTooltip(props, <Icon type={this._getStatus(props)} className='icon' />)}
      </Row>
    );
  }
  _getStatus(props) {
    if (props.status === 'started') return 'loading';
    if (props.status === 'finished') return 'check';
    if (props.status === 'failed') return 'warning';
    else return 'history';
  }
  _getTooltipText(props) {
    if (props.status === 'started') return 'Uploading...';
    if (props.status === 'finished') return 'Completed!';
    if (props.status === 'failed') return `Failed! ${(!props.err) ? '' : props.err}`;
    else return 'Queued';
  }
  _getWrapperTooltip(props, child) {
    return ( <Tooltip title={this._getTooltipText(props)} placement="left"> {child} </Tooltip> );
  }
}
