import React from "react";
import autoBind from "react-autobind";
import { Row, Col, Divider, Table, Typography, Button } from "antd";
import { CheckOutlined, WarningTwoTone } from "@ant-design/icons";
import * as ExcelJS from 'exceljs';
//
import Globals from "../../../../config/Globals";
import Utils from "../../../../components/helpers/Utils";
//
import docketIcon from "../../../../assets/images/icons/docket.svg";
import documentIcon from "../../../../assets/images/icons/file.svg";
//
import CommonLoadingView from "../../CommonLoadingView";
import CommonVaultSearch from "../../VaultSearch/CommonVaultSearch";
import CommonDocketSearch from "../../DocketSearch/CommonDocketSearch";
import CommonCreateDocket from "../../../commonSubviews/DocketStack/CommonCreateDocket";
//
import "../../../../assets/stylesheets/CommonTransferModal.scss";
//
// Props: app, dockets, documents, duplicatedDockets, isLoading, onSelectVault, vaultID, selectedDocket
// onSelectDocket, showCreateDocket, onCreateDocket, onCreateNewDocket, onCreateDocketCancel
export default class CommonTransferCaseModalBase extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  // public UI
  renderLoading() {
    return <Row style={{minHeight: '120px'}}><Col><CommonLoadingView isLoading={this.props.isLoading} isFixed /></Col></Row>
  }
  renderSelectVault(vaultID) {
    return (
      <>
        <Typography.Text>{this.props.title}</Typography.Text>
        <Row gutter={[8, 8]} className="selectVault">
          <Col span={22}><CommonVaultSearch icon search app={this.props.app} vaultID={vaultID} onSelectVault={this.props.onSelectVault} /></Col>
          <Col span={2} align="right">{this.props.vaultID && <CheckOutlined className="checkedIcon" />}</Col>
        </Row>
      </>
    );
  }
  renderSelectDocket() {
    const docketsIDs = [...new Set((this.props.dockets.concat(this.props.documents)).map(obj => obj.docketID))];
    return (
      <>
        <Row gutter={[8, 8]} style={{opacity: this.props.showCreateDocket ? '0.25' : '1'}}>
          <Col span={22}>
            <CommonDocketSearch
              app={this.props.app}
              selectedDocket={this.props.selectedDocket}
              onSelectDocket={this.props.onSelectDocket}
              onCreateDocket={this.props.onCreateDocket}
              vaultID={this.props.vaultID}
              docketsIDs={docketsIDs}
              hideSummary
              icon
              hasCheckedIcon
            />
          </Col>
          <Col span={2} align="right">{this.props.docketID && <CheckOutlined className="checkedIcon" />}</Col>
        </Row>
        {this.props.showCreateDocket && this.renderCreateDocket()}
      </>
    );
  }
  renderCreateDocket() {
    return (
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <CommonCreateDocket
            app={this.props.app}
            vaultID={this.props.vaultID}
            onDocketCreated={this.props.onCreateNewDocket}
            cancelText="Back"
            onCreateDocketCancel={this.props.onCreateDocketCancel}
            showDocketAttributes={false}
          />
        </Col>
      </Row>
    )
  }
  renderList(type, confirm) {
    return (
      <>
        <Divider />
        <div className="listDiv">
          <Table columns={this._listColumns(type, confirm)} dataSource={this._listDataSource(type)} bordered={false} pagination={false} scroll={{ y: 190 }} />
          {this._renderSummary(type)}
        </div>
      </>
    );
  }
  renderConfirmation(type) {
    return (
      <>
        <Row style={{ padding: '5px'}}>
          <Typography.Text style={{ fontSize: '16px'}}>
            <WarningTwoTone twoToneColor="#E00000" />{' '}
            <strong>Combine {this.props.duplicatedDockets.length} {this._isSingular(this.props.duplicatedDockets.length, Globals.Transfer_Type.DOCKET)}</strong>
          </Typography.Text>
        </Row>
        <Row style={{ padding: '5px'}}>
          <Typography.Text>The selected Vault already contains {this.props.duplicatedDockets.length} {this._isSingular(this.props.duplicatedDockets.length, Globals.Transfer_Type.DOCKET)} with the same name. The content of the {this._isSingular(this.props.duplicatedDockets.length, Globals.Transfer_Type.DOCKET)} with the same name will be combined.</Typography.Text>
        </Row>
        <Row style={{ padding: '5px'}}>
          <Table columns={this._listColumns(type, 'confirmation')} dataSource={this._listDataSource(type, this.props.duplicatedDockets, 'confirmation')} showHeader={false} bordered={false} pagination={false} scroll={{ y: 190 }} style={{minHeight: 190}} />
        </Row>
      </>
    )
  }
  // helpers
  _listColumns(type, confirmation) {
    if (type === Globals.Transfer_Type.DOCKET && !confirmation) {
      return [
        {
          title: "Docket Name", dataIndex: "name", key: "name", ellipsis: true,
          render: (name) => (<Typography.Text><img src={docketIcon} alt="Docket" /> {name}</Typography.Text>),
        },
        { title: "Documents", dataIndex: "docketNumDocs", key: "docketNumDocs", width: 120, align: "right" },
      ];
    } else if (type === Globals.Transfer_Type.DOCKET && confirmation) {
      return [
        {
          title: "Docket Name", dataIndex: "name", key: "name", ellipsis: true,
          render: (name) => (<Typography.Text><img src={docketIcon} alt="Docket" /> {name}</Typography.Text>),
        }
      ];
    } else {
      return [
        {
          title: "Document Title", dataIndex: "name", key: "name", ellipsis: true,
          render: (name) => (<Typography.Text><img src={documentIcon} alt="Document" /> {name}</Typography.Text>),
        },
        { title: "Date created", dataIndex: "createdOn", key: "createdOn", width: 120, align: "right" },
        { title: "Size", dataIndex: "fileSize", key: "fileSize", width: 120, align: "right" },
      ];
    }
  }
  _listDataSource(type, objs, confirmation) {
    const objects = objs || (type === Globals.Transfer_Type.DOCKET ? this.props.dockets : this.props.documents);
    if (type === Globals.Transfer_Type.DOCKET && !confirmation) {
      return objects.slice(0, Globals.Transfer_MaxList)
        .map((docket, index) => ({ key: index, name: docket?.metadata?.name, docketNumDocs: docket?.metadata?.docketNumDocs }));
    } else if (type === Globals.Transfer_Type.DOCKET && confirmation) {
      return objects.slice(0, Globals.Transfer_MaxList)
        .map((docket, index) => ({ key: index, name: docket?.metadata?.name }));
    } else {
      return objects.slice(0, Globals.Transfer_MaxList)
        .map((file, index) => ({
          key: index,
          name: file?.metadata?.name,
          createdOn: Utils.getDateOnUIFormatByTimestamp(file?.metadata?.createdOn),
          fileSize: Utils.formatSizeUnits(file?.metadata?.fileSize)
        }));
    }
  }
  _renderSummary(type) {
    const dataSource = type === Globals.Transfer_Type.DOCKET ? this.props.dockets : this.props.documents;
    const summaryType = type === Globals.Transfer_Type.DOCKET
      ? this._isSingular(dataSource.length - Globals.Transfer_MaxList, Globals.Transfer_Type.DOCKET)
      : this._isSingular(dataSource.length - Globals.Transfer_MaxList, Globals.Transfer_Type.DOCUMENT);
    return (
      <Row className="sumary">
        <Col span={12}>{dataSource.length > Globals.Transfer_MaxList && <Typography.Text className="summaryText">and {dataSource.length - Globals.Transfer_MaxList} more {summaryType}...</Typography.Text>}</Col>
        <Col span={12} align="right"><Button size="small" type="secondary" onClick={() => this._downloadSummary(dataSource, type)}>Download summary (.xls)</Button></Col>
      </Row>
    );
  }
  // eslint-disable-next-line no-nested-ternary
  _isSingular(length, type) { return type === Globals.Transfer_Type.DOCKET ? length === 1 ? "Docket" : "Dockets" : length === 1 ? "Document" : "Documents"; }

  // summary
  async _downloadSummary(dataSource, type) {
    if (!dataSource.length) return;
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Sheet1');
    if (type === Globals.Transfer_Type.DOCKET) {
      ws.addRow(['Docket Name', 'Documents']);
      dataSource.forEach(obj => ws.addRow([ obj.metadata.name.trim(), obj.metadata.docketNumDocs ]));
    } else {
      ws.addRow(['Document Title', 'Date Created', 'Size']);
      dataSource.forEach(obj => {
        ws.addRow([
          obj.metadata.name.trim(),
          Utils.getDateOnUIFormatByTimestamp(obj.metadata.createdOn),
          Utils.formatSizeUnits(obj.metadata.fileSize)
        ]);
      });
    }
    const buffer = await wb.xlsx.writeBuffer();
    const fileName = `orangepiggy_report_${Utils.getCurrentDateAndTimeOnUIFormat().replace(" ", "_")}`;
    Utils.downloadArrayBuffer(buffer, fileName, 'xlsx');
  }
}
