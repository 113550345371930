import React, { Fragment } from 'react';
import { Row } from 'antd';
import autoBind from 'react-autobind';
import { Prompt } from 'react-router-dom';
//
import ConnectionManager from './core/ConnectionManager';
//
import UploadOperationView from './operations/UploadOperationView';
import DownloadDocketOperationView from './operations/DownloadDocketOperationView';
import DownloadBatchOperationView from './operations/DownloadBatchOperationView';
import DownloadDocumentOperationView from './operations/DownloadDocumentOperationView';
import TransferOperationView from './operations/TransferOperationView';
//
import '../../assets/stylesheets/OperationsControllerView.scss';
//
export default class OperationsControllerView extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
    this.unloadMessage = 'If you leave this page, your upload will be interrupted. Are you sure you want to stop your upload?';
    this.windowListener = false;
    this.connectionManager = new ConnectionManager(this);
    this.operations = {};
    this.operationsRef = {};
  }
    //shortcuts
    //props are: app, dockets, documents, isMove, operationsController, onOperationDidClose
  newTransferOperationView(dockets, documents, isMove) {
    console.log('*-*- inside newTransferOperationView dockets is ', dockets); console.log('documents is ', documents);
    const key = this._getRandomUIKey();
    const view = <TransferOperationView dockets={dockets} documents={documents} app={this.props.app} key={key} operationsController={this}
      isMove={isMove} onOperationDidClose={this._removeOperation.bind(this, key)}
      ref={(r) => {
        if (!r) return; //prevent 'nullification' of the reference when view is deallocated, leave to the controller, removing it's ref.
        this.operationsRef[key] = r;
        r.start(); //auto start
      }} />;
    this._addOperation(view, key);
  }
  newUploadOperationView(files, docket, onStartOver, onParentUpdate, onClick, refCB) {
    const key = this._getRandomUIKey();
    const view = <UploadOperationView files={files} docket={docket} app={this.props.app} key={key} operationsController={this}
                    onStartOver={onStartOver} onParentUpdate={onParentUpdate} onClick={onClick}
                    onOperationDidClose={this._removeOperation.bind(this, key)}
                    ref={(r) => {
                      if (!r) return; //prevent 'nullification' of the reference when view is deallocated, leave to the controller, removing it's ref.
                      this.operationsRef[key] = r;
                      if (refCB) refCB(r);
                    }}/>;
    this._addOperation(view, key);
  }
  newViewlessUploadOperationView(files, docket, onCompletion) {
    const key = this._getRandomUIKey();
    const view = <UploadOperationView viewless files={files} docket={docket} app={this.props.app} key={key} 
                    operationsController={this} onRestart={()=>{}} onParentUpdate={()=>{}} 
                    onOperationDidClose={this._removeOperation.bind(this, key)}
                    onOperationDidFinished={() => {
                      if (onCompletion) onCompletion();
                    }} 
                    ref={(r) => {
                      if (!r) return; //prevent 'nullification' of the reference when view is deallocated, leave to the controller, removing it's ref.
                      this.operationsRef[key] = r;
                      r.start(); //auto start
                    }}/>;
    this._addOperation(view, key);
  }
  newDocketDownloadOperationView(docket, onCompletion, onClick) {
    const key = this._getRandomUIKey();
    const view = <DownloadDocketOperationView docket={docket} app={this.props.app} key={key} operationsController={this}
                    onClick={onClick} onOperationDidClose={() => {
                      this._removeOperation(key);
                      if (onCompletion) onCompletion();
                    }} 
                    ref={(r) => {
                      if (!r) return; //prevent 'nullification' of the reference when view is deallocated, leave to the controller, removing it's ref.
                      this.operationsRef[key] = r;
                      r.start(); //auto start
                    }}/>;
    this._addOperation(view, key);
  }
  newDocumentDownloadOperationView(document, onCompletion, onClick) {
    const key = this._getRandomUIKey();
    const view = <DownloadDocumentOperationView document={document} app={this.props.app} key={key} operationsController={this}
      onClick={onClick} onOperationDidClose={() => {
        this._removeOperation(key);
        if (onCompletion) onCompletion();
      }}
      ref={(r) => {
        if (!r) return; //prevent 'nullification' of the reference when view is deallocated, leave to the controller, removing it's ref.
        this.operationsRef[key] = r;
        r.start(); //auto start
      }} />;
    this._addOperation(view, key);
  }
  newBatchDownloadOperationView(dockets, documents, onCompletion, onClick) {
    const key = this._getRandomUIKey();
    const view = <DownloadBatchOperationView dockets={dockets} documents={documents} vaultID={this.props.app.sharedCache().getCurrentVaultID()}
                    app={this.props.app} key={key} operationsController={this}
                    onClick={onClick} onOperationDidClose={() => {
                      this._removeOperation(key);
                      if (onCompletion) onCompletion();
                    }} 
                    ref={(r) => {
                      if (!r) return; //prevent 'nullification' of the reference when view is deallocated, leave to the controller, removing it's ref.
                      this.operationsRef[key] = r;
                      r.start(); //auto start
                    }}/>;
    this._addOperation(view, key);
  }

  /* private operations */
  _addOperation(operation, key) { 
    this.operations[key] = operation;
    this.forceUpdate();
  }
  _removeOperation(key) { 
    if (this.operations[key]) delete this.operations[key];
    if (this.operationsRef[key]) delete this.operationsRef[key];
    this.forceUpdate();
  }
  // Helpers
  _getRandomUIKey() { return Date.now() + Math.floor(Math.random() * 100000); }
  _anyOperationInProgress() { 
    return (Object.keys(this.operations).length > 0 && Object.keys(this.operations).filter((opKey) => {
      const ref = this.operationsRef[opKey];
      return (!ref || !ref?.isCompleted);
    }).length > 0);
  }
  //UI
  render() { 
    const anyOperationInProgress = this._anyOperationInProgress();
    this._checkUnloadListener(anyOperationInProgress);
    return (
      <>
        {anyOperationInProgress && <Prompt when={anyOperationInProgress} message={this.unloadMessage} />}
        <Row type='flex' className='operationsControllerContainer'>
          {Object.values(this.operations)}
        </Row>
      </>
    );
  }

  /* private window unload handler */
  _checkUnloadListener(anyOperationInProgress) {
    if (anyOperationInProgress && !this.windowListener) this._addWindowUnloadListener();
    else if (!anyOperationInProgress && this.windowListener) this._removeWindowUnloadListener();
  }
  _addWindowUnloadListener() { 
    this.windowListener = true;
    window.addEventListener('beforeunload', this._handleWindowUnload); 
  }
  _removeWindowUnloadListener() { 
    this.windowListener = false;
    window.removeEventListener('beforeunload', this._handleWindowUnload); 
  }
  //window unload
  _handleWindowUnload(event) {
    event.returnValue = this.unloadMessage;
    return event.returnValue;
  }
}
