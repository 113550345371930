import Globals from '../../../config/Globals';
import autoBind from 'react-autobind';
//
export default class LaunchConfig {
  constructor(optionalOverride = {}) {
    autoBind(this);
    //default configs
    this.vaultAlias = this._fallbackWhenUndefined(optionalOverride.vaultAlias, null);
    this.appType = this._fallbackWhenUndefined(optionalOverride.appType, Globals.LaunchConfig_AppType.VAULT);
    this.hideAppHeader = this._fallbackWhenUndefined(optionalOverride.hideAppHeader, false);
    this.session = this._fallbackWhenUndefined(optionalOverride.session, null);
    this.hideSupport = this._fallbackWhenUndefined(optionalOverride.hideSupport, true);
    //docket specific default configs
    this.docketID = this._fallbackWhenUndefined(optionalOverride.docketID, null);
    this.showDocketHeader = this._fallbackWhenUndefined(optionalOverride.showDocketHeader, true);
    this.showViewType = this._fallbackWhenUndefined(optionalOverride.showViewType, true);
    this.viewType = this._fallbackWhenUndefined(optionalOverride.viewType, null);
    this.allowUpload = this._fallbackWhenUndefined(optionalOverride.allowUpload, true);
    this.allowDeletion = this._fallbackWhenUndefined(optionalOverride.allowDeletion, true);
    this.showMetadata = this._fallbackWhenUndefined(optionalOverride.showMetadata, true);
    this.allowMetadataEditing = this._fallbackWhenUndefined(optionalOverride.allowMetadataEditing, true);
  }
  _fallbackWhenUndefined(value, fallback) {
    if (value === undefined) return fallback;
    return value;
  }
}
