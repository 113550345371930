import React from 'react';
import autoBind from 'react-autobind';
import { Divider, Icon, Layout, Row, Col, Typography, Button } from 'antd';
//
import CustomComponent from '../../../ui-components/CustomComponent';
import CommonOperationsProgressBar from '../../commonComponents/Operations/CommonOperationsProgressBar';
import CommonOperationsTags from '../../commonComponents/Operations/CommonOperationsTags';
import CommonUploadFilesTable from '../../commonComponents/CommonUploadFilesTable';
import CommonConfirmationModal from '../../commonComponents/Modals/CommonConfirmationModal';
//
import Utils from '../../../components/helpers/Utils';
//Style
import '../../../assets/stylesheets/CommonUploadNavigationView.scss';
//props are: app, files, docket
export default class CommonUploadNavigationView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
    //Initialize operation on the operation controller
    this.props.app.operationsController.newUploadOperationView(props.files, props.docket,
      this.handleOperationRestart, this.handleOperationUpdate, this.handleOperationClick,
      (op) => { this.operation = op }
    );
  }
  //Life Cycle
  componentWillUnmount() { this.operation?.close(); }
  navigationControllerDidShowView() { this.operation?.start(); }
  tabViewDidAppear() { if (this.operation) this.operation.isParentVisible = true; }
  tabViewWillDisappear() { if (this.operation) this.operation.isParentVisible = false; }
  //Actions
  handleCancel() { this.operation?.cancel(); }
  async handleUploadMoreFiles() {
    if (this.operation?.isUnmounting) return;
    //If failed files, ask for confirmation
    const failedFiles = this.operation?.failedFiles;
    if (failedFiles && failedFiles.length > 0) {
      if (!(await this.confirmationModal.show('Attention!', 'You’re sure you want to start a new upload? All failed files will not be uploaded.', true))) return;
    }
    //
    if (this.operation) this.operation.isUnmounting = true;
    this.props.navigationController.popToRootView();
  }
  handleRetryFailed() { this.operation?.retryFailedFiles(); }
    //Drag and Drop
  handleDragFile(dndEvent) {
    if (this.operation?.isUploadCompleted) {
      if (dndEvent.approvals.length > 0) this.props.navigationController.popView({ popProps: { files: dndEvent.approvals } });
    } else {
      this.warningModal.show('Operation in progress', `You are not allowed to append files while uploading. Please, wait until the upload has completed!`);
    }
  }
  handleAcceptDrag() { return this.operation?.isUploadCompleted; }
    //Operation
  handleOperationUpdate() { this.forceUpdate(); }
  handleOperationRestart() { this.props.navigationController.popToRootView(); }
  handleOperationClick() { this.props.controller.selectCurrentTab(); }
  //UI
  render() {
    //
    const progress = this.operation?.totalUploadProgressPercentage || 0;
    return (
      <Layout.Content className="commonTabContent inUploadContent">
        {/* Modals */}
        {this._renderConfirmationModal()}
        {this._renderWarningModal()}
        {/* Upload components (In Upload) */}
        {this._renderProgressSections(progress)}
        {this._renderFilesTable()}
        <Row type="flex" justify="end" className="uploadProgressButtonsContainer">
          {(!this.operation || !this.operation?.isUploadCompleted) && (
            <Button type="secondary" className="cancelButton" onClick={this.handleCancel}>Cancel Upload</Button>
          )}
        </Row>
        {/* Upload components (after upload*/}
        {this._renderResult()}
      </Layout.Content>
    );
  }

  /* Private UI */
  _renderProgressSections(progress) {
    if (this.operation && this.operation?.isUploadCompleted) return <></>;
    //
    return (
      <>
        <Row type='flex' justify='space-between' align='middle'>
          <Col>
            <Row className="uploadTitleContainer">
              <Col>
                <Typography.Text className="uploadTitleMain">Uploading file(s) to</Typography.Text>
                <Typography.Text className="uploadTitleSub">{this.props.docket.name}</Typography.Text>
              </Col>
            </Row>
            <Row className="uploadSubtitleContainer">
              <Typography.Text className="uploadSubtitleMain"> Please wait while the documents are being uploaded </Typography.Text>
            </Row>
          </Col>
          {this._renderTags()}
        </Row>
        {this._renderProgressBar(progress)}
      </>
    );
  }
  _renderTags() {
    return (
      <CommonOperationsTags
        totalText={`Total Files: ${this.props.files?.length || 0}`}
        inprogress={(this.props.files?.length || 0) - (Object.values(this.operation?.filesStatuses || {}).length || 0)}
        inprogressText={'Enqueued uploads'}
        success={Object.values(this.operation?.filesStatuses || {}).filter((s) => s.statusCode == 200 || s.statusCode == 204).length || 0}
        successText={'Completed uploads'}
        failures={this.operation?.failedFiles?.length || 0}
        failuresText={'Failed uploads'}
        isAutoRetryInProgress={this.operation?.isAutoRetryInProgress}
        retryingText={'Auto Retrying'}
      />
    );
  }
  _renderProgressBar(progress) {
    let progressLabel = progress == 0 ? 'Please wait...' : `${progress}%`;
    if (!this.operation?.totalUploadBytes) progressLabel = 'Preparing files to be uploaded...';
    return (
      <CommonOperationsProgressBar progress={progress} progressLabel={progressLabel} />
    )
  }
  //filteredStatus - undefined (no filter), filteredStatus - true (filter success only), filteredStatus - false (filter failed only)
  _renderFilesTable(filteredStatus) {
    if (filteredStatus == undefined && this.operation?.isUploadCompleted) return <></>;
    return (
      <Row className="uploadProgressTableContainer">
        <CommonUploadFilesTable statuses={this.operation?.filesStatuses} progress={this.operation?.totalUploadedBytes}
          app={this.props.app} willAutoRetry={!this.operation?.isAutoRetryInProgress && !this.operation?.isUploadCompleted}
          files={this.props.files.filter((f) => {
            if (filteredStatus == undefined) return true;
            else if (filteredStatus == false) {
              if (this.operation?.filesStatuses [f.operationID] && !(this.operation?.filesStatuses [f.operationID]?.status == 200 || this.operation?.filesStatuses [f.operationID]?.statusCode == 200 || this.operation?.filesStatuses [f.operationID]?.statusCode == 204)) return true;
            } else if (this.operation?.filesStatuses [f.operationID] && (this.operation?.filesStatuses [f.operationID]?.status == 200 || this.operation?.filesStatuses [f.operationID]?.statusCode == 200 || this.operation?.filesStatuses [f.operationID]?.statusCode == 204)) return true;
            return false;
          })}
        />
      </Row>
    );
  }
  _renderResult() {
    if (!this.operation?.isUploadCompleted || this.operation?.isUnmounting) return null;
    const failedFiles = this.operation?.failedFiles;
    const hasFailedFiles = (failedFiles && failedFiles.length > 0);
    return (
      <Row className="uploadResultContainer">
        <Row type="flex" justify="center" align="middle" className={hasFailedFiles ? "uploadResultFailedUpper" : "uploadResultSuccessUpper"}>
          <Icon type={hasFailedFiles ? "warning" : "check-circle"} theme="filled" />
          <Typography.Text>Upload completed!</Typography.Text>
        </Row>
        {hasFailedFiles && <Row type="flex" justify="center" align="middle" className={hasFailedFiles ? "uploadResultFailedUpper" : "uploadResultSuccessUpper"}>
          <span className='subtitle'> ({failedFiles.length} of {this.props.files.length} files failed!)</span>
        </Row>}
        {/* Success files */}
        {hasFailedFiles && <>
          <Divider> <Icon type="close-circle" theme="filled" /> Failed ({(failedFiles || []).length} files)</Divider>
          {this._renderFilesTable(false)}
          <Row type="flex" justify="center" align="middle">
            <Button type='primary' onClick={this.handleRetryFailed}>Retry All</Button>
          </Row>
        </>}
        {/* Success files */}
        {hasFailedFiles && <Divider> <Icon type="check-circle" theme="filled" /> Completed ({this.props.files.length - (failedFiles || []).length} files) </Divider>}
        {this._renderFilesTable(true)}
        {/*  */}
        <Row type="flex" justify="center" align="middle">
          <Button type={hasFailedFiles ? 'secondary' : 'primary'} onClick={this.handleUploadMoreFiles}> Done </Button>
        </Row>
      </Row>
    );
  }
  /* Modals */
  _renderWarningModal() {
    return (<CommonConfirmationModal confirmationText="" hideCancel {...Utils.propagateRef(this, 'warningModal')} />);
  }
  _renderConfirmationModal() {
    return (<CommonConfirmationModal confirmationText="Yes" cancellationText="No" {...Utils.propagateRef(this, 'confirmationModal')} />);
  }
}
