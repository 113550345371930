import React from 'react';
import autoBind from 'react-autobind';
//Components
import { Form, Row, Col, Modal, Input, Select, Popover, Icon, Table } from 'antd';
import Globals from '../../../config/Globals';

//
export default class CommonInviteModal extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  preventDefault(e) {
    e.preventDefault();
  }

  async createInvitation() {
    const resp = await this.props.form.validateFields(['invitationCreate.email', 'invitationCreate.role']);
    if (resp) {
      this.props.app.analytics.logEvent('Vault', 'InvitationCreated');
      this.props.datasource.createInvitation(this.props.form.getFieldsValue().invitationCreate);
    }
  }

  _renderCreateInvitationModal() {
    const account = this.props.app.sharedCache().getCurrentVaultAccount();
    return (
      <Modal
        title="Invite Users"
        visible={this.props.datasource.state.showCreateInvitation}
        onOk={this.createInvitation}
        width={600}
        okText="Submit"
        confirmLoading={this.props.datasource.state.confirmLoading}
        onCancel={this.props.datasource.handleCancelModalInvitation}
        center
      >
        <Row>
          <Col span={24}>
            <Form colon={false} onSubmit={this.preventDefault} className="createInvitation">
              {account.limits.userRolesEnabled && (
                <Form.Item label={(
                  <>
                    <span>Role</span>
                    <Popover
                      content={(
                        <Table
                          rowKey="featureName"
                          size="small"
                          pagination={false}
                          columns={[
                            { title: '', dataIndex: 'featureName', align: 'right' },
                            ...Object.entries(Globals.AvailableRegistrationRoles).map(([key]) => ({
                              title: key,
                              dataIndex: key,
                              render: hasPermission => (
                                hasPermission
                                  ? <Icon type="check-circle" theme="filled" style={{ color: '#00D06C', fontSize: 18 }} />
                                  : <Icon type="close-circle" theme="filled" style={{ color: '#E00000', fontSize: 18 }} />
                              ),
                              align: 'center',
                            })),
                          ]}
                          dataSource={[
                            { featureName: 'Search documents', MANAGER: true, EDITOR: true, CONTRIBUTOR: true, MEMBER: true },
                            { featureName: 'Download documents', MANAGER: true, EDITOR: true, CONTRIBUTOR: true, MEMBER: true },
                            { featureName: 'Upload documents', MANAGER: true, EDITOR: true, CONTRIBUTOR: true, MEMBER: false },
                            { featureName: 'Remove documents', MANAGER: true, EDITOR: true, CONTRIBUTOR: false, MEMBER: false },
                            { featureName: 'Manage users', MANAGER: true, EDITOR: false, CONTRIBUTOR: false, MEMBER: false },
                            { featureName: 'Manage vaults', MANAGER: true, EDITOR: false, CONTRIBUTOR: false, MEMBER: false },
                          ]}
                        />
                      )}
                    >
                      <Icon type="question-circle" style={{ marginLeft: 8, marginTop: 11 }} />
                    </Popover>
                  </>
                )}>
                  {this._renderInput('invitationCreate.role', {
                    rules: [{ required: true, message: 'Please, select a role for the user!' }],
                    initialValue: account.limits.userRolesEnabled ? Globals.Vault_Roles.CONTRIBUTOR : Globals.Vault_Roles.MEMBER
                  })(
                    <Select style={{ width: '100%' }}>
                      {Object.keys(Globals.AvailableRegistrationRoles).map((key) => (
                        <Select.Option value={key} key={key}>
                          {Globals.AvailableRegistrationRoles[key]} - {Globals.AvailableRegistrationRolesDescriptions[key]}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              )}

              <Form.Item label="Emails" help="Separate emails by comma.">
                {this._renderInput('invitationCreate.email', {
                  rules: [{ required: true, message: 'Please, insert a email for the invitation!' }],
                })(<Input.TextArea placeholder="Emails" style={{ width: '100%' }} />)}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    );
  }

  //UI
  render() {
    return <>{this._renderCreateInvitationModal()}</>;
  }

  //Helper
  _renderInput(val, opts) {
    const { getFieldDecorator } = this.props.form;
    opts.initialValue = this.props.datasource.state[val] ? this.props.datasource.state[val] : opts.initialValue;
    return getFieldDecorator(val, opts);
  }
}
