// import URLQuery from 'query-string-manipulator';
// //
// import config from '../../config/config';
// import Globals from '../../config/Globals';
import autoBind from 'react-autobind';
//
import LaunchConfig from './LaunchConfig';
//
export default class LaunchConfigManager {
  constructor(ipcManager) {
    autoBind(this);
    this.ipcManager = ipcManager;
    this.launchConfig = null;
    //delegate
    this.launchConfigDidBecomeAvailable = null;
  }
  /* Public */
    //Initial load
  detectConfig() { this._detectHostApp(); }
    //Operation
  setLaunchConfig(launchConfig) { this._loadLaunchConfig(launchConfig); }
    //Getters
  isAvailable() { return !!this.launchConfig; }
  getFileTransferIPC() {
    if (this.ipcManager.isDesktopHost()) return this.ipcManager.electron;
    return null;
  }
  /* Private */
  _detectHostApp() {
    if (this.ipcManager.isDesktopHost()) {
      this._loadLaunchConfig(); //default until further changes
    } else if (this.ipcManager.isMobileHost()) {
      //Wait mobile IPC to setLaunchConfig -- Nothing to do here
    } else if (this.ipcManager.isLightningHost()) {
      //Wait lightining IPC to setLaunchConfig -- Nothing to do here
    } else { //web
      this._loadLaunchConfig(); //init default
    }
  }

  _loadLaunchConfig(optionalConfig) {
    this.launchConfig = new LaunchConfig(optionalConfig);
    if (this.launchConfigDidBecomeAvailable) this.launchConfigDidBecomeAvailable();
  }
}
