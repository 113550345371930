import React from 'react';
import autoBind from 'react-autobind';
//
import CustomComponent from '../../../ui-components/CustomComponent';
//
import CommonCreateDocket from "../DocketStack/CommonCreateDocket";
//
import '../../../assets/stylesheets/CommonCreateDocketController.scss';
// Props: app, appendFiles, callbackContext (optional)
export default class CommonCreateDocketNavigationView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  //Actions
  handleCreateNewDocket(docket, docketID) {
    this.props.navigationController.popView({
      popProps: {
        ... (this.props.callbackContext || {}),
        selectedDocket: {
          ...docket,
          id: docketID,
          userID: this.props.app.idm.session.authorization.getUserID(),
          vaultID: this.props.app.sharedCache().getCurrentVaultID(),
        }
      },
    });
  }
  _createDocketCancel() {
    this.props.navigationController.popView({
      popProps: { selectedDocket: null },
    });
  }
  //Drag and drop
  handleDragFile(dndEvent, docket) {
    if (dndEvent.approvals.length > 0) {
      const {files, selectedDocket} = this.props.appendFiles(dndEvent.approvals, docket);
      this.props.navigationController.popView({
        popProps: { ...(this.props.callbackContext || {}), selectedDocket, files },
      });
    }
  }

  // UI
  render() {
    return (
      <CommonCreateDocket
        app={this.props.app}
        onDocketCreated={this.handleCreateNewDocket}
        onCreateDocketCancel={this._createDocketCancel}
        showDocketAttributes
      />
    );
  }
}
