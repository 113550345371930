export default class ElectronIPCPacket {
    constructor(channel) {
        this.message = {};
        this.channel = channel;
    }
    /* proto */
    encodedMessage() {
        let encoded = null;
        try {
            if (this.message) { encoded = this.message; }
        } catch (e) {
            console.error(`Error while encoding IPC packet message! ${e}`);
        } return encoded;
    }
    decodeMessage(message) {
        let err = null;
        try {
            if (message) { this.message = message; }
        } catch (e) {
            err = new Error(e);
            console.error(`Error while decoding IPC packet message! ${e}`);
        } return err;
    }
    getChannel() { return this.channel; }
    /* message helpers */
    setMessage(object) { this.message = object; }
    appendMessage(object) {
        this.message = { ...this.message, ...object };
    }
}
