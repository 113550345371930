import React from 'react';
import autoBind from 'react-autobind';
import { Typography, Row, Col, Form, Input, Button } from 'antd';
//
import Utils from '../../../components/helpers/Utils';
//
import '../../../assets/stylesheets/CommonCreateDocketForm.scss';
import CommonTagSelect from '../Attributes/CommonTagSelect';
import CommonAttributesSubform from '../Attributes/CommonAttributesSubform';
//Props: app, onCreateDocketCancel, cancelText, showDocketAttributes
export default class CommonCreateDocketForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      disableButton: true
    };
  }

  preventDefault(e) { e.preventDefault(); }

  //Helper
  async validateFields() {
    const resp = await this.props.form.validateFields(['form.name', 'form.tags', 'form.notes']);
    if (!resp) return;
    if (this.props.showDocketAttributes) {
      const attrResp = await this.attributesForm.validate();
      if (!attrResp) return;
    }
    //Analytics
    this.props.app.analytics.logEvent('Docket', 'Created');
    //ask datasource to create docket
    this.props.datasource.createDocket({
      ...this.props.form.getFieldsValue().form,
      tags: this.tagsSelect.getSelectedTags(),
    });
  }

  verifyDisableButton(docketName) {
    if (docketName.length >= 3) {
      this.setState({ disableButton: false })
    } else {
      this.setState({ disableButton: true })
    }
  }

  // UI
  render() {
    const vaultAttributes = this.props.app.sharedCache()?.getCurrentVaultAttributes();
    const docketAttributes = vaultAttributes
      ? vaultAttributes.filter(attributes => attributes.enabledOnDockets || attributes.isMandatoryOnDockets)
      : [];
    return (
      <Form onSubmit={this.preventDefault}>
        <div className='CreateDocketForm-content'>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Typography.Text>Let’s start by giving it a name:</Typography.Text>
            </Col>
            <Col span={24}>
              <Form.Item>
                {this._renderInput('form.name', {
                  rules: [
                    { required: true, message: 'Please, insert a name for the docket!' },
                    { min: 3, message: 'Docket names must be at least 3 characters!' }
                  ],
                })(<Input
                      placeholder="Docket's Name"
                      onChange={(e) => this.verifyDisableButton(e.target.value)}
                      />)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Typography.Text>Tags:</Typography.Text>
            </Col>
            <Col span={24}>
              <Form.Item>
                <CommonTagSelect app={this.props.app} {...Utils.propagateRef(this, 'tagsSelect')} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Typography.Text>Add some notes to your docket:</Typography.Text>
            </Col>
            <Col span={24}>
              <Form.Item>
                {this._renderInput('form.notes', {
                  initialValue: '',
                })(<Input.TextArea placeholder="Your notes here" maxLength="300" />)}
              </Form.Item>
            </Col>
            {this.props.showDocketAttributes &&
            <Col span={24}>
              {docketAttributes && docketAttributes.length > 0 && (
                <Typography.Text>Now let’s add custom attributes to it:</Typography.Text>
              )}
            </Col>
            }
          </Row>
          {this.props.showDocketAttributes &&
          <CommonAttributesSubform editable app={this.props.app} form={this.props.form}
                                   isDocument={false} datasource={this.props.datasource}
                                   {...Utils.propagateRef(this, 'attributesForm')}/>
          }
        </div>
        <Row type="flex" justify="end" gutter={16}>
          <Col>
            <Button onClick={this.props.onCreateDocketCancel}>{this.props.cancelText || "Cancel"}</Button>
          </Col>
          <Col>
            <Button
              type="primary"
              loading={this.props.datasource.state.isLoading}
              onClick={this.validateFields}
              disabled={this.state.disableButton}
            >
              Create
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  //Helper
  _renderInput(val, opts) {
    const { getFieldDecorator } = this.props.form;
    opts.initialValue = this.props.datasource.state[val] ? this.props.datasource.state[val] : opts.initialValue;
    return getFieldDecorator(val, opts);
  }
}
