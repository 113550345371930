import React from 'react';
import autoBind from 'react-autobind';
//Components
import { Form, Card, Row, Col, Divider, Button, Input } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import config from '../../config/config';
import Utils from '../../components/helpers/Utils';
//
// import Globals from '../../config/Globals';
import config from '../../config/config';
import CustomAttributesTable from './AdminVaultSettingsView/CustomAttributesTable';
import CustomAttributesFormModal from './AdminVaultSettingsView/CustomAttributesFormModal';
//
export default class AdminVaultSettingsForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      payload: [],
      aliasCopied: false
    };
  }
  //Actions
  handleSubmit(e) { e.preventDefault(); }
  //UI
  render() {
    const defaultInputOptions = {};
    return (
      <Card className="userProfileFormContainer">
        <Form colon={false} onSubmit={this.handleSubmit} className="userProfileForm">
          {this._renderSettingsSection(defaultInputOptions)}
          {this._renderCustomAttributeSection()}
        </Form>
      </Card>
    );
  }

  //Actions
  handleAdd() {
    this.customAttributesFormModal.open();
  }

  handleEdit(customAttribute) {
    this.customAttributesFormModal.open(customAttribute);
  }

  handleDelete(customAttribute) {
    console.log({customAttribute})
    alert('Not implemented');
  }

  handleCustomAttributesFormSave(customAttribute) {
    return this.props.handleCustomAttributesFormSave(customAttribute);
  }

  handleFormCancel() {
    console.log('handleFormCancel');
    this.setState({ payload: [] });
  }

  //Sections
  _renderSettingsSection(defaultInputOptions) {
    const account = this.props.app.sharedCache().getCurrentVaultAccount();

    return (
      <>
        <Row>
          {/* <Col span={4} offset={1}>
            <UploadLogo app={this.props.app} vaultID={this.props.app.sharedCache().getCurrentVaultID()}
              size={200} className="uploadLogo"/>
          </Col> */}
          <Row type="flex" gutter={24}>
            <Col xs={24} sm={24} md={10}>
              <Form.Item label="Name">
                {this._renderInput('currVault.name', {
                  ...defaultInputOptions,
                  rules: [{ required: true, message: 'Please, type the vault name!' }],
                })(<Input id="name" name="name" maxLength={20} />)}
              </Form.Item>
            </Col>
            <Col xs={22} sm={22} md={10}>
              <Form.Item label={
                  <>
                    <span style={{ marginRight: 6 }}>Custom URL</span>
                    {!account.limits.brandingEnabled && <strong className="paid-feature-flag">
                      <em>paid feature</em>
                    </strong>}
                  </>
                }
              >
                {this._renderInput('currVault.alias', {
                  ...defaultInputOptions, rules: [
                    { required: true, message: 'Please, type the vault alias!' },
                    { max: 63, message: 'Maximum of 63 characters are allowed!' },
                    { validator: (rule, value, callback) => {
                      if(value.indexOf('-') == 0 || value.slice(-1) == '-') callback("Invalid alias. Sub-domains can't start or end with dashes!");
                      else callback();
                    } },
                    { validator: (rule, value, callback) => {
                      const regExpDns = new RegExp("^[a-zA-Z0-9-]*$");
                      if(!regExpDns.test(value)) callback('Invalid alias. Please, use only digits, letters and dashes!');
                      else callback();
                    } }
                  ]
                })(
                  <Input id="alias" name="alias" disabled={!account.limits.brandingEnabled}
                         suffix={<div style={{ paddingLeft: 8, background: '#fff' }}>.{config.ApplicationDomain}</div>} />
                )}
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label=" " colon={false}>
                <CopyToClipboard onCopy={() => this.setState({ aliasCopied: true })}
                  text={this.props.app.vaultManager.getRedirectVaultURL(this.props.alias)}>
                  <Button icon="copy" style={{ marginLeft: 8 }} />
                </CopyToClipboard>
              </Form.Item>
            </Col>
            {/* <Col span={5}>
              <Form.Item
                label={
                  <>
                    <span style={{ marginRight: 6 }}>Theme</span>
                    {!account.limits.brandingEnabled && <strong className="paid-feature-flag">
                      <em>paid feature</em>
                    </strong>}
                  </>
                }
              >
                {this._renderInput('currVault.colorSchema', { ...defaultInputOptions })(
                  <Select disabled={!account.limits.brandingEnabled}>
                    {Object.keys(Globals.VaultSettings_AvailableColorsSchema).map((item, index) => (
                      <Select.Option value={item} key={index}>
                        {Globals.VaultSettings_AvailableColorsSchema[item]}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col> */}
          </Row>
          <Row type="flex" gutter={24}>
            <Col xs={24} sm={4} md={4}>
              <Form.Item label="Vault size">{Utils.formatSizeUnits(this.props.datasource?.state?.currVault?.vaultSize || 0)}</Form.Item>
            </Col>
            <Col xs={12} sm={4} md={4}>
              <Form.Item label="Documents">{this.props.datasource?.state?.currVault?.vaultNumDocuments || 0}</Form.Item>
            </Col>
            <Col xs={12} sm={4} md={4}>
              <Form.Item label="Dockets">{this.props.datasource?.state?.currVault?.vaultNumDockets || 0}</Form.Item>
            </Col>
          </Row>
          {/* <Row type='flex'>
            <Col span={6} offset={2}>
              <Form.Item label="Permanently remove deleted objects after X days">
                {this._renderInput('currVault.hardDeleteAfter', {
                  initialValue: 14, step: 1, min: 1, max: 60,
                  rules: [
                    { required: true, message: 'Please, type the number of days document and dockets should be permanently deleted after flagging for deletion!' },
                  ],
                })(<InputNumber id="hardDeleteAfter" name="hardDeleteAfter" />)}
              </Form.Item>
            </Col>
          </Row> */}

        </Row>
      </>
    );
  }

  _renderCustomAttributeSection() {
    const account = this.props.app.sharedCache().getCurrentVaultAccount();

    return (
      <>
        <Divider orientation="left">
          <span style={{ marginRight: 6 }}>Custom Attributes</span>
          {!account.customAttributesEnabled && <strong className="paid-feature-flag">
            <em>paid feature</em>
          </strong>}
        </Divider>
        {account.customAttributesEnabled && <Row type="flex" justify="end">
          <Col span={2}>
            <Button
              onClick={this.handleAdd}
              type="primary"
              disabled={account.limits.currentCustAttr >= account.limits.maxCustAttr}
              style={{ marginBottom: 16 }}
            >
              Add
            </Button>
          </Col>
        </Row>}
        <Row>
          <Col span={22} offset={1} style={{ position: 'relative' }}>
            <CustomAttributesTable
              data={this.props.datasource.state.vaultAttributes}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
            />
          </Col>
        </Row>

        <CustomAttributesFormModal
          wrappedComponentRef={(ref) => { this.customAttributesFormModal = ref; }}
          onSubmit={this.handleCustomAttributesFormSave}
          currentAttributes={this.props.datasource.state.vaultAttributes}
        />
      </>
    );
  }

  //Helper
  _renderInput(val, opts) {
    const { getFieldDecorator } = this.props.form;
    const readState = this.props.datasource.state ? this.props.datasource.state : {};
    opts.initialValue = Utils.getNestedObject(readState, val);
    if (opts.initialValue === 0) opts.initialValue = 60;
    return getFieldDecorator(val, opts);
  }
}
